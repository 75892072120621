import {ROUTES} from "@core/api/routes";
import SelectField from "@core/components/FormikSelect";
import NormAutocomplete from "@core/components/NormAutocomplete";
import {ACTIONS} from "@core/constants/api";
import {useFetchedCertificateNorms} from "@core/hooks/useFetchedCertificateNorms";
import axios from "axios";
import {Formik} from "formik";
import React, {useMemo} from "react";
import moment from "moment";
import {observer} from "mobx-react";
import {api} from "Config";
import classNames from "classnames";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import {
  Table,
  Tooltip,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  MenuItem
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {TAGS} from "@core/constants/tags";
import {DATE_FORMAT} from "@core/constants/dateFormats";
import {Date, MultipleSelect} from "@core/components/Form";
import styles from "./styles";
import useStores from "../../../../useStores";

const TestInformation = observer(({classes}) => {
  const {TestStore, ConditionStore} = useStores();

  const {norms} = useFetchedCertificateNorms();

  const test = TestStore.test.data;
  const condition = ConditionStore.condition.data;

  const initialValues = {
    norm: test.norm ?? test.properties.norm ?? "",
    grade: test.grade ?? test.properties.grade ?? "",
    tags: test.properties.tags ?? [],
    testExecutionDate: test.properties.testExecutionDate ?? ""
  };

  const updateTest = async (changes) => {
    const {data} = await axios.patch(ROUTES.TEST[ACTIONS.UPDATE](test._id), {
      properties: {
        ...test.properties,
        ...changes
      }
    });

    TestStore.changeTest(data);
  };

  return (
    <Formik initialValues={initialValues} enableReinitialize>
      {({values}) => {
        const grades = useMemo(
          () => norms.filter((norm) => norm.Norm === values.norm),
          [values.norm, norms.length]
        );

        return (
          <>
            <h3>Test Information</h3>
            <Table className={classNames("styled-table", classes.table)}>
              <TableHead>
                <TableRow>
                  <TableCell>Customer</TableCell>
                  <TableCell>{test.company.name}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {test.internalWorkOrder && (
                  <TableRow>
                    <TableCell>Work Order</TableCell>
                    <TableCell>{test.internalWorkOrder}</TableCell>
                  </TableRow>
                )}
                {test.internalItemNumber && (
                  <TableRow>
                    <TableCell>Item No.</TableCell>
                    <TableCell>{test.internalItemNumber}</TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell>Heat</TableCell>
                  <TableCell>{test.item_heat}</TableCell>
                </TableRow>
                {test.lotId && (
                  <TableRow>
                    <TableCell>Lot ID</TableCell>
                    <TableCell>{test.lotId}</TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell>Material Specification</TableCell>
                  <TableCell>
                    <NormAutocomplete
                      required={false}
                      disabled={test.norm}
                      name="norm"
                      testType="certificate"
                      onChange={({norm}) => updateTest({norm})}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Grade/UNS</TableCell>
                  <TableCell>
                    <SelectField
                      disabled={test.grade || !values.norm}
                      name="grade"
                      onChange={(grade) => updateTest({grade})}
                    >
                      {grades.map((grade) => (
                        <MenuItem key={grade.Material} value={grade.Material}>
                          {grade.Material}
                        </MenuItem>
                      ))}
                    </SelectField>
                  </TableCell>
                </TableRow>
                {condition.name && (
                  <TableRow>
                    <TableCell>Condition</TableCell>
                    <TableCell>{condition.name}</TableCell>
                  </TableRow>
                )}
                {test.notes && (
                  <TableRow>
                    <TableCell>Notes</TableCell>
                    <TableCell>{test.notes}</TableCell>
                  </TableRow>
                )}
                {!!test.witnesses.length && (
                  <TableRow>
                    <TableCell>Witness</TableCell>
                    <TableCell>
                      {test.witnesses
                        .map((witness) => witness.company.name)
                        .join(", ")}
                    </TableCell>
                  </TableRow>
                )}
                {test.inspectorJobNumber && (
                  <TableRow>
                    <TableCell>Inspector Job Number</TableCell>
                    <TableCell>{test.inspectorJobNumber}</TableCell>
                  </TableRow>
                )}
                {(test.inspectionDate || test.inspectionWaivedDocument) && (
                  <TableRow>
                    <TableCell>Inspection Date</TableCell>
                    <TableCell>
                      {test.inspectionWaivedDocument ? (
                        <span>
                          <span className={classes.inspectionDocumentTitle}>
                            Inspection Waived
                          </span>
                          <Tooltip
                            title={`Click to view the document ${test.inspectionWaivedDocument}`}
                            placement="bottom"
                          >
                            <a
                              href={`${api}${test.inspectionWaivedDocument}`}
                              download
                              target="_blank"
                              className={classes.inspectionDocumentLink}
                            >
                              <CloudDownloadIcon color="primary" />
                            </a>
                          </Tooltip>
                        </span>
                      ) : (
                        moment(test.inspectionDate).format(DATE_FORMAT) || "-"
                      )}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell width="50%">Tags</TableCell>
                  <TableCell width="50%">
                    <div className={classes.tags}>
                      <MultipleSelect
                        value={test.properties.tags || []}
                        elements={TAGS}
                        onChange={(values) => updateTest({tags: values})}
                      />
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="50%">Test execution date</TableCell>
                  <TableCell width="50%">
                    <Date
                      format={DATE_FORMAT}
                      type="date"
                      value={test.properties?.testExecutionDate}
                      onChange={(event) =>
                        updateTest({testExecutionDate: event.target.value})
                      }
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </>
        );
      }}
    </Formik>
  );
});

export default withStyles(TestInformation, styles);
