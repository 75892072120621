import React from "react";
import {Grid, Typography, Table, TableHead, TableRow, TableCell, TableBody, Button} from "@mui/material";
import {Link} from "react-router-dom";
import CollapsibleSection from "../../../../../components/CollapsibleSection";

const PendingActions = () => {
  return (
    <Grid item xs={12}>
      <Typography variant="h6" marginBottom={1}>
        Pending actions / Needs attention
      </Typography>
      <CollapsibleSection
        title={(
          <Typography>
            {"Tests pending approval (1)"}
          </Typography>
        )}
      >
        <Table className="styled-table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Approve Chemical Composition</TableCell>
              <TableCell>
                <Button
                  component={Link}
                  to={"/certificates/"}
                >
                  Approve
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CollapsibleSection>
      <CollapsibleSection
        title={(
          <Typography>
            {"Non-conforming tests (1)"}
          </Typography>
        )}
      >
        <Table className="styled-table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Chemical Composition</TableCell>
              <TableCell>
                <Button
                  component={Link}
                  to={"/certificates/"}
                >
                  View
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CollapsibleSection>
    </Grid>
  );
};

export default PendingActions;