import React, {useEffect, useState} from "react";
import {Button, Step, StepContent, StepLabel, Stepper, Typography} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {any, partition} from "ramda";
import {observer} from "mobx-react-lite";
import {useHistory} from "react-router-dom";
import {STATUSES} from "@core/constants/test";
import {WITNESS_STATUSES} from "@core/constants/witnessStatuses";
import WS from "@core/api/socketConnection";
import {TestService} from "@core/services";
import MODULES from "@core/constants/modules";
import MultipleSigningChoose from "./components/MultipleSigningChoose";
import MultipleSigningSubmit from "./components/MultipleSigningSubmit";
import useStores from "../../../useStores";
import styles from "./styles";

const MultipleSigningStepper = observer(({classes}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [certificateByTestId, setCertificateByTestId] = useState({});
  const {UserStore, SigningStore, NotificationStore, TestStore} = useStores();
  const {selected} = TestStore.tests;

  const history = useHistory();

  const steps = ["Select Tests To Sign", "Sign Selected Tests"];

  const [module] = UserStore.user.data.company.modules;

  useEffect(() => {
    WS.listen("transaction:test:submit", (res) => {
      SigningStore.closeSigner();

      if (res.status === "error") {
        NotificationStore.showError("Something went wrong!");

        return;
      }

      NotificationStore.showSuccess("Successfully submitted!");
      history.go(0);

    });

    WS.listen("transaction:test:witness", (res) => {
      SigningStore.closeSigner();

      if (res.status === "DECLINED") {
        NotificationStore.showError("Something went wrong!");

        return;
      }

      NotificationStore.showSuccess("Successfully witnessed!");
      history.go(0);
    });

    WS.listen("transaction:test:approve", (res) => {
      SigningStore.closeSigner();

      if (res.status === "DECLINED") {
        NotificationStore.showError("Something went wrong!");

        return;
      }

      NotificationStore.showSuccess("Successfully witnessed!");
      history.go(0);
    });

    WS.listen("transaction:test:witnessRequest", (res) => {
      SigningStore.closeSigner();

      if (res.status === "DECLINED") {
        NotificationStore.showError("Something went wrong!");

        return;
      }

      NotificationStore.showSuccess("Successfully witnessed!");
      history.go(0);
    });

    return () => {
      WS.remove("transaction:test:submit");
      WS.remove("transaction:test:witness");
      WS.remove("transaction:test:approve");
      WS.remove("transaction:test:witnessRequest");
    };
  }, []);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <MultipleSigningChoose
        handleStep={handleStep}
      />;

      case 1:
        return <MultipleSigningSubmit
        handleBack={handleBack}
        handleSubmit={handleSubmit}
        certificateByTestId={certificateByTestId}
        setCertificateByTestId={setCertificateByTestId}
      />;

      default:
        return (
          <>
          <Typography>Unknown step</Typography>
          <Button onClick={() => this.handleReset}>reset</Button>
          </>
        );
    }
  };

  const handleStep = (name) => {
    switch (name) {
      case "Select Tests To Sign":
        setActiveStep(activeStep + 1);
        break;
      case "Sign Selected Tests":
        setActiveStep(activeStep + 1);
        break;
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const submitTests = () => {
    const [testsToSubmit, testsToCreate] = partition((test) => test.assignee._id !== test.company._id, selected);

    if(testsToSubmit.length) TestService.submit(testsToSubmit, UserStore.user.data._id);

    if(testsToCreate.length) TestService.create(testsToCreate, UserStore.user.data._id, UserStore.user.data.company._id);
  };

  const attestTests = (selected) => {
    const reviewedTests = selected.filter((test) => any((witness) => witness.status !== WITNESS_STATUSES.UNSET, test.witnesses));

    if (!reviewedTests.length) {
      NotificationStore.showError("At least one test has to be witnessed!");

      return;
    }

    TestService.attestTests(reviewedTests, UserStore.user.data.company._id);
  };

  const signAsProducer = () => {
    const testsToAccept = selected.filter((test) => test.status === STATUSES.INSPECTED && !test.assignee);

    if (testsToAccept.length) {
      testsToAccept.forEach((test) => {
        TestStore.update({status: STATUSES.APPROVED}, test._id);
      });
    }

    TestService.multipleSignAsProducer(selected, certificateByTestId, UserStore.user.data._id);
  };

  const handleSubmit = (selected) => {
    if ([MODULES.END_OWNER, MODULES.PRODUCER].includes(module.name)) signAsProducer();

    if (module.name === MODULES.WITNESS) attestTests(selected);

    if (module.name === MODULES.LAB_INTERNAL) submitTests();
  };

  return (
    <div>
      <Stepper className={classes.stepper} activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              {getStepContent(index)}
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
});

export default withStyles(MultipleSigningStepper, styles);
