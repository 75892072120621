import {PO_ITEMS} from "@core/constants/poItems";
import {CONFIG} from "./big-constants/config";

const config = CONFIG;

const ACCEPTANCE_CRITERIA = {
  locationDependantValues: [
    {
      location: "Base",
      single: 80,
      average: 100,
      singleShearArea: 75,
      averageShearArea: 85
    },
    {
      location: "Weld",
      single: 38,
      average: 45
    },
    {
      location: "HAZ",
      single: 38,
      average: 45
    }
  ]
};

const TEST_TEMPERATURES_1 = [
  {
    temperature: 0
  },
  {
    temperature: -10
  },
  {
    temperature: -20
  }
];

const TEST_TEMPERATURES_2 = [
  {
    temperature: 0
  },
  {
    temperature: -10
  }
];

const exceptionsConfig = {
  "QCP ITP-01123-124277-BuBi MLP (NOA-BUT-00011)": {
    company: ["Tenaris-Siderca", "Tremblay Inc.", "Test Lab"],
    temperature: {
      value: "-28",
      data: {
        single: 80,
        average: 100,
        singleShearArea: 75,
        averageShearArea: 75
      }
    }
  },
  "QCP ITP-00923-124277 (NOA-BUT-00013) - HRB - MPQT (Impact curve)": {
    company: ["Tenaris-Siderca", "Tremblay Inc.", "Test Lab"],
    temperature: {
      impactTestCurve: true,
      data: {
        elements: [
          {
            temperature: 20
          },
          {
            temperature: 0
          },
          {
            temperature: -10
          },
          {
            temperature: -20
          },
          {
            temperature: -40
          },
          {
            temperature: -80
          }
        ],
        single: 80,
        average: 100,
        singleShearArea: 75,
        averageShearArea: 75
      }
    }
  },
  "QCP ITP-00923-124277 (NOA-BUT-00013) - HRB - MPQT (After reeling)": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    temperature: {
      value: "-10",
      data: {
        single: 38,
        average: 45
      }
    }
  },
  "QCP ITP-00923-124277 (NOA-BUT-00013) - HRB - MPQT": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    temperature: {
      value: "-10",
      data: {
        locationDependantValues: [
          {
            location: "Base",
            single: 80,
            average: 100,
            singleShearArea: 75,
            averageShearArea: 75
          },
          {
            location: "HAZ",
            single: 38,
            average: 45
          },
          {
            location: "Weld",
            single: 38,
            average: 45
          }
        ]
      }
    }
  },
  "QCP ITP-00923-124277 (NOA-BUT-00013) - HRB": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    temperature: {
      value: "-10",
      data: {
        locationDependantValues: [
          {
            location: "Base",
            single: 80,
            average: 100,
            singleShearArea: 75,
            averageShearArea: 75
          },
          {
            location: "HAZ",
            single: 38,
            average: 45
          },
          {
            location: "Weld",
            single: 38,
            average: 45
          }
        ]
      }
    }
  },
  "QCP CPW-ITP-21-23-HFW (YGG-COP-00005) - MPQT": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    temperature: {
      temperatureByPoItem: {
        [PO_ITEMS.PO_ITEM_10]: -20,
        [PO_ITEMS.PO_ITEM_20]: -20,
        [PO_ITEMS.PO_ITEM_30]: -10,
        [PO_ITEMS.PO_ITEM_40]: -10,
        [PO_ITEMS.PO_ITEM_50]: -10,
        [PO_ITEMS.PO_ITEM_60]: -10,
        [PO_ITEMS.PO_ITEM_70]: -21,
        [PO_ITEMS.PO_ITEM_80]: -21
      },
      data: {
        locationDependantValues: [
          {
            location: "Base",
            single: 80,
            average: 100,
            singleShearArea: 75,
            averageShearArea: 85
          },
          {
            location: "Weld",
            single: 38,
            average: 45
          },
          {
            location: "HAZ",
            single: 38,
            average: 45
          }
        ]
      }
    }
  },
  "QCP CPW-ITP-21-23-HFW (YGG-COP-00005) - MPQT (After reeling)": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    temperature: {
      temperatureByPoItem: {
        [PO_ITEMS.PO_ITEM_10]: -20,
        [PO_ITEMS.PO_ITEM_20]: -20,
        [PO_ITEMS.PO_ITEM_30]: -10,
        [PO_ITEMS.PO_ITEM_40]: -10,
        [PO_ITEMS.PO_ITEM_50]: -10,
        [PO_ITEMS.PO_ITEM_60]: -10,
        [PO_ITEMS.PO_ITEM_70]: -21,
        [PO_ITEMS.PO_ITEM_80]: -21
      },
      data: {
        locationDependantValues: [
          {
            location: "Base",
            single: 80,
            average: 100,
            singleShearArea: 75,
            averageShearArea: 85
          },
          {
            location: "Weld",
            single: 38,
            average: 45
          },
          {
            location: "HAZ",
            single: 38,
            average: 45
          }
        ]
      }
    }
  },
  "QCP CPW-ITP-21-23-HFW (YGG-COP-00005) - MPQT (Impact curve)": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    temperature: {
      impactTestCurve: true,
      data: {
        poItem: {
          [PO_ITEMS.PO_ITEM_10]: {
            ...ACCEPTANCE_CRITERIA,
            elements: TEST_TEMPERATURES_1
          },
          [PO_ITEMS.PO_ITEM_20]: {
            ...ACCEPTANCE_CRITERIA,
            elements: TEST_TEMPERATURES_1
          },
          [PO_ITEMS.PO_ITEM_30]: {
            ...ACCEPTANCE_CRITERIA,
            elements: TEST_TEMPERATURES_2
          },
          [PO_ITEMS.PO_ITEM_40]: {
            ...ACCEPTANCE_CRITERIA,
            elements: TEST_TEMPERATURES_2
          },
          [PO_ITEMS.PO_ITEM_50]: {
            ...ACCEPTANCE_CRITERIA,
            elements: TEST_TEMPERATURES_2
          },
          [PO_ITEMS.PO_ITEM_60]: {
            ...ACCEPTANCE_CRITERIA,
            elements: TEST_TEMPERATURES_2
          },
          [PO_ITEMS.PO_ITEM_70]: {
            ...ACCEPTANCE_CRITERIA,
            elements: TEST_TEMPERATURES_1
          },
          [PO_ITEMS.PO_ITEM_80]: {
            ...ACCEPTANCE_CRITERIA,
            elements: TEST_TEMPERATURES_1
          }
        },
        elements: [
          {
            temperature: -50
          },
          {
            temperature: -40
          },
          {
            temperature: -30
          },
          {
            temperature: -20
          },
          {
            temperature: -10
          },
          {
            temperature: 0
          }
        ]
      }
    }
  },
  "QCP CPW-ITP-21-23-HFW (YGG-COP-00005)": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    temperature: {
      temperatureByPoItem: {
        [PO_ITEMS.PO_ITEM_10]: -20,
        [PO_ITEMS.PO_ITEM_20]: -20,
        [PO_ITEMS.PO_ITEM_30]: -10,
        [PO_ITEMS.PO_ITEM_40]: -10,
        [PO_ITEMS.PO_ITEM_50]: -10,
        [PO_ITEMS.PO_ITEM_60]: -10,
        [PO_ITEMS.PO_ITEM_70]: -21,
        [PO_ITEMS.PO_ITEM_80]: -21
      },
      data: {
        locationDependantValues: [
          {
            location: "Base",
            single: 80,
            average: 100,
            singleShearArea: 75,
            averageShearArea: 85
          },
          {
            location: "Weld",
            single: 38,
            average: 45
          },
          {
            location: "HAZ",
            single: 38,
            average: 45
          }
        ]
      }
    }
  },
  "QCP 4500256818-HFU-QP-VSB (YGG-BUT-00064) - FDPT (Impact curve)": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    temperature: {
      impactTestCurve: true,
      data: {
        poItem: {
          [PO_ITEMS.PO_ITEM_11]: {
            elements: [
              {
                temperature: -29
              },
              {
                temperature: 0
              },
              {
                temperature: 20
              },
              {
                temperature: -60
              },
              {
                temperature: -50
              },
              {
                temperature: -39
              }
            ],
            singleShearArea: 85,
            averageShearArea: 85,
            single: 180,
            average: 200
          },
          [PO_ITEMS.PO_ITEM_11]: {
            elements: [
              {
                temperature: -39
              },
              {
                temperature: -29
              },
              {
                temperature: 0
              },
              {
                temperature: 20
              },
              {
                temperature: -60
              },
              {
                temperature: -50
              }
            ],
            singleShearArea: 85,
            averageShearArea: 85,
            single: 180,
            average: 200
          }
        },
        elements: [
          {
            temperature: -60
          },
          {
            temperature: -50
          },
          {
            temperature: -39
          },
          {
            temperature: -29
          },
          {
            temperature: 0
          },
          {
            temperature: 20
          }
        ]
      }
    }
  },
  "QCP 4500256818-HFU-QP-VSB (YGG-BUT-00064) - FDPT (After reeling)": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    temperature: {
      data: {
        single: 138,
        average: 145,
        singleShearArea: 0,
        averageShearArea: 0
      }
    }
  },
  "QCP 4500256818-HFU-QP-VSB (YGG-BUT-00064) - FDPT": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    temperature: {
      temperatureByPoItem: {
        [PO_ITEMS.PO_ITEM_11]: -29,
        [PO_ITEMS.PO_ITEM_20]: -39,
        [PO_ITEMS.PO_ITEM_21]: -39,
        [PO_ITEMS.PO_ITEM_30]: -39,
        [PO_ITEMS.PO_ITEM_31]: -39,
        [PO_ITEMS.PO_ITEM_40]: -39
      },
      data: {
        single: 180,
        average: 200,
        singleShearArea: 85,
        averageShearArea: 85
      }
    }
  },
  "QCP 4500256818-HFU-QP-VSB (YGG-BUT-00064)": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    temperature: {
      temperatureByPoItem: {
        [PO_ITEMS.PO_ITEM_11]: -29,
        [PO_ITEMS.PO_ITEM_20]: -39,
        [PO_ITEMS.PO_ITEM_21]: -39,
        [PO_ITEMS.PO_ITEM_30]: -39,
        [PO_ITEMS.PO_ITEM_31]: -39,
        [PO_ITEMS.PO_ITEM_40]: -39
      },
      data: {
        single: 180,
        average: 200,
        singleShearArea: 85,
        averageShearArea: 85
      }
    }
  },
  "QCP 9348 (YGG-TGS-00010 - GL Reelable)": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    temperature: {
      value: "-18",
      data: {
        single: 80,
        average: 100,
        singleShearArea: 75,
        averageShearArea: 75,
        orientation: "Transversal"
      }
    }
  },
  "QCP 9348 (YGG-TGS-00010 - GL Reelable) - FDPT": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    temperature: {
      value: "-18",
      data: {
        single: 80,
        average: 100,
        singleShearArea: 75,
        averageShearArea: 75
      }
    }
  },
  "QCP 9348 (YGG-TGS-00010 - GL Reelable) - FDPT (After reeling)": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    temperature: {
      temperatureByPoItem: {
        [PO_ITEMS.PO_ITEM_1]: -18,
        [PO_ITEMS.PO_ITEM_2]: -18
      },
      data: {
        single: 80,
        average: 100,
        singleShearArea: 0,
        averageShearArea: 0
      }
    }
  },
  "QCP 9348 (YGG-TGS-00010 - GL Reelable) - FDPT (Impact curve)": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    temperature: {
      impactTestCurve: true,
      data: {
        elements: [
          {
            temperature: -50
          },
          {
            temperature: -40
          },
          {
            temperature: -30
          },
          {
            temperature: -20
          },
          {
            temperature: -10,
            singleShearArea: 80,
            averageShearArea: 100,
            singleLateralExpansion: 75,
            averageLateralExpansion: 75
          },
          {
            temperature: 0,
            singleShearArea: 80,
            averageShearArea: 100,
            singleLateralExpansion: 75,
            averageLateralExpansion: 75
          }
        ]
      }
    }
  },
  "QCP 10378 (YGG-TGS-00045 - GL Pipe)": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    temperature: {
      value: "-18",
      data: {
        single: 80,
        average: 100,
        orientation: "Transversal",
        singleShearArea: 75,
        averageShearArea: 75
      }
    }
  },
  "QCP 7928 Rev. 2": {
    company: ["TenarisLab", "Tremblay Inc."],
    temperature: {
      value: "-29",
      data: {
        single: 60,
        average: 80,
        averageShearArea: 85
      }
    }
  },
  "Total exception": {
    temperature: {
      value: "-50",
      data: {
        orientation: "Transversal",
        single: 34,
        average: 54
      }
    }
  },
  "QCP 6916 Rev. 3": {
    company: ["TenarisLab", "Tremblay Inc."],
    temperature: {
      value: "-28",
      data: {
        single: 80,
        average: 120,
        singleLateralExpansion: 0,
        averageLateralExpansion: 0,
        singleShearArea: 60,
        averageShearArea: 85
      }
    }
  },
  "QCP 6916 Rev. 3 (After reeling)": {
    company: ["TenarisLab", "Tremblay Inc."],
    temperature: {
      value: "-28",
      data: {
        single: 80,
        average: 100,
        singleShearArea: 0,
        averageShearArea: 0
      }
    }
  },
  "QCP 5987 Rev. 2": {
    company: ["TenarisLab", "Tremblay Inc."],
    temperature: {
      value: -29,
      data: {
        elements: [
          {
            location: "Base",
            orientation: "Longitudinal",
            position: "1/2 t"
          },
          {
            location: "Base",
            orientation: "Transversal",
            position: "1/2 t"
          },
          {
            location: "Base",
            orientation: "Longitudinal",
            position: "2 mm from OD"
          },
          {
            location: "Base",
            orientation: "Transversal",
            position: "2 mm from OD"
          }
        ],
        single: 80,
        average: 120,
        singleLateralExpansion: 0,
        averageLateralExpansion: 0,
        singleShearArea: 60,
        averageShearArea: 60,
        averageShearAreaAllSpecimens: 85
      }
    }
  },
  "QCP 5987 Rev. 2 (Impact curve)": {
    company: ["TenarisLab", "Tremblay Inc."],
    temperature: {
      impactTestCurve: true,
      data: {
        elements: [
          {
            position: "1/2 t",
            location: "Base",
            orientation: "Transversal",
            temperature: -60
          },
          {
            position: "1/2 t",
            location: "Base",
            orientation: "Transversal",
            temperature: -50
          },
          {
            position: "1/2 t",
            location: "Base",
            orientation: "Transversal",
            temperature: -40
          },
          {
            position: "1/2 t",
            location: "Base",
            orientation: "Transversal",
            temperature: -29
          },
          {
            position: "1/2 t",
            location: "Base",
            orientation: "Transversal",
            temperature: -20
          },
          {
            position: "1/2 t",
            location: "Base",
            orientation: "Transversal",
            temperature: -10
          },
          {
            position: "1/2 t",
            location: "Base",
            orientation: "Transversal",
            temperature: 0
          }
        ],
        singleShearArea: 0,
        averageShearArea: 0,
        singleLateralExpansion: 0,
        averageLateralExpansion: 0
      }
    }
  },
  "QCP 5983 Rev. 5": {
    company: ["TenarisLab", "Tremblay Inc."],
    temperature: {
      temperatureByPoItem: {
        [PO_ITEMS.PO_ITEM_1]: -7,
        [PO_ITEMS.PO_ITEM_2]: -7,
        [PO_ITEMS.PO_ITEM_3]: -29,
        [PO_ITEMS.PO_ITEM_4]: -29
      },
      data: {
        elements: [
          {
            location: "Base",
            orientation: "Longitudinal",
            position: "1/2 t"
          },
          {
            location: "Base",
            orientation: "Transversal",
            position: "1/2 t"
          },
          {
            location: "Base",
            orientation: "Longitudinal",
            position: "2 mm from OD"
          },
          {
            location: "Base",
            orientation: "Transversal",
            position: "2 mm from OD"
          }
        ],
        single: 60,
        average: 70,
        singleLateralExpansion: 0,
        averageLateralExpansion: 0,
        singleShearArea: 60,
        averageShearArea: 85
      }
    }
  },
  "QCP 5983 Rev. 5 (Impact curve)": {
    company: ["TenarisLab", "Tremblay Inc."],
    temperature: {
      impactTestCurve: true,
      data: {
        poItem: {
          [PO_ITEMS.PO_ITEM_1]: {
            elements: [
              {
                position: "1/2 t",
                location: "Base",
                orientation: "Transversal",
                temperature: -60
              },
              {
                position: "1/2 t",
                location: "Base",
                orientation: "Transversal",
                temperature: -50
              },
              {
                position: "1/2 t",
                location: "Base",
                orientation: "Transversal",
                temperature: -40
              },
              {
                position: "1/2 t",
                location: "Base",
                orientation: "Transversal",
                temperature: -30
              },
              {
                position: "1/2 t",
                location: "Base",
                orientation: "Transversal",
                temperature: -20
              },
              {
                position: "1/2 t",
                location: "Base",
                orientation: "Transversal",
                temperature: -7
              },
              {
                position: "1/2 t",
                location: "Base",
                orientation: "Transversal",
                temperature: 0
              }
            ],
            singleShearArea: 0,
            averageShearArea: 0,
            singleLateralExpansion: 0,
            averageLateralExpansion: 0
          },
          [PO_ITEMS.PO_ITEM_2]: {
            elements: [
              {
                position: "1/2 t",
                location: "Base",
                orientation: "Transversal",
                temperature: -60
              },
              {
                position: "1/2 t",
                location: "Base",
                orientation: "Transversal",
                temperature: -50
              },
              {
                position: "1/2 t",
                location: "Base",
                orientation: "Transversal",
                temperature: -40
              },
              {
                position: "1/2 t",
                location: "Base",
                orientation: "Transversal",
                temperature: -30
              },
              {
                position: "1/2 t",
                location: "Base",
                orientation: "Transversal",
                temperature: -20
              },
              {
                position: "1/2 t",
                location: "Base",
                orientation: "Transversal",
                temperature: -7
              },
              {
                position: "1/2 t",
                location: "Base",
                orientation: "Transversal",
                temperature: 0
              }
            ],
            singleShearArea: 0,
            averageShearArea: 0,
            singleLateralExpansion: 0,
            averageLateralExpansion: 0
          },
          [PO_ITEMS.PO_ITEM_3]: {
            elements: [
              {
                position: "1/2 t",
                location: "Base",
                orientation: "Transversal",
                temperature: -60
              },
              {
                position: "1/2 t",
                location: "Base",
                orientation: "Transversal",
                temperature: -50
              },
              {
                position: "1/2 t",
                location: "Base",
                orientation: "Transversal",
                temperature: -40
              },
              {
                position: "1/2 t",
                location: "Base",
                orientation: "Transversal",
                temperature: -29
              },
              {
                position: "1/2 t",
                location: "Base",
                orientation: "Transversal",
                temperature: -20
              },
              {
                position: "1/2 t",
                location: "Base",
                orientation: "Transversal",
                temperature: -10
              },
              {
                position: "1/2 t",
                location: "Base",
                orientation: "Transversal",
                temperature: 0
              }
            ],
            singleShearArea: 0,
            averageShearArea: 0,
            singleLateralExpansion: 0,
            averageLateralExpansion: 0
          },
          [PO_ITEMS.PO_ITEM_4]: {
            elements: [
              {
                position: "1/2 t",
                location: "Base",
                orientation: "Transversal",
                temperature: -60
              },
              {
                position: "1/2 t",
                location: "Base",
                orientation: "Transversal",
                temperature: -50
              },
              {
                position: "1/2 t",
                location: "Base",
                orientation: "Transversal",
                temperature: -40
              },
              {
                position: "1/2 t",
                location: "Base",
                orientation: "Transversal",
                temperature: -29
              },
              {
                position: "1/2 t",
                location: "Base",
                orientation: "Transversal",
                temperature: -20
              },
              {
                position: "1/2 t",
                location: "Base",
                orientation: "Transversal",
                temperature: -10
              },
              {
                position: "1/2 t",
                location: "Base",
                orientation: "Transversal",
                temperature: 0
              }
            ],
            singleShearArea: 0,
            averageShearArea: 0,
            singleLateralExpansion: 0,
            averageLateralExpansion: 0
          }
        }
      }
    }
  },
  "HS-D-ITP-22-02-02 Rev. 5": {
    company: ["HusteelLab", "Tremblay Inc."],
    temperature: {
      value: "-18",
      data: {
        locationDependantValues: [
          {
            location: "Base",
            single: 40,
            average: 40,
            singleShearArea: 60,
            averageShearArea: 85
          },
          {
            location: "HAZ",
            single: 14,
            average: 14,
            singleShearArea: 20
          },
          {
            location: "Weld",
            single: 14,
            average: 14,
            singleShearArea: 20
          }
        ]
      }
    }
  },
  "HS-D-ITP-23-01-06 Rev. 3": {
    company: ["HusteelLab", "Tremblay Inc."],
    temperature: {
      value: "-18",
      data: {
        poItem: {
          [PO_ITEMS.PO_ITEM_1]: {
            locationDependantValues: [
              {
                location: "Base",
                single: 41,
                average: 54,
                singleShearArea: 60,
                averageShearArea: 85
              },
              {
                location: "HAZ",
                single: 18,
                average: 18,
                singleShearArea: 20,
                averageShearArea: 20
              },
              {
                location: "Weld",
                single: 18,
                average: 18,
                singleShearArea: 20,
                averageShearArea: 20
              }
            ]
          },
          [PO_ITEMS.PO_ITEM_2]: {
            locationDependantValues: [
              {
                location: "Base",
                single: 41,
                average: 54,
                singleShearArea: 60,
                averageShearArea: 85
              },
              {
                location: "HAZ",
                single: 18,
                average: 18,
                singleShearArea: 20,
                averageShearArea: 20
              },
              {
                location: "Weld",
                single: 18,
                average: 18,
                singleShearArea: 20,
                averageShearArea: 20
              }
            ]
          },
          [PO_ITEMS.PO_ITEM_3]: {
            locationDependantValues: [
              {
                location: "Base",
                single: 60,
                average: 80,
                singleShearArea: 60,
                averageShearArea: 85
              },
              {
                location: "HAZ",
                single: 27,
                average: 27,
                singleShearArea: 20,
                averageShearArea: 20
              },
              {
                location: "Weld",
                single: 27,
                average: 27,
                singleShearArea: 20,
                averageShearArea: 20
              }
            ]
          }
        }
      }
    }
  },
  "FP-ITP Rev. 0": {
    company: ["Tremblay Inc.", "Mechan Lab"],
    temperature: {
      value: "-18",
      data: {
        locationDependantValues: [
          {
            location: "Base",
            single: 40,
            average: 40,
            singleShearArea: 60,
            averageShearArea: 85
          },
          {
            location: "HAZ",
            single: 14,
            average: 14,
            singleShearArea: 20
          },
          {
            location: "Weld",
            single: 14,
            average: 14,
            singleShearArea: 20
          }
        ]
      }
    }
  },
  "MTIP WA0058-4400025974-H06-44004 Rev. 6": {
    company: ["SumitomoLab", "Tremblay Inc."],
    temperature: {
      value: "-30",
      data: {
        single: 200,
        average: 200,
        averageShearArea: 85,
        singleShearArea: 75
      }
    }
  },
  "QCP 8263 Rev. 6": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    temperature: {
      value: "-10",
      data: {
        dependantValues: {
          5: {
            single: 90,
            average: 100,
            singleShearArea: 75
          },
          3.33: {
            single: 60,
            average: 66,
            singleShearArea: 75
          }
        }
      }
    }
  },
  "QCP 8263 Rev. 6 (Impact curve)": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    temperature: {
      impactTestCurve: true,
      data: {
        elements: [
          {
            temperature: -50
          },
          {
            temperature: -40
          },
          {
            temperature: -30
          },
          {
            temperature: -20
          },
          {
            temperature: -10
          },
          {
            temperature: 0
          }
        ]
      }
    }
  },
  "QCP 8252 Rev. 6": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    temperature: {
      value: "-10",
      data: {
        dependantValues: {
          5: {
            single: 90,
            average: 100,
            singleShearArea: 75
          },
          6.67: {
            single: 121,
            average: 134,
            singleShearArea: 75
          }
        }
      }
    }
  },
  "QCP 8251 Rev. 6": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    temperature: {
      value: "-10",
      data: {
        single: 80,
        average: 100,
        singleShearArea: 75
      }
    }
  },
  "QCP 8251 Rev. 6 (Impact curve)": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    temperature: {
      impactTestCurve: true,
      data: {
        elements: [
          {
            temperature: -50
          },
          {
            temperature: -40
          },
          {
            temperature: -30
          },
          {
            temperature: -20
          },
          {
            temperature: -10
          },
          {
            temperature: 0
          }
        ]
      }
    }
  },
  "QCP 8262 Rev. 6": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    temperature: {
      temperatureByPoItem: {
        [PO_ITEMS.PO_ITEM_1]: "-18",
        [PO_ITEMS.PO_ITEM_2]: "-18",
        [PO_ITEMS.PO_ITEM_3]: "-10"
      },
      data: {
        single: 80,
        average: 100,
        singleShearArea: 75
      }
    }
  },
  "QCP 8262 Rev. 6 (Impact curve)": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    temperature: {
      impactTestCurve: true,
      data: {
        elements: [
          {
            temperature: -50
          },
          {
            temperature: -40
          },
          {
            temperature: -30
          },
          {
            temperature: -20
          },
          {
            temperature: -10
          },
          {
            temperature: 0
          }
        ]
      }
    }
  },
  "QCP 8252 Rev. 6 (After reeling)": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    temperature: {
      value: "-10",
      data: {
        single: 38,
        average: 45
      }
    }
  },
  "QCP 8252 Rev. 6 (Impact curve)": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    temperature: {
      impactTestCurve: true,
      data: {
        elements: [
          {
            temperature: -50
          },
          {
            temperature: -40
          },
          {
            temperature: -30
          },
          {
            temperature: -20
          },
          {
            temperature: -10
          },
          {
            temperature: 0
          }
        ]
      }
    }
  },
  "QCP 8251 Rev. 6 (After reeling)": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    temperature: {
      value: "-10",
      data: {
        single: 38,
        average: 45
      }
    }
  },
  "ITP-BPM-22.20 Rev.6": {
    company: ["Berg Pipe", "Tremblay Inc."],
    temperature: {
      value: "-18",
      data: {
        locationDependantValues: [
          {
            location: "Base",
            single: 68,
            average: 89,
            singleShearArea: 70,
            averageShearArea: 85
          },
          {
            location: "Weld",
            singleShearArea: 70,
            averageShearArea: 85
          },
          {
            location: "HAZ",
            single: 45,
            average: 60
          }
        ]
      }
    }
  },
  get "ITP-BPM-22.20 Rev.6 MPQT"() {
    return this["ITP-BPM-22.20 Rev.6"];
  },
  "ITP 011/23/124277 Rev. 05": {
    company: ["Tremblay Inc."],
    temperature: {
      value: "-28",
      data: {
        orientation: "Transversal",
        single: 80,
        average: 100,
        singleShearArea: 75,
        averageShearArea: 75
      }
    }
  },
  get "ITP 011/23/124277 Rev. 05 MPQT"() {
    return this["ITP 011/23/124277 Rev. 05"];
  },
  "ITP 011/23/124277 Rev. 05 MPQT (Impact curve)": {
    company: ["Tremblay Inc."],
    temperature: {
      impactTestCurve: true,
      data: {
        elements: [
          {
            temperature: 20
          },
          {
            temperature: 0
          },
          {
            temperature: -28
          },
          {
            temperature: -40
          },
          {
            temperature: -60,
            ithRequirements: false
          },
          {
            temperature: -80
          }
        ],
        orientation: "Transversal",
        single: 80,
        average: 100,
        singleShearArea: 75,
        averageShearArea: 75
      }
    }
  },
  "QCP 5513 Rev. 2": {
    company: ["TenarisLab", "Tremblay Inc."],
    temperature: {
      temperatureByPoItem: {
        [PO_ITEMS.PO_ITEM_1]: -8,
        [PO_ITEMS.PO_ITEM_2]: -18
      },
      data: {
        single: 72,
        average: 86,
        singleShearArea: 60,
        averageShearArea: 60,
        averageShearAreaAllSpecimens: 85,
        singleLateralExpansion: 0,
        averageLateralExpansion: 0
      }
    }
  },
  "QCP 5513 Rev. 2 (After reeling)": {
    company: ["TenarisLab", "Tremblay Inc."],
    temperature: {
      temperatureByPoItem: {
        [PO_ITEMS.PO_ITEM_1]: -8,
        [PO_ITEMS.PO_ITEM_2]: -18
      },
      data: {
        single: 72,
        average: 86,
        singleShearArea: 0,
        averageShearArea: 0
      }
    }
  },
  "QCP 5513 Rev. 2 (Impact curve)": {
    company: ["TenarisLab", "Tremblay Inc."],
    temperature: {
      impactTestCurve: true,
      data: {
        poItem: {
          [PO_ITEMS.PO_ITEM_1]: {
            elements: [
              {
                temperature: 0
              },
              {
                temperature: -8
              },
              {
                temperature: -20
              },
              {
                temperature: -30
              },
              {
                temperature: -40
              },
              {
                temperature: -50
              },
              {
                temperature: -60
              }
            ]
          },
          [PO_ITEMS.PO_ITEM_2]: {
            elements: [
              {
                temperature: 0
              },
              {
                temperature: -10
              },
              {
                temperature: -18
              },
              {
                temperature: -30
              },
              {
                temperature: -40
              },
              {
                temperature: -50
              },
              {
                temperature: -60
              }
            ]
          }
        }
      }
    }
  },
  "QCP 5982 Rev. 4": {
    company: ["TenarisLab", "Tremblay Inc."],
    temperature: {
      value: -29,
      data: {
        poItem: {
          [PO_ITEMS.PO_ITEM_1]: {
            single: 90,
            average: 120,
            singleShearArea: 75,
            averageShearArea: 85,
            singleLateralExpansion: 0,
            averageLateralExpansion: 0
          },
          [PO_ITEMS.PO_ITEM_2]: {
            single: 90,
            average: 120,
            singleShearArea: 75,
            averageShearArea: 85,
            singleLateralExpansion: 0,
            averageLateralExpansion: 0
          },
          [PO_ITEMS.PO_ITEM_3]: {
            single: 90,
            average: 120,
            singleShearArea: 60,
            averageShearArea: 85,
            singleLateralExpansion: 0,
            averageLateralExpansion: 0
          },
          [PO_ITEMS.PO_ITEM_4]: {
            single: 80,
            average: 120,
            singleShearArea: 60,
            averageShearArea: 60,
            averageShearAreaAllSpecimens: 85,
            singleLateralExpansion: 0,
            averageLateralExpansion: 0
          }
        }
      }
    }
  },
  "QCP 5982 Rev. 4 (Impact curve)": {
    company: ["TenarisLab", "Tremblay Inc."],
    temperature: {
      impactTestCurve: true,
      data: {
        elements: [
          {
            temperature: 0
          },
          {
            temperature: -10
          },
          {
            temperature: -20
          },
          {
            temperature: -29
          },
          {
            temperature: -40
          },
          {
            temperature: -50
          },
          {
            temperature: -60
          }
        ]
      }
    }
  },
  "CPW-ITP-24-23-SAWL Rev. 3": {
    company: ["Tremblay Inc."],
    temperature: {
      value: -10,
      data: {
        elements: [
          {
            location: "Base",
            temperature: -10
          },
          {
            location: "HAZ",
            temperature: -10
          },
          {
            location: "Weld",
            temperature: -10
          }
        ],
        locationDependantValues: [
          {
            location: "Base",
            single: 30,
            average: 40,
            singleShearArea: 75,
            averageShearArea: 85
          },
          {
            location: "Weld",
            single: 30,
            average: 40
          },
          {
            location: "HAZ",
            single: 30,
            average: 40
          }
        ]
      }
    }
  },
  "CPW-ITP-24-23-SAWL Rev. 3 MPQT": {
    company: ["Tremblay Inc."],
    temperature: {
      value: -10,
      data: {
        elements: [
          {
            location: "Base",
            temperature: -10
          },
          {
            location: "HAZ",
            temperature: -10
          },
          {
            location: "Weld",
            temperature: -10
          },
          {
            location: "Base",
            temperature: -10
          },
          {
            location: "HAZ",
            temperature: -10
          },
          {
            location: "Weld",
            temperature: -10
          }
        ],
        locationDependantValues: [
          {
            location: "Base",
            single: 30,
            average: 40,
            singleShearArea: 75,
            averageShearArea: 85
          },
          {
            location: "Weld",
            single: 30,
            average: 40
          },
          {
            location: "HAZ",
            single: 30,
            average: 40
          }
        ]
      }
    }
  },
  "CPW-ITP-24-23-SAWL Rev. 3 (Impact curve)": {
    company: ["Tremblay Inc."],
    temperature: {
      impactTestCurve: true,
      data: {
        elements: [
          {
            temperature: -50
          },
          {
            temperature: -40
          },
          {
            temperature: -30
          },
          {
            temperature: -20
          },
          {
            temperature: -10
          },
          {
            temperature: 0
          }
        ]
      }
    }
  },
  "Van Leeuwen exception": {
    temperature: {
      value: "-45",
      data: {
        dependantValues: {
          10: {
            single: 19,
            average: 27
          },
          7.5: {
            single: 14,
            average: 20
          },
          6.67: {
            single: 13,
            average: 18
          },
          5: {
            single: 10,
            average: 14
          },
          3.33: {
            single: 6,
            average: 9
          },
          2.5: {
            single: 5,
            average: 7
          }
        },
        orientation: "Transversal",
        single: 13,
        average: 18
      }
    }
  }
};

const notchConfig = ["KV", "KU"];
const radiusConfig = ["2 mm", "8 mm"];
const testStandards = ["ASTM E23 (2018)", "ISO 148-1 (2016)"];
const dimensionY = [10, 7.5, 6.67, 5, 3.33, 2.5];
const positions = ["1/4 t", "1/2 t", "3/4 t", "2 mm from ID", "2 mm from OD"];
const orientations = ["Transversal", "Longitudinal", "tangential", "radial"];
const locations = ["Base", "HAZ", "Weld"];
const notchPositions = [
  "fusion line",
  "0.5 mm from fusion line",
  "1 mm from fusion line",
  "2 mm from fusion line",
  "3 mm from fusion line",
  "4 mm from fusion line",
  "5 mm from fusion line",
  "0.5 mm from weld CL"
];

const temperatureUnits = {
  CELSIUS: "celsius",
  FAHRENHEIT: "fahrenheit"
};

export {
  config,
  temperatureUnits,
  exceptionsConfig,
  notchConfig,
  radiusConfig,
  testStandards,
  dimensionY,
  positions,
  orientations,
  locations,
  notchPositions
};
