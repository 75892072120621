import React, {useEffect, useState} from "react";
import {keys} from "ramda";
import {observer} from "mobx-react-lite";
import {useParams} from "react-router-dom";
import useStores from "../../useStores";
import Header from "./components/Header";
import Content from "./components/Content";
import {TABS} from "./constants";

const SingleMaterialCampaign = () => {
  const [activeTab, setActiveTab] = useState(TABS.DASHBOARD);
  const [activeSubTab, setActiveSubTab] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const {SingleMaterialCampaignStore} = useStores();

  const {id} = useParams();

  const loadCampaign = async () => {
    await SingleMaterialCampaignStore.getCampaignById(id);
    setIsLoaded(true);
  };

  useEffect(() => {
    loadCampaign();
  }, [id]);

  const changeActiveTab = async (tab) => {
    const newTab = tab || activeTab;

    setActiveTab(newTab);
    await SingleMaterialCampaignStore.getSubTabs(id, newTab);
    const subTabs = SingleMaterialCampaignStore.subTabsByTab[newTab] || {};

    setActiveSubTab(keys(subTabs)[0] || null);
  };

  return (
    <>
      <Header
        activeTab={activeTab}
        setActiveTab={changeActiveTab}
        activeSubTab={activeSubTab}
        setActiveSubTab={setActiveSubTab}
        showFilters={showFilters}
        toggleFilters={() => setShowFilters((prevValue) => !prevValue)}
        isLoaded={isLoaded}
      />
      <Content
        showFilters={showFilters}
        activeTab={activeTab}
        activeSubTab={activeSubTab}
        isLoaded={isLoaded}
      />
    </>
  );
};

export default observer(SingleMaterialCampaign);