import AnalyticsSlider from "@core/components/AnalyticsSlider";
import {TestPlot} from "@core/components/Chart";
import testConfig from "@core/configs/test";
import {ALL_TESTS, STATUSES} from "@core/constants/test";
import CircularProgress from "@mui/material/CircularProgress";
import {observer} from "mobx-react-lite";
import {groupBy, prop, keys} from "ramda";
import React, {useState} from "react";
import {Grid} from "@mui/material";
import useStores from "../../../../../../useStores";

const Charts = ({category, isLoaded}) => {
  const [chartSlideOutOpen, setChartSlideOutOpen] = useState(false);
  const [openTestType, setOpenTestType] = useState(null);
  
  const {SingleMaterialCampaignStore} = useStores();

  const tests = SingleMaterialCampaignStore.testsByCategory[category] || [];

  const plottableTests = tests.filter((test) => [STATUSES.SUBMITTED, STATUSES.APPROVED, STATUSES.INSPECTING, STATUSES.INSPECTED].includes(test.status));

  const testsByType = groupBy(prop("type"), plottableTests);

  const plottableTestTypes = keys(testsByType).filter((type) => testConfig[type].plots);
  
  const onChartClick = (testType) => {
    if(!testsByType[testType]) return;

    setChartSlideOutOpen(true);
    setOpenTestType(testType);
  };

  if(!plottableTestTypes.length || category === ALL_TESTS) return null;

  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {plottableTestTypes.map((type) => {
            const tests = testsByType[type];

            return (
              <Grid
                key={type}
                item xs={12 / plottableTestTypes.length}
                sx={{
                  cursor: tests ? "zoom-in" : "default",
                  "&:hover": {
                    opacity: tests ? 0.8 : 1,
                  },
                  "&>div": {
                    "&>div, img": {
                      borderRadius: "4px",
                    },
                    "&>div": {
                      border: "1px solid #efefef",
                    }
                  }
                }}
                onClick={() => onChartClick(type)}
              >
                {isLoaded ? (
                  <TestPlot
                    testConfig={testConfig[type]}
                    tests={tests || []}
                    isFullPlotView={false}
                  />
                ) : (
                  <CircularProgress />
                )}
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <AnalyticsSlider
        open={chartSlideOutOpen}
        close={() => setChartSlideOutOpen(false)}
        tests={testsByType[openTestType] || []}
      />
    </>
  );
};

export default observer(Charts);