export const TEST_STANDARDS = [
  "NACE TM0284 (2016)",
  "API 5L Annex H, clause H 7.2.2 / H.7.3.1"
];

export const ACCEPTANCE_CRITERIA = {
  "QCP 9348 (YGG-TGS-00010 - GL Reelable)": {
    company: ["Tremblay Inc.", "Test Lab"],
    elementsQuantity: 3,
    testStandard: "NACE TM0284",
    solution: "Solution A of NACE TM0177",
    duration: 96,
    requirements: {
      crackLengthRatioMax: 10,
      crackThicknessRatioMax: 3,
      crackSensitivityRatioMax: 1
    }
  },
  "QCP 10378 (YGG-TGS-00045 - GL Pipe)": {
    company: ["Tremblay Inc.", "Test Lab"],
    requirements: {
      crackLengthRatioMax: 10,
      crackSensitivityRatioMax: 1,
      crackThicknessRatioMax: 3
    }
  },
  "ISO 15156-2 (2015) - NACE MR0175 Table B.3": {
    requirements: {
      crackLengthRatioMax: 15,
      crackThicknessRatioMax: 5,
      crackSensitivityRatioMax: 2
    }
  },
  "QCP 6916 Rev. 3": {
    company: ["TenarisLab", "Tremblay Inc."],
    requirements: {
      crackLengthRatioMax: 15,
      crackThicknessRatioMax: 5,
      crackSensitivityRatioMax: 2
    },
    solution: "Solution A of NACE TM0284",
    duration: 96,
    elementsQuantity: 3
  },
  "HS-D-ITP-22-02-02 Rev. 5": {
    company: ["HusteelLab", "Tremblay Inc."],
    requirements: {
      crackLengthRatioMax: 10,
      crackThicknessRatioMax: 3,
      crackSensitivityRatioMax: 1
    },
    solution:
      "Solution A of NACE TM0284: 50g Nacl + 5g CH3COOH + 945g Distilled Water",
    duration: 96,
    elementsQuantity: 3
  },
  "HS-D-ITP-23-01-06 Rev. 3": {
    company: ["HusteelLab", "Tremblay Inc."],
    requirements: {
      crackLengthRatioMax: 10,
      crackThicknessRatioMax: 3,
      crackSensitivityRatioMax: 1
    }
  },
  "FP-ITP Rev. 0": {
    company: ["Tremblay Inc.", "Mechan Lab"],
    requirements: {
      crackLengthRatioMax: 10,
      crackThicknessRatioMax: 3,
      crackSensitivityRatioMax: 1
    },
    solution:
      "Solution A of NACE TM0284: 50g Nacl + 5g CH3COOH + 945g Distilled Water",
    duration: 96,
    elementsQuantity: 3
  },
  "QCP 8262 Rev. 6": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    requirements: {
      crackLengthRatioMax: 15,
      crackThicknessRatioMax: 5,
      crackSensitivityRatioMax: 2
    },
    duration: 96,
    elementsQuantity: 3
  },
  "QCP 5513 Rev. 2": {
    company: ["TenarisLab", "Tremblay Inc."],
    requirements: {
      crackLengthRatioMax: 15,
      crackThicknessRatioMax: 5,
      crackSensitivityRatioMax: 2
    }
  },
  "QCP 5982 Rev. 4": {
    company: ["TenarisLab", "Tremblay Inc."],
    requirements: {
      crackLengthRatioMax: 15,
      crackThicknessRatioMax: 5,
      crackSensitivityRatioMax: 2
    }
  },
  "QCP 5987 Rev. 2": {
    company: ["TenarisLab", "Tremblay Inc."],
    requirements: {
      averageCrackLengthRatioMax: 15,
      averageCrackThicknessRatioMax: 5,
      averageCrackSensitivityRatioMax: 2
    },
    duration: 96,
    elementsQuantity: 3
  }
};
