import React, {useState, useEffect} from "react";
import {FieldArray, Formik, getIn} from "formik";
import {Input, Select} from "@core/components/Form";
import {
  Grid,
  Button,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  IconButton,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import DeleteIcon from "@mui/icons-material/Delete";
import * as yup from "yup";
import {
  getConfigFromCondition,
  capitalizeFirstLetter,
  getPoItemNumber
} from "@core/helpers";
import useFetchTestNorms from "@core/hooks/useFetchTestNorms";
import {
  isEmpty,
  keys,
  isNil,
  prop,
  indexBy,
  values as getValues,
  any,
  flatten,
  groupBy
} from "ramda";
import ImpactCurve from "@core/components/ImpactCurve";
import SelectField from "@core/components/FormikSelect";
import NormAutocomplete from "@core/components/NormAutocomplete";
import NewException from "./NewException";
import ClientField from "../../../../Tests/Test/components/ClientField";
import TestFooter from "../../LabTestFooter";
import {
  averageTemp,
  getTimesMin,
  getTestResult,
  getActualValue,
  getElementRequirements
} from "./services";
import {
  config as norms,
  exceptionsConfig,
  notchConfig,
  radiusConfig,
  testStandards,
  dimensionY,
  positions,
  orientations,
  locations,
  notchPositions,
  temperatureUnits
} from "./data";
import styles from "./styles";
import MuiSelect from "@core/components/MuiSelect";
import Uploader from "@core/components/Uploaders/Uploader";

const WARNING_MIN = 1000;

const ELEMENT = {
  laboratory: "",
  location: "",
  notchPosition: "",
  orientation: "",
  position: "",
  temperature: "",
  energyJoule1: "",
  lateralExpansion1: "",
  shearArea1: "",
  energyJoule2: "",
  lateralExpansion2: "",
  shearArea2: "",
  energyJoule3: "",
  lateralExpansion3: "",
  shearArea3: ""
};

function validateLateralExpansion(value) {
  const {from} = this;
  const {singleLateralExpansion, averageLateralExpansion} = from[1].value;

  return (
    (isNil(singleLateralExpansion) && isNil(averageLateralExpansion)) || !!value
  );
}

function validateShearArea(value) {
  const {from} = this;
  const {singleShearArea, averageShearArea} = from[1].value;

  return (isNil(singleShearArea) && isNil(averageShearArea)) || !!value;
}

const ImpactTest = ({
  classes,
  test,
  saveTest,
  certificate,
  user,
  client,
  formRef
}) => {
  const [materialGrade, setMaterialGrade] = useState({});

  const DIMENSION_X = 10;
  const NOTCH_NUMERIC_VALUE = 2;

  let defaultValues = {};

  const filteredAcceptances = Object.keys(exceptionsConfig).reduce(function (
    r,
    e
  ) {
    if (
      !exceptionsConfig[e].hasOwnProperty("company") ||
      exceptionsConfig[e].company.includes(user.company.name)
    )
      r[e] = exceptionsConfig[e];

    return r;
  }, {});

  const initialValues = {
    client: test.properties.client || client.name || "",
    lab: test.properties.lab || user.company.name || "",
    notch: test.properties.notch || "",
    radius: test.properties.radius || "",
    width: DIMENSION_X,
    height: test.properties.height || "",
    outsideDiameter: test.properties.outsideDiameter || "",
    thickness: test.properties.thickness || "",
    elements: test.properties.elements || [ELEMENT],
    norm:
      test.properties.norm || test.norm || certificate?.properties?.norm || "",
    grade: test.properties.grade || certificate?.properties?.grade || "",
    temperature: test.properties.temperature || "",
    testStandard: test.properties.testStandard || "",
    acceptance: test.properties.acceptance || "",
    result: test.properties.result || "",
    unit: test.properties.unit || temperatureUnits.CELSIUS,
    notes: test.properties.notes || "",
    impactTestCurve: test.properties.impactTestCurve || false,
    requirements: test.properties.requirements || {},
    file: test.properties.file || ""
  };

  // prettier-ignore
  const validationSchema = yup.object().shape({
    client: yup.string().required("Client field is required"),
    lab: yup.string().required("Laboratory field is required"),
    radius: yup.string().required("Striker radius is required"),
    notch: yup.string().required("Notch type is required"),
    width: yup
      .number()
      .typeError("Should be a number")
      .required("Width is required"),
    height: yup
      .number()
      .typeError("Should be a number")
      .required("Height is required"),
    outsideDiameter: yup.string(),
    thickness: yup.string(),
    norm: yup.string().required("Material specification is required"),
    ...{
      ...(getValues(materialGrade).some((grade) => grade.Material)
        ? {
          grade: yup.string().required("Grade / UNS is required!")
        }
        : {})
    },
    temperature: yup.string().when("impactTestCurve", {
      is: false,
      then: (schema) => schema.required("Test temperature is required")
    }),
    testStandard: yup.string().required("Test standard is required"),
    elements: yup
      .array()
      .of(
        yup.object().shape({
          laboratory: yup.string().required("Specimen ID is required!"),
          location: yup.string().required("Location is required!"),
          notchPosition: yup.string().when("location", {
            is: (location) => ["Weld", "HAZ"].includes(location),
            then: yup.string().required("Notch position is required!")
          }),
          position: yup.string(),
          orientation: yup.string().required("Orientation is required"),
          temperature: yup
            .string()
            .test("temperature", "Temperature is required", function (value) {
              const {impactTestCurve} = this.from[1].value;

              return !impactTestCurve || value;
            }),
          energyJoule1: yup
            .number()
            .min(0, "Should be more than 0")
            .typeError("Should be a number!")
            .required("Absorbed energy is required!"),
          lateralExpansion1: yup
            .number()
            .min(0, "Should be more than 0")
            .typeError("Should be a number!")
            .test(
              "lateralExpansion1",
              "Lateral expansion is required!",
              validateLateralExpansion
            ),
          shearArea1: yup
            .number()
            .min(0, "Should be more than 0")
            .max(100, "Should be less than 100")
            .test("shearArea1", "Shear area is required!", validateShearArea),
          energyJoule2: yup
            .number()
            .min(0, "Should be more than 0")
            .typeError("Should be a number!")
            .required("Absorbed energy is required!"),
          lateralExpansion2: yup
            .number()
            .min(0, "Should be more than 0")
            .typeError("Should be a number!")
            .test(
              "lateralExpansion2",
              "Lateral expansion is required!",
              validateLateralExpansion
            ),
          shearArea2: yup
            .number()
            .min(0, "Should be more than 0")
            .max(100, "Should be less than 100")
            .test("shearArea2", "Shear area is required!", validateShearArea),
          energyJoule3: yup
            .number()
            .min(0, "Should be more than 0")
            .typeError("Should be a number!")
            .required("Absorbed energy is required!"),
          lateralExpansion3: yup
            .number()
            .min(0, "Should be more than 0")
            .typeError("Should be a number!")
            .test(
              "lateralExpansion3",
              "Lateral expansion is required!",
              validateLateralExpansion
            ),
          shearArea3: yup
            .number()
            .min(0, "Should be more than 0")
            .max(100, "Should be less than 100")
            .test("shearArea3", "Shear area is required!", validateShearArea)
        })
      )
      .required()
  });

  useFetchTestNorms("certificate", initialValues.norm, setMaterialGrade);

  const onSubmit = (values) => {
    saveTest({
      ...values,
      result: getTestResult(values)
    });
  };

  return (
    <>
      <Formik
        onSubmit={onSubmit}
        innerRef={formRef}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount
        render={(props) => {
          const {
            values: {
              lab,
              notch,
              width,
              height,
              radius,
              outsideDiameter,
              thickness,
              norm,
              grade,
              temperature,
              impactTestCurve,
              acceptance,
              elements,
              notes,
              requirements,
              file
            },
            errors,
            isValid,
            setFieldValue,
            touched,
            setFieldTouched
          } = props;

          const poItemNumber = getPoItemNumber(certificate.lineItem);

          useEffect(() => {
            if (impactTestCurve) return;

            const newElements = elements.map((element) => ({
              ...element,
              temperature: temperature || element.temperature,
              unit: temperatureUnits.CELSIUS
            }));
            setFieldValue("elements", newElements);
          }, [temperature]);

          const change = (name, event) => {
            event?.persist && event.persist();
            setFieldTouched(name, true);
            setFieldValue(name, event.target.value);
          };

          const changeHeightOrException = (name, event) => {
            event?.persist && event.persist();

            if (name === "height" && !acceptance)
              changeLimitsOnHeight(temperature, event.target.value);

            if (name === "acceptance" || (name === "height" && acceptance)) {
              const val =
                name === "acceptance" ? event.target.value : acceptance;

              if (!val) {
                setFieldValue("temperature", "");
              } else {
                setFieldValue("impactTestCurve", false);
                setFieldValue("elements", [ELEMENT]);

                defaultValues = getData({
                  temperature,
                  outsideDiameter,
                  thickness,
                  height
                });

                const exceptionConfig = exceptionsConfig[val];

                let temp =
                  exceptionConfig.temperature.data.poItem && poItemNumber
                    ? exceptionConfig.temperature.data.poItem[poItemNumber]
                    : exceptionConfig.temperature.data;
                const orientation = temp.orientation;
                let elements = temp.elements || [ELEMENT];

                if (temp.dependantValues) {
                  const _height =
                    (name === "height" ? event.target.value : height) ||
                    keys(temp.dependantValues)[0];
                  temp = temp.dependantValues[_height];
                  setFieldValue("height", _height);
                }

                const temperature = getTemperature(exceptionConfig);
                const unit =
                  exceptionConfig.temperature.unit || temperatureUnits.CELSIUS;
                setFieldValue("temperature", temperature);
                setFieldValue("unit", unit);
                elements = elements.map((element) => ({
                  temperature,
                  unit,
                  ...element
                }));

                if (exceptionConfig.temperature.impactTestCurve) {
                  setFieldValue("impactTestCurve", true);
                }

                if (orientation)
                  setFieldValue("requirements", {[orientation]: temp});
                else {
                  const orientationDependantValues = orientations.reduce(
                    (requirements, orientation) => {
                      requirements[orientation] = {
                        ...temp,
                        orientation,
                        temperature,
                        unit: temperatureUnits.CELSIUS
                      };

                      return requirements;
                    },
                    {}
                  );

                  const locationDependantValues =
                    temp.locationDependantValues &&
                    indexBy(prop("location"), temp.locationDependantValues);
                  const requirements = temp.locationDependantValues
                    ? locationDependantValues
                    : orientationDependantValues;
                  setFieldValue("requirements", requirements);
                }

                setFieldValue("elements", elements);
              }
            }

            setFieldTouched(name, true, false);
            setFieldValue(name, event.target.value);
          };

          const getData = ({
            temperature,
            outsideDiameter,
            thickness,
            height
          }) => {
            const config =
              findTemp().find((temp) => temp.value === temperature) || {};
            const data = config.data || {};

            if (height && data.dependantValues) {
              return {
                [data.orientation]: {
                  ...data.dependantValues[height],
                  orientation: data.orientation
                }
              };
            }

            if (outsideDiameter && data.diameterDependantValues) {
              return {
                [data.orientation]: {
                  ...getConfigFromCondition(
                    data.diameterDependantValues,
                    outsideDiameter
                  ),
                  orientation: data.orientation
                }
              };
            }

            if (thickness && data.thicknessDependantValues) {
              return {
                [data.orientation]: {
                  ...getConfigFromCondition(
                    data.thicknessDependantValues,
                    thickness
                  ),
                  orientation: data.orientation
                }
              };
            }

            if (data.orientationDependantValues) {
              return keys(data.orientationDependantValues).reduce(
                (acc, orientation) => {
                  const orientationConfig =
                    data.orientationDependantValues[orientation];

                  if (!orientationConfig.thicknessDependantValues)
                    acc[orientation] = orientationConfig;
                  else if (
                    orientationConfig.thicknessDependantValues &&
                    thickness
                  ) {
                    acc[orientation] = getConfigFromCondition(
                      orientationConfig.thicknessDependantValues,
                      thickness
                    );
                  }

                  return acc;
                },
                {}
              );
            }

            return {
              [data.orientation]: {
                ...data,
                orientation: data.orientation
              }
            };
          };

          const getTemperature = (exceptionConfig) => {
            if (exceptionConfig.temperature.temperatureByPoItem) {
              return (
                exceptionConfig.temperature.temperatureByPoItem[poItemNumber] ||
                ""
              );
            }

            return exceptionConfig.temperature.value;
          };

          const changeOutsideDiameter = (event) => {
            event?.persist && event.persist();

            const requirements = getData({
              temperature,
              outsideDiameter: event.target.value,
              thickness,
              height
            });

            setFieldValue("requirements", requirements);

            setFieldTouched("outsideDiameter", true, false);
            setFieldValue("outsideDiameter", event.target.value);
          };

          const changeThickness = (event) => {
            event?.persist && event.persist();

            setFieldTouched("thickness", true, false);
            setFieldValue("thickness", event.target.value);

            if (acceptance) return;

            const requirements = getData({
              temperature,
              thickness: event.target.value,
              outsideDiameter,
              height
            });
            setFieldValue("requirements", requirements);
          };

          const changeGrade = (value) => {
            if (!value) return;

            setFieldValue("grade", value.Material);

            if (acceptance) return;

            setFieldValue("temperature", "", false);
          };

          const changeNorm = ({norm, value}) => {
            setFieldValue("norm", norm);

            if (!value) {
              setMaterialGrade({});
              setFieldValue("grade", "");

              return;
            }

            setMaterialGrade(indexBy(prop("Material"), value));

            if (value.length === 1) {
              const [grade] = value;
              changeGrade(grade);

              return;
            }

            setFieldValue("grade", "");
          };

          const findGrade = () =>
            norms.find((nrm) => nrm.value === norm)?.grade;

          const findTemp = () => {
            if (acceptance)
              return exceptionsConfig[acceptance]
                ? [exceptionsConfig[acceptance]?.temperature]
                : [];

            const temperature = findGrade()?.find(
              (grd) => grd.value === grade
            )?.temperature;

            if (temperature) return temperature;

            return [];
          };

          const changeLimitsOnHeight = (temperature, height) => {
            const gradeRequirements = getData({
              temperature,
              outsideDiameter,
              thickness,
              height
            });

            if (isCustomRequirements) {
              const withAppliedHeight = flatten(getValues(requirements)).map(
                (requirement) => ({
                  ...requirement,
                  average: getActualValue(requirement.average, height),
                  single: getActualValue(requirement.single, height)
                })
              );

              setFieldValue(
                "requirements",
                groupBy(prop("orientation"), withAppliedHeight)
              );
            } else setFieldValue("requirements", gradeRequirements);
          };

          const getFooterCell = (value, orientation, field) => {
            if (
              acceptance &&
              defaultValues[orientation] &&
              defaultValues[orientation][field]
            ) {
              return (
                <Tooltip
                  title={`Default: ${defaultValues[orientation][field]}`}
                  placement={"bottom"}
                >
                  <TableCell className={classes.tooltipCell}>
                    Min {value}
                  </TableCell>
                </Tooltip>
              );
            }

            return <TableCell>{value ? `Min ${value}` : null}</TableCell>;
          };

          const populateElements = (changes) => {
            const populatedElements = elements.map((element) => ({
              ...element,
              ...changes
            }));

            setFieldValue("elements", populatedElements);
          };

          const isCustomRequirements = flatten(getValues(requirements)).some(
            (requirement) => requirement.temperature
          );
          const isCustomTemperature =
            !findTemp().length ||
            findTemp().some((temp) => isEmpty(temp.value)) ||
            acceptance;

          return (
            <Grid container spacing={2}>
              <Grid item container spacing={5} alignItems="flex-end">
                <Grid item xs={2}>
                  <ClientField isFromProducer={!!client.name} />
                </Grid>

                <Grid item xs={2}>
                  <Input
                    disabled
                    value={lab}
                    label="Laboratory"
                    name="lab"
                    required
                    error={Boolean(errors.lab) && touched.lab}
                    errorMessage={errors.lab}
                    onChange={(event) => change("lab", event)}
                  />
                </Grid>

                <Grid item xs={2}>
                  <MuiSelect
                    required
                    label="Test standard"
                    name="testStandard"
                    defaultOptions={testStandards}
                  />
                </Grid>
              </Grid>

              <Grid item container spacing={5}>
                <Grid item xs={2}>
                  <Select
                    value={acceptance}
                    label={"Load requirements"}
                    name={"acceptance"}
                    onChange={(event) =>
                      changeHeightOrException("acceptance", event)
                    }
                  >
                    <MenuItem key="N/A" value={undefined}>
                      N/A
                    </MenuItem>
                    {Object.keys(filteredAcceptances).map((name) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={2} container alignItems="flex-end">
                  <NewException
                    setFieldValue={setFieldValue}
                    height={height}
                    notch={NOTCH_NUMERIC_VALUE}
                    width={width}
                    requirements={requirements}
                    defaultRequirements={
                      acceptance
                        ? requirements
                        : getData({
                            temperature,
                            outsideDiameter,
                            thickness,
                            height
                          })
                    }
                    temperature={temperature}
                    acceptance={acceptance}
                  />
                </Grid>
              </Grid>

              <Grid item container spacing={5} alignItems="flex-end">
                {certificate._id ? (
                  <>
                    <Grid item xs={3}>
                      <Input
                        value={norm}
                        label="Material Specification"
                        name="norm"
                        required
                        disabled
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <Input
                        value={grade}
                        label="Grade / UNS"
                        name="grade"
                        required
                        disabled
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={3}>
                      <NormAutocomplete
                        label="Material Specification"
                        name="norm"
                        testType="certificate"
                        onChange={changeNorm}
                        required
                      />
                    </Grid>

                    {norm &&
                      getValues(materialGrade).some(
                        (norm) => norm.Material
                      ) && (
                        <Grid item xs={3}>
                          <SelectField
                            label="Grade / UNS"
                            name="grade"
                            onChange={(value) =>
                              changeGrade(materialGrade[value])
                            }
                            required
                          >
                            {getValues(materialGrade).map((item) => (
                              <MenuItem
                                key={item.Material}
                                value={item.Material}
                              >
                                {item.Material}
                              </MenuItem>
                            ))}
                          </SelectField>
                        </Grid>
                      )}
                  </>
                )}
                {!isCustomRequirements && !impactTestCurve && (
                  <Grid item xs={3}>
                    {!isCustomTemperature ? (
                      <Select
                        required
                        value={temperature}
                        label="Test Temperature"
                        name="temperature"
                        placeholder={!grade && "Select grade first"}
                        onChange={(event) => change("temperature", event)}
                      >
                        {findTemp().map((temp, index) => (
                          <MenuItem key={index} value={temp.value}>
                            {temp.value}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <Input
                        required
                        value={temperature}
                        label="Test Temperature"
                        name="temperature"
                        onChange={(event) => change("temperature", event)}
                        disabled={!!acceptance}
                      />
                    )}
                  </Grid>
                )}
                <Grid item xs={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={impactTestCurve}
                        onChange={(e) =>
                          setFieldValue("impactTestCurve", e.target.checked)
                        }
                        color="primary"
                      />
                    }
                    label="Impact test curve"
                  />
                </Grid>
              </Grid>

              <Grid item container spacing={5} alignItems="flex-end">
                <Grid item xs={2}>
                  <Input
                    label="Specimen Dimensions"
                    name="width"
                    required
                    value={width}
                    type={"number"}
                    error={Boolean(errors.width) && touched.width}
                    errorMessage={errors.width}
                    onChange={(event) => change("width", event)}
                    endAdornment="mm"
                    disabled
                  />
                </Grid>

                <Grid item xs={2}>
                  <Select
                    value={height}
                    name="height"
                    label="Height"
                    required
                    onChange={(event) =>
                      changeHeightOrException("height", event)
                    }
                  >
                    {dimensionY.map((dimY) => (
                      <MenuItem key={dimY} value={dimY}>
                        {dimY} mm
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>

                <Grid item xs={2}>
                  <Select
                    value={notch}
                    label="Notch type"
                    name="notch"
                    required
                    onChange={(event) => change("notch", event)}
                  >
                    {notchConfig.map((value, index) => (
                      <MenuItem key={index} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>

                <Grid item xs={2}>
                  <Select
                    value={radius}
                    label="Striker Radius"
                    name="radius"
                    required
                    onChange={(event) => change("radius", event)}
                  >
                    {radiusConfig.map((value, index) => (
                      <MenuItem key={index} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>

                {temperature &&
                  !acceptance &&
                  findTemp().find((t) => t.value === temperature)?.data
                    .diameterDependantValues && (
                    <Grid item xs={2}>
                      <Input
                        value={outsideDiameter}
                        name="outsideDiameter"
                        required
                        label="Outside diameter"
                        onChange={changeOutsideDiameter}
                        error={
                          Boolean(errors.outsideDiameter) &&
                          touched.outsideDiameter
                        }
                        errorMessage={errors.outsideDiameter}
                        endAdornment="MM"
                      />
                    </Grid>
                  )}
                <Grid item xs={2}>
                  <Input
                    value={thickness}
                    name="thickness"
                    label="Final product thickness"
                    required={
                      findTemp().find((t) => t.value === temperature)?.data
                        .thicknessDependantValues
                    }
                    onChange={changeThickness}
                    error={Boolean(errors.thickness) && touched.thickness}
                    errorMessage={errors.thickness}
                    endAdornment="MM"
                  />
                </Grid>
              </Grid>

              <Grid item container spacing={5}>
                <Grid item xs={4}>
                  <Input
                    value={notes}
                    name="notes"
                    label="Notes"
                    multiline
                    rows={5}
                    onChange={(event) => change("notes", event)}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <p>Test sketch</p>
                <Uploader
                  fileType={"image/*, application/pdf, .csv"}
                  file={file}
                  buttonText={"Choose file"}
                  handleUploadedFile={(file) =>
                    setFieldValue("file", file.file.dir + file.file.name)
                  }
                  onRemove={() => setFieldValue("file", "")}
                />
              </Grid>

              <Grid item xs={12}>
                <FieldArray name="elements">
                  {({remove, push}) => {
                    const requirementsArr = flatten(
                      Object.values(requirements)
                    );
                    const showLateral = any(
                      (requirement) =>
                        !isNil(requirement.averageLateralExpansion) ||
                        !isNil(requirement.singleLateralExpansion)
                    )(requirementsArr);
                    const showShearArea = any(
                      (requirement) =>
                        !isNil(requirement.averageShearArea) ||
                        !isNil(requirement.singleShearArea)
                    )(requirementsArr);

                    const showNotchPosition = elements.some((element) =>
                      ["HAZ", "Weld"].includes(element.location)
                    );

                    return (
                      <div style={{overflowX: "scroll"}}>
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>
                              <TableCell>Specimen ID</TableCell>
                              <TableCell>Location</TableCell>
                              {showNotchPosition && (
                                <TableCell>Notch position</TableCell>
                              )}
                              <TableCell>Position</TableCell>
                              <TableCell>Orientation</TableCell>
                              <TableCell>Temperature</TableCell>
                              <TableCell>
                                Absorbed energy [J]
                                <div className={classes.cellSubtitle}>
                                  <span>1</span>
                                  <span>2</span>
                                  <span>3</span>
                                </div>
                              </TableCell>
                              <TableCell>Average</TableCell>
                              {showLateral && (
                                <>
                                  <TableCell>
                                    Lateral expansion [mm]
                                    <div className={classes.cellSubtitle}>
                                      <span>1</span>
                                      <span>2</span>
                                      <span>3</span>
                                    </div>
                                  </TableCell>
                                  <TableCell>Average</TableCell>
                                </>
                              )}
                              {showShearArea && (
                                <>
                                  <TableCell>
                                    Shear area [%]
                                    <div className={classes.cellSubtitle}>
                                      <span>1</span>
                                      <span>2</span>
                                      <span>3</span>
                                    </div>
                                  </TableCell>
                                  <TableCell>Average</TableCell>
                                </>
                              )}
                              <TableCell>
                                <Tooltip title="Times < Min">
                                  <span>Result</span>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          {elements && elements.length > 0 && (
                            <TableBody>
                              {elements.map((element, index) => {
                                const elementPath = `elements[${index}]`;

                                const elementRequirements =
                                  getElementRequirements(
                                    element,
                                    requirements,
                                    acceptance,
                                    index
                                  );

                                return (
                                  <>
                                    <TableRow
                                      key={elementPath}
                                      className={classes.item}
                                    >
                                      <TableCell>
                                        <Input
                                          required
                                          name={`elements.${index}.laboratory`}
                                          value={element.laboratory}
                                          onChange={(event) =>
                                            change(
                                              `elements.${index}.laboratory`,
                                              event
                                            )
                                          }
                                          onBlur={() =>
                                            setFieldValue(
                                              `elements.${index}.laboratory`,
                                              element.laboratory
                                            )
                                          }
                                          error={
                                            Boolean(
                                              getIn(
                                                errors,
                                                `${elementPath}.laboratory`
                                              )
                                            ) &&
                                            getIn(
                                              touched,
                                              `${elementPath}.laboratory`
                                            )
                                          }
                                          errorMessage={getIn(
                                            errors,
                                            `${elementPath}.laboratory`
                                          )}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Select
                                          required
                                          value={element.location || ""}
                                          name={`elements.${index}.location`}
                                          onChange={(event) => {
                                            change(
                                              `elements.${index}.location`,
                                              event
                                            );
                                            const elementRequirements =
                                              getElementRequirements(
                                                {
                                                  ...element,
                                                  location: event.target.value
                                                },
                                                requirements,
                                                acceptance,
                                                index
                                              );

                                            if (
                                              elementRequirements.temperature
                                            ) {
                                              setFieldValue(
                                                `elements.${index}.temperature`,
                                                elementRequirements.temperature
                                              );
                                              setFieldValue(
                                                `elements.${index}.unit`,
                                                elementRequirements.unit
                                              );
                                            }

                                            if (impactTestCurve && !index)
                                              populateElements({
                                                location: event.target.value
                                              });
                                          }}
                                        >
                                          {locations.map((location, index) => (
                                            <MenuItem
                                              key={`elements.${index}.location`}
                                              value={location}
                                            >
                                              {location}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </TableCell>
                                      {showNotchPosition && (
                                        <TableCell>
                                          <Select
                                            required
                                            disabled={
                                              element.location === "Base"
                                            }
                                            value={element.notchPosition}
                                            name={`elements.${index}.notchPosition`}
                                            onChange={(event) =>
                                              change(
                                                `elements.${index}.notchPosition`,
                                                event
                                              )
                                            }
                                          >
                                            {notchPositions.map(
                                              (notchPosition, index) => (
                                                <MenuItem
                                                  key={`elements.${index}.notchPosition`}
                                                  value={notchPosition}
                                                >
                                                  {notchPosition}
                                                </MenuItem>
                                              )
                                            )}
                                          </Select>
                                        </TableCell>
                                      )}
                                      <TableCell>
                                        <Select
                                          value={element.position}
                                          name={`elements.${index}.position`}
                                          onChange={(event) => {
                                            change(
                                              `elements.${index}.position`,
                                              event
                                            );

                                            if (impactTestCurve && !index)
                                              populateElements({
                                                position: event.target.value
                                              });
                                          }}
                                          error={
                                            Boolean(
                                              getIn(
                                                errors,
                                                `${elementPath}.position`
                                              )
                                            ) &&
                                            getIn(
                                              touched,
                                              `${elementPath}.position`
                                            )
                                          }
                                          errorMessage={getIn(
                                            errors,
                                            `${elementPath}.position`
                                          )}
                                        >
                                          {positions.map((value) => (
                                            <MenuItem key={value} value={value}>
                                              {value}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </TableCell>
                                      <TableCell>
                                        <Select
                                          key={`elements.${index}.orientation.${element.orientation}`}
                                          required
                                          value={element.orientation}
                                          name={`elements.${index}.orientation`}
                                          onChange={(event) => {
                                            change(
                                              `elements.${index}.orientation`,
                                              event
                                            );
                                            const elementRequirements =
                                              getElementRequirements(
                                                {
                                                  ...element,
                                                  orientation:
                                                    event.target.value
                                                },
                                                requirements,
                                                acceptance,
                                                index
                                              );

                                            if (
                                              elementRequirements.temperature
                                            ) {
                                              setFieldValue(
                                                `elements.${index}.temperature`,
                                                elementRequirements.temperature
                                              );
                                              setFieldValue(
                                                `elements.${index}.unit`,
                                                elementRequirements.unit
                                              );
                                            }

                                            if (impactTestCurve && !index)
                                              populateElements({
                                                orientation: event.target.value
                                              });
                                          }}
                                          error={
                                            Boolean(
                                              getIn(
                                                errors,
                                                `${elementPath}.orientation`
                                              )
                                            ) &&
                                            getIn(
                                              touched,
                                              `${elementPath}.orientation`
                                            )
                                          }
                                          errorMessage={getIn(
                                            errors,
                                            `${elementPath}.orientation`
                                          )}
                                        >
                                          {orientations.map((value) => (
                                            <MenuItem key={value} value={value}>
                                              {capitalizeFirstLetter(value)}
                                            </MenuItem>
                                          ))}
                                          <MenuItem key="-" value="-">
                                            -
                                          </MenuItem>
                                        </Select>
                                      </TableCell>
                                      <TableCell>
                                        {impactTestCurve ? (
                                          <Input
                                            inputProps={{min: 0}}
                                            required
                                            name={`elements.${index}.temperature`}
                                            value={element.temperature}
                                            type="number"
                                            onChange={(event) =>
                                              change(
                                                `elements.${index}.temperature`,
                                                event
                                              )
                                            }
                                            onBlur={() =>
                                              setFieldValue(
                                                `elements.${index}.temperature`,
                                                element.temperature
                                              )
                                            }
                                            error={
                                              Boolean(
                                                getIn(
                                                  errors,
                                                  `${elementPath}.temperature`
                                                )
                                              ) &&
                                              getIn(
                                                touched,
                                                `${elementPath}.temperature`
                                              )
                                            }
                                            errorMessage={getIn(
                                              errors,
                                              `${elementPath}.temperature`
                                            )}
                                          />
                                        ) : (
                                          <span>
                                            {element.temperature}&nbsp;
                                            {"\u00b0"}
                                            {element.unit ===
                                            temperatureUnits.CELSIUS
                                              ? "C"
                                              : "F"}
                                          </span>
                                        )}
                                      </TableCell>
                                      <TableCell
                                        className={classes.noBorderRight}
                                      >
                                        <div
                                          className={classes.tableCellContainer}
                                        >
                                          <Input
                                            inputProps={{min: 0}}
                                            required
                                            name={`elements.${index}.energyJoule1`}
                                            value={element.energyJoule1}
                                            type={"number"}
                                            onChange={(event) =>
                                              change(
                                                `elements.${index}.energyJoule1`,
                                                event
                                              )
                                            }
                                            onBlur={() =>
                                              setFieldValue(
                                                `elements.${index}.energyJoule1`,
                                                element.energyJoule1
                                              )
                                            }
                                            error={
                                              Boolean(
                                                getIn(
                                                  errors,
                                                  `${elementPath}.energyJoule1`
                                                )
                                              ) &&
                                              getIn(
                                                touched,
                                                `${elementPath}.energyJoule1`
                                              )
                                            }
                                            errorMessage={getIn(
                                              errors,
                                              `${elementPath}.energyJoule1`
                                            )}
                                            warning={
                                              element.energyJoule1 >=
                                              WARNING_MIN
                                            }
                                            warningMessage="This value may be invalid"
                                          />
                                          <Input
                                            inputProps={{min: 0}}
                                            required
                                            name={`elements.${index}.energyJoule2`}
                                            value={element.energyJoule2}
                                            type={"number"}
                                            onChange={(event) =>
                                              change(
                                                `elements.${index}.energyJoule2`,
                                                event
                                              )
                                            }
                                            onBlur={() =>
                                              setFieldValue(
                                                `elements.${index}.energyJoule2`,
                                                element.energyJoule2
                                              )
                                            }
                                            error={
                                              Boolean(
                                                getIn(
                                                  errors,
                                                  `${elementPath}.energyJoule2`
                                                )
                                              ) &&
                                              getIn(
                                                touched,
                                                `${elementPath}.energyJoule2`
                                              )
                                            }
                                            errorMessage={getIn(
                                              errors,
                                              `${elementPath}.energyJoule2`
                                            )}
                                            warning={
                                              element.energyJoule2 >=
                                              WARNING_MIN
                                            }
                                            warningMessage="This value may be invalid"
                                          />
                                          <Input
                                            inputProps={{min: 0}}
                                            required
                                            name={`elements.${index}.energyJoule3`}
                                            value={element.energyJoule3}
                                            type={"number"}
                                            onChange={(event) =>
                                              change(
                                                `elements.${index}.energyJoule3`,
                                                event
                                              )
                                            }
                                            onBlur={() =>
                                              setFieldValue(
                                                `elements.${index}.energyJoule3`,
                                                element.energyJoule3
                                              )
                                            }
                                            error={
                                              Boolean(
                                                getIn(
                                                  errors,
                                                  `${elementPath}.energyJoule3`
                                                )
                                              ) &&
                                              getIn(
                                                touched,
                                                `${elementPath}.energyJoule3`
                                              )
                                            }
                                            errorMessage={getIn(
                                              errors,
                                              `${elementPath}.energyJoule3`
                                            )}
                                            warning={
                                              element.energyJoule3 >=
                                              WARNING_MIN
                                            }
                                            warningMessage="This value may be invalid"
                                          />
                                        </div>
                                      </TableCell>
                                      <TableCell
                                        className={classes.noBorderLeft}
                                      >
                                        {elements[index].energyJoule1 &&
                                        elements[index].energyJoule2 &&
                                        elements[index].energyJoule3
                                          ? averageTemp(
                                              elements[index].energyJoule1,
                                              elements[index].energyJoule2,
                                              elements[index].energyJoule3
                                            )
                                          : "fill all"}
                                      </TableCell>
                                      {showLateral && (
                                        <>
                                          <TableCell
                                            className={classes.noBorderRight}
                                          >
                                            <div
                                              className={
                                                classes.tableCellContainer
                                              }
                                            >
                                              <Input
                                                inputProps={{min: 0}}
                                                required
                                                name={`elements.${index}.lateralExpansion1`}
                                                value={
                                                  element.lateralExpansion1
                                                }
                                                type={"number"}
                                                onChange={(event) =>
                                                  change(
                                                    `elements.${index}.lateralExpansion1`,
                                                    event
                                                  )
                                                }
                                                onBlur={() =>
                                                  setFieldValue(
                                                    `elements.${index}.lateralExpansion1`,
                                                    element.lateralExpansion1
                                                  )
                                                }
                                                error={
                                                  Boolean(
                                                    getIn(
                                                      errors,
                                                      `${elementPath}.lateralExpansion1`
                                                    )
                                                  ) &&
                                                  getIn(
                                                    touched,
                                                    `${elementPath}.lateralExpansion1`
                                                  )
                                                }
                                                errorMessage={getIn(
                                                  errors,
                                                  `${elementPath}.lateralExpansion1`
                                                )}
                                              />
                                              <Input
                                                inputProps={{min: 0}}
                                                required
                                                name={`elements.${index}.lateralExpansion2`}
                                                value={
                                                  element.lateralExpansion2
                                                }
                                                type={"number"}
                                                onChange={(event) =>
                                                  change(
                                                    `elements.${index}.lateralExpansion2`,
                                                    event
                                                  )
                                                }
                                                onBlur={() =>
                                                  setFieldValue(
                                                    `elements.${index}.lateralExpansion2`,
                                                    element.lateralExpansion2
                                                  )
                                                }
                                                error={
                                                  Boolean(
                                                    getIn(
                                                      errors,
                                                      `${elementPath}.lateralExpansion2`
                                                    )
                                                  ) &&
                                                  getIn(
                                                    touched,
                                                    `${elementPath}.lateralExpansion2`
                                                  )
                                                }
                                                errorMessage={getIn(
                                                  errors,
                                                  `${elementPath}.lateralExpansion2`
                                                )}
                                              />
                                              <Input
                                                inputProps={{min: 0}}
                                                required
                                                name={`elements.${index}.lateralExpansion3`}
                                                value={
                                                  element.lateralExpansion3
                                                }
                                                type={"number"}
                                                onChange={(event) =>
                                                  change(
                                                    `elements.${index}.lateralExpansion3`,
                                                    event
                                                  )
                                                }
                                                onBlur={() =>
                                                  setFieldValue(
                                                    `elements.${index}.lateralExpansion3`,
                                                    element.lateralExpansion3
                                                  )
                                                }
                                                error={
                                                  Boolean(
                                                    getIn(
                                                      errors,
                                                      `${elementPath}.lateralExpansion3`
                                                    )
                                                  ) &&
                                                  getIn(
                                                    touched,
                                                    `${elementPath}.lateralExpansion3`
                                                  )
                                                }
                                                errorMessage={getIn(
                                                  errors,
                                                  `${elementPath}.lateralExpansion3`
                                                )}
                                              />
                                            </div>
                                          </TableCell>
                                          <TableCell
                                            className={classes.noBorderLeft}
                                          >
                                            {elements[index]
                                              .lateralExpansion1 &&
                                            elements[index].lateralExpansion2 &&
                                            elements[index].lateralExpansion3
                                              ? averageTemp(
                                                  elements[index]
                                                    .lateralExpansion1,
                                                  elements[index]
                                                    .lateralExpansion2,
                                                  elements[index]
                                                    .lateralExpansion3
                                                )
                                              : "fill all"}
                                          </TableCell>
                                        </>
                                      )}
                                      {showShearArea && (
                                        <>
                                          <TableCell
                                            className={classes.noBorderRight}
                                          >
                                            <div
                                              className={
                                                classes.tableCellContainer
                                              }
                                            >
                                              <Input
                                                required
                                                inputProps={{min: 0}}
                                                name={`elements.${index}.shearArea1`}
                                                value={element.shearArea1}
                                                type="number"
                                                onChange={(event) =>
                                                  change(
                                                    `elements.${index}.shearArea1`,
                                                    event
                                                  )
                                                }
                                                onBlur={() =>
                                                  setFieldValue(
                                                    `elements.${index}.shearArea1`,
                                                    element.shearArea1
                                                  )
                                                }
                                                error={
                                                  Boolean(
                                                    getIn(
                                                      errors,
                                                      `${elementPath}.shearArea1`
                                                    )
                                                  ) &&
                                                  getIn(
                                                    touched,
                                                    `${elementPath}.shearArea1`
                                                  )
                                                }
                                                errorMessage={getIn(
                                                  errors,
                                                  `${elementPath}.shearArea1`
                                                )}
                                              />
                                              <Input
                                                required
                                                inputProps={{min: 0}}
                                                name={`elements.${index}.shearArea2`}
                                                value={element.shearArea2}
                                                type="number"
                                                onChange={(event) =>
                                                  change(
                                                    `elements.${index}.shearArea2`,
                                                    event
                                                  )
                                                }
                                                onBlur={() =>
                                                  setFieldValue(
                                                    `elements.${index}.shearArea2`,
                                                    element.shearArea2
                                                  )
                                                }
                                                error={
                                                  Boolean(
                                                    getIn(
                                                      errors,
                                                      `${elementPath}.shearArea2`
                                                    )
                                                  ) &&
                                                  getIn(
                                                    touched,
                                                    `${elementPath}.shearArea2`
                                                  )
                                                }
                                                errorMessage={getIn(
                                                  errors,
                                                  `${elementPath}.shearArea2`
                                                )}
                                              />
                                              <Input
                                                required
                                                inputProps={{min: 0}}
                                                name={`elements.${index}.shearArea3`}
                                                value={element.shearArea3}
                                                type="number"
                                                onChange={(event) =>
                                                  change(
                                                    `elements.${index}.shearArea3`,
                                                    event
                                                  )
                                                }
                                                onBlur={() =>
                                                  setFieldValue(
                                                    `elements.${index}.shearArea3`,
                                                    element.shearArea3
                                                  )
                                                }
                                                error={
                                                  Boolean(
                                                    getIn(
                                                      errors,
                                                      `${elementPath}.shearArea3`
                                                    )
                                                  ) &&
                                                  getIn(
                                                    touched,
                                                    `${elementPath}.shearArea3`
                                                  )
                                                }
                                                errorMessage={getIn(
                                                  errors,
                                                  `${elementPath}.shearArea3`
                                                )}
                                              />
                                            </div>
                                          </TableCell>
                                          <TableCell
                                            className={classes.noBorderLeft}
                                          >
                                            {elements[index].shearArea1 &&
                                            elements[index].shearArea2 &&
                                            elements[index].shearArea3
                                              ? averageTemp(
                                                  elements[index].shearArea1,
                                                  elements[index].shearArea2,
                                                  elements[index].shearArea3
                                                )
                                              : "fill all"}
                                          </TableCell>
                                        </>
                                      )}
                                      <TableCell>
                                        {getTimesMin(
                                          [
                                            element.energyJoule1,
                                            element.energyJoule2,
                                            element.energyJoule3
                                          ],
                                          elementRequirements.single || 0
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        <IconButton
                                          disabled={elements.length === 1}
                                          color="secondary"
                                          onClick={() => remove(index)}
                                          size="large"
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                    {!isEmpty(elementRequirements) && (
                                      <TableRow
                                        className={classes.tableExceptions}
                                      >
                                        <TableCell />
                                        <TableCell />
                                        <TableCell />
                                        <TableCell />
                                        {showNotchPosition && <TableCell />}
                                        <TableCell />
                                        {getFooterCell(
                                          elementRequirements.single,
                                          element.orientation,
                                          "single"
                                        )}
                                        {getFooterCell(
                                          elementRequirements.average,
                                          element.orientation,
                                          "average"
                                        )}
                                        {showLateral && (
                                          <>
                                            {Number(
                                              elementRequirements.singleLateralExpansion
                                            ) ? (
                                              getFooterCell(
                                                elementRequirements.singleLateralExpansion,
                                                element.orientation,
                                                "singleLateralExpansion"
                                              )
                                            ) : (
                                              <TableCell>N/A</TableCell>
                                            )}
                                            {Number(
                                              elementRequirements.averageLateralExpansion
                                            ) ? (
                                              getFooterCell(
                                                elementRequirements.averageLateralExpansion,
                                                element.orientation,
                                                "averageLateralExpansion"
                                              )
                                            ) : (
                                              <TableCell>N/A</TableCell>
                                            )}
                                          </>
                                        )}
                                        {showShearArea && (
                                          <>
                                            {Number(
                                              elementRequirements.singleShearArea
                                            ) ? (
                                              getFooterCell(
                                                elementRequirements.singleShearArea,
                                                element.orientation,
                                                "singleShearArea"
                                              )
                                            ) : (
                                              <TableCell>N/A</TableCell>
                                            )}
                                            {Number(
                                              elementRequirements.averageShearArea
                                            ) ? (
                                              getFooterCell(
                                                elementRequirements.averageShearArea,
                                                element.orientation,
                                                "averageShearArea"
                                              )
                                            ) : (
                                              <TableCell>N/A</TableCell>
                                            )}
                                          </>
                                        )}
                                      </TableRow>
                                    )}
                                  </>
                                );
                              })}
                            </TableBody>
                          )}

                          <TableFooter className={classes.tableExceptions}>
                            <TableRow>
                              <TableCell
                                colSpan={
                                  7 + (showLateral && 2) + (showShearArea && 2)
                                }
                              />
                              <TableCell>{"\u2264 1"}</TableCell>
                              <TableCell>
                                <Button
                                  className={classes.addButton}
                                  variant="contained"
                                  color="primary"
                                  onClick={() =>
                                    push({
                                      ...ELEMENT,
                                      temperature,
                                      unit: temperatureUnits.CELSIUS
                                    })
                                  }
                                >
                                  Add
                                </Button>
                              </TableCell>
                            </TableRow>
                          </TableFooter>
                        </Table>
                      </div>
                    );
                  }}
                </FieldArray>
              </Grid>
              {impactTestCurve && (
                <Grid item xs={12}>
                  <ImpactCurve
                    elements={elements.map((element) => ({
                      ...element,
                      values: [
                        element.energyJoule1,
                        element.energyJoule2,
                        element.energyJoule3
                      ]
                    }))}
                    valuesLabel="Absorbed energy [J]"
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <TestFooter
                  onSubmit={onSubmit}
                  result={isValid && getTestResult(props.values)}
                />
              </Grid>
            </Grid>
          );
        }}
      />
    </>
  );
};

export default withStyles(ImpactTest, styles);
