import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";
import {TableStore} from "@core/stores";
import axios from "axios";
import {action, makeObservable, observable} from "mobx";
import {groupBy, prop} from "ramda";
import {ALL_TESTS} from "@core/constants/test";
import {SUB_TABS_HANDLER_BY_TAB} from "../SingleMaterialCampaign/services";
import {TABS} from "../SingleMaterialCampaign/constants";

class SingleMaterialCampaignStore {
  constructor() {
    makeObservable(this, {
      campaigns: observable,
      campaign: observable,
      testsByCategory: observable,
      singleProducts: observable,
      subTabsByTab: observable,
      allTests: observable,
      getCampaignById: action,
      updateCampaignById: action,
      updateCampaign: action,
      updateSingleProducts: action,
      getTestsByCategory: action,
      getAllTests: action,
      createTest: action,
      removeTest: action,
      updateTest: action,
      importTests: action,
      getSubTabs: action,
    });
  }

  campaigns = new TableStore({
    loader: (queryParams) => {
      const newQueryParams = {...this.campaigns.fetch, ...queryParams};
      const {offset, limit, sort} = newQueryParams;

      const params = {
        sort,
        offset,
        limit,
      };

      this.campaigns.setFetch(newQueryParams);

      return axios.get(ROUTES.SINGLE_MATERIAL_CAMPAIGN[ACTIONS.ALL], {params});
    }
  });

  singleProducts = new TableStore({
    loader: (queryParams) => {
      const newQueryParams = {...this.singleProducts.fetch, ...queryParams};
      const {offset, limit, sort, singleCampaign} = newQueryParams;

      const params = {
        sort,
        offset,
        limit,
        singleCampaign,
      };

      this.singleProducts.setFetch(newQueryParams);

      return axios.get(ROUTES.SINGLE_PRODUCT[ACTIONS.ALL], {params});
    }
  });

  campaign = {};

  testsByCategory = {};

  subTabsByTab = {};
  
  allTests = new TableStore({
    loader: ({campaignId, ...queryParams}) => {
      const newQueryParams = {...this.singleProducts.fetch, ...queryParams};
      const {offset, limit, sort, availableToSign} = newQueryParams;

      const params = {
        sort,
        offset,
        limit,
        availableToSign,
      };

      this.allTests.setFetch(newQueryParams);

      return axios.get(
        ROUTES.SINGLE_MATERIAL_CAMPAIGN_TESTS[ACTIONS.ALL](campaignId),
        {params: {...this.allTests.fetch, ...params}}
      );
    }
  });

  async getCampaignById(id) {
    const response = await axios.get(ROUTES.SINGLE_MATERIAL_CAMPAIGN[ACTIONS.SINGLE](id));

    this.campaign = response.data;
  }

  updateCampaign(data) {
    this.campaign = {...this.campaign, ...data};
  }

  async updateCampaignById(id, data) {
    const response = await axios.patch(ROUTES.SINGLE_MATERIAL_CAMPAIGN[ACTIONS.UPDATE](id), data);

    this.updateCampaign(response.data);
  }

  async getTestsByCategory(id, category) {
    const response = await axios.get(ROUTES.SINGLE_MATERIAL_CAMPAIGN_TESTS[ACTIONS.ALL](id), {params: {category}});

    this.testsByCategory = {
      ...this.testsByCategory,
      [category]: response.data.items,
    };
  }

  async getAllTests(id) {
    const response = await axios.get(ROUTES.SINGLE_MATERIAL_CAMPAIGN_TESTS[ACTIONS.ALL](id));

    this.testsByCategory = {
      ...this.testsByCategory,
      [ALL_TESTS]: response.data.items,
    };
  }

  async createTest(id, test) {
    const response = await axios.post(ROUTES.SINGLE_MATERIAL_CAMPAIGN_TESTS[ACTIONS.CREATE](id), test);
    await this.getSubTabs(id, TABS.TESTS);

    const category = response.data.category;
    const categoryTests = this.testsByCategory[category] || [];

    const allTests = this.testsByCategory[ALL_TESTS] || [];

    this.testsByCategory = {
      ...this.testsByCategory,
      [ALL_TESTS]: [...allTests, response.data],
      [category]: [...categoryTests, response.data],
    };
  }

  async removeTest(id, category) {
    await axios.delete(ROUTES.TEST[ACTIONS.REMOVE](id));
    this.testsByCategory[category] = this.testsByCategory[category].filter((test) => test._id !== id);
  }

  async updateTest(id, data) {
    const response = await axios.patch(ROUTES.TEST[ACTIONS.UPDATE](id), data);

    const category = response.data.category;
    const categoryTests = this.testsByCategory[category] || [];

    const allTests = this.testsByCategory[ALL_TESTS] || [];

    this.testsByCategory = {
      ...this.testsByCategory,
      [ALL_TESTS]: allTests.map((test) => test._id === id ? response.data : test),
      [category]: categoryTests.map((test) => test._id === id ? response.data : test),
    };
  }

  async importTests(id, tests) {
    await axios.post(ROUTES.SINGLE_MATERIAL_CAMPAIGN_TESTS[ACTIONS.IMPORT](id), {tests});

    const response = await axios.get(ROUTES.SINGLE_MATERIAL_CAMPAIGN_TESTS[ACTIONS.ALL](id));
    await this.getSubTabs(id, TABS.TESTS);

    this.testsByCategory = groupBy(prop("category"), response.data.items);
  }

  async updateSingleProducts(id, singleProductIds){
    await axios.patch(ROUTES.SINGLE_PRODUCT[ACTIONS.LINK](id), {singleProductIds});

    this.campaign = {...this.campaign, singleProducts: singleProductIds};
  }
  
  async getSubTabs(id, tab) {
    const handler = SUB_TABS_HANDLER_BY_TAB[tab];
    
    if(handler) {
      const subTabs = await handler(id) || {};
      this.subTabsByTab = {...this.subTabsByTab, [tab]: subTabs};
    }
  }
}

export default new SingleMaterialCampaignStore();