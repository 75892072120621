import {PO_ITEMS} from "@core/constants/poItems";
import {keys} from "ramda";
import {formatNumber, api5LElongation} from "./services";
import {getConfigFromCondition} from "@core/helpers";
import {ZONES} from "../../../constants/test";

const GLOBAL_REQUIREMENTS = {
  yieldStrengthRange: "",
  tensileStrengthRange: ""
};

const REQUIREMENTS = {
  yeldMin: "",
  yeldMax: "",
  tensMin: "",
  tensMax: "",
  elongation: "",
  reductionOfArea: "",
  yeildTensileRatio: "",
  uniformElongationMin: ""
};

const ELEMENT = {
  position: "",
  specimenId: "",
  fracturAppearance: "",
  dimensionX: "",
  dimensionY: "",
  gaugeLength: "",
  temperature: "",
  breakPosition: "",
  zone: "Base Metal",
  orientation: "",
  heatTreatedThickness: "",
  boltDiameter: "",
  rp: "",
  rm: "",
  a: "",
  reductionOfArea: "",
  uniformElongation: "",
  requirements: REQUIREMENTS
};

const SPECIMEN_TYPES = {
  ROUND: "Round",
  RECTANGULAR: "Rectangular",
  STRIP: "Strip"
};

const DIMENSIONS_BY_SPECIMEN_TYPE = {
  [SPECIMEN_TYPES.ROUND]: {
    x: "Diameter"
  },
  [SPECIMEN_TYPES.RECTANGULAR]: {
    x: "Width",
    y: "Thickness"
  },
  [SPECIMEN_TYPES.STRIP]: {
    x: "Width",
    y: "Thickness"
  }
};

const ELONGATION_SPECIFIERS = {
  GAUGE_LENGTH: "Gauge",
  SPECIMEN: "Specimen",
  ORIENTATION: "Orientation",
  HEAT_TREATED_THICKNESS: "HeatTreatedThickness",
  SPECIMEN_AREA: "SpecimenArea"
};

const STRENGTH_SPECIFIERS = {
  HEAT_TREATED_THICKNESS: "HeatTreatedThickness"
};

const AXC_BY_DIAMETER = {
  6.4: 65,
  8.9: 130,
  12.7: 130
};

const ELONGATION_BY_NORM = {
  "API 5L (2018) PSL1": api5LElongation,
  "API 5L (2018) PSL2": api5LElongation,
  "ASTM A860 (2018)": ({dimensionY, elongation, specimen}) => {
    const tableElongation = elongation && elongation[specimen];

    if (!dimensionY || !tableElongation) return tableElongation;

    let amount = 0;
    let n = 0;

    if (dimensionY < 7.94) {
      amount = 7.94 - dimensionY;
      n = amount / 0.79;
    }

    if (Math.trunc(n) - n === 0) {
      elongation = Number(tableElongation) * (1 - 0.015 * n);
    } else {
      elongation = (48 * dimensionY) / 25.4 + 15;
    }

    return formatNumber(elongation);
  },
  "ASTM A350 (2018)": ({
    heatTreatedThickness,
    specimen,
    elongation: tableElongation
  }) => {
    let elongation;

    switch (specimen) {
      case SPECIMEN_TYPES.ROUND: {
        if (tableElongation) elongation = tableElongation[specimen];

        break;
      }
      case SPECIMEN_TYPES.STRIP: {
        if (!tableElongation) break;

        const formula =
          getConfigFromCondition(
            tableElongation[specimen],
            heatTreatedThickness
          ) || "";
        elongation = eval(formula.replace("t", heatTreatedThickness));
        break;
      }
    }

    return Math.round(elongation);
  },
  "ASTM A234 (2019)": ({
    heatTreatedThickness,
    specimen,
    orientation,
    elongation: tableElongation
  }) => {
    let elongation;

    switch (specimen) {
      case SPECIMEN_TYPES.ROUND: {
        if (tableElongation)
          elongation = tableElongation[specimen][orientation];

        break;
      }
      case SPECIMEN_TYPES.RECTANGULAR: {
        if (!tableElongation) break;

        const config =
          getConfigFromCondition(
            tableElongation[specimen],
            heatTreatedThickness
          ) || {};
        const formula = config[orientation] || "";
        elongation = eval(formula.replace("t", heatTreatedThickness));
        break;
      }
    }

    return Math.round(elongation * 2) / 2;
  },
  "ASTM A333 (2018)": ({
    heatTreatedThickness,
    specimen,
    orientation,
    gaugeLength,
    elongation: tableElongation
  }) => {
    let elongation;

    switch (specimen) {
      case SPECIMEN_TYPES.ROUND: {
        if (!tableElongation) break;

        const config =
          getConfigFromCondition(tableElongation[specimen], gaugeLength) || {};
        elongation = config[orientation];
        break;
      }
      case SPECIMEN_TYPES.STRIP: {
        if (!heatTreatedThickness || !orientation || !tableElongation) break;

        const formula = tableElongation[specimen][orientation] || "";
        elongation = eval(formula.replace("t", heatTreatedThickness));
        break;
      }
    }

    return Math.round(elongation);
  }
};

const ISO_TEST_STANDARDS = ["ISO 6892-1:2019", "ISO 6892-2:2018"];

const TEST_STANDARDS = [
  "ASTM A370 (2020)",
  "ASTM A370 (2021)",
  "ASTM E8 (2021)",
  "ASTM E21 (2020)",
  ...ISO_TEST_STANDARDS
];

const ZONE = [
  "Base Metal",
  "Weld Metal",
  "Heat affected zone",
  "CRA",
  "Weld metal weld overlay"
];

const BREAK_POSITION = ["Out Weld", "In Weld"];

const MIN_ELEVATED_TEMPERATURE = 40;

const EXCEPTIONS = {
  "QCP ITP-01123-124277-BuBi MLP (NOA-BUT-00011)": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    elements: [
      {
        ...ELEMENT,
        zone: ZONES.BASE_METAL,
        orientation: "Longitudinal",
        requirements: {
          ...REQUIREMENTS,
          ...GLOBAL_REQUIREMENTS,
          yieldStrengthRange: 100,
          tensileStrengthRange: 100,
          yeldMin: 485,
          yeldMax: 605,
          tensMin: 570,
          tensMax: 720,
          yieldTensileRatios: 0.93,
          elongation: 18,
          uniformElongationMin: 5
        }
      },
      {
        ...ELEMENT,
        zone: ZONES.BASE_METAL,
        orientation: "Transversal",
        requirements: {
          ...REQUIREMENTS,
          ...GLOBAL_REQUIREMENTS,
          yieldStrengthRange: 100,
          tensileStrengthRange: 100,
          yeldMin: 485,
          yeldMax: 605,
          tensMin: 570,
          tensMax: 720,
          yieldTensileRatios: 0.9,
          elongation: 18
        }
      },
      {
        ...ELEMENT,
        zone: ZONES.CRA,
        requirements: {
          ...REQUIREMENTS,
          yeldMin: 207,
          tensMin: 515,
          elongation: 40
        }
      },
      {
        ...ELEMENT,
        zone: ZONES.WELD_METAL,
        orientation: "Longitudinal",
        requirements: {
          ...REQUIREMENTS,
          yeldMin: 415,
          tensMin: 450,
          elongation: 30
        }
      },
      {
        ...ELEMENT,
        zone: ZONES.TRANSITION_AREA_LINER,
        orientation: "Longitudinal",
        requirements: {
          ...REQUIREMENTS,
          tensMin: 450
        }
      },
      {
        ...ELEMENT,
        zone: ZONES.WELD_CENTRAL_LINE,
        orientation: "Longitudinal",
        requirements: {
          ...REQUIREMENTS,
          tensMin: 450
        }
      }
    ]
  },
  "QCP ITP-00923-124277 (NOA-BUT-00013) - HRB - MPQT (Hot tensile) (After reeling)":
    {
      company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
      elements: [
        {
          ...ELEMENT,
          zone: ZONES.WELD_METAL,
          requirements: {
            ...REQUIREMENTS,
            yeldMin: 140,
            temperature: 115
          }
        }
      ]
    },
  "QCP ITP-00923-124277 (NOA-BUT-00013) - HRB - MPQT (Hot tensile)": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    elements: [
      {
        ...ELEMENT,
        zone: ZONES.BASE_METAL,
        requirements: {
          ...REQUIREMENTS,
          yeldMin: 415,
          temperature: 115
        }
      },
      {
        ...ELEMENT,
        zone: ZONES.WELD_METAL,
        requirements: {
          ...REQUIREMENTS,
          yeldMin: 140,
          temperature: 115
        }
      }
    ]
  },
  "QCP ITP-00923-124277 (NOA-BUT-00013) - HRB - MPQT (After reeling)": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    elements: [
      {
        ...ELEMENT,
        zone: "Base Metal",
        orientation: "Longitudinal",
        requirements: {
          ...REQUIREMENTS,
          yeldMin: 450,
          yeldMax: 570,
          tensMin: 535,
          tensMax: 655,
          elongation: 15,
          uniformElongation: 5
        }
      },
      {
        ...ELEMENT,
        zone: "Weld Metal",
        orientation: "Longitudinal",
        requirements: {
          ...REQUIREMENTS,
          yeldMin: 450,
          tensMin: 535,
          elongation: 15,
          uniformElongation: 5
        }
      }
    ]
  },
  "QCP ITP-00923-124277 (NOA-BUT-00013) - HRB - MPQT": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    elements: [
      {
        ...ELEMENT,
        zone: "Base Metal",
        orientation: "Longitudinal",
        requirements: {
          ...REQUIREMENTS,
          yeldMin: 450,
          yeldMax: 570,
          tensMin: 535,
          tensMax: 655,
          yeildTensileRatio: 0.9,
          elongation: 18,
          uniformElongation: 5
        }
      },
      {
        ...ELEMENT,
        zone: "Base Metal",
        orientation: "Transversal",
        requirements: {
          ...REQUIREMENTS,
          yeldMin: 450,
          yeldMax: 570,
          tensMin: 535,
          tensMax: 655,
          yeildTensileRatio: 0.9,
          elongation: 18
        }
      },
      {
        ...ELEMENT,
        zone: "CRA",
        requirements: {
          ...REQUIREMENTS,
          yeldMin: 207,
          tensMin: 482,
          elongation: 25
        }
      },
      {
        ...ELEMENT,
        orientation: "Transversal",
        zone: "CRA",
        requirements: {
          ...REQUIREMENTS,
          tensMin: 482
        }
      },
      {
        ...ELEMENT,
        zone: "Weld Metal",
        orientation: "Longitudinal",
        requirements: {
          ...REQUIREMENTS,
          yeldMin: 450,
          tensMin: 535,
          elongation: 18,
          uniformElongation: 5
        }
      },
      {
        ...ELEMENT,
        zone: "Weld Metal",
        orientation: "Transversal",
        requirements: {
          ...REQUIREMENTS,
          tensMin: 535,
          tensMax: 655
        }
      }
    ]
  },
  "QCP ITP-00923-124277 (NOA-BUT-00013) - HRB": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    elements: [
      {
        ...ELEMENT,
        zone: "Base Metal",
        orientation: "Longitudinal",
        requirements: {
          ...REQUIREMENTS,
          yeldMin: 450,
          yeldMax: 570,
          tensMin: 535,
          tensMax: 655,
          yeildTensileRatio: 0.9,
          elongation: 18,
          uniformElongation: 5
        }
      },
      {
        ...ELEMENT,
        zone: "Base Metal",
        orientation: "Transversal",
        requirements: {
          ...REQUIREMENTS,
          yeldMin: 450,
          yeldMax: 570,
          tensMin: 535,
          tensMax: 655,
          yeildTensileRatio: 0.9,
          elongation: 18
        }
      },
      {
        ...ELEMENT,
        zone: "Weld Metal",
        orientation: "Transversal",
        requirements: {
          ...REQUIREMENTS,
          tensMin: 535,
          tensMax: 655
        }
      }
    ]
  },
  "QCP QP-OST-HF (YGG-BUT-00068)": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    yeldMin: 207,
    yeldMax: 340,
    tensMin: 485,
    elongation: 40,
    reductionOfArea: 50
  },
  "QCP ITP 030/24-130786 (YGG-BUT-00073)": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    elements: [
      {
        ...ELEMENT,
        zone: "Base Metal",
        requirements: {
          ...REQUIREMENTS,
          yeldMin: 207,
          tensMin: 482,
          elongation: 25
        }
      },
      {
        ...ELEMENT,
        zone: "Weld Metal",
        requirements: {
          ...REQUIREMENTS,
          yeldMin: 207,
          tensMin: 482
        }
      }
    ]
  },
  "QCP CPW-ITP-21-23-HFW (YGG-COP-00005) - MPQT": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    elements: [
      {
        ...ELEMENT,
        zone: "Base Metal",
        requirements: {
          ...REQUIREMENTS,
          yeldMin: 450,
          yeldMax: 570,
          tensMin: 535,
          tensMax: 760,
          tensileStrengthRange: 100,
          yeildTensileRatio: 0.93,
          elongation: 0
        }
      },
      {
        ...ELEMENT,
        zone: "Weld Metal",
        requirements: {
          ...REQUIREMENTS,
          tensMin: 535
        }
      }
    ]
  },
  "QCP CPW-ITP-21-23-HFW (YGG-COP-00005) - MPQT (Hot tensile)": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    poItem: {
      [PO_ITEMS.PO_ITEM_70]: {
        yeldMin: 420,
        temperature: 95
      },
      [PO_ITEMS.PO_ITEM_80]: {
        yeldMin: 420,
        temperature: 95
      }
    }
  },
  "QCP CPW-ITP-21-23-HFW (YGG-COP-00005) - MPQT (After reeling)": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    elements: [
      {
        ...ELEMENT,
        zone: "Base Metal",
        requirements: {
          ...REQUIREMENTS,
          yeldMin: 450,
          yeldMax: 570,
          tensMin: 535,
          tensMax: 760,
          tensileStrengthRange: 100,
          yeildTensileRatio: 0.93,
          elongation: 0
        }
      }
    ]
  },
  "QCP CPW-ITP-21-23-HFW (YGG-COP-00005)": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    elements: [
      {
        ...ELEMENT,
        zone: "Base Metal",
        requirements: {
          ...REQUIREMENTS,
          yeldMin: 450,
          yeldMax: 570,
          tensMin: 535,
          tensMax: 760,
          tensileStrengthRange: 100,
          yeildTensileRatio: 0.93,
          elongation: 0
        }
      },
      {
        ...ELEMENT,
        zone: "Weld Metal",
        requirements: {
          ...REQUIREMENTS,
          tensMin: 535
        }
      }
    ]
  },
  "QCP 4500256818-HFU-QP-VSB (YGG-BUT-00064) - FDPT (Hot tensile)": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    elements: [
      {
        ...ELEMENT,
        orientation: "Transversal",
        requirements: {
          ...REQUIREMENTS,
          yeldMin: 415,
          tensMin: 500,
          temperature: 120
        }
      },
      {
        ...ELEMENT,
        orientation: "Longitudinal",
        requirements: {
          ...REQUIREMENTS,
          yeldMin: 415,
          tensMin: 500,
          temperature: 120
        }
      }
    ]
  },
  "QCP 4500256818-HFU-QP-VSB (YGG-BUT-00064) - FDPT (After reeling)": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    yieldStrengthRange: 450,
    tensileStrengthRange: 535,
    elongation: 15,
    uniformElongation: 2.5,
    isTensileStrengthValid: ({rp, rm}) => Number(rm) > Number(rp)
  },
  "QCP 4500256818-HFU-QP-VSB (YGG-BUT-00064)": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    elements: [
      {
        ...ELEMENT,
        orientation: "Transversal",
        requirements: {
          ...REQUIREMENTS,
          ...GLOBAL_REQUIREMENTS,
          yeldMin: 464,
          yeldMax: 570,
          tensMin: 535,
          tensMax: 655,
          uniformElongationMin: 8,
          yieldStrengthRange: 100,
          tensileStrengthRange: 100,
          yeildTensileRatio: 0.89,
          elongation: 20
        }
      },
      {
        ...ELEMENT,
        orientation: "Longitudinal",
        requirements: {
          ...REQUIREMENTS,
          ...GLOBAL_REQUIREMENTS,
          yeldMin: 470,
          yeldMax: 590,
          tensMin: 535,
          tensMax: 665,
          yieldStrengthRange: 100,
          tensileStrengthRange: 100,
          yeildTensileRatio: 0.89,
          elongation: 20,
          uniformElongationMin: 5
        }
      }
    ]
  },
  "QCP 9348 (YGG-TGS-00010 - GL Reelable)": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    yeldMin: 450,
    yeldMax: 570,
    tensMin: 535,
    tensMax: 655,
    yeildTensileRatio: 0.89,
    elongation: 24,
    uniformElongationMin: 5,
    yieldStrengthRange: 100
  },
  "QCP 10378 (YGG-TGS-00045 - GL Pipe)": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    yeldMin: 450,
    yeldMax: 570,
    tensMin: 535,
    tensMax: 655,
    elongation: 24,
    yeildTensileRatio: 0.93
  },
  "QCP 10378 (YGG-TGS-00045 - GL Pipe) - FDPT (Hot tensile)": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    temperature: 80,
    yieldStrengthRange: 432,
    tensileStrengthRange: 517,
    yieldTensileRatios: 0.93,
    elongation: 5
  },
  "QCP 7928 Rev. 2": {
    company: ["TenarisLab", "Tremblay Inc."],
    yeldMin: 415,
    yeldMax: 565,
    tensMin: 520,
    tensMax: 760,
    elongation: 24,
    yeildTensileRatio: 0.93
  },
  "QCP 6916 Rev. 3": {
    company: ["TenarisLab", "Tremblay Inc."],
    poItem: {
      [PO_ITEMS.PO_ITEM_1]: {
        yeldMin: 450,
        yeldMax: 570,
        tensMin: 535,
        tensMax: 655,
        elongation: 18,
        yeildTensileRatio: 0.87,
        uniformElongationMin: 8
      },
      [PO_ITEMS.PO_ITEM_2]: {
        yeldMin: 450,
        yeldMax: 570,
        tensMin: 535,
        tensMax: 760,
        yeildTensileRatio: 0.92
      }
    }
  },
  "QCP 6916 Rev. 3 (Hot tensile)": {
    company: ["TenarisLab", "Tremblay Inc."],
    poItem: {
      [PO_ITEMS.PO_ITEM_1]: {
        yeldMin: 390,
        yeldMax: 570,
        tensMin: 475,
        tensMax: 655,
        elongation: 0,
        yeildTensileRatio: 0,
        temperature: 350
      },
      [PO_ITEMS.PO_ITEM_2]: {
        yeldMin: 390,
        yeldMax: 570,
        tensMin: 475,
        tensMax: 760,
        elongation: 0,
        yeildTensileRatio: 0,
        temperature: 350
      }
    }
  },
  "QCP 6916 Rev. 3 (After reeling)": {
    company: ["TenarisLab", "Tremblay Inc."],
    yeldMin: 450,
    yeldMax: 600,
    tensMin: 535,
    tensMax: 760,
    elongation: 15,
    yeildTensileRatio: 0.97
  },
  "QCP 6916 Rev. 3 (After SPWHT)": {
    company: ["TenarisLab", "Tremblay Inc."],
    yeldMin: 450,
    tensMin: 535,
    elongation: 20
  },
  "QCP 5987 Rev. 2": {
    company: ["TenarisLab", "Tremblay Inc."],
    poItem: {
      [PO_ITEMS.PO_ITEM_1]: {
        yieldStrengthRange: 100,
        tensileStrengthRange: 100,
        yieldTensileRatios: {
          label:
            "At least 80% of all heats should have Ys/Ts <= 0.87 the remaining items should have Ys/Ts <= 0.89",
          value:
            "(elements) => {const eightyPercentNumber = Math.ceil(elements.length * 0.8);const yieldTensileRatios = elements.map((element) => element.rp / element.rm);const acceptableTests = yieldTensileRatios.filter((yieldTensileRatio) => yieldTensileRatio <= 0.87);const rest = yieldTensileRatios.filter((yieldTensileRatio) => yieldTensileRatio > 0.87);const isRestAcceptable = rest.every((yieldTensileRatio) => yieldTensileRatio <= 0.89);return acceptableTests.length >= eightyPercentNumber && isRestAcceptable;}"
        },
        specimen: SPECIMEN_TYPES.ROUND,
        elements: [
          {
            ...ELEMENT,
            dimensionX: 12.7,
            orientation: "Longitudinal",
            zone: "Base Metal",
            requirements: {
              yeldMin: 450,
              yeldMax: 570,
              tensMin: 535,
              tensMax: 655,
              elongation: 18
            }
          },
          {
            ...ELEMENT,
            dimensionX: 12.7,
            orientation: "Transversal",
            zone: "Base Metal",
            requirements: {
              yeldMin: 450,
              yeldMax: 570,
              tensMin: 535,
              tensMax: 655,
              elongation: 18
            }
          }
        ]
      },
      [PO_ITEMS.PO_ITEM_2]: {
        specimen: SPECIMEN_TYPES.ROUND,
        elements: [
          {
            ...ELEMENT,
            dimensionX: 12.7,
            orientation: "Longitudinal",
            zone: "Base Metal",
            requirements: {
              yeldMin: 450,
              yeldMax: 570,
              tensMin: 535,
              tensMax: 655,
              elongation: 20,
              yeildTensileRatio: 0.89
            }
          },
          {
            ...ELEMENT,
            dimensionX: 12.7,
            orientation: "Transversal",
            zone: "Base Metal",
            requirements: {
              yeldMin: 450,
              yeldMax: 570,
              tensMin: 535,
              tensMax: 655,
              elongation: 20,
              yeildTensileRatio: 0.89
            }
          }
        ]
      }
    }
  },
  "HS-D-ITP-22-02-02 Rev. 5": {
    company: ["HusteelLab", "Tremblay Inc."],
    yeldMin: 359,
    yeldMax: 530,
    tensMin: 455,
    tensMax: 625,
    elongation: 23
  },
  "HS-D-ITP-23-01-06 Rev. 3": {
    company: ["HusteelLab", "Tremblay Inc."],
    poItem: {
      [PO_ITEMS.PO_ITEM_1]: {
        yeldMin: 359,
        yeldMax: 530,
        tensMin: 455,
        tensMax: 625,
        elongation: 26
      },
      [PO_ITEMS.PO_ITEM_2]: {
        yeldMin: 359,
        yeldMax: 530,
        tensMin: 455,
        tensMax: 625,
        elongation: 26
      },
      [PO_ITEMS.PO_ITEM_3]: {
        yeldMin: 359,
        yeldMax: 530,
        tensMin: 455,
        tensMax: 625,
        elongation: 27
      }
    }
  },
  "FP-ITP Rev. 0": {
    company: ["Tremblay Inc.", "Mechan Lab"],
    yeldMin: 359,
    yeldMax: 530,
    tensMin: 455,
    tensMax: 625,
    elongation: 23
  },
  "MTIP WA0058-4400025974-H06-44004 Rev. 6": {
    company: ["SumitomoLab", "Tremblay Inc."],
    yeldMin: 450,
    yeldMax: 570,
    tensMin: 535,
    tensMax: 655,
    yeildTensileRatio: 0.89,
    uniformElongationMin: 5,
    getElongation: (data) => api5LElongation({...data, lesser: 485}),
    notes: "The plateau is defined as the area within 0.5% of the maximum load",
    diameter: keys(AXC_BY_DIAMETER)
  },
  "MTIP WA0058-4400025974-H06-44004 Rev. 6 (Hot tensile)": {
    company: ["SumitomoLab", "Tremblay Inc."],
    yeldMin: 444,
    temperature: 60
  },
  "MTIP WA0058-4400025974-H06-44004 Rev. 6 - Strain aging": {
    company: ["SumitomoLab", "Tremblay Inc."],
    yeldMin: 450,
    tensMin: 535,
    uniformElongationMin: 1,
    elongation: 15,
    notes: "The plateau is defined as the area within 0.5% of the maximum load"
  },
  "QCP 8263 Rev. 6": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    yieldStrengthRange: 100,
    yeldMin: 450,
    yeldMax: 570,
    tensMin: 535,
    tensMax: 685,
    yeildTensileRatio: 0.93,
    getElongation: api5LElongation,
    diameter: keys(AXC_BY_DIAMETER)
  },
  "QCP 8252 Rev. 6": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    yieldStrengthRange: 100,
    yeldMin: 450,
    yeldMax: 570,
    tensMin: 535,
    tensMax: 655,
    yeildTensileRatio: 0.89,
    getElongation: api5LElongation,
    uniformElongationMin: 5,
    diameter: keys(AXC_BY_DIAMETER)
  },
  "QCP 8251 Rev. 6": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    yieldStrengthRange: 100,
    yeldMin: 450,
    yeldMax: 570,
    tensMin: 535,
    tensMax: 655,
    yeildTensileRatio: 0.89,
    getElongation: api5LElongation,
    uniformElongationMin: 5,
    diameter: keys(AXC_BY_DIAMETER)
  },
  "QCP 8262 Rev. 6": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    poItem: {
      [PO_ITEMS.PO_ITEM_1]: {
        yieldStrengthRange: 100,
        yeldMin: 450,
        yeldMax: 570,
        tensMin: 535,
        tensMax: 655,
        yeildTensileRatio: 0.89,
        getElongation: api5LElongation,
        uniformElongationMin: 5,
        diameter: keys(AXC_BY_DIAMETER)
      },
      [PO_ITEMS.PO_ITEM_2]: {
        yieldStrengthRange: 100,
        yeldMin: 450,
        yeldMax: 570,
        tensMin: 535,
        tensMax: 655,
        yeildTensileRatio: 0.89,
        getElongation: api5LElongation,
        uniformElongationMin: 5,
        diameter: keys(AXC_BY_DIAMETER)
      },
      [PO_ITEMS.PO_ITEM_3]: {
        yeldMin: 485,
        yeldMax: 605,
        tensMin: 570,
        tensMax: 720,
        yeildTensileRatio: 0.93,
        getElongation: api5LElongation,
        diameter: keys(AXC_BY_DIAMETER)
      }
    }
  },
  "QCP 8263 Rev. 6 (Hot tensile)": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    yeldMin: 420,
    tensMin: 505,
    temperature: 100
  },
  "QCP 8262 Rev. 6 (Hot tensile)": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    yeldMin: 420,
    tensMin: 505,
    temperature: 100
  },
  "QCP 8252 Rev. 6 (After reeling)": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    isTensileStrengthValid: ({rp, rm}) => Number(rm) > Number(rp),
    elongation: 0,
    uniformElongationMin: 2.5
  },
  "QCP 8251 Rev. 6 (After reeling)": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    isTensileStrengthValid: ({rp, rm}) => Number(rm) > Number(rp),
    uniformElongationMin: 2.5,
    elongation: 15
  },
  "ITP-BPM-22.20 Rev.6": {
    company: ["Tremblay Inc.", "Berg Pipe"],
    elements: [
      {
        ...ELEMENT,
        position: "full thickness",
        orientation: "Transversal",
        zone: "Base Metal",
        requirements: {
          ...REQUIREMENTS,
          yeldMin: 485,
          yeldMax: 635,
          tensMin: 570,
          tensMax: 713,
          yeildTensileRatio: 0.93,
          elongation: 22.1,
          triggerRetestTensMax: 713,
          triggerRetestYeldMin: 500,
          retest: {
            retestNumber: 2,
            yeldMin: 485,
            yeldMax: 635,
            tensMin: 570,
            tensMax: 760,
            elongation: 22.1
          }
        }
      },
      {
        ...ELEMENT,
        position: "full thickness",
        orientation: "Longitudinal",
        zone: "Base Metal",
        requirements: {
          ...REQUIREMENTS,
          yeldMin: 485,
          tensMin: 570,
          elongation: 22.1
        }
      },
      {
        ...ELEMENT,
        position: "full thickness",
        orientation: "Transversal",
        zone: "Weld Metal",
        requirements: {
          ...REQUIREMENTS,
          tensMin: 570
        }
      }
    ]
  },
  get "ITP-BPM-22.20 Rev.6 MPQT"() {
    return this["ITP-BPM-22.20 Rev.6"];
  },
  "ITP 011/23/124277 Rev. 05": {
    company: ["Tremblay Inc."],
    elements: [
      {
        ...ELEMENT,
        orientation: "Longitudinal",
        zone: "CRA",
        requirements: {
          ...REQUIREMENTS,
          yeldMin: 207,
          tensMin: 515,
          elongation: 40
        }
      },
      {
        ...ELEMENT,
        orientation: "Transversal",
        zone: "CRA",
        requirements: {
          ...REQUIREMENTS,
          yeldMin: 207,
          tensMin: 515,
          elongation: 40
        }
      },
      {
        ...ELEMENT,
        orientation: "Longitudinal",
        zone: "Base metal",
        requirements: {
          ...REQUIREMENTS,
          yeldMin: 485,
          yeldMax: 605,
          tensMin: 570,
          tensMax: 720,
          yeildTensileRatio: 0.93,
          elongation: 18,
          uniformElongationMin: 5
        }
      },
      {
        ...ELEMENT,
        orientation: "Transversal",
        zone: "Base metal",
        requirements: {
          ...REQUIREMENTS,
          yeldMin: 485,
          yeldMax: 605,
          tensMin: 570,
          tensMax: 720,
          yeildTensileRatio: 0.9,
          elongation: 18
        }
      },
      {
        ...ELEMENT,
        orientation: "Longitudinal",
        zone: "Weld metal weld overlay",
        requirements: {
          ...REQUIREMENTS,
          yeldMin: 415,
          tensMin: 450,
          elongation: 30
        }
      },
      {
        ...ELEMENT,
        orientation: "Longitudinal",
        zone: "Weld metal weld overlay",
        requirements: {
          ...REQUIREMENTS,
          tensMin: 450
        }
      }
    ]
  },
  get "ITP 011/23/124277 Rev. 05 MPQT"() {
    return this["ITP 011/23/124277 Rev. 05"];
  },
  "ITP 011/23/124277 Rev. 05 MPQT (Hot tensile)": {
    company: ["Tremblay Inc."],
    elements: [
      {
        ...ELEMENT,
        orientation: "Longitudinal",
        zone: "Base metal",
        temperature: 115,
        requirements: {
          ...REQUIREMENTS,
          yeldMin: 140
        }
      },
      {
        ...ELEMENT,
        orientation: "Longitudinal",
        zone: "Base metal",
        temperature: 115,
        requirements: {
          ...REQUIREMENTS,
          yeldMin: 449,
          tensMin: 534
        }
      },
      {
        ...ELEMENT,
        orientation: "Transversal",
        zone: "Weld metal weld overlay",
        temperature: 115,
        requirements: {
          ...REQUIREMENTS,
          yeldMin: 449,
          tensMin: 534
        }
      }
    ]
  },
  "QCP 5513 Rev. 2": {
    company: ["TenarisLab", "Tremblay Inc."],
    yieldStrengthRange: 100,
    tensileStrengthRange: 100,
    yieldTensileRatios: {
      label:
        "At least 80% of all heats should have Ys/Ts <= 0.87 the remaining items should have Ys/Ts <= 0.89",
      value:
        "(elements) => {const eightyPercentNumber = Math.ceil(elements.length * 0.8);const yieldTensileRatios = elements.map((element) => element.rp / element.rm);const acceptableTests = yieldTensileRatios.filter((yieldTensileRatio) => yieldTensileRatio <= 0.87);const rest = yieldTensileRatios.filter((yieldTensileRatio) => yieldTensileRatio > 0.87);const isRestAcceptable = rest.every((yieldTensileRatio) => yieldTensileRatio <= 0.89);return acceptableTests.length >= eightyPercentNumber && isRestAcceptable;}"
    },
    elements: [
      {
        ...ELEMENT,
        orientation: "Longitudinal",
        requirements: {
          ...REQUIREMENTS,
          yeldMin: 450,
          yeldMax: 570,
          tensMin: 535,
          tensMax: 655,
          elongation: 20,
          uniformElongationMin: 8
        }
      },
      {
        ...ELEMENT,
        orientation: "Transversal",
        requirements: {
          ...REQUIREMENTS,
          yeldMin: 450,
          yeldMax: 570,
          tensMin: 535,
          tensMax: 655,
          elongation: 20,
          uniformElongationMin: 8
        }
      }
    ]
  },
  "QCP 5513 Rev. 2 (After reeling)": {
    company: ["TenarisLab", "Tremblay Inc."],
    yeldMin: 450,
    tensMin: 535,
    elongation: 15,
    yeildTensileRatio: 0.97
  },
  "QCP 5513 Rev. 2 (Hot tensile)": {
    company: ["TenarisLab", "Tremblay Inc."],
    poItem: {
      [PO_ITEMS.PO_ITEM_1]: {
        elements: [
          {
            ...ELEMENT,
            orientation: "Transversal",
            temperature: 135,
            requirements: REQUIREMENTS
          }
        ]
      },
      [PO_ITEMS.PO_ITEM_2]: {
        elements: [
          {
            ...ELEMENT,
            orientation: "Longitudinal",
            temperature: 124,
            requirements: REQUIREMENTS
          },
          {
            ...ELEMENT,
            orientation: "Transversal",
            temperature: 135,
            requirements: REQUIREMENTS
          }
        ]
      }
    }
  },
  "QCP 5982 Rev. 4": {
    company: ["TenarisLab", "Tremblay Inc."],
    poItem: {
      [PO_ITEMS.PO_ITEM_1]: {
        yieldStrengthRange: 100,
        tensileStrengthRange: 100,
        yeldMin: 450,
        yeldMax: 570,
        tensMin: 535,
        tensMax: 655,
        yeildTensileRatio: 0.89,
        getElongation: ({specimen}) => {
          switch (specimen) {
            case SPECIMEN_TYPES.ROUND:
              return 18;
            case SPECIMEN_TYPES.STRIP:
              return 25;
          }
        }
      },
      get [PO_ITEMS.PO_ITEM_2]() {
        return this[PO_ITEMS.PO_ITEM_1];
      },
      get [PO_ITEMS.PO_ITEM_3]() {
        return {
          ...this[PO_ITEMS.PO_ITEM_1],
          tensMax: 760
        };
      },
      get [PO_ITEMS.PO_ITEM_4]() {
        return this[PO_ITEMS.PO_ITEM_3];
      }
    }
  },
  "CPW-ITP-24-23-SAWL Rev. 3": {
    company: ["Tremblay Inc."],
    diameter: keys(AXC_BY_DIAMETER),
    elements: [
      {
        ...ELEMENT,
        orientation: "Transversal",
        zone: "Base Metal",
        requirements: {
          ...REQUIREMENTS,
          yeldMin: 360,
          yeldMax: 525,
          tensMin: 460,
          tensMax: 760,
          yeildTensileRatio: 0.93,
          getElongation: api5LElongation
        }
      },
      {
        ...ELEMENT,
        orientation: "Transversal",
        zone: "Weld Metal",
        requirements: {
          ...REQUIREMENTS,
          tensMin: 460
        }
      }
    ]
  },
  "CPW-ITP-24-23-SAWL Rev. 3 MPQT": {
    company: ["Tremblay Inc."],
    diameter: keys(AXC_BY_DIAMETER),
    elements: [
      {
        ...ELEMENT,
        orientation: "Transversal",
        zone: "Base Metal",
        requirements: {
          ...REQUIREMENTS,
          yeldMin: 360,
          yeldMax: 525,
          tensMin: 460,
          tensMax: 760,
          yeildTensileRatio: 0.93,
          getElongation: api5LElongation
        }
      },
      {
        ...ELEMENT,
        orientation: "Transversal",
        zone: "Base Metal",
        requirements: {
          ...REQUIREMENTS,
          yeldMin: 360,
          yeldMax: 525,
          tensMin: 460,
          tensMax: 760,
          yeildTensileRatio: 0.93,
          getElongation: api5LElongation
        }
      },
      {
        ...ELEMENT,
        orientation: "Longitudinal",
        zone: "Base Metal",
        requirements: REQUIREMENTS
      },
      {
        ...ELEMENT,
        orientation: "Longitudinal",
        zone: "Base Metal",
        requirements: REQUIREMENTS
      },
      {
        ...ELEMENT,
        orientation: "Transversal",
        zone: "Weld Metal",
        requirements: {
          ...REQUIREMENTS,
          tensMin: 460
        }
      },
      {
        ...ELEMENT,
        orientation: "Transversal",
        zone: "Weld Metal",
        requirements: {
          ...REQUIREMENTS,
          tensMin: 460
        }
      },
      {
        ...ELEMENT,
        orientation: "Longitudinal",
        zone: "Weld Metal",
        requirements: {
          ...REQUIREMENTS,
          yeldMin: 360,
          tensMin: 460
        }
      },
      {
        ...ELEMENT,
        orientation: "Longitudinal",
        zone: "Weld Metal",
        requirements: {
          ...REQUIREMENTS,
          yeldMin: 360,
          tensMin: 460
        }
      }
    ]
  },
  "CPW-ITP-24-23-SAWL Rev. 3 MPQT (ISO)": {
    company: ["Tremblay Inc."],
    ...REQUIREMENTS
  },
  "QCP 5983 Rev. 5": {
    company: ["TenarisLab", "Tremblay Inc."],
    poItem: {
      [PO_ITEMS.PO_ITEM_1]: {
        yieldStrengthRange: 100,
        tensileStrengthRange: 100,
        elements: [
          {
            ...ELEMENT,
            dimensionX: 12.7,
            orientation: "Longitudinal",
            zone: "Base Metal",
            requirements: {
              yeldMin: 450,
              yeldMax: 570,
              tensMin: 535,
              tensMax: 655,
              getElongation: ({specimen}) => {
                switch (specimen) {
                  case SPECIMEN_TYPES.ROUND:
                    return 18;
                  case SPECIMEN_TYPES.STRIP:
                    return 25;
                }
              },
              yeildTensileRatio: 0.89
            }
          },
          {
            ...ELEMENT,
            dimensionX: 12.7,
            orientation: "Transversal",
            zone: "Base Metal",
            requirements: {
              yeldMin: 450,
              yeldMax: 570,
              tensMin: 535,
              tensMax: 655,
              getElongation: ({specimen}) => {
                switch (specimen) {
                  case SPECIMEN_TYPES.ROUND:
                    return 18;
                  case SPECIMEN_TYPES.STRIP:
                    return 25;
                }
              },
              yeildTensileRatio: 0.89
            }
          }
        ]
      },
      [PO_ITEMS.PO_ITEM_2]: {
        elements: [
          {
            ...ELEMENT,
            dimensionX: 12.7,
            orientation: "Longitudinal",
            zone: "Base Metal",
            requirements: {
              yeldMin: 450,
              yeldMax: 570,
              tensMin: 535,
              tensMax: 760,
              getElongation: ({specimen}) => {
                switch (specimen) {
                  case SPECIMEN_TYPES.ROUND:
                    return 18;
                  case SPECIMEN_TYPES.STRIP:
                    return 25;
                }
              },
              yeildTensileRatio: 0.89
            }
          },
          {
            ...ELEMENT,
            dimensionX: 12.7,
            orientation: "Transversal",
            zone: "Base Metal",
            requirements: {
              yeldMin: 450,
              yeldMax: 570,
              tensMin: 535,
              tensMax: 760,
              getElongation: ({specimen}) => {
                switch (specimen) {
                  case SPECIMEN_TYPES.ROUND:
                    return 18;
                  case SPECIMEN_TYPES.STRIP:
                    return 25;
                }
              },
              yeildTensileRatio: 0.89
            }
          }
        ]
      },
      [PO_ITEMS.PO_ITEM_3]: {
        yieldStrengthRange: 100,
        tensileStrengthRange: 100,
        elements: [
          {
            ...ELEMENT,
            dimensionX: 12.7,
            orientation: "Longitudinal",
            zone: "Base Metal",
            requirements: {
              yeldMin: 450,
              yeldMax: 570,
              tensMin: 535,
              tensMax: 655,
              getElongation: ({specimen}) => {
                switch (specimen) {
                  case SPECIMEN_TYPES.ROUND:
                    return 18;
                  case SPECIMEN_TYPES.STRIP:
                    return 25;
                }
              },
              yeildTensileRatio: 0.89
            }
          },
          {
            ...ELEMENT,
            dimensionX: 12.7,
            orientation: "Transversal",
            zone: "Base Metal",
            requirements: {
              yeldMin: 450,
              yeldMax: 570,
              tensMin: 535,
              tensMax: 655,
              getElongation: ({specimen}) => {
                switch (specimen) {
                  case SPECIMEN_TYPES.ROUND:
                    return 18;
                  case SPECIMEN_TYPES.STRIP:
                    return 25;
                }
              },
              yeildTensileRatio: 0.89
            }
          }
        ]
      },
      [PO_ITEMS.PO_ITEM_4]: {
        elements: [
          {
            ...ELEMENT,
            dimensionX: 12.7,
            orientation: "Longitudinal",
            zone: "Base Metal",
            requirements: {
              yeldMin: 450,
              yeldMax: 570,
              tensMin: 535,
              tensMax: 760,
              getElongation: ({specimen}) => {
                switch (specimen) {
                  case SPECIMEN_TYPES.ROUND:
                    return 18;
                  case SPECIMEN_TYPES.STRIP:
                    return 25;
                }
              },
              yeildTensileRatio: 0.89
            }
          },
          {
            ...ELEMENT,
            dimensionX: 12.7,
            orientation: "Transversal",
            zone: "Base Metal",
            requirements: {
              yeldMin: 450,
              yeldMax: 570,
              tensMin: 535,
              tensMax: 760,
              getElongation: ({specimen}) => {
                switch (specimen) {
                  case SPECIMEN_TYPES.ROUND:
                    return 18;
                  case SPECIMEN_TYPES.STRIP:
                    return 25;
                }
              },
              yeildTensileRatio: 0.89
            }
          }
        ]
      }
    }
  }
};

export {
  SPECIMEN_TYPES,
  ELONGATION_BY_NORM,
  DIMENSIONS_BY_SPECIMEN_TYPE,
  AXC_BY_DIAMETER,
  ELONGATION_SPECIFIERS,
  TEST_STANDARDS,
  ISO_TEST_STANDARDS,
  STRENGTH_SPECIFIERS,
  ZONE,
  BREAK_POSITION,
  MIN_ELEVATED_TEMPERATURE,
  EXCEPTIONS,
  REQUIREMENTS,
  GLOBAL_REQUIREMENTS,
  ELEMENT
};
