import React, {useEffect, useRef} from "react";
import jStat from "jstat";
import {Chart as ChartJS} from "chart.js";
import useHistogram from "../../hooks/useHistogram";
import {usePlotOptions} from "../../plotContext";
import {getLabel, getPlugins, getScale} from "../../services";
import {COLORS, PLOT_TYPES} from "../../сonstants";
import InfoPlot from "@core/components/Chart/components/InfoPlot";

const TITLE = "Histogram";

const HistogramPlot = ({plotData, testType, testConfig, isFullScreen, isFullPlotView}) => {
  const canvas = useRef(null);
  const plot = useRef(null);

  const {options: plotOptions, paramType} = usePlotOptions();
  const {showAcceptanceCriteria = false, autoBinning = true, binCountWanted, binWidthWanted} = plotOptions;

  const xAxisLabel = getLabel(plotData.units, testType, paramType, testConfig);
  
  const values = plotData?.plot?.datasets && plotData.plot.datasets.length >= 0 ? plotData.plot.datasets[0].data : null;
  const {minY: min = 0, maxY: max = 0} = plotData.minmax ?? {};
  const {labels, frequencies: histogramData, pdf: pdfData} = useHistogram(values, autoBinning, binCountWanted, binWidthWanted);
  const {min: minAxisX , max: maxAxisX} = getScale(values, showAcceptanceCriteria, plotData.minmax);
  const maxAxisY = jStat.max(histogramData);

  useEffect(() => {
    const additionalPlugins = {
      tooltip: {
        callbacks: {
          title: (items) => getTitle(items, labels, xAxisLabel),
        }
      },
    };

    const plugins = {
      ...additionalPlugins,
      ...getPlugins(plotOptions.showAcceptanceCriteria, {x: {min, max}}, isFullPlotView, PLOT_TYPES.HISTOGRAM, testType)
    };

    const chartConfig = {
      data: {
        labels,
        datasets: [
          {
            data: pdfData,
            ...PDF_OPTIONS
          },
          {
            data: histogramData,
            ...HISTOGRAM_OPTIONS,
          }
        ]
      },
      options: {
        ...chartOptions(minAxisX, maxAxisX, maxAxisY, xAxisLabel, isFullPlotView),
        plugins
      },
    };

    plot.current = new ChartJS(canvas.current, chartConfig);

    return () => {
      plot.current.destroy();
    };
  }, [labels, histogramData, pdfData, showAcceptanceCriteria, min, max, minAxisX, autoBinning, isFullScreen]);

  if(!autoBinning && !binCountWanted && !binWidthWanted) {
    return (
      <InfoPlot
        text="Please provide custom bin amount/bin width"
      />
    );
  }

  return (
    <canvas ref={canvas} aria-label={TITLE} role="img" />
  );
};
const chartOptions = (minAxisX, maxAxisX, maxAxisY, xAxisLabel, isFullPlotView) => ({
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: "x",
    intersect: true
  },
  stacked: false,
  scales: {
    x: {
      beginAtZero: true,
      min: minAxisX,
      max: maxAxisX,
      type: "linear",
      grid: {
        display: false
      },
      title: {
        display: isFullPlotView,
        text: xAxisLabel,
      },
    },
    y: {
      beginAtZero: true,
      max: maxAxisY + maxAxisY * 0.1,
      title: {
        display: isFullPlotView,
        text: "Frequency"
      }
    },
    pdf: {
      beginAtZero: true,
      position: "right",
      title: {
        display: false,
      },
      grid: {
        drawOnChartArea: false
      },
      ticks: {
        display: false
      },
    },
  },
});

const getTitle = (items, labels, xAxisLabel) => {
  if (!items.length) {
    return "";
  }

  const item = items[0];
  const x = item.parsed.x;

  const [label1, label2] = labels;
  const difference = label2 - label1;

  const min = x - difference / 2;
  const max = x + difference / 2;

  return `${xAxisLabel}: ${min.toFixed(2)} - ${max.toFixed(2)}`;
};

const HISTOGRAM_OPTIONS = {
  type: "bar",
  label: "Frequency",
  borderWidth: 2,
  barPercentage: 1,
  backgroundColor: COLORS.lightBlue.backgroundColor,
  borderColor: COLORS.lightBlue.borderColor,
  categoryPercentage: 1,
  borderRadius: 5,
  barThickness: "flex",
};

const PDF_OPTIONS = {
  type: "line",
  label: "Probability Density Function",
  yAxisID: "pdf",
  borderColor: COLORS.violet.borderColor,
  backgroundColor: "transparent",
  borderWidth: 2,
  fill: false,
  cubicInterpolationMode: "monotone",
  tension: 0.4,
  pointRadius: 0,
  pointHitRadius: 0,
};

export default HistogramPlot;
