import {TEST_RESULTS} from "@core/constants/testResults";
import {AXC_BY_DIAMETER, ELONGATION_SPECIFIERS, EXCEPTIONS, SPECIMEN_TYPES, STRENGTH_SPECIFIERS} from "./data";
import {getConfigFromCondition} from "@core/helpers";

/**
 * @name checkRp
 * @param {object} requirements - data from config depending on the value
 * @param {number} value - value of 'Rp' from Tensile form
 * @returns {boolean}
 */
const checkRp = (requirements, value) => {
  if(!requirements.yeldMin && !requirements.yeldMax) {
    return true;
  }

  if (requirements.yeldMax){
    return value >= requirements.yeldMin && value <= requirements.yeldMax;
  }

  return value >= requirements.yeldMin;
};

/**
 * @name checkRm
 * @param {object} requirements - data from config depending on the value
 * @param {number} value - value of 'Rm' from Tensile form
 * @returns {boolean}
 */
const checkRm = (requirements, value) => {
  if(!requirements.tensMin && !requirements.tensMax) {
    return true;
  }

  if (requirements.tensMax){
    return value >= requirements.tensMin && value <= requirements.tensMax;
  }

  return value >= requirements.tensMin;
};

/**
 * @name checkA
 * @param {object} requirements - data from config depending on the value
 * @param {number} value - value of 'a' from Tensile form
 * @returns {boolean}
 */
const checkA = (requirements, value) => {
  return !requirements.elongation || value >= requirements.elongation;
};

/**
 * @name checkReductionOfArea
 * @param {object} requirements - data from config depending on the value
 * @param {number} value - value of 'reductionOfArea' from Tensile form
 * @returns {boolean}
 */
const checkReductionOfArea = (requirements, value) => {
  return !requirements.reductionOfArea || value >= requirements.reductionOfArea;
};

/**
 * @name checkReductionOfArea
 * @param {object} requirements - data from config depending on the value
 * @returns {boolean}
 */
const checkYeildTensileRatio = (poItemNumber, requirements, yeild, tens, elements, acceptance) => {
  const exceptions = EXCEPTIONS[acceptance] || {};

  const {yieldTensileRatios = {}, isTensileStrengthValid} =  exceptions.poItem && poItemNumber ?  exceptions.poItem[poItemNumber] : exceptions || {};

  const areRatiosValid =  !yieldTensileRatios.value || eval(yieldTensileRatios.value)(elements);
  const isSingleRatioValid = !requirements.yeildTensileRatio || (yeild / tens)  <= requirements.yeildTensileRatio;

  const customRatioValid = !isTensileStrengthValid || isTensileStrengthValid({rp: yeild, rm: tens});

  return areRatiosValid && isSingleRatioValid && customRatioValid;
};

/**
 * @name checkUniformElongation
 * @param {object} requirements - data from config depending on the value
 * @returns {boolean}
 */
const checkUniformElongation = (requirements, value) => {
  return !requirements.uniformElongationMin || value >= requirements.uniformElongationMin;
};

const checkRange = (maxRange, elements) => {
  const min = Math.min(...elements);
  const max = Math.max(...elements);

  return !maxRange || (max - min) <= maxRange;
};

const checkYeildRange = (maxYeildRange, elements) => {
  const yeildStrengths = elements.map((el) => Number(el.rp));

  return checkRange(maxYeildRange, yeildStrengths);
};

const checkTensileRange = (maxTensileRange, elements) => {
  const tensileStrengths = elements.map((el) => Number(el.rm));

  return checkRange(maxTensileRange, tensileStrengths);
};

const getIsAcceptable = (elements, acceptance, globalRequirements = {}, poItemNumber) => {
  const isElementAcceptable = (el) => {
    const requirements = el.requirements || {};

    return checkRp(requirements, Number(el.rp)) &&
      checkRm(requirements, Number(el.rm)) &&
      checkA(requirements, Number(el.a)) &&
      checkReductionOfArea(requirements, Number(el.reductionOfArea)) &&
      checkYeildTensileRatio(poItemNumber, requirements, el.rp, el.rm, elements, acceptance) &&
      checkUniformElongation(requirements, Number(el.uniformElongation));
  };

  const locallyAcceptable = elements.every(isElementAcceptable);
  const globalAcceptable = checkYeildRange(globalRequirements.yieldStrengthRange, elements) && checkTensileRange(globalRequirements.tensileStrengthRange, elements);

  return locallyAcceptable && globalAcceptable;
};
/**
 *
 * @param {boolean} isAcceptable
 * @param {boolean} isValid
 * @returns {string}  - Acceptable / Not Acceptable
 * @summary checks if checkRp, checkRm, checkRm are all true
 */
const getResult = (isAcceptable) => {
  return isAcceptable ? TEST_RESULTS.ACCEPTABLE : TEST_RESULTS.NOT_ACCEPTABLE;
};

const formatNumber = (number) => {
  if(Number.isInteger(Number(number))) return number;

  return (Math.round(number * 100) / 100).toFixed(2);
};

const api5LElongation = ({tensileMin, specimen, dimensionX, dimensionY = 0, lesser = 458}) => {
  const C = 1940;
  let Axc = 0;

  switch (specimen) {
    case SPECIMEN_TYPES.ROUND: {
      Axc = AXC_BY_DIAMETER[dimensionX] || 0;
      break;
    }
    case SPECIMEN_TYPES.STRIP:
    case SPECIMEN_TYPES.RECTANGULAR: {
      const area = dimensionX * dimensionY;
      Axc = lesser < area ? lesser : Math.round(area / 10) * 10;
      break;
    }
  }

  const elongation = C * Math.pow(Axc, 0.2) / Math.pow(tensileMin, 0.9);

  return formatNumber(elongation);
};

const getCustomExceptionAllowed = (materialData, limit, specimen, element) => {
  const dimensionY = element.dimensionY || specimen === SPECIMEN_TYPES.ROUND;
  const orientation = element.orientation || materialData.InfluencedBy !== ELONGATION_SPECIFIERS.ORIENTATION;
  const gaugeLength = element.gaugeLength || materialData.InfluencedBy !== ELONGATION_SPECIFIERS.GAUGE_LENGTH;
  const heatTreatedThickness = element.heatTreatedThickness || materialData.InfluencedBy !== STRENGTH_SPECIFIERS.HEAT_TREATED_THICKNESS;
  const boltDiameter = element.boltDiameter || !materialData.BoltDiameter;

  return limit && specimen && element.dimensionX && dimensionY && orientation && gaugeLength && heatTreatedThickness && boltDiameter;
};

const getLimitFromCondition = (values, value) => {
  const config = getConfigFromCondition(values, value);

  return Number(config);
};

export {checkRp, checkRm, checkA, checkReductionOfArea, checkYeildTensileRatio, checkUniformElongation, getResult, getIsAcceptable, formatNumber, api5LElongation, getCustomExceptionAllowed, getLimitFromCondition};
