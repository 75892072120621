import TestResultTableCell from "@core/components/TestResultTableCell";
import {getIsWithinRequirements} from "@core/helpers";
import React from "react";
import {adjust} from "ramda";
import {TableCell} from "@mui/material";
import EditableCell from "@core/components/EditableCell";
import {calculateElementValue} from "@core/components/TestForms/ChemicalComposition/services";
import {ChemicalCompositionResult} from "@core/services/testResult/chemicalComposition";
import {checkMissingElement} from "../../helper/checkMissingElement";

const ElementsResults = ({editable, specimenId, elements, propertyName, updateTest}) => {
  const withRequirements = elements.some((el) => el.min || el.max);

  const getRecalculatedElements = (elements) => {
    return elements.map((element) => {
      if(!element.formula) return element;

      return {...element, [propertyName]: calculateElementValue(elements, element.formula, propertyName)};
    });
  };
  
  const updateElement = (value, index) => {
    const newElements = adjust(index, (element) => ({...element, [propertyName]: value}), elements);
    
    const recalculatedElements = getRecalculatedElements(newElements);

    const chemicalCompositionResult = new ChemicalCompositionResult(recalculatedElements);
    const result = chemicalCompositionResult.getResult();

    updateTest({elements: recalculatedElements, result});
  };

  return (
    <>
      <TableCell
        colSpan={withRequirements ? 2 : 1}
      >
        <EditableCell
          disabled={!editable}
          value={specimenId}
          editable={editable}
          setValue={(value) => updateTest({specimenId: value})}
        />
      </TableCell>
      {elements.map((originalEl, index) => {
        const el = checkMissingElement(originalEl);
        const value = el[propertyName];
        const min = el.min || null;
        const max = el.max || null;

        if(editable && !el.formula) {
          return (
            <TableCell>
              <EditableCell
                required
                error={!getIsWithinRequirements(value, min, max)}
                disabled={!editable}
                type="number"
                value={value}
                editable
                setValue={(value) => updateElement(value, index)}
                inputProps={{min: 0}}
              />
            </TableCell>
          );
        }
        
        return (
          <TableCell>
            <TestResultTableCell
              value={value}
              max={max}
              min={min}
            />
          </TableCell>
        );
      })}
    </>
  );
};

export default ElementsResults;