import React, {useEffect, useState} from "react";
import {Button, Grid, MenuItem, FormControlLabel, Checkbox} from "@mui/material";
import {AiOutlineFullscreen, AiOutlineFullscreenExit} from "react-icons/ai";
import {Select} from "@core/components/Form";
import HistogramSettings from "../HistogramSettings";
import usePlotFilter from "../../hooks/usePlotFilter";
import {openFullScreen, closeFullScreen} from "@core/helpers";
import {usePlotOptions} from "../../plotContext";
import {PLOT_TYPES, PARAM_TYPES_BY_TEST_TYPE} from "../../сonstants";

const ADVANCED_SETTINGS_BY_PLOT_TYPE = {
  [PLOT_TYPES.HISTOGRAM]: HistogramSettings,
};

const PlotSettings = ({testType, plotType, plotData, availableParamTypes, plot, isFullScreen}) => {
  const testParams = PARAM_TYPES_BY_TEST_TYPE[testType] || {};

  const {...plotOptions} = usePlotOptions();
  const {toggleShowAcceptanceCriteria, toggleSplitByZones, onNewParamType} = usePlotFilter();
  const {paramType, splitByZones = true} = plotOptions;
  const {showAcceptanceCriteria = false} = plotOptions.options;
  const {minY: min, maxY: max} = plotData.minmax ?? {};

  const [plotWithZones, setPlotWithZones] = useState(false);

  useEffect(() => {
    if(!plotData.plot || !splitByZones) return;
    
    const datasets = plotData.plot.datasets;
    setPlotWithZones(datasets.length > 1);
  }, [plotData.plot]);

  const onParamTypeChange = (event) => {
    const value = event.target.value;
    onNewParamType(value);
  };

  const toggleFullScreen = () => {
    if(!isFullScreen) openFullScreen(plot.current);
    else closeFullScreen();
  };

  const AdvancedSettingsComponent = ADVANCED_SETTINGS_BY_PLOT_TYPE[plotType] || null;

  return (
    <>
      {(availableParamTypes.length > 1) && (
        <Grid item>
          <Select
            menuProps={{
              container: isFullScreen ? plot.current : document.body,
            }}
            label="Value"
            value={paramType}
            onChange={onParamTypeChange}
          >
            {availableParamTypes.map((param) => (
              <MenuItem key={param} value={param}>
                {testParams[param] || param}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      )}
      {(plotWithZones && [PLOT_TYPES.BOX_PLOT, PLOT_TYPES.VIOLIN].includes(plotType)) && (
        <Grid item>
          <FormControlLabel
            control={(
              <Checkbox
                color="primary"
                checked={splitByZones}
                onClick={toggleSplitByZones}
              />
            )}
            label="Split by zones"
          />
        </Grid>
      )}
      {!!(min || max) && (
        <Grid item>
          <FormControlLabel
            control={(
              <Checkbox
                color="primary"
                checked={showAcceptanceCriteria}
                onClick={toggleShowAcceptanceCriteria}
              />
            )}
            label="Show acceptance criteria"
          />
        </Grid>
      )}
      {AdvancedSettingsComponent && (
        <Grid item>
          <AdvancedSettingsComponent
            isFullScreen={isFullScreen}
            plot={plot}
          />
        </Grid>
      )}
      <Grid item>
        <Button
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            "& svg": {
              marginRight: 1,
            }
          }}
          variant="outlined"
          onClick={toggleFullScreen}
        >
          {isFullScreen ? <AiOutlineFullscreenExit size={20} /> : <AiOutlineFullscreen size={20} />}
        Toggle fullscreen
        </Button>
      </Grid>
    </>
  );
};

export default PlotSettings;