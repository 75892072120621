import React from "react";
import {
  FormControl,
  InputLabel,
  InputBase,
  FormHelperText
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import classNames from "classnames";
import styles from "./styles";

const Input = (props) => {
  const {
    classes,
    type,
    name,
    value,
    defaultValue,
    multiline,
    rows,
    label,
    onChange,
    onBlur,
    error,
    errorMessage,
    warning,
    warningMessage,
    info,
    infoMessage,
    inputRef,
    endAdornment,
    startAdornment,
    startAdornmentWrapperClass,
    className,
    onClick,
    disabled,
    placeholder,
    required,
    inputProps,
    inputComponent,
    onKeyDown,
    onKeyUp,
    onFocus,
    customAdornment
  } = props;

  const getEndAdornment = () => {
    if (customAdornment) {
      return customAdornment;
    } else if (endAdornment) {
      return <span className={classes.endAdornment}>{endAdornment}</span>;
    }
  };

  return (
    <FormControl
      variant="standard"
      className={classNames(className, classes.control)}
    >
      {label && (
        <InputLabel
          shrink
          htmlFor={name}
          className={classNames(
            classes.label,
            required && label ? classes.asteriskLabel : null
          )}
        >
          {label}
        </InputLabel>
      )}
      <InputBase
        classes={{
          root: classNames(
            label ? classes.root : classes.rootNoLabel,
            !label && required ? classes.asteriskField : null,
            endAdornment ? classes.customPadding : ""
          ),
          input: classes.input,
          error: classes.error
        }}
        id={name}
        error={error}
        name={name}
        type={type}
        multiline={multiline}
        rows={rows}
        value={value}
        placeholder={placeholder}
        endAdornment={getEndAdornment()}
        startAdornment={
          startAdornment && (
            <span
              className={classNames([
                classes.startAdornment,
                startAdornmentWrapperClass
              ])}
            >
              {startAdornment}
            </span>
          )
        }
        onChange={onChange}
        onBlur={onBlur}
        onClick={onClick}
        disabled={disabled}
        inputProps={inputProps}
        inputComponent={inputComponent}
        inputRef={inputRef}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onFocus={onFocus}
        autoComplete="off"
        defaultValue={defaultValue}
      />
      {error && (
        <FormHelperText
          className={classNames(classes.helperText, classes.helperTextError)}
        >
          {errorMessage}
        </FormHelperText>
      )}
      {warning && (
        <FormHelperText
          className={classNames(classes.helperText, classes.helperTextWarning)}
        >
          {warningMessage}
        </FormHelperText>
      )}
      {info && (
        <FormHelperText
          className={classNames(classes.helperText, classes.helperTextInfo)}
        >
          {infoMessage}
        </FormHelperText>
      )}
    </FormControl>
  );
};

Input.defaultProps = {
  type: "text"
};

export default withStyles(Input, styles);
