import React from "react";
import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  Fade,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {STATUSES, TYPES} from "@core/constants/test";
import {OPERATORS, SPEC_TYPES} from "@core/constants/specifications";
import * as R from "ramda";
import styles from "./styles";
import testConfig from "@core/configs/test";
import Items from "@core/components/Items";
import ChemicalElements from "../ChemicalElements";
import DesiredItems from "../DesiredItems";
import FadeContent from "@core/components/FadeContent";
import {ACCEPTABLE_TEST_RESULTS} from "@core/constants/testResults";
import {getMean} from "@core/helpers";

const FUNCTION_BY_OPERATOR = {
  [OPERATORS.LTE]: (receivedValue, desiredValue) => {
    const desiredNumber = desiredValue.replace(",", ".");

    return R.lte(receivedValue, desiredNumber);
  },
  [OPERATORS.LT]: (receivedValue, desiredValue) => {
    const desiredNumber = desiredValue.replace(",", ".");

    return R.lt(receivedValue, desiredNumber);
  },
  [OPERATORS.GTE]: (receivedValue, desiredValue) => {
    const desiredNumber = desiredValue.replace(",", ".");

    return R.gte(receivedValue, desiredNumber);
  },
  [OPERATORS.GT]: (receivedValue, desiredValue) => {
    const desiredNumber = desiredValue.replace(",", ".");

    return R.gt(receivedValue, desiredNumber);
  },
  [OPERATORS.EQUAL]: (receivedValue, desiredValue) => {
    if(R.is(String, receivedValue)) {
      return String(receivedValue).toLowerCase() === String(desiredValue).toLowerCase();
    }

    const desiredNumber = Number(desiredValue.replace(",", "."));

    return receivedValue === desiredNumber;
  },
  [OPERATORS.BETWEEN]: (receivedValue, desiredValue, desiredValue2) => {
    const desiredNumber = desiredValue.replace(",", ".");
    const desiredNumber2 = desiredValue2.replace(",", ".");

    return R.gte(receivedValue, desiredNumber) && R.lte(receivedValue, desiredNumber2);
  },
  [OPERATORS.CONTAINS]: (receivedValue, desiredValue) => String(receivedValue).toLowerCase().includes(String(desiredValue).toLowerCase())
};

const Specification = (props) => {
  const {classes, open, onClose, certificate, specifications} = props;
  const specification = specifications.find((spec) => spec._id === props.chosenSpecificationId) || {};

  const {config = [], name = ""} = specification;
  /**
   * @name isBinaryRight
   * @summary compares two values
   * @param desiredValue {string || number} specification value of a certain field
   * @param desiredValue2 {string || number} specification value of a certain field
   * @param receivedValue {string || number} actual value of a certain field
   * @param operator? {function} - function from Ramda to calculate binary operations
   * @returns {boolean}
   */
  const isBinaryRight = (desiredValue, desiredValue2, receivedValue, operator) => {
    if (typeof receivedValue === "string" && typeof desiredValue === "string" && !operator) {
      const received = receivedValue.toLowerCase();
      const desired = desiredValue.toLowerCase();

      return received === desired || received.includes(desired);
    }

    const func = FUNCTION_BY_OPERATOR[operator] || FUNCTION_BY_OPERATOR[OPERATORS.EQUAL];

    return func(receivedValue, desiredValue, desiredValue2);
  };

  /**
   * @name generateResObj
   * @summary creates an object from parameters
   * @param isPassed {boolean}
   * @param desiredValue specification value of a certain field
   * @param receivedValue actual value of a certain field
   * @param operator? {function} - function from Ramda to calculate binary operations
   * @returns {{isPassed: *, receivedValue: *, operator: *, desiredValue: *}}
   */
  const generateResObj = (isPassed, desiredValue, operator, receivedValue) => ({
    isPassed,
    desiredValue,
    operator,
    receivedValue
  });

  const validateChemicalComposition = (receivedValue, field, value) => {
    return value.every((element) => {
      const receivedElement = receivedValue.find((el) => el.bm === element.field);

      return isBinaryRight(element.value, element.secondValue, receivedElement ? receivedElement.value : "Not found", element.operator);
    });
  };

  /**
   * @name validateTest
   * @summary checks test for a desired field and its value
   * @param type {string} - type of a test
   * @param field {string} - desired field name
   * @param value {string || number} - desired field value
   * @param secondValue {string || number} - desired field value
   * @param operator? {function} - function from Ramda to calculate binary operations
   * @returns {object} - params about checked test
   */

  const isChemicalCompositionElements = (type, field) => type === TYPES.CHEMICAL && field === "elements";

  const validateTest = (type, field, value, secondValue, operator) => {
    let resultObj = generateResObj(false, isChemicalCompositionElements(type, field) ? <ChemicalElements elements={value} /> : value, operator, "Not found");
    certificate.tests.find((test) => {
      const isFound = test.type === type || (test.type === TYPES.OTHER && test.properties.elements.some((element) => FUNCTION_BY_OPERATOR[OPERATORS.EQUAL](element.test, testConfig[type].title)));

      const isFilled = ![STATUSES.EMPTY, STATUSES.EMPTY, STATUSES.ASSIGNED].includes(test.status);

      if (isFound && isFilled) {
        const fieldFound = !!Object.keys(test.properties).find((key) => key === field);

        if(type === TYPES.CHEMICAL && field === "elements") {
          if(fieldFound) {
            const isPassed = validateChemicalComposition(test.properties[field], field, value, secondValue, operator);
            resultObj = generateResObj(isPassed, <ChemicalElements elements={value} />, operator, <ChemicalElements elementsToShow={R.map(R.prop("field"), value)} elements={test.properties[field]} />);

            return isPassed;
          } else {
            resultObj = generateResObj(false, <ChemicalElements elements={value} />, operator, "Not found");

            return false;
          }
        }

        if(field === "result") {
          const acceptableResults = R.values(ACCEPTABLE_TEST_RESULTS);

          const receivedValueAcceptable = acceptableResults.includes(value);
          const actualValueAcceptable = acceptableResults.includes(test.properties.result);

          const isPassed = receivedValueAcceptable === actualValueAcceptable;
          resultObj = generateResObj(isPassed, value, operator, test.properties.result);

          return isPassed;
        }

        if (fieldFound) {
          const isPassed = isBinaryRight(value, secondValue, test.properties[field], operator);
          resultObj = generateResObj(isPassed, value, operator, test.properties[field]);

          return isPassed;
        }

        const fields = field.split(".") || [];
        const [field1, field2] = fields;

        if (Array.isArray(test.properties[field1])) {
          const values = R.flatten(test.properties[field1].map((obj) => {
            if(field2 === "yeildTensileRatio") return obj.rp / obj.rm;
            
            if(field2 === "average") return getMean([obj.energyJoule1, obj.energyJoule2, obj.energyJoule3]); 

            if(field2 === "single") return [obj.energyJoule1, obj.energyJoule2, obj.energyJoule3];

            return Number(obj[field2]);
          }));

          const isPassed = values.length && values.every((receivedValue) => isBinaryRight(value, secondValue, receivedValue, operator));

          const min = Math.min(...values).toFixed(2);
          const max = Math.max(...values).toFixed(2);
          const avg = getMean(values).toFixed(2);

          resultObj = generateResObj(isPassed, value, operator, `Min: ${min}, Max: ${max}, Avg: ${avg}`);

          return isPassed;
        } else {
          const isPassed = isBinaryRight(value, secondValue, R.path(fields, test.properties), operator);
          resultObj = generateResObj(isPassed, value, operator, R.path(fields, test.properties));

          return isPassed;
        }
      }
    });

    return resultObj;
  };

  const validateCertificateItems = (field, value, secondValue, operator) => {
    const isPassed = value.every((item, index) => {
      const receivedItem = certificate.items[index];

      return item.every((field) => isBinaryRight(field.value, field.secondValue, receivedItem[field.field], field.operator));
    });

    return generateResObj(isPassed, <DesiredItems items={value} />, operator, <Items centered certificate={certificate} />);
  };

  /**
   * @name validateCertificate
   * @summary checks certificate for a desired field and its value
   * @param field {string} - desired field name
   * @param value {string || number} - desired field value
   * @param operator? {function} - function from Ramda to calculate binary operations
   * @returns {object} - params about checked certificate
   */
  const validateCertificate = (field, value, secondValue, operator) => {
    if (field === "items") return validateCertificateItems(field, value, secondValue, operator);

    let resultObj = generateResObj(false, value, operator, "Not found");
    const fieldFound = !!Object.keys(certificate).find((key) => key === field);

    if (fieldFound) {
      const isPassed = isBinaryRight(value, secondValue, certificate[field], operator);
      resultObj = generateResObj(isPassed, value, operator, certificate[field]);
    }

    if (!fieldFound && certificate.properties) {
      const isInProperties = !!Object.keys(certificate.properties).find((key) => key === field);

      if (isInProperties) {
        const isPassed = isBinaryRight(value, secondValue, certificate.properties[field], operator);
        resultObj = generateResObj(isPassed, value, operator, certificate.properties[field]);
      }
    }

    return resultObj;
  };

  /**
   * @name result
   * @summary compares the certificate and its values  and tests to certain specification rules
   * @returns {object} - params about rules mentioned in the specification
   */
  const result = () => {
    return config.reduce((acc, spec) => {
      if (spec.entity === "test") {
        return [...acc, validateTest(spec.type, spec.field, spec.value, spec.secondValue, spec.operator)];
      }

      if (spec.entity === "certificate") return [...acc, validateCertificate(spec.field, spec.value, spec.secondValue, spec.operator)];

      if (spec.entity === "test_results") {
        return [...acc, validateTest(spec.type, "result", spec.value, spec.secondValue, spec.operator)];
      }

      if (spec.entity === "statements") return [...acc, validateTest(TYPES.STATEMENT, "elements.statement", spec.value, spec.secondValue, spec.operator)];
    }, []);
  };

  /**
   * @name convertString
   * @summary concerts string from camel/snake case to pascal case
   * used to convert field names from DB to more user friendly titles
   * @param str {string}
   * @returns
   */
  const convertString = (str) => {
    const camelToNormal = (string) => {
      return string.replace(/([A-Z])/g, " $1")
        .replace(/^./, function(str) {
          return str.toUpperCase();
        });
    };
    const snake_case = str.includes("_");

    if (snake_case) {
      return camelToNormal(str.replace(/(_\w)/g, function(word) {
        return word[1].toUpperCase();
      }));
    }

    return camelToNormal(str);
  };

  /**
   * @name transformOperatorToString
   * @summary converts Ramda functions (lt, lte, gt, gte) to string with binary operator. Default: "="
   * @param funcName {function} - function from Ramda to calculate binary operations
   * @returns {string}
   */
  const transformOperatorToString = (funcName) => {
    switch (funcName) {
      case "lte":
        return "less or equal";
      case "lt":
        return "lower than";
      case "gte":
        return "greater or equal";
      case "gt":
        return "greater";
      case "equal":
        return "equal";
      case "between":
        return "between";
      case "contains":
        return "contains";
    }
  };

  /**
   * @name checkAllTestsApproved
   * @summary checks if specification has certificate -> type -> 3.2, and if so. returns a row for the specification table
   * @returns tableRow with data about checked tests
   */
  const checkAllTestsApproved = () => {
    const isType32 = config.find((S) => S.entity === "certificate" && S.field === "certificateType" && S.value === "3.2");

    if (isType32) {
      const isAllApproved = certificate.tests.every((test) => test.status === STATUSES.APPROVED);
      const approvedCount = certificate.tests.filter((test) => test.status === STATUSES.APPROVED).length;

      return <TableRow>
        <TableCell>All tests are witnessed</TableCell>
        <TableCell align={"center"}>{certificate.tests.length}</TableCell>
        <TableCell align={"center"} className={classes.operator}>equal</TableCell>
        <TableCell align={"center"}>{approvedCount}</TableCell>
        <TableCell className={isAllApproved ? classes.passed : classes.failed}>{isAllApproved ? "Passed" : "Failed"}</TableCell>
      </TableRow>;
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={"lg"}
      onClose={onClose}
      classes={{
        paperWidthLg: classes.paperWidth
      }}
    >
      <Fade in={open} timeout={300}>
        <FadeContent>
          <h6 className={classes.title}>
            {`Checking compliance to specification ${name}`}
          </h6>
          <DialogContent>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow className={classes.header}>
                      <TableCell>Specification</TableCell>
                      <TableCell align={"center"}>Actual value</TableCell>
                      <TableCell align={"center"}>Operator</TableCell>
                      <TableCell align={"center"}>Required value</TableCell>
                      <TableCell align={"center"}>Result</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {config.map((spec, index) => {
                      const res = result()[index];

                      return (
                        <TableRow key={index} hover>
                          <TableCell
                            className={classes.specName}
                          >
                            {spec.entity === "test" ? testConfig[spec.type].title : SPEC_TYPES[spec.entity]}
                            {spec.field ? ` - ${convertString(spec.field)}` : null}
                            {spec.entity === "test_results" && ` - ${testConfig[spec.type].title}`}
                          </TableCell>
                          <TableCell align={"center"}>
                            {res.receivedValue}
                          </TableCell>
                          <TableCell
                            align={"center"}
                            className={classes.operator}
                          >
                            {!res.operator ? "See required value column" : transformOperatorToString(res.operator)}
                          </TableCell>
                          <TableCell align={"center"}>
                            {res.desiredValue}
                          </TableCell>
                          <TableCell
                            align={"center"}
                            className={res.isPassed ? classes.passed : classes.failed}
                          >
                            {res.isPassed ? "Passed" : "Failed"}
                          </TableCell>
                        </TableRow>
                      );
                    }
                    )}
                    {checkAllTestsApproved()}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Close</Button>
          </DialogActions>
        </FadeContent>
      </Fade>
    </Dialog>
  );
};

export default withStyles(Specification, styles);
