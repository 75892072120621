import {getPoItemNumber} from "@core/helpers";
import {Qcp} from "@core/services/qcp";
import React, {useState} from "react";
import {Formik, FieldArray} from "formik";
import * as yup from "yup";
import {partition, prop, keys, times, omit, indexBy, any, propEq} from "ramda";
import {observer} from "mobx-react-lite";
import {
  Grid,
  Button,
  DialogTitle,
  DialogContent,
  Typography
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import Loader from "@core/components/Loader";
import {QCPS} from "@core/constants/qcps";
import {STATUSES, INTERNAL_LAB} from "@core/constants/test";
import modules from "@core/constants/modules";
import Test from "./components/Test";
import InviteLabForm from "./components/InviteLabForm";
import useStores from "../../../useStores";
import styles from "./styles";

const getValidationSchema = (testsWithRequiredWitnesses) =>
  yup.object().shape({
    tests: yup.array().of(
      yup.lazy((item) => {
        const isProducerTest = item.assignee === INTERNAL_LAB;

        return yup.object().shape({
          assignee: isProducerTest
            ? yup.string()
            : yup.object().required("This field is required"),
          witnesses: testsWithRequiredWitnesses.includes(item.type)
            ? yup.array().of(yup.object()).required("This field is required")
            : yup.array().of(yup.object()),
          notes: yup.string(),
          properties: yup.object().shape({
            tags: yup.array().of(yup.string()),
            acceptance: isProducerTest
              ? yup.string().required("This field is required")
              : yup.string(),
            acceptanceItem: isProducerTest
              ? yup.string().required("This field is required")
              : yup.string()
          }),
          confidential: yup.bool()
        });
      })
    )
  });

const getInitialValues = (types, companyId, tags) => ({
  tests: types.map((type) => ({
    assignee: "",
    witnesses: [],
    notes: "",
    type,
    company: companyId,
    status: STATUSES.EMPTY,
    confidential: false,
    properties: {
      tags: tags || [],
      acceptance: "",
      acceptanceItem: ""
    }
  }))
});

const AddTestOrDeclarationForm = ({
  classes,
  types,
  onClose,
  certificates,
  qcps,
  testsWithRequiredWitnesses = [],
  createTests,
  createTestsWithData
}) => {
  const {QcpStore, UserStore, CompanyStore, CertificateStore} = useStores();

  const [isLoading, setIsLoading] = useState(false);

  const laboratories = CompanyStore.meta.filter((company) => {
    return any(propEq(modules.LAB_INTERNAL, "name"), company.modules);
  });

  const witnesses = CompanyStore.meta.filter((company) => {
    return any(propEq(modules.WITNESS, "name"), company.modules);
  });

  const poItem = getPoItemNumber(CertificateStore.certificate.data.lineItem);
  const tags = CertificateStore.certificate.data.tags;

  const getQcpTests = () => {
    return qcps.reduce((tests, qcp) => {
      const config = QCPS[qcp] || {};

      const labToPerformTests =
        laboratories.find((lab) => config.labs?.includes(lab.name)) || {};

      const witnessToInspectTests = config.witnesses
        ?.map((wit) => {
          const witness = witnesses.find((w) => w.name.includes(wit));

          if (witness) {
            return {
              _id: witness._id,
              status: 0
            };
          }
        })
        .filter((w) => !!w);

      const producerTests = config.producerTests?.map((test) => {
        const data = {
          status: STATUSES.FILLED,
          type: test.type,
          properties: test.getProperties
            ? test.getProperties(poItem)
            : test.properties,
          company: UserStore.user.data.company._id
        };

        if (test.witnesses) {
          data.assignee = data.company;
          data.witnesses = witnessToInspectTests;
        }

        return data;
      });

      const labTests = config.labTests?.reduce((tests, test) => {
        if (test.poItems && !test.poItems?.includes(poItem)) {
          return tests;
        }

        const data = {
          type: test.type,
          status: STATUSES.EMPTY,
          assignee: labToPerformTests._id,
          company: UserStore.user.data.company._id,
          properties: test.properties || {}
        };

        if (test.witnesses) {
          data.witnesses = witnessToInspectTests;
        }

        return [...tests, ...times(() => data, test.quantity ?? 1)];
      }, []);

      return [...tests, ...producerTests, ...labTests];
    }, []);
  };

  const createProducerTests = (tests) => {
    if (!tests.length) return;

    const qcpsById = indexBy(prop("_id"), QcpStore.qcps.data);

    const producerTestsWithData = tests.reduce((tests, test) => {
      const qcp = qcpsById[test.properties.acceptanceItem];
      const qcpInstance = new Qcp(qcp);

      test.assignee = "";
      test.properties = {
        ...test.properties,
        ...qcpInstance.getTestProperties(test.type)
      };
      test.status = STATUSES.FILLED;
      test.witnesses = test.witnesses.map((company) => ({
        _id: company._id,
        status: 0
      }));

      const testsToCreate = times(() => test, types[test.type]);
      tests.push(...testsToCreate);

      return tests;
    }, []);

    createTestsWithData(producerTestsWithData);
  };

  const getAssignedTestsToLab = (tests) => {
    return tests.reduce((tests, test) => {
      const testAssigneeId = test.assignee?._id;
      const testWitnesses = test.witnesses?.map((wit) => ({
        _id: wit?._id,
        status: 0
      }));

      const testToCreate = omit(
        ["assignee", "witnesses", "acceptance", "acceptanceItem"],
        test
      );

      if (testAssigneeId) {
        testToCreate.assignee = testAssigneeId;
        testToCreate.witnesses = testWitnesses;
      }

      const testsToCreate = times(() => testToCreate, types[test.type]);
      tests.push(...testsToCreate);

      return tests;
    }, []);
  };

  const onSubmit = async (values) => {
    setIsLoading(true);
    const [testsToAssignToLab, testsToAssignToProducer] = partition(
      (test) => test.assignee !== INTERNAL_LAB,
      values.tests
    );

    const assignedTests = getAssignedTestsToLab(testsToAssignToLab);

    createProducerTests(testsToAssignToProducer);

    const qcpTests = getQcpTests();

    const testsToCreate = [...assignedTests, ...qcpTests];
    const testDataByType = indexBy(prop("type"), testsToCreate);

    if (testsToCreate.length) {
      await createTests(testsToCreate, testDataByType);
    }

    setIsLoading(false);
    onClose();
  };

  return (
    <>
      <DialogTitle>Add test or declaration</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={getInitialValues(
            keys(types),
            UserStore.user.data.company._id,
            tags
          )}
          validationSchema={getValidationSchema(testsWithRequiredWitnesses)}
          onSubmit={onSubmit}
          validateOnMount
        >
          {({values, setFieldValue, handleSubmit, isValid}) => {
            return (
              <Grid container spacing={2}>
                {values.tests.length > 1 && (
                  <InviteLabForm
                    setFieldValue={setFieldValue}
                    tests={values.tests}
                  />
                )}
                <Grid
                  item
                  xs={12}
                  className={values.tests.length > 1 && classes.container}
                >
                  {qcps.map((qcp) => (
                    <Grid item container spacing={3} alignItems="center">
                      <Grid item>
                        <Typography component="h5" variant="h5">
                          {qcp}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                  <FieldArray name="tests">
                    {() =>
                      values.tests.map((test, index) => (
                        <Test
                          key={test.type + index}
                          types={types}
                          index={index}
                          test={test}
                          onClose={onClose}
                          certificates={certificates}
                          createTestWithData={(test) =>
                            createTestsWithData([test])
                          }
                          testsWithRequiredWitnesses={
                            testsWithRequiredWitnesses
                          }
                        />
                      ))
                    }
                  </FieldArray>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="flex-end"
                  className={values.tests.length > 1 && classes.container}
                >
                  <Button
                    disabled={!isValid || isLoading}
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                  >
                    Create Tests
                    {isLoading ? (
                      <div style={{marginLeft: "20px"}}>
                        <Loader size={20} noMargin={true} />
                      </div>
                    ) : null}
                  </Button>
                </Grid>
              </Grid>
            );
          }}
        </Formik>
      </DialogContent>
    </>
  );
};

export default withStyles(observer(AddTestOrDeclarationForm), styles);
