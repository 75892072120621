export default (theme) => {
  const timeLineBlockCore = {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "stretch",
    position: "relative",
    zIndex: 2,
    "& div:firstChild": {
      zIndex: 1,
    }
  };

  const before = {
    content: "''",
    position: "absolute",
    height: theme.spacing(6), // 3 units - margin of wrap for sidebar + content
    top: `-${theme.spacing(6)}`,
    width: 2,
    left: "50%",
    transform: "translate3d(-1px, 0, 0 )",
    backgroundColor: "#878787",
    zIndex: 0,
  };

  const after = {
    content: "''",
    position: "absolute",
    bottom: 0,
    height: "100%",
    width: 2,
    left: "50%",
    transform: "translate3d(-1px, 0, 0 )",
    backgroundColor: "#878787",
    zIndex: 0,
  };

  return {
    timeLineBlock: {
      ...timeLineBlockCore,
      marginLeft: 20
    },
    lineAfter: {
      "&::after": {
        ...after
      }
    },
    lineBefore: {
      "&::before": {
        ...before
      }
    },
    lineBeforeAfter: {
      "&::before": {
        ...before
      },
      "&::after": {
        ...after
      }
    },
    timelineBottom: {
      position: "relative",
      width: "100%",
      display: "flex",
      flex: 1,
      flexDirection: "column-reverse",
      alignItems: "center",
      "& button": {
        zIndex: 3,
      }
    },
    timelineCircle: {
      position: "absolute",
      top: 0,
      background: "#878787",
      display: "block",
      width: 15,
      height: 15,
      borderRadius: 10,
      zIndex: 2,
    },
  };};