import React, {useState, useEffect, useRef} from "react";
import * as yup from "yup";
import {
  Grid,
  MenuItem,
  Dialog,
  Typography,
  DialogContent,
  Button,
  Table,
  TableBody
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {Select, MultipleSelect, Input} from "@core/components/Form";
import {Formik} from "formik";
import {observer} from "mobx-react-lite";
import SelectField from "@core/components/FormikSelect";
import TextField from "@core/components/FormikTextField";
import {isEmpty, any, propEq, indexBy, prop, clone, equals} from "ramda";
import {STATUSES} from "@core/constants/test";
import {RELEENG_PRESET, PRESET_CONDITIONS, TESTS, DECLARATIONS} from "./data";
import testConfig from "@core/configs/test";
import modules from "@core/constants/modules";
import styles from "./styles";
import useStores from "../../../../useStores";
import Treatments from "./Treatments";
import ConfirmationOnModalClose from "@core/components/ConfirmationOnModalClose";

const EMPTY_TEST = {
  witnesses: [],
  properties: {}
};

const AddTestConditioning = observer(
  ({classes, open, onClose, certificate, isPreset = false, prevCondition}) => {
    const validationSchema = yup.object().shape({
      name: yup.string().required("Name is required"),
      presetConditions: yup.string(),
      tests: yup.array().of(
        yup.object().shape({
          assignee: yup.string().required("This field is required"),
          witnesses:
            certificate.certificateType === "3.2"
              ? yup.array().of(yup.object()).required("This field is required")
              : yup.array().of(yup.object()),
          notes: yup.string()
        })
      )
    });

    const initialValues = {
      name: prevCondition?.name || "",
      presetConditions: PRESET_CONDITIONS[0],
      tests:
        prevCondition?.tests.map((test) => ({
          ...test,
          company: test.company._id
        })) || [],
      treatments: prevCondition?.treatments || []
    };

    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [treatmentType, setTreatmentType] = useState("");
    const [treatmentToEdit, setTreatmentToEdit] = useState({});

    const [treatments, setTreatments] = useState([]);
    const [prefilledTreatments, setPrefilledTreatments] = useState([]);

    const [isOpenTreatmentModal, setIsOpenTreatmentModal] = useState(false);

    const formRef = useRef();

    const allTypes = Object.values(testConfig);
    const testTypes = indexBy(
      prop("title"),
      allTypes.filter(({type}) => TESTS.includes(type))
    );

    const {CompanyStore, UserStore, ConditionStore, CertificateStore} =
      useStores();

    useEffect(() => {
      if (!open || !prevCondition) return;

      setTreatments(
        prevCondition.treatments.map((treatment) => ({
          ...treatment,
          company: treatment.company._id
        }))
      );
    }, [open]);

    const laboratories = CompanyStore.meta.filter((company) => {
      return any(propEq(modules.LAB_INTERNAL, "name"), company.modules);
    });

    const witnessCompanies = CompanyStore.meta.filter((company) => {
      return any(propEq(modules.WITNESS, "name"), company.modules);
    });

    useEffect(() => {
      CompanyStore.getCompaniesMetaData();
      ConditionStore.getPresetsByCompany();
    }, []);

    const checkClose = () => {
      if (
        equals(formRef.current.values, formRef.current.initialValues) &&
        equals(formRef.current.values.treatments, treatments)
      )
        onClose();
      else setConfirmationOpen(true);
    };

    const onCloseTestConditioning = () => {
      setPrefilledTreatments([]);
      setTreatmentType("");
      setTreatments([]);
      onClose();
    };

    const onSave = async ({name, tests}) => {
      const condition = {
        name,
        owner: certificate.owner,
        treatments: [...prefilledTreatments, ...treatments],
        tests
      };

      if (prevCondition) {
        await ConditionStore.update(condition, prevCondition._id);

        if (isPreset) await ConditionStore.getPresetsByCompany();
      } else {
        if (isPreset) {
          await ConditionStore.createPreset(condition);
          await ConditionStore.getPresetsByCompany();
        } else {
          await ConditionStore.create(condition);
          await CertificateStore.addCondition(
            ConditionStore.condition.data._id,
            certificate._id
          );
        }
      }

      if (certificate._id)
        await ConditionStore.getByCertificateId(certificate._id);

      onCloseTestConditioning();
      setConfirmationOpen(false);
    };

    return (
      <>
        <Dialog
          open={isOpenTreatmentModal}
          maxWidth="md"
          fullWidth
          onClose={() => {
            setTreatmentType("");
            setTreatmentToEdit({});
            setIsOpenTreatmentModal(false);
          }}
        >
          <DialogContent>
            {treatmentType &&
              React.cloneElement(testConfig[treatmentType].addDataForm, {
                saveTest: (data) => {
                  if (isEmpty(treatmentToEdit)) {
                    setTreatments((items) => [
                      ...items,
                      {
                        type: treatmentType,
                        company: UserStore.user.data.company._id,
                        certificate: {_id: certificate._id},
                        status: STATUSES.FILLED,
                        properties: {...data, isRelatedToCondition: true}
                      }
                    ]);
                  } else {
                    if (treatmentToEdit.isPrefilled === true) {
                      setPrefilledTreatments(
                        prefilledTreatments.map((item) => {
                          if (
                            equals(
                              item.properties,
                              treatmentToEdit.value.properties
                            )
                          )
                            item.properties = data;

                          return item;
                        })
                      );
                    } else {
                      setTreatments(
                        treatments.map((item) => {
                          if (
                            equals(
                              item.properties,
                              treatmentToEdit.value.properties
                            )
                          )
                            item.properties = data;

                          return item;
                        })
                      );
                    }
                  }

                  setTreatmentType("");
                  setTreatmentToEdit({});
                  setIsOpenTreatmentModal(false);
                },
                isProducerTest: true,
                isRelatedToCondition: true,
                test: {...EMPTY_TEST, ...treatmentToEdit.value}
              })}
          </DialogContent>
        </Dialog>

        <Dialog open={open} onClose={checkClose} maxWidth="md" fullWidth>
          <DialogContent>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Formik
                  innerRef={formRef}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  enableReinitialize
                  onSubmit={onSave}
                >
                  {(props) => {
                    const {
                      values: {name, presetConditions, tests},
                      isValid,
                      handleSubmit,
                      handleChange,
                      setFieldValue,
                      setFieldTouched
                    } = props;

                    const addTreatment = () => {
                      if (treatmentType) {
                        setIsOpenTreatmentModal(true);
                      }
                    };

                    const editTreatment = (indexToEdit, isPrefilled) => {
                      let treatmentToEdit;

                      if (isPrefilled)
                        treatmentToEdit = prefilledTreatments[indexToEdit];
                      else treatmentToEdit = treatments[indexToEdit];

                      setTreatmentToEdit({value: treatmentToEdit, isPrefilled});
                      setTreatmentType(treatmentToEdit.type);

                      setIsOpenTreatmentModal(true);
                    };

                    const removeTreatment = (indexToRemove, isPrefilled) => {
                      if (isPrefilled) {
                        const copy = clone(prefilledTreatments);
                        copy.splice(indexToRemove, 1);
                        setPrefilledTreatments(copy);
                      } else {
                        const copy = clone(treatments);
                        copy.splice(indexToRemove, 1);
                        setTreatments(copy);
                      }
                    };

                    const change = (name, event) => {
                      handleChange(event);
                      setFieldTouched(name, true, false);
                    };

                    const changeTestTypes = (values) => {
                      const newTests = tests.filter((test) =>
                        values.includes(test.title)
                      );

                      values.forEach((testTitle) => {
                        if (!newTests.find(({title}) => title === testTitle)) {
                          newTests.push({
                            title: testTypes[testTitle].title,
                            type: testTypes[testTitle].type,
                            company: UserStore.user.data.company._id,
                            properties: {},
                            status: STATUSES.EMPTY,
                            assignee: "",
                            witnesses: [],
                            notes: ""
                          });
                        }
                      });

                      setFieldValue("tests", newTests);
                      setFieldTouched("tests", true, false);
                    };

                    const createPreffiedTreatmens = (type, properties) => {
                      setPrefilledTreatments((items) => [
                        ...items,
                        {
                          type,
                          company: UserStore.user.data.company._id,
                          certificate: {_id: certificate._id},
                          status: STATUSES.FILLED,
                          properties: {
                            ...properties,
                            isRelatedToCondition: true
                          }
                        }
                      ]);
                    };

                    const changePresetConditions = (value) => {
                      setPrefilledTreatments([]);
                      setFieldValue("presetConditions", value);

                      if (value.name === "None") {
                        return;
                      } else if (value.name === "Reeling") {
                        RELEENG_PRESET.treatments.map((item) => {
                          createPreffiedTreatmens(item.type, item.properties);
                        });
                      } else {
                        value.treatments.map((item) => {
                          createPreffiedTreatmens(item.type, item.properties);
                        });
                      }
                    };

                    const changeTestAssignee = (value) => {
                      if (tests.every((t) => t.assignee)) return;

                      const testsWithAssignee = tests.map((t) => ({
                        ...t,
                        assignee: value
                      }));

                      setFieldValue("tests", testsWithAssignee);
                    };

                    return (
                      <>
                        <Grid container item xs={12} spacing={3}>
                          <Grid item xs={12}>
                            <Typography component="h5" variant="h5">
                              Condition
                            </Typography>
                          </Grid>

                          <Grid item xs={4}>
                            <Input
                              value={name}
                              label="Name"
                              name={"name"}
                              required
                              onChange={(event) => change("name", event)}
                              onBlur={() => setFieldValue("name", name.trim())}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <div className={classes.divider} />
                          </Grid>

                          <Grid item xs={4}>
                            <Select
                              value={presetConditions}
                              name={"presetConditions"}
                              label="Preset Conditions"
                              onChange={(event) =>
                                changePresetConditions(event.target.value)
                              }
                            >
                              {[
                                ...PRESET_CONDITIONS,
                                ...ConditionStore.conditionsPresets.data
                              ].map((E, index) => (
                                <MenuItem key={E + index} value={E}>
                                  {E.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>

                          <Grid item xs={4}>
                            <Select
                              value={treatmentType}
                              name={"treatmentType"}
                              label="Treatment"
                              onChange={(event) =>
                                setTreatmentType(event.target.value)
                              }
                            >
                              {DECLARATIONS.map((E) => (
                                <MenuItem key={E.value} value={E.value}>
                                  {E.key}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>

                          <Grid className={classes.button} item xs={4}>
                            <Button
                              fullWidth
                              variant="contained"
                              size="large"
                              color="primary"
                              onClick={addTreatment}
                            >
                              Add Treatment
                            </Button>
                          </Grid>

                          {!isEmpty([
                            ...prefilledTreatments,
                            ...treatments
                          ]) && (
                            <Grid container item xs={12} spacing={3}>
                              <Grid item xs={12}>
                                <Typography component="h6" variant="h6">
                                  Treatments
                                </Typography>
                              </Grid>

                              <Grid item xs={12}>
                                <Table>
                                  <TableBody>
                                    <Treatments
                                      treatments={prefilledTreatments}
                                      editTreatment={editTreatment}
                                      removeTreatment={removeTreatment}
                                      isPrefilled={true}
                                    />
                                    <Treatments
                                      treatments={treatments}
                                      editTreatment={editTreatment}
                                      removeTreatment={removeTreatment}
                                      isPrefilled={false}
                                    />
                                  </TableBody>
                                </Table>
                              </Grid>
                            </Grid>
                          )}

                          {!isPreset && (
                            <Grid item xs={12}>
                              <MultipleSelect
                                label="Tests"
                                value={tests.map((test) => test.title)}
                                elements={Object.keys(testTypes).map(
                                  (key) => testTypes[key].title
                                )}
                                onChange={(values) => changeTestTypes(values)}
                                disabled={
                                  isEmpty([
                                    ...prefilledTreatments,
                                    ...treatments
                                  ]) &&
                                  (prevCondition?.treatments === undefined ||
                                    isEmpty(prevCondition?.treatments))
                                }
                              />
                            </Grid>
                          )}

                          {!isEmpty(tests) && (
                            <Grid item xs={12}>
                              <div className={classes.divider} />
                            </Grid>
                          )}
                        </Grid>

                        {!isEmpty(tests) && (
                          <Grid container item xs={12} spacing={3}>
                            {tests.map((test, index) => (
                              <React.Fragment key={test.title + index}>
                                <Grid item xs={12}>
                                  <Typography component="h6" variant="h6">
                                    {test.title}
                                  </Typography>
                                </Grid>

                                <Grid item xs={4}>
                                  <SelectField
                                    name={`tests[${index}].assignee`}
                                    label="Laboratory"
                                    onChange={changeTestAssignee}
                                    required
                                  >
                                    {laboratories.map((lab) => (
                                      <MenuItem key={lab._id} value={lab._id}>
                                        {lab.name}
                                      </MenuItem>
                                    ))}
                                  </SelectField>
                                </Grid>

                                <Grid item xs={4} style={{paddingTop: "16px"}}>
                                  <MultipleSelect
                                    label="Witnesses"
                                    value={tests[index].witnesses.map(
                                      (c) => c.name
                                    )}
                                    elements={witnessCompanies.map(
                                      (c) => c.name
                                    )}
                                    onChange={(values) => {
                                      const witnesses = witnessCompanies.filter(
                                        (c) => values.includes(c.name)
                                      );
                                      setFieldValue(
                                        `tests[${index}].witnesses`,
                                        witnesses
                                      );
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={4}>
                                  <TextField
                                    rows={2}
                                    multiline
                                    label="Notes"
                                    name={`tests[${index}].notes`}
                                  />
                                </Grid>
                              </React.Fragment>
                            ))}
                          </Grid>
                        )}

                        <Grid container justifyContent="flex-end">
                          <Grid
                            className={classes.button}
                            style={{marginTop: "40px"}}
                            item
                            xs={2}
                          >
                            <Button
                              fullWidth
                              variant="contained"
                              size="large"
                              color="primary"
                              onClick={handleSubmit}
                              disabled={!isValid}
                            >
                              Save
                            </Button>
                          </Grid>
                        </Grid>
                      </>
                    );
                  }}
                </Formik>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        <ConfirmationOnModalClose
          confirmationOpen={confirmationOpen}
          formRef={formRef}
          setConfirmationOpen={setConfirmationOpen}
          onClose={onCloseTestConditioning}
          onSave={() => onSave(formRef.current.values)}
        />
      </>
    );
  }
);

export default withStyles(AddTestConditioning, styles);
