import React, {useState} from "react";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import TableFooter from "@core/components/TableFooter";
import classNames from "classnames";
import styles from "./styles";

const BaseProductsList = ({
  classes,
  products,
  selectedProducts,
  setSelectedProducts
}) => {
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  const productsPaginated = products.slice(offset, offset + limit);

  return (
    <Table className={classNames("styled-table", classes.productTable)}>
      <TableHead>
        <TableRow>
          <TableCell padding="none" width="48">
            <Checkbox
              onChange={() => {
                if (selectedProducts.length === products.length) {
                  setSelectedProducts([]);
                } else {
                  setSelectedProducts(products);
                }
              }}
              color="primary"
              indeterminate={
                Boolean(selectedProducts.length) &&
                products.length !== selectedProducts.length
              }
              checked={products.length === selectedProducts.length}
            />
          </TableCell>
          <TableCell>Product Type</TableCell>
          <TableCell>Manufacturer</TableCell>
          <TableCell>Heat</TableCell>
          <TableCell>Pipe ID</TableCell>
          <TableCell>Quarantine</TableCell>
          <TableCell>Quarantine reason</TableCell>
          <TableCell>Description</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {productsPaginated.map((product) => (
          <TableRow
            hover
            onClick={() => {
              if (selectedProducts.find((p) => p._id === product._id)) {
                setSelectedProducts(
                  selectedProducts.filter((p) => p._id !== product._id)
                );
              } else {
                setSelectedProducts([...selectedProducts, product]);
              }
            }}
          >
            <TableCell padding="none" width="48">
              <Checkbox
                color="primary"
                checked={!!selectedProducts.find((p) => p._id === product._id)}
              />
            </TableCell>
            <TableCell>{product.productType || "-"}</TableCell>
            <TableCell>{product.manufacturer}</TableCell>
            <TableCell>{product.heat}</TableCell>
            <TableCell>{product.pipeNumber}</TableCell>
            <TableCell>{product.quarantine ? "Yes" : "No"}</TableCell>
            <TableCell>{product.quarantineReason || "-"}</TableCell>
            <TableCell>{product.description || "-"}</TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter
        isLoaded
        items={productsPaginated}
        total={products.length}
        limit={limit}
        offset={offset}
        onOffsetChange={setOffset}
        onLimitChange={setLimit}
        selectedItems={selectedProducts}
      />
    </Table>
  );
};

export default withStyles(BaseProductsList, styles);
