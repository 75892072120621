import React, {useMemo} from "react";
import {Input, MenuItem, Select} from "@mui/material";
import {withStyles} from "tss-react/mui";
import styles from "./styles";
import {any, indexBy, prop, propEq} from "ramda";
import modules from "@core/constants/modules";
import {observer} from "mobx-react";
import useStores from "../../../../../useStores";

const WitnessEdit = observer(({classes, test, groupedTests, updateTests}) => {
  const {CompanyStore} = useStores();

  const witnessCompanies = useMemo(() => CompanyStore.meta.filter((company) => {
    return any(propEq(modules.WITNESS, "name"), company.modules);
  }), [CompanyStore.meta]);

  const witnessCompaniesById = useMemo(() => indexBy(prop("_id"), witnessCompanies), [witnessCompanies]);

  const testIds = useMemo(() => groupedTests.map((test) => test._id), [groupedTests]);

  const selectedWitnesses = test.witnesses?.map((witness) => witness?._id) ?? [];

  const onChange = (event) => {
    const witnesses = event.target.value.map((id) => witnessCompaniesById[id]);
    updateTests({witnesses}, testIds);
  };

  return (
    <Select
      classes={{select: classes.select}}
      fullWidth
      multiple
      value={selectedWitnesses}
      onChange={onChange}
      input={<Input />}
    >
      {witnessCompanies?.map((company) => (
        <MenuItem key={company._id} value={company._id}>
          {company.name}
        </MenuItem>
      ))}
    </Select>
  );
});

export default withStyles(WitnessEdit, styles);