import {PO_ITEMS} from "@core/constants/poItems";
import {TEST_UNITS} from "@core/constants/test";
import {QCP_NAMES} from "@core/constants/qcpNames";
import {getMean} from "@core/helpers";
import {isAcceptable} from "./services";

// prettier-ignore
export const CUSTOM_EXCEPTIONS = {
  [QCP_NAMES.QCP_ITP_030_24_130786_YGG_BUT_00073]: {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    data: {
      thickness: {
        body: {
          nominal: 3.4,
          toleranceMinus: 0.1,
          tolerancePlus: 0.14
        }
      }
    }
  },
  [QCP_NAMES.QCP_CPW_ITP_21_23_HFW_YGG_COP_00005]: {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    data: {
      externalDiameter: {
        body: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_10:
                return 409.3;
              case PO_ITEMS.PO_ITEM_20:
                return 409.3;
              case PO_ITEMS.PO_ITEM_30:
                return 409.3;
              case PO_ITEMS.PO_ITEM_40:
                return 409.3;
              case PO_ITEMS.PO_ITEM_50:
                return 273.1;
              case PO_ITEMS.PO_ITEM_60:
                return 273.1;
              case PO_ITEMS.PO_ITEM_70:
                return 409.3;
              case PO_ITEMS.PO_ITEM_80:
                return 409.3;
              default:
                return "";
            }
          },
          toleranceMinusFormula(element, poItemNumber) {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_10:
                return 3;
              case PO_ITEMS.PO_ITEM_20:
                return 3;
              case PO_ITEMS.PO_ITEM_30:
                return 3;
              case PO_ITEMS.PO_ITEM_40:
                return 3;
              case PO_ITEMS.PO_ITEM_50:
                return 2;
              case PO_ITEMS.PO_ITEM_60:
                return 2;
              case PO_ITEMS.PO_ITEM_70:
                return 3;
              case PO_ITEMS.PO_ITEM_80:
                return 3;
              default:
                return "";
            }
          },
          tolerancePlusFormula(element, poItemNumber) {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_10:
                return 3;
              case PO_ITEMS.PO_ITEM_20:
                return 3;
              case PO_ITEMS.PO_ITEM_30:
                return 3;
              case PO_ITEMS.PO_ITEM_40:
                return 3;
              case PO_ITEMS.PO_ITEM_50:
                return 2;
              case PO_ITEMS.PO_ITEM_60:
                return 2;
              case PO_ITEMS.PO_ITEM_70:
                return 3;
              case PO_ITEMS.PO_ITEM_80:
                return 3;
              default:
                return "";
            }
          },
        }
      },
      thickness: {
        body: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_10:
                return 19.1;
              case PO_ITEMS.PO_ITEM_20:
                return 19.1;
              case PO_ITEMS.PO_ITEM_30:
                return 19.1;
              case PO_ITEMS.PO_ITEM_40:
                return 19.1;
              case PO_ITEMS.PO_ITEM_50:
                return 15.9;
              case PO_ITEMS.PO_ITEM_60:
                return 15.9;
              case PO_ITEMS.PO_ITEM_70:
                return 19.1;
              case PO_ITEMS.PO_ITEM_80:
                return 19.1;
              default:
                return "";
            }
          },
          toleranceMinus: 0.5,
          tolerancePlus: 0.5
        }
      },
      internalDiameter: {
        ends: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_10:
                return 371.1;
              case PO_ITEMS.PO_ITEM_20:
                return 371.1;
              case PO_ITEMS.PO_ITEM_30:
                return 371.1;
              case PO_ITEMS.PO_ITEM_40:
                return 371.1;
              case PO_ITEMS.PO_ITEM_50:
                return 241.3;
              case PO_ITEMS.PO_ITEM_60:
                return 241.3;
              case PO_ITEMS.PO_ITEM_70:
                return 241.3;
              case PO_ITEMS.PO_ITEM_80:
                return 371.1;
              default:
                return "";
            }
          },
          toleranceMinus: 1.2,
          tolerancePlus: 1.2
        }
      },
      length: {
        nominal: 11.5, 
        toleranceMinus: 0.3, 
        tolerancePlus: 0.3, 
      },
      straightness: {
        body: {
          nominal: 0,
          tolerancePlus: 0.12
        },
        end: {
          nominal: 0,
          tolerancePlus: 3.2
        }
      },
      outOfRoundness: {
        body: {
          nominal: 0,
          toleranceMinusFormula(element, poItemNumber) {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_10:
                return 5;
              case PO_ITEMS.PO_ITEM_20:
                return 5;
              case PO_ITEMS.PO_ITEM_30:
                return 5;
              case PO_ITEMS.PO_ITEM_40:
                return 5;
              case PO_ITEMS.PO_ITEM_50:
                return 4;
              case PO_ITEMS.PO_ITEM_60:
                return 4;
              case PO_ITEMS.PO_ITEM_70:
                return 5;
              case PO_ITEMS.PO_ITEM_80:
                return 5;
              default:
                return "";
            }
          },
        },
        ends: {
          nominal: 0,
          tolerancePlus: 2
        }
      },
      depthOfGroove: {
        nominal: 0,
        tolerancePlus: 0.05
      },
      weldFLash: {
        body: {
          nominal: 0,
          tolerancePlus: 0.3 + 0.05
        },
      },
    }
  },
  [QCP_NAMES.QCP_9348_YGG_TGS_00010_GL_Reelable_FDPT]: {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    data: {
      externalDiameter: {
        body: {
          nominal: 168.3,
          toleranceMinus: 0.75,
          tolerancePlus: 0.75
        }
      },
      internalDiameter: {
        body: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 142.9;
              case PO_ITEMS.PO_ITEM_2:
                return 139.7;
              case PO_ITEMS.PO_ITEM_3:
                return 136.5;
              default:
                return "";
            }
          },
          toleranceMinus: 0.5,
          tolerancePlus: 0.5
        }
      },
      thickness: {
        body: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 12.7;
              case PO_ITEMS.PO_ITEM_2:
                return 14.3;
              case PO_ITEMS.PO_ITEM_3:
                return 15.9;
              default:
                return "";
            }
          },
          toleranceMinusFormula(element, poItemNumber) {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 12.5;
              case PO_ITEMS.PO_ITEM_2:
                return 12.5;
              case PO_ITEMS.PO_ITEM_3:
                return 10;
              default:
                return "";
            }
          },
          tolerancePlus: 12.5
        }
      },
      outOfRoundness: {
        body: {
          nominal: 0,
          tolerancePlus: 1.5
        },
        ends: {
          nominal: 0,
          tolerancePlus: 1
        }
      },
      straightness: {
        body: {
          nominal: 0,
          tolerancePlus: 0.15
        },
        end: {
          nominal: 0,
          tolerancePlus: 3
        }
      },
      geometricDeviation: {
        nominal: 0,
        tolerancePlus: 1
      },
    }
  },
  "QCP 9348 (YGG-TGS-00010 - GL Reelable)": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    data: {
      externalDiameter: {
        body: {
          nominal: 168.3,
          toleranceMinus: 0.75,
          tolerancePlus: 0.75
        }
      },
      internalDiameter: {
        body: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 142.9;
              case PO_ITEMS.PO_ITEM_2:
                return 139.7;
              case PO_ITEMS.PO_ITEM_3:
                return 136.5;
              default:
                return "";
            }
          },
          toleranceMinus: 0.5,
          tolerancePlus: 0.5
        }
      },
      thickness: {
        body: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 12.7;
              case PO_ITEMS.PO_ITEM_2:
                return 14.3;
              case PO_ITEMS.PO_ITEM_3:
                return 15.9;
              default:
                return "";
            }
          },
          toleranceMinusFormula(element, poItemNumber) {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 12.5;
              case PO_ITEMS.PO_ITEM_2:
                return 12.5;
              case PO_ITEMS.PO_ITEM_3:
                return 10;
              default:
                return "";
            }
          },
          tolerancePlus: 12.5
        }
      },
      outOfRoundness: {
        body: {
          nominal: 0,
          tolerancePlus: 1.5
        },
        ends: {
          nominal: 0,
          tolerancePlus: 1
        }
      },
      straightness: {
        body: {
          nominal: 0,
          tolerancePlus: 0.15
        },
        ends: {
          nominal: 0,
          tolerancePlus: 3
        }
      }
    }
  },
  "QCP 10378 (YGG-TGS-00045 - GL Pipe)": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    data: {
      externalDiameter: {
        body: {
          nominal: 168.3,
          toleranceMinus: 0.75,
          tolerancePlus: 0.75
        }
      },
      internalDiameter: {
        body: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 143.1;
              case PO_ITEMS.PO_ITEM_2:
                return 139.9;
              case PO_ITEMS.PO_ITEM_3:
                return 136.5;
              default:
                return "";
            }
          },
          toleranceMinus: 0.5,
          tolerancePlus: 0.5
        }
      },
      thickness: {
        body: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 12.6;
              case PO_ITEMS.PO_ITEM_2:
                return 14.3;
              case PO_ITEMS.PO_ITEM_3:
                return 15.9;
              default:
                return "";
            }
          },
          toleranceMinusFormula(element, poItemNumber) {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 12.5;
              case PO_ITEMS.PO_ITEM_2:
                return 12.5;
              case PO_ITEMS.PO_ITEM_3:
                return 10;
              default:
                return "";
            }
          },
          tolerancePlus: 12.5
        }
      },
      outOfRoundness: {
        body: {
          nominal: 0,
          tolerancePlus: 1.5
        },
        ends: {
          nominal: 0,
          tolerancePlus: 1
        }
      },
      straightness: {
        body: {
          nominal: 0,
          tolerancePlus: 0.15
        },
        ends: {
          nominal: 0,
          tolerancePlus: 3
        }
      }
    }
  },
  "QCP 7928 Rev. 2": {
    company: ["TenarisLab", "Tremblay Inc."],
    units: TEST_UNITS.IMPERIAL,
    data: {
      externalDiameter: {
        body: {
          nominal: 12.75, // Dimensions are in inches
          toleranceMinus: 0.095625, // Dimensions are in inches
          tolerancePlus: 0.095625 // Dimensions are in inches
        },
        ends: {
          nominal: 12.75, // Dimensions are in inches
          toleranceMinus: 0.06375, // Dimensions are in inches
          tolerancePlus: 0.06375 // Dimensions are in inches
        }
      },
      outOfRoundness: {
        body: {
          nominal: 0,
          tolerancePlus: 2
        },
        ends: {
          nominal: 0,
          tolerancePlus: 1.5
        }
      },
      thickness: {
        body: {
          nominal: 0.406, // Dimensions are in inches
          toleranceMinus: 0.0406, // Dimensions are in inches
          tolerancePlus: 0.07105 // Dimensions are in inches
        },
        ends: {
          nominal: 0.406, // Dimensions are in inches
          toleranceMinus: 0.0406, // Dimensions are in inches
          tolerancePlus: 0.07105 // Dimensions are in inches
        }
      },
      length: {
        nominal: 480, // Dimensions are in inches
        toleranceMinus: 24, // Dimensions are in inches
        tolerancePlus: 24, // Dimensions are in inches,
        // Take into account that 5% of the total pipes may be shorter between 432 and 456 inches
        getIsAcceptable: (elements, min, max) => {
          if (!elements) return;

          const smallerMin = 432;
          const smallerMax = 456;

          const allowedNumberOfSmallerElements = Math.floor(
            (5 * elements.length) / 100
          );
          const notAcceptable = elements.filter(
            (element) => !isAcceptable(element, min, max),
            elements
          );
          const actualNumberOfSmallAcceptableElements = notAcceptable.filter(
            (element) =>
              Number(element.value) >= smallerMin &&
              Number(element.value) < smallerMax
          );

          return (
            actualNumberOfSmallAcceptableElements.length ===
              notAcceptable.length &&
            actualNumberOfSmallAcceptableElements.length <=
              allowedNumberOfSmallerElements
          );
        }
      },
      straightness: {
        body: {
          nominal: 0,
          tolerancePlus: 3.2
        },
        ends: {
          nominal: 0,
          tolerancePlus: 3.2
        }
      },
      squareness: {
        body: {
          nominal: 0,
          tolerancePlus: 1.6
        },
        ends: {
          nominal: 0,
          tolerancePlus: 1.6
        }
      },
      bevelAngle: {
        nominal: 30,
        toleranceMinus: 0,
        tolerancePlus: 5
      },
      bevelRootFace: {
        nominal: 1.6,
        toleranceMinus: 0.8,
        tolerancePlus: 0.8
      },
      linearWeight: {
        nominal: 31.33,
        toleranceMinus: 1.097,
        tolerancePlus: 3.13
      }
    }
  },
  "HS-D-ITP-22-02-02 Rev. 5": {
    company: ["Husteel", "HusteelLab", "Turcotte Group", "Tremblay Inc."],
    units: TEST_UNITS.METRIC,
    data: {
      externalDiameter: {
        body: {
          nominal: 168.3,
          toleranceMinus: 1.26,
          tolerancePlus: 1.26
        },
        ends: {
          nominal: 168.3,
          toleranceMinus: 0.4,
          tolerancePlus: 1.6
        }
      },
      outOfRoundness: {
        body: {
          nominal: 0,
          tolerancePlus: 2
        },
        ends: {
          nominal: 0,
          tolerancePlus: 1.5
        }
      },
      thickness: {
        body: {
          nominal: 7.92,
          toleranceMinus: 0.79,
          tolerancePlus: 0.79
        },
        ends: {
          nominal: 7.92,
          toleranceMinus: 0.79,
          tolerancePlus: 0.79
        }
      },
      length: {
        nominal: 18000,
        toleranceMinus: 0,
        tolerancePlus: 50
      },
      straightness: {
        body: {
          nominal: 0,
          tolerancePlus: 36
        },
        ends: {
          nominal: 0,
          tolerancePlus: 36
        }
      },
      squareness: {
        body: {
          nominal: 0,
          tolerancePlus: 1.6
        },
        ends: {
          nominal: 0,
          tolerancePlus: 1.6
        }
      },
      bevelAngle: {
        nominal: 30,
        toleranceMinus: 0,
        tolerancePlus: 5
      },
      bevelRootFace: {
        nominal: 1.6,
        toleranceMinus: 0.8,
        tolerancePlus: 0.8
      },
      weldFlashOutside: {
        body: {
          nominal: 0,
          tolerancePlus: 0
        },
        ends: {
          nominal: 0,
          tolerancePlus: 0
        }
      },
      weldFLash: {
        body: {
          nominal: 0,
          tolerancePlus: 0.69
        },
        ends: {
          nominal: 0,
          tolerancePlus: 0.69
        }
      },
      depthOfGroove: {
        nominal: 0,
        tolerancePlus: 0.4
      },
      linearWeight: {
        nominal: 31.33,
        toleranceMinus: 1.097,
        tolerancePlus: 3.13
      }
    }
  },
  "HS-D-ITP-23-01-06 Rev. 3": {
    company: ["Husteel", "HusteelLab", "Turcotte Group", "Tremblay Inc."],
    units: TEST_UNITS.METRIC,
    data: {
      externalDiameter: {
        body: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 217.46;
              case PO_ITEMS.PO_ITEM_2:
                return 321.48;
              case PO_ITEMS.PO_ITEM_3:
                return 321.48;
              default:
                return "";
            }
          },
          tolerancePlusFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 3.28;
              case PO_ITEMS.PO_ITEM_2:
                return 4.84;
              case PO_ITEMS.PO_ITEM_3:
                return 4.84;
              default:
                return "";
            }
          }
        },
        ends: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 218.7;
              case PO_ITEMS.PO_ITEM_2:
                return 322.3;
              case PO_ITEMS.PO_ITEM_3:
                return 322.3;
              default:
                return "";
            }
          },
          tolerancePlusFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 2;
              case PO_ITEMS.PO_ITEM_2:
                return 3.2;
              case PO_ITEMS.PO_ITEM_3:
                return 3.2;
              default:
                return "";
            }
          }
        }
      },
      outOfRoundness: {
        body: {
          nominal: 0,
          tolerancePlusFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 4.38;
              case PO_ITEMS.PO_ITEM_2:
                return 6.47;
              case PO_ITEMS.PO_ITEM_3:
                return 6.47;
              default:
                return "";
            }
          }
        },
        ends: {
          nominal: 0,
          tolerancePlusFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 3.28;
              case PO_ITEMS.PO_ITEM_2:
                return 4.85;
              case PO_ITEMS.PO_ITEM_3:
                return 4.85;
              default:
                return "";
            }
          }
        }
      },
      thickness: {
        body: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 9.27;
              case PO_ITEMS.PO_ITEM_2:
                return 8.55;
              case PO_ITEMS.PO_ITEM_3:
                return 11.43;
              default:
                return "";
            }
          },
          tolerancePlusFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 2.06;
              case PO_ITEMS.PO_ITEM_2:
                return 1.9;
              case PO_ITEMS.PO_ITEM_3:
                return 2.54;
              default:
                return "";
            }
          }
        },
        ends: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 9.27;
              case PO_ITEMS.PO_ITEM_2:
                return 8.55;
              case PO_ITEMS.PO_ITEM_3:
                return 11.43;
              default:
                return "";
            }
          },
          tolerancePlusFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 2.06;
              case PO_ITEMS.PO_ITEM_2:
                return 1.9;
              case PO_ITEMS.PO_ITEM_3:
                return 2.54;
              default:
                return "";
            }
          }
        }
      },
      length: {
        nominal: 18000,
        toleranceMinus: 0,
        tolerancePlus: 50
      },
      straightness: {
        body: {
          nominal: 0,
          tolerancePlus: 36
        },
        ends: {
          nominal: 0,
          tolerancePlus: 36
        }
      },
      squareness: {
        body: {
          nominal: 0,
          tolerancePlus: 1.6
        },
        ends: {
          nominal: 0,
          tolerancePlus: 1.6
        }
      },
      bevelAngle: {
        nominal: 30,
        toleranceMinus: 0,
        tolerancePlus: 5
      },
      bevelRootFace: {
        nominal: 1.6,
        toleranceMinus: 0.8,
        tolerancePlus: 0.8
      },
      weldFlashOutside: {
        body: {
          nominal: 0,
          tolerancePlus: 0
        },
        ends: {
          nominal: 0,
          tolerancePlus: 0
        }
      },
      weldFLash: {
        body: {
          nominal: 0,
          tolerancePlus: 1
        },
        ends: {
          nominal: 0,
          tolerancePlus: 1
        }
      },
      depthOfGroove: {
        nominal: 0,
        tolerancePlusFormula: (element, poItemNumber) => {
          switch (poItemNumber) {
            case PO_ITEMS.PO_ITEM_1:
              return 0.51;
            case PO_ITEMS.PO_ITEM_2:
              return 0.47;
            case PO_ITEMS.PO_ITEM_3:
              return 0.63;
            default:
              return "";
          }
        }
      },
      linearWeight: {
        nominalFormula: (element, poItemNumber) => {
          switch (poItemNumber) {
            case PO_ITEMS.PO_ITEM_1:
              return 51.18;
            case PO_ITEMS.PO_ITEM_2:
              return 71.08;
            case PO_ITEMS.PO_ITEM_3:
              return 94.06;
            default:
              return "";
          }
        },
        tolerancePlusFormula: (element, poItemNumber) => {
          switch (poItemNumber) {
            case PO_ITEMS.PO_ITEM_1:
              return 7.15;
            case PO_ITEMS.PO_ITEM_2:
              return 9.93;
            case PO_ITEMS.PO_ITEM_3:
              return 13.14;
            default:
              return "";
          }
        }
      }
    }
  },
  "FP-ITP Rev. 0": {
    company: [
      "Turcotte Group",
      "Tremblay Inc.",
      "FoxPipe Producer",
      "Mechan Lab"
    ],
    units: TEST_UNITS.METRIC,
    data: {
      externalDiameter: {
        body: {
          nominal: 168.3,
          toleranceMinus: 1.26,
          tolerancePlus: 1.26
        },
        ends: {
          nominal: 168.3,
          toleranceMinus: 0.4,
          tolerancePlus: 1.6
        }
      },
      outOfRoundness: {
        body: {
          nominal: 0,
          tolerancePlus: 2
        },
        ends: {
          nominal: 0,
          tolerancePlus: 1.5
        }
      },
      thickness: {
        body: {
          nominal: 7.92,
          toleranceMinus: 0.79,
          tolerancePlus: 0.79
        },
        ends: {
          nominal: 7.92,
          toleranceMinus: 0.79,
          tolerancePlus: 0.79
        }
      },
      length: {
        nominal: 18000,
        toleranceMinus: 0,
        tolerancePlus: 50
      },
      straightness: {
        body: {
          nominal: 0,
          tolerancePlus: 36
        },
        ends: {
          nominal: 0,
          tolerancePlus: 36
        }
      },
      squareness: {
        body: {
          nominal: 0,
          tolerancePlus: 1.6
        },
        ends: {
          nominal: 0,
          tolerancePlus: 1.6
        }
      },
      bevelAngle: {
        nominal: 30,
        toleranceMinus: 0,
        tolerancePlus: 5
      },
      bevelRootFace: {
        nominal: 1.6,
        toleranceMinus: 0.8,
        tolerancePlus: 0.8
      },
      weldFlashOutside: {
        body: {
          nominal: 0,
          tolerancePlus: 0
        },
        ends: {
          nominal: 0,
          tolerancePlus: 0
        }
      },
      weldFLash: {
        body: {
          nominal: 0,
          tolerancePlus: 0.69
        },
        ends: {
          nominal: 0,
          tolerancePlus: 0.69
        }
      },
      depthOfGroove: {
        nominal: 0,
        tolerancePlus: 0.4
      },
      linearWeight: {
        nominal: 31.33,
        toleranceMinus: 1.097,
        tolerancePlus: 3.13
      }
    }
  },
  "FX ITP 2537 Rev. 2": {
    company: [
      "Turcotte Group",
      "Tremblay Inc.",
      "FoxPipe Producer",
      "Mechan Lab"
    ],
    units: TEST_UNITS.METRIC,
    data: {
      externalDiameter: {
        body: {
          nominal: 273.1,
          toleranceMinus: 1.6386,
          tolerancePlus: 1.6386
        }
      },
      internalDiameter: {
        ends: {
          reqsHeader: "+/- 3.75 mm applied to Actual ID",
          nominal: 177.7,
          toleranceMinus: 3.75,
          tolerancePlus: 3.75
        }
      },
      outOfRoundness: {
        body: {
          nominal: 0,
          tolerancePlus: 2.731
        },
        ends: {
          nominal: 0,
          tolerancePlus: 6
        }
      },
      thickness: {
        body: {
          nominal: 45.47,
          toleranceMinus: 2.2735,
          tolerancePlus: 6.8205
        },
        ends: {
          nominal: 45.47,
          toleranceMinus: 2.2735,
          tolerancePlus: 6.8205
        }
      },
      length: {
        nominal: 11950,
        toleranceMinus: 350,
        tolerancePlus: 350
      },
      straightness: {
        body: {
          reqsHeader: "Max 0.15% total length",
          nominal: 0,
          tolerancePlusFormula: (element = {}) =>
            (0.15 * Number(element.length)) / 100,
          tolerancePlus: 0.15,
          toleranceUnits: "%"
        },
        ends: {
          reqsHeader: "Max 3.2mm in last 1.5m",
          nominal: 0,
          tolerancePlus: 3.2
        }
      },
      squareness: {
        ends: {
          nominal: 0,
          tolerancePlus: 1.6
        }
      },
      eccentricity: {
        ends: {
          reqsHeader: "Max 15% WT fab",
          nominal: 0,
          tolerancePlus: 7.115
        }
      },
      linearWeight: {
        nominal: 255.68,
        toleranceMinus: 8.9488,
        tolerancePlus: 25.568
      }
    }
  },
  "QCP 6916 Rev. 3 - Item 1": {
    company: ["Tenaris", "TenarisLab", "Turcotte Group", "Tremblay Inc."],
    units: TEST_UNITS.METRIC,
    data: {
      externalDiameter: {
        body: {
          nominal: 273.1,
          toleranceMinus: 1.6386,
          tolerancePlus: 1.6386
        }
      },
      internalDiameter: {
        ends: {
          reqsHeader: "+/- 3.75 mm applied to Actual ID",
          nominal: 177.7,
          toleranceMinus: 3.75,
          tolerancePlus: 3.75
        }
      },
      outOfRoundness: {
        body: {
          nominal: 0,
          tolerancePlus: 2.731
        },
        ends: {
          nominal: 0,
          tolerancePlus: 6
        }
      },
      thickness: {
        body: {
          nominal: 45.47,
          toleranceMinus: 2.2735,
          tolerancePlus: 6.8205
        },
        ends: {
          nominal: 45.47,
          toleranceMinus: 2.2735,
          tolerancePlus: 6.8205
        }
      },
      length: {
        nominal: 11950,
        toleranceMinus: 350,
        tolerancePlus: 350
      },
      straightness: {
        body: {
          reqsHeader: "Max 0.15% total length",
          nominal: 0,
          tolerancePlusFormula: (element = {}) =>
            (0.15 * Number(element.length)) / 100,
          tolerancePlus: 0.15,
          toleranceUnits: "%"
        },
        ends: {
          reqsHeader: "Max 3.2mm in last 1.5m",
          nominal: 0,
          tolerancePlus: 3.2
        }
      },
      squareness: {
        ends: {
          nominal: 0,
          tolerancePlus: 1.6
        }
      },
      eccentricity: {
        ends: {
          reqsHeader: "Max 15% WT fab",
          nominal: 0,
          tolerancePlus: 7.115
        }
      },
      linearWeight: {
        nominal: 255.68,
        toleranceMinus: 8.9488,
        tolerancePlus: 25.568
      }
    }
  },
  "QCP 6916 Rev. 3 - Item 2": {
    company: ["Tenaris", "TenarisLab", "Turcotte Group", "Tremblay Inc."],
    units: TEST_UNITS.METRIC,
    data: {
      externalDiameter: {
        body: {
          reqsHeader: "+/-0.75% OD nom",
          nominal: 273.1,
          toleranceMinus: 2.04825,
          tolerancePlus: 2.04825
        }
      },
      outOfRoundness: {
        body: {
          reqsHeader: "Max 1.5% OD nom",
          nominal: 0,
          tolerancePlus: 4.0965
        }
      },
      thickness: {
        body: {
          reqsHeader: "-5%/+15% WT nom",
          nominal: 49.73,
          toleranceMinus: 2.4865,
          tolerancePlus: 7.4595
        },
        ends: {
          reqsHeader: "-5%/+15% WT nom",
          nominal: 49.73,
          toleranceMinus: 2.4865,
          tolerancePlus: 7.4595
        }
      },
      length: {
        reqsHeader: "7400-11300mm",
        nominal: 9350,
        toleranceMinus: 1950,
        tolerancePlus: 1950
      },
      straightness: {
        body: {
          reqsHeader: "Max 0.15% total length",
          nominal: 0,
          tolerancePlusFormula: (element = {}) =>
            (0.15 * Number(element.length)) / 100,
          tolerancePlus: 0.15,
          toleranceUnits: "%"
        },
        ends: {
          reqsHeader: "Max 3.2mm in last 1.5m",
          nominal: 0,
          tolerancePlus: 3.2
        }
      },
      squareness: {
        ends: {
          reqsHeader: "Max 1.6mm",
          nominal: 0,
          tolerancePlus: 1.6
        }
      },
      eccentricity: {
        ends: {
          reqsHeader: "Max 18% WT fab",
          nominal: 0,
          tolerancePlus: 9.396
        }
      },
      linearWeight: {
        reqsHeader: "-3.5%/+10% Weight nom",
        nominal: 274.4,
        toleranceMinus: 9.604,
        tolerancePlus: 27.44
      }
    }
  },
  "QCP 8262 Rev. 6": {
    company: ["Turcotte Group", "Tenaris-Siderca", "Tremblay Inc."],
    units: TEST_UNITS.METRIC,
    data: {
      internalDiameter: {
        body: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 160.5;
              case PO_ITEMS.PO_ITEM_2:
                return 164.3;
              case PO_ITEMS.PO_ITEM_3:
                return 239.5;
              default:
                return "";
            }
          },
          tolerancePlusFormula(element, poItemNumber) {
            const nominal = this.nominalFormula(element, poItemNumber);

            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return (0.5 * Number(nominal)) / 100;
              case PO_ITEMS.PO_ITEM_2:
                return (0.5 * Number(nominal)) / 100;
              case PO_ITEMS.PO_ITEM_3:
                return 1.2;
              default:
                return "";
            }
          },
          toleranceMinusFormula(element, poItemNumber) {
            return this.tolerancePlusFormula(element, poItemNumber);
          }
        }
      },
      outOfRoundness: {
        body: {
          nominal: 0,
          tolerancePlusFormula(element, poItemNumber) {
            const nominalOD = CUSTOM_EXCEPTIONS[
              "QCP 8262 Rev. 6"
            ].data.externalDiameter.body.nominalFormula(element, poItemNumber);
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return (1 * Number(nominalOD)) / 100;
              case PO_ITEMS.PO_ITEM_2:
                return (1 * Number(nominalOD)) / 100;
              case PO_ITEMS.PO_ITEM_3:
                return 2;
              default:
                return "";
            }
          }
        },
        ends: {
          nominal: 0,
          tolerancePlusFormula(element, poItemNumber) {
            const nominalOD = CUSTOM_EXCEPTIONS[
              "QCP 8262 Rev. 6"
            ].data.externalDiameter.body.nominalFormula(element, poItemNumber);

            return (1.5 * Number(nominalOD)) / 100;
          }
        }
      },
      externalDiameter: {
        body: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 193.7;
              case PO_ITEMS.PO_ITEM_2:
                return 193.7;
              case PO_ITEMS.PO_ITEM_3:
                return 273.1;
              default:
                return "";
            }
          },
          tolerancePlusFormula(element, poItemNumber) {
            const nominal = this.nominalFormula(element, poItemNumber);

            return (0.75 * Number(nominal)) / 100;
          },
          toleranceMinusFormula(element, poItemNumber) {
            return this.tolerancePlusFormula(element, poItemNumber);
          }
        }
      },
      thickness: {
        body: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 16.6;
              case PO_ITEMS.PO_ITEM_2:
                return 14.7;
              case PO_ITEMS.PO_ITEM_3:
                return 16.8;
              default:
                return "";
            }
          },
          tolerancePlusFormula(element, poItemNumber) {
            const nominal = this.nominalFormula(element, poItemNumber);

            return (12.5 * Number(nominal)) / 100;
          },
          toleranceMinusFormula(element, poItemNumber) {
            const nominal = this.nominalFormula(element, poItemNumber);

            return (10 * Number(nominal)) / 100;
          }
        }
      },
      eccentricity: {
        ends: {
          reqsHeader: "Max 18%",
          nominal: 0,
          tolerancePlus: 18,
          toleranceUnits: "%",
          tolerancePlusFormula: (element = {}) =>
            (18 * Number(element.length)) / 100
        }
      },
      squareness: {
        ends: {
          reqsHeader: "Max 1.6mm",
          nominal: 0,
          tolerancePlus: 1.6
        }
      },
      straightness: {
        body: {
          reqsHeader: "Max 0.15% on length",
          nominal: 0,
          tolerancePlusFormula: (element = {}) =>
            (0.15 * Number(element.length)) / 100,
          tolerancePlus: 0.15,
          toleranceUnits: "%"
        },
        ends: {
          reqsHeader: "Max 3 mm on pipe ends",
          nominal: 0,
          tolerancePlus: 3
        }
      }
    }
  },
  "QCP 8252 Rev. 6": {
    company: ["Turcotte Group", "Tenaris-Siderca", "Tremblay Inc."],
    units: TEST_UNITS.METRIC,
    data: {
      internalDiameter: {
        body: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
              case PO_ITEMS.PO_ITEM_2:
                return 98.5;
              case PO_ITEMS.PO_ITEM_3:
              case PO_ITEMS.PO_ITEM_4:
                return 97.1;
              default:
                return "";
            }
          },
          tolerancePlus: 0.57,
          toleranceMinus: 0.57
        }
      },
      outOfRoundness: {
        body: {
          nominal: 0,
          tolerancePlus: 1.14
        },
        ends: {
          nominal: 0,
          tolerancePlus: 1.71
        }
      },
      externalDiameter: {
        body: {
          nominal: 114.3,
          tolerancePlus: 0.86,
          toleranceMinus: 0.86
        }
      },
      thickness: {
        body: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
              case PO_ITEMS.PO_ITEM_2:
                return 7.9;
              case PO_ITEMS.PO_ITEM_3:
              case PO_ITEMS.PO_ITEM_4:
                return 8.6;
              default:
                return "";
            }
          },
          tolerancePlusFormula(element, poItemNumber) {
            const nominal = this.nominalFormula(element, poItemNumber);

            return (15 * Number(nominal)) / 100;
          },
          toleranceMinusFormula(element, poItemNumber) {
            const nominal = this.nominalFormula(element, poItemNumber);

            return (12.5 * Number(nominal)) / 100;
          }
        }
      },
      eccentricity: {
        ends: {
          reqsHeader: "Max 18%",
          nominal: 0,
          tolerancePlus: 18,
          toleranceUnits: "%",
          tolerancePlusFormula: (element = {}) =>
            (18 * Number(element.length)) / 100
        }
      },
      squareness: {
        ends: {
          reqsHeader: "Max 1.6mm",
          nominal: 0,
          tolerancePlus: 1.6
        }
      },
      straightness: {
        body: {
          reqsHeader: "Max 0.15% on length",
          nominal: 0,
          tolerancePlusFormula: (element = {}) =>
            (0.15 * Number(element.length)) / 100,
          tolerancePlus: 0.15,
          toleranceUnits: "%"
        },
        ends: {
          reqsHeader: "Max 3 mm on pipe ends",
          nominal: 0,
          tolerancePlus: 3
        }
      }
    }
  },
  "QCP 8251 Rev. 6": {
    company: ["Turcotte Group", "Tenaris-Siderca", "Tremblay Inc."],
    units: TEST_UNITS.METRIC,
    data: {
      length: {
        nominal: 12.2,
        tolerancePlus: 0.3,
        toleranceMinus: 0.3,
        toleranceUnits: "m"
      },
      internalDiameter: {
        body: {
          nominal: 236.5,
          tolerancePlus: 1.2,
          toleranceMinus: 1.2
        }
      },
      outOfRoundness: {
        body: {
          nominal: 0,
          tolerancePlus: 2
        },
        ends: {
          nominal: 0,
          tolerancePlus: 4.1
        }
      },
      externalDiameter: {
        body: {
          nominal: 273.1,
          tolerancePlus: 2.05,
          toleranceMinus: 2.05
        }
      },
      thickness: {
        body: {
          nominal: 18.3,
          tolerancePlusFormula(element, poItemNumber) {
            const nominal = this.nominal;

            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 1.6;
              case PO_ITEMS.PO_ITEM_2:
                return (12.5 * Number(nominal)) / 100;
              case PO_ITEMS.PO_ITEM_3:
                return (12.5 * Number(nominal)) / 100;
              default:
                return "";
            }
          },
          toleranceMinusFormula(element, poItemNumber) {
            const nominal = this.nominal;

            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 1.6;
              case PO_ITEMS.PO_ITEM_2:
                return (10 * Number(nominal)) / 100;
              case PO_ITEMS.PO_ITEM_3:
                return (10 * Number(nominal)) / 100;
              default:
                return "";
            }
          }
        }
      },
      eccentricity: {
        ends: {
          reqsHeader: "Max 18%",
          nominal: 0,
          tolerancePlus: 18,
          toleranceUnits: "%",
          tolerancePlusFormula: (element = {}) =>
            (18 * Number(element.length)) / 100
        }
      },
      squareness: {
        ends: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_2:
                return 0;
              case PO_ITEMS.PO_ITEM_3:
                return 0;
              default:
                return "";
            }
          },
          tolerancePlusFormula(element, poItemNumber) {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_2:
                return 1.6;
              case PO_ITEMS.PO_ITEM_3:
                return 1.6;
              default:
                return "";
            }
          },
          toleranceMinusFormula(element, poItemNumber) {
            return this.tolerancePlusFormula(element, poItemNumber);
          }
        }
      },
      straightness: {
        body: {
          reqsHeader: "Max 0.15% on length",
          nominal: 0,
          tolerancePlusFormula: (element = {}) =>
            (0.15 * Number(element.length)) / 100,
          tolerancePlus: 0.15,
          toleranceUnits: "%"
        },
        ends: {
          reqsHeader: "Max 3 mm on pipe ends",
          nominal: 0,
          tolerancePlus: 3
        }
      }
    }
  },
  "QCP 8263 Rev. 6": {
    company: ["Turcotte Group", "Tenaris-Siderca", "Tremblay Inc."],
    units: TEST_UNITS.METRIC,
    data: {
      internalDiameter: {
        body: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
              case PO_ITEMS.PO_ITEM_2:
                return 98.5;
              case PO_ITEMS.PO_ITEM_3:
              case PO_ITEMS.PO_ITEM_4:
                return 97.1;
              default:
                return "";
            }
          },
          tolerancePlus: 0.57,
          toleranceMinus: 0.57
        }
      },
      outOfRoundness: {
        body: {
          nominal: 0,
          tolerancePlus: 1.14
        },
        ends: {
          nominal: 0,
          tolerancePlus: 1.71
        }
      },
      externalDiameter: {
        body: {
          nominal: 114.3,
          tolerancePlus: 0.86,
          toleranceMinus: 0.86
        }
      },
      thickness: {
        body: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
              case PO_ITEMS.PO_ITEM_2:
                return 7.9;
              case PO_ITEMS.PO_ITEM_3:
              case PO_ITEMS.PO_ITEM_4:
                return 8.6;
              default:
                return "";
            }
          },
          tolerancePlusFormula(element, poItemNumber) {
            const nominal = this.nominalFormula(element, poItemNumber);

            return (15 * Number(nominal)) / 100;
          },
          toleranceMinusFormula(element, poItemNumber) {
            const nominal = this.nominalFormula(element, poItemNumber);

            return (12.5 * Number(nominal)) / 100;
          }
        }
      },
      eccentricity: {
        ends: {
          reqsHeader: "Max 18%",
          nominal: 0,
          tolerancePlus: 18,
          toleranceUnits: "%",
          tolerancePlusFormula: (element = {}) =>
            (18 * Number(element.length)) / 100
        }
      },
      squareness: {
        ends: {
          reqsHeader: "Max 1.6mm",
          nominal: 0,
          tolerancePlus: 1.6
        }
      },
      straightness: {
        body: {
          reqsHeader: "Max 0.15% on length",
          nominal: 0,
          tolerancePlusFormula: (element = {}) =>
            (0.15 * Number(element.length)) / 100,
          tolerancePlus: 0.15,
          toleranceUnits: "%"
        },
        ends: {
          reqsHeader: "Max 3 mm on pipe ends",
          nominal: 0,
          tolerancePlus: 3
        }
      }
    }
  },
  "ITP-BPM-22.20 Rev.6": {
    company: ["Berg Pipe", "Turcotte Group"],
    units: TEST_UNITS.METRIC,
    data: {
      externalDiameter: {
        body: {
          nominal: 1067,
          tolerancePlus: 4,
          toleranceMinus: 2.1
        },
        ends: {
          nominal: 1067,
          tolerancePlus: 1.6,
          toleranceMinus: 1.6
        }
      },
      thickness: {
        body: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 15.2;
              case PO_ITEMS.PO_ITEM_2:
                return 18.3;
              default:
                return "";
            }
          },
          tolerancePlusFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 1.6;
              case PO_ITEMS.PO_ITEM_2:
                return 1.5;
              default:
                return "";
            }
          },
          toleranceMinus: 1.5
        },
        ends: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 15.2;
              case PO_ITEMS.PO_ITEM_2:
                return 18.3;
              default:
                return "";
            }
          },
          tolerancePlusFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 1.6;
              case PO_ITEMS.PO_ITEM_2:
                return 1.5;
              default:
                return "";
            }
          },
          toleranceMinus: 1.5
        }
      },
      outOfRoundness: {
        body: {
          nominal: 0,
          tolerancePlus: 15
        },
        ends: {
          nominal: 0,
          tolerancePlus: 8
        }
      },
      straightness: {
        body: {
          tolerancePlusFormula: (
            element,
            poItem,
            nominal,
            acceptanceCriteria = {}
          ) => {
            const length = acceptanceCriteria.length || "";
            switch (Number(length.nominal)) {
              case 40:
                return 18.3;
              case 80:
                return 36.5;
              default:
                return "";
            }
          }
        },
        ends: {
          tolerancePlusFormula: (
            element,
            poItem,
            nominal,
            acceptanceCriteria = {}
          ) => {
            const length = acceptanceCriteria.length || "";
            switch (Number(length.nominal)) {
              case 40:
                return 18.3;
              case 80:
                return 36.5;
              default:
                return "";
            }
          }
        }
      },
      depthOfGroove: {
        nominal: 0,
        tolerancePlus: 3
      },
      radialOffset: {
        nominal: 0,
        tolerancePlus: 1.5
      },
      weldFLash: {
        body: {
          nominal: 0,
          tolerancePlus: 3.5
        },
        ends: {
          nominal: 0,
          tolerancePlus: 3.5
        }
      },
      weldFlashOutside: {
        body: {
          nominal: 0,
          tolerancePlus: 4.5
        },
        ends: {
          nominal: 0,
          tolerancePlus: 4.5
        }
      },
      peaking: {
        ends: {
          nominal: 0,
          tolerancePlus: 2
        }
      },
      bevelAngle: {
        nominal: 30,
        toleranceMinus: 0,
        tolerancePlus: 5
      },
      bevelRootFace: {
        nominal: 1.6,
        toleranceMinus: 0.8,
        tolerancePlus: 0.8
      },
      squareness: {
        body: {
          nominal: 0,
          tolerancePlus: 1.6
        },
        ends: {
          nominal: 0,
          tolerancePlus: 1.6
        }
      },
      length: {
        units: "ft",
        tolerancePlus: 5,
        toleranceMinusFormula: (element, poItem, nominal) => {
          switch (nominal) {
            case 80:
              return 40;
            case 40:
              return 17;
            default:
              return "";
          }
        },
        // if nominal is 80 then average of all input values should be minimum 73 ft
        // if nominal is 40 then average of all input values should be minimum 35 ft and 90% of all input values should be minimum 30 ft
        getIsAcceptable: (elements, min, max, nominal) => {
          if (!elements) return;

          const acceptable = elements.every((element) =>
            isAcceptable(element, min, max)
          );

          if (!acceptable) return false;

          const average =
            elements.reduce((acc, element) => acc + Number(element.length), 0) /
            elements.length;

          if (nominal === 80) return average >= 73;

          if (nominal === 40) {
            const minimumNumberOfAcceptableElements = Math.floor(
              (90 * elements.length) / 100
            );
            const actualNumberOfAcceptableElements = elements.filter(
              (element) => element.length >= 30
            ).length;

            return (
              average >= 35 &&
              actualNumberOfAcceptableElements >=
                minimumNumberOfAcceptableElements
            );
          }
        }
      },
      weight: {
        tolerancePlus: 10,
        toleranceMinus: 1.75,
        toleranceUnits: "%"
      }
    }
  },
  "QCP 5513 Rev. 2": {
    company: ["Tenaris", "Turcotte Group"],
    units: TEST_UNITS.METRIC,
    data: {
      externalDiameter: {
        body: {
          nominal: 273.1,
          toleranceMinus: 1.64,
          tolerancePlus: 1.64
        }
      },
      internalDiameter: {
        body: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 199.1;
              case PO_ITEMS.PO_ITEM_2:
                return 182.7;
              default:
                return "";
            }
          }
        },
        ends: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 195.4;
              case PO_ITEMS.PO_ITEM_2:
                return 182.7;
              default:
                return "";
            }
          },
          tolerancePlusFormula(element, poItemNumber) {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 1.7;
              case PO_ITEMS.PO_ITEM_2:
                return 3.75;
              default:
                return "";
            }
          },
          toleranceMinusFormula(element, poItemNumber) {
            return this.tolerancePlusFormula(element, poItemNumber);
          }
        }
      },
      thickness: {
        body: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 37;
              case PO_ITEMS.PO_ITEM_2:
                return 45.2;
              default:
                return "";
            }
          },
          tolerancePlusFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 5.55;
              case PO_ITEMS.PO_ITEM_2:
                return 6.78;
              default:
                return "";
            }
          },
          toleranceMinusFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 1.85;
              case PO_ITEMS.PO_ITEM_2:
                return 2.26;
              default:
                return "";
            }
          }
        }
      },
      outOfRoundness: {
        body: {
          nominal: 0,
          tolerancePlus: 2.73
        },
        ends: {
          nominal: 0,
          tolerancePlus: 6
        }
      },
      eccentricity: {
        ends: {
          reqsHeader: "<= 15% of thickness",
          nominal: 0,
          tolerancePlus: 15,
          toleranceUnits: "%",
          tolerancePlusFormula: (element = {}) =>
            (15 * Number(element.thickness?.body || 0)) / 100
        }
      },
      straightness: {
        body: {
          reqsHeader: "Max 0.15% total length",
          nominal: 0,
          tolerancePlusFormula: (element = {}) =>
            (0.15 * Number(element.length || 0) * 1000) / 100,
          toleranceUnits: "%"
        },
        ends: {
          nominal: 0,
          tolerancePlus: 3.2
        }
      },
      squareness: {
        body: {
          nominal: 0,
          tolerancePlus: 1.6
        },
        ends: {
          nominal: 0,
          tolerancePlus: 1.6
        }
      },
      length: {
        nominalFormula: (element, poItemNumber) => {
          switch (poItemNumber) {
            case PO_ITEMS.PO_ITEM_1:
              return 11.9;
            case PO_ITEMS.PO_ITEM_2:
              return 11.6;
            default:
              return "";
          }
        },
        tolerancePlus: 0.7,
        toleranceMinus: 0,
        // PO Item 1: 11.9 - 12.6 m (at least 80% of products shall have length between 12 and 12.4 m)
        // PO Item 2: 11.6 - 12.3 m (the average of all products shall have length between 11.8 and 12.1 m)
        getIsAcceptable: (elements, min, max, nominal, poItem) => {
          if (!elements) return;

          const acceptable = elements.every((element) =>
            isAcceptable(element, min, max)
          );

          if (!acceptable) return false;

          if (poItem === PO_ITEMS.PO_ITEM_1) {
            const eightyPercentOfElements = elements.length * 0.8;
            const elementsWithApplicableLength = elements.filter(
              (element) => element.value >= 12 && element.value <= 12.4
            ).length;

            return elementsWithApplicableLength >= eightyPercentOfElements;
          } else if (poItem === PO_ITEMS.PO_ITEM_2) {
            const averageLength = getMean(
              elements.map((element) => element.value)
            );

            return averageLength >= 11.8 && averageLength <= 12.1;
          }

          return true;
        }
      }
    }
  },
  "QCP 5982 Rev. 4": {
    company: ["Tenaris", "Turcotte Group"],
    units: TEST_UNITS.METRIC,
    data: {
      externalDiameter: {
        body: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
              case PO_ITEMS.PO_ITEM_2:
              case PO_ITEMS.PO_ITEM_3:
                return 406.4;
              case PO_ITEMS.PO_ITEM_4:
                return 273.1;
              default:
                return "";
            }
          },
          tolerancePlusFormula(element, poItemNumber) {
            const nominal = this.nominalFormula(element, poItemNumber);

            return (0.75 * Number(nominal)) / 100;
          },
          toleranceMinusFormula(element, poItemNumber) {
            return this.tolerancePlusFormula(element, poItemNumber);
          }
        },
        ends: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_3:
                return 406.4;
              case PO_ITEMS.PO_ITEM_4:
                return 273.1;
              default:
                return "";
            }
          },
          tolerancePlusFormula(element, poItemNumber) {
            const nominal = this.nominalFormula(element, poItemNumber);

            return nominal ? (0.75 * Number(nominal)) / 100 : null;
          },
          toleranceMinusFormula(element, poItemNumber) {
            return this.tolerancePlusFormula(element, poItemNumber);
          }
        }
      },
      internalDiameter: {
        body: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 363.52;
              case PO_ITEMS.PO_ITEM_2:
                return 345.44;
              case PO_ITEMS.PO_ITEM_3:
                return 340.2;
              case PO_ITEMS.PO_ITEM_4:
                return 174.9;
              default:
                return "";
            }
          }
        },
        ends: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 363.52;
              case PO_ITEMS.PO_ITEM_2:
                return 345.44;
              default:
                return "";
            }
          },
          tolerancePlusFormula(element, poItemNumber) {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 1.2;
              case PO_ITEMS.PO_ITEM_2:
                return 3.5;
              default:
                return "";
            }
          },
          toleranceMinusFormula(element, poItemNumber) {
            return this.tolerancePlusFormula(element, poItemNumber);
          }
        }
      },
      thickness: {
        body: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 21.44;
              case PO_ITEMS.PO_ITEM_2:
                return 30.48;
              case PO_ITEMS.PO_ITEM_3:
                return 33.1;
              case PO_ITEMS.PO_ITEM_4:
                return 49.1;
              default:
                return "";
            }
          },
          tolerancePlusFormula(element, poItemNumber) {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 2.14;
              case PO_ITEMS.PO_ITEM_2:
                return 4.57;
              case PO_ITEMS.PO_ITEM_3:
                return 3.31;
              case PO_ITEMS.PO_ITEM_4:
                return 7.37;
              default:
                return "";
            }
          },
          toleranceMinusFormula(element, poItemNumber) {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 2.14;
              case PO_ITEMS.PO_ITEM_2:
                return 1.52;
              case PO_ITEMS.PO_ITEM_3:
                return 3.31;
              case PO_ITEMS.PO_ITEM_4:
                return 2.46;
              default:
                return "";
            }
          }
        }
      },
      outOfRoundness: {
        body: {
          nominal: 0,
          tolerancePlusFormula(element, poItemNumber) {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 6.1;
              case PO_ITEMS.PO_ITEM_2:
                return 4.06;
              case PO_ITEMS.PO_ITEM_3:
                return 6.1;
              case PO_ITEMS.PO_ITEM_4:
                return 4.1;
              default:
                return "";
            }
          }
        },
        ends: {
          nominal: 0,
          tolerancePlusFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
              case PO_ITEMS.PO_ITEM_2:
              case PO_ITEMS.PO_ITEM_3:
                return 6.1;
              case PO_ITEMS.PO_ITEM_4:
                return 4.1;
              default:
                return "";
            }
          }
        }
      },
      outOfRoundnessInternal: {
        ends: {
          nominal: 0,
          tolerancePlusFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 2.4;
              case PO_ITEMS.PO_ITEM_2:
                return 5.5;
              default:
                return "";
            }
          }
        }
      },
      eccentricity: {
        ends: {
          reqsHeader: "<= 158% of thickness",
          nominal: 0,
          tolerancePlus: 158,
          toleranceUnits: "%",
          tolerancePlusFormula: (element = {}) =>
            (158 * Number(element.thickness?.body || 0)) / 100
        }
      },
      straightness: {
        body: {
          reqsHeader: "Max 0.15% total length",
          nominal: 0,
          tolerancePlusFormula: (element = {}) =>
            (0.15 * Number(element.length)) / 100,
          tolerancePlus: 0.15,
          toleranceUnits: "%"
        },
        ends: {
          nominal: 0,
          tolerancePlus: 3.2
        }
      },
      squareness: {
        body: {
          nominal: 0,
          tolerancePlus: 1.6
        },
        ends: {
          nominal: 0,
          tolerancePlus: 1.6
        }
      },
      length: {
        nominalFormula: (element, poItemNumber) => {
          switch (poItemNumber) {
            case PO_ITEMS.PO_ITEM_1:
            case PO_ITEMS.PO_ITEM_2:
              return 11.9;
            case PO_ITEMS.PO_ITEM_3:
            case PO_ITEMS.PO_ITEM_4:
              return 9;
            default:
              return "";
          }
        },
        tolerancePlusFormula: (element, poItemNumber) => {
          switch (poItemNumber) {
            case PO_ITEMS.PO_ITEM_1:
            case PO_ITEMS.PO_ITEM_2:
              return 0.7;
            case PO_ITEMS.PO_ITEM_3:
            case PO_ITEMS.PO_ITEM_4:
              return 4;
            default:
              return "";
          }
        },
        toleranceMinus: 0,
        // PO Item 1 and 2: 11.9 - 12.6 m (average shall have length >= 12.1 m)
        getIsAcceptable: (elements, min, max, nominal, poItem) => {
          if (!elements) return;

          const acceptable = elements.every((element) =>
            isAcceptable(element, min, max)
          );

          if (!acceptable) return false;

          if (poItem === PO_ITEMS.PO_ITEM_1) {
            const averageLength = getMean(
              elements.map((element) => element.value)
            );

            return averageLength >= 12.1;
          }

          return true;
        }
      }
    }
  },
  "CPW-ITP-24-23-SAWL Rev. 3": {
    company: ["Turcotte Group"],
    units: TEST_UNITS.METRIC,
    data: {
      externalDiameter: {
        body: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_10:
                return 686;
              case PO_ITEMS.PO_ITEM_20:
                return 730;
              default:
                return "";
            }
          },
          toleranceMinus: 2,
          tolerancePlus: 2
        }
      },
      internalDiameter: {
        ends: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_10:
                return 660;
              case PO_ITEMS.PO_ITEM_20:
                return 702;
              default:
                return "";
            }
          },
          toleranceMinus: 1.5,
          tolerancePlus: 1.5
        }
      },
      thickness: {
        ends: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_10:
                return 13;
              case PO_ITEMS.PO_ITEM_20:
                return 14;
              default:
                return "";
            }
          },
          toleranceMinus: 0.5,
          tolerancePlus: 1
        }
      },
      geometricDeviation: {
        nominal: 0,
        tolerancePlus: 3.2
      },
      outOfRoundness: {
        body: {
          nominal: 0,
          tolerancePlusFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_10:
                return 6.8;
              case PO_ITEMS.PO_ITEM_20:
                return 7.3;
              default:
                return "";
            }
          }
        },
        ends: {
          nominal: 0,
          tolerancePlusFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_10:
                return 5.1;
              case PO_ITEMS.PO_ITEM_20:
                return 5.4;
              default:
                return "";
            }
          }
        }
      },
      length: {
        nominal: 17.22,
        tolerancePlus: 1
      },
      straightness: {
        body: {
          reqsHeader: "Max 0.15% total length",
          nominal: 0,
          tolerancePlusFormula: (element = {}) =>
            (0.15 * Number(element.length)) / 100,
          tolerancePlus: 0.15,
          toleranceUnits: "%"
        },
        ends: {
          nominal: 0,
          tolerancePlus: 2.9
        }
      },
      squareness: {
        body: {
          nominal: 0,
          tolerancePlus: 1.6
        },
        ends: {
          nominal: 0,
          tolerancePlus: 1.6
        }
      },
      radialOffset: {
        nominal: 0,
        tolerancePlusFormula: (element, poItemNumber) => {
          switch (poItemNumber) {
            case PO_ITEMS.PO_ITEM_10:
              return 1.3;
            case PO_ITEMS.PO_ITEM_20:
              return 1.4;
            default:
              return "";
          }
        }
      },
      heightOfWeldBead: {
        body: {
          nominal: 0,
          tolerancePlus: 3.5
        },
        ends: {
          nominal: 0,
          tolerancePlusFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_10:
                return 3.5;
              case PO_ITEMS.PO_ITEM_20:
                return 4.5;
              default:
                return "";
            }
          }
        }
      },
      misalignmentOfWeldBead: {
        nominal: 0,
        tolerancePlus: 3
      }
    }
  },
  "ITP 011/23/124277 Rev. 05": {
    company: ["Turcotte Group"],
    units: TEST_UNITS.METRIC,
    data: {
      externalDiameter: {
        body: {
          nominal: 406.4,
          toleranceMinus: 3,
          tolerancePlus: 3
        },
        ends: {
          nominal: 406.4,
          toleranceMinus: 3,
          tolerancePlus: 3
        }
      },
      length: {
        nominalFormula: (element, poItemNumber) => {
          switch (poItemNumber) {
            case PO_ITEMS.PO_ITEM_1:
            case PO_ITEMS.PO_ITEM_2:
            case PO_ITEMS.PO_ITEM_3:
            case PO_ITEMS.PO_ITEM_4:
              return 11900;
            case PO_ITEMS.PO_ITEM_5:
              return 1000;
            default:
              return "";
          }
        },
        tolerancePlusFormula(element, poItemNumber) {
          switch (poItemNumber) {
            case PO_ITEMS.PO_ITEM_1:
            case PO_ITEMS.PO_ITEM_2:
            case PO_ITEMS.PO_ITEM_3:
            case PO_ITEMS.PO_ITEM_4:
              return 350;
            case PO_ITEMS.PO_ITEM_5:
              return 100;
            default:
              return "";
          }
        },
        toleranceMinusFormula(element, poItemNumber) {
          switch (poItemNumber) {
            case PO_ITEMS.PO_ITEM_5:
              return 50;
            default:
              return "";
          }
        },
        // PO Items 1-2-3-4
        // 95% or more of all produced pipes shall have length between 11900 - 12250 mm (included)
        // 5% of pipes are allowed to be shorter and have length between 11000 - 11899 mm (included)
        globalCriteria: {
          label:
            "Only for QCP Items 1-4: 95% or more of all produced pipes shall have length between 11900 - 12250 mm (included) while 5% of pipes are allowed to be shorter and have length between 11000 - 11899 mm (included)",
          value:
            "(elements) => {const ninetyFivePercentElementsAmount = Math.ceil(elements.length * 0.95);const acceptableElements = elements.filter((element) => element.value >= 11900 && element.value <= 12250);const notAcceptableElements = elements.filter((element) => element.value < 11900 || element.value > 12250);const restAcceptable = notAcceptableElements.every((element) => element.value >= 11000 && element.value <= 11899);return acceptableElements.length >= ninetyFivePercentElementsAmount && restAcceptable;}"
        },
        getIsAcceptable: (elements, min, max, nominal, poItem) => {
          if (!elements) return;

          if (
            [
              PO_ITEMS.PO_ITEM_1,
              PO_ITEMS.PO_ITEM_2,
              PO_ITEMS.PO_ITEM_3,
              PO_ITEMS.PO_ITEM_4
            ].includes(poItem)
          ) {
            const smallerMin = 11000;
            const smallerMax = 11899;

            const ninetyFivePercentElementsAmount = Math.ceil(
              elements.length * 0.95
            );

            const acceptableElements = elements.filter((element) =>
              isAcceptable(element, min, max)
            );
            const notAcceptableElements = elements.filter(
              (element) => !isAcceptable(element, min, max)
            );

            const restAcceptable = notAcceptableElements.every(
              (element) =>
                element.value >= smallerMin && element.value <= smallerMax
            );

            return (
              acceptableElements.length >= ninetyFivePercentElementsAmount &&
              restAcceptable
            );
          }

          return true;
        }
      },
      straightness: {
        body: {
          reqsHeader: "Max 0.12% of the length",
          tolerancePlusFormula: (element = {}) =>
            (0.12 * Number(element.length)) / 100,
          tolerancePlus: 0.12,
          toleranceUnits: "%"
        },
        ends: {
          nominal: 0,
          tolerancePlus: 3.2
        }
      },
      weight: {
        tolerancePlus: 5,
        toleranceMinus: 4,
        toleranceUnits: "%",
        globalCriteria: {
          label:
            "All produced pipes shall not exceed +/- 2.65% of nominal weight",
          value:
            "(elements, nominal) => {const nominalWeight = nominal * elements.length;const tolerance = 2.65 * nominalWeight / 100;const maxWeight = nominalWeight + tolerance;const minWeight = nominalWeight - tolerance;const weightSum = elements.reduce((acc, element) => acc + Number(element.value), 0);return weightSum <= maxWeight && maxWeight >= minWeight;}"
        },
        getIsAcceptable: (elements, min, max, nominal) => {
          if (!elements) return;

          const acceptable = elements.every((element) =>
            isAcceptable(element, min, max)
          );

          if (!acceptable) return false;

          const nominalWeight = nominal * elements.length;

          const tolerance = (2.65 * nominalWeight) / 100;
          const maxWeight = nominalWeight + tolerance;
          const minWeight = nominalWeight - tolerance;

          const weightSum = elements.reduce(
            (acc, element) => acc + Number(element.value),
            0
          );

          return weightSum <= maxWeight && maxWeight >= minWeight;
        }
      },
      outOfRoundness: {
        body: {
          nominal: 0,
          tolerancePlus: 5
        },
        ends: {
          nominal: 0,
          tolerancePlus: 1
        }
      },
      internalDiameter: {
        ends: {
          tolerancePlus: 1,
          toleranceMinus: 1
        }
      },
      squareness: {
        body: {
          nominal: 0,
          tolerancePlus: 1.6
        },
        ends: {
          nominal: 0,
          tolerancePlus: 1.6
        }
      },
      thicknessOfLiner: {
        ends: {
          nominal: 2.5,
          tolerancePlus: 0.5
        }
      },
      thicknessOfWeldOverlay: {
        ends: {
          nominal: 3,
          tolerancePlus: 2
        }
      },
      totalThickness: {
        ends: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
              case PO_ITEMS.PO_ITEM_3:
                return 33.18;
              case PO_ITEMS.PO_ITEM_2:
              case PO_ITEMS.PO_ITEM_4:
              case PO_ITEMS.PO_ITEM_5:
                return 35;
              default:
                return "";
            }
          },
          toleranceMinusFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
              case PO_ITEMS.PO_ITEM_3:
                return 3;
              case PO_ITEMS.PO_ITEM_2:
              case PO_ITEMS.PO_ITEM_4:
              case PO_ITEMS.PO_ITEM_5:
                return 3.2;
              default:
                return "";
            }
          },
          tolerancePlusFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
              case PO_ITEMS.PO_ITEM_3:
                return 5;
              case PO_ITEMS.PO_ITEM_2:
              case PO_ITEMS.PO_ITEM_4:
              case PO_ITEMS.PO_ITEM_5:
                return 5.3;
              default:
                return "";
            }
          }
        }
      },
      carbonSteelThickness: {
        ends: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
              case PO_ITEMS.PO_ITEM_3:
                return 30.18;
              case PO_ITEMS.PO_ITEM_2:
              case PO_ITEMS.PO_ITEM_4:
              case PO_ITEMS.PO_ITEM_5:
                return 32;
              default:
                return "";
            }
          },
          toleranceMinusFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
              case PO_ITEMS.PO_ITEM_3:
                return 3;
              case PO_ITEMS.PO_ITEM_2:
              case PO_ITEMS.PO_ITEM_4:
              case PO_ITEMS.PO_ITEM_5:
                return 3.2;
              default:
                return "";
            }
          },
          tolerancePlusFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
              case PO_ITEMS.PO_ITEM_3:
                return 3;
              case PO_ITEMS.PO_ITEM_2:
              case PO_ITEMS.PO_ITEM_4:
              case PO_ITEMS.PO_ITEM_5:
                return 3.2;
              default:
                return "";
            }
          }
        }
      }
    }
  },
  "QCP 5987 Rev. 2": {
    company: ["Tenaris", "TenarisLab", "Turcotte Group", "Tremblay Inc."],
    units: TEST_UNITS.METRIC,
    data: {
      externalDiameter: {
        body: {
          nominal: 273.1,
          toleranceMinus: 2.05,
          tolerancePlus: 2.05
        }
      },
      thickness: {
        body: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 45.21;
              case PO_ITEMS.PO_ITEM_2:
                return 47.93;
              default:
                return "";
            }
          }
        },
        ends: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 45.21;
              case PO_ITEMS.PO_ITEM_2:
                return 47.93;
              default:
                return "";
            }
          },
          toleranceMinusFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 2.26;
              case PO_ITEMS.PO_ITEM_2:
                return 0;
              default:
                return "";
            }
          },
          tolerancePlusFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 6.78;
              case PO_ITEMS.PO_ITEM_2:
                return 9.59;
              default:
                return "";
            }
          }
        }
      },
      internalDiameter: {
        body: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 182.68;
              case PO_ITEMS.PO_ITEM_2:
                return 177.24;
              default:
                return "";
            }
          }
        },
        ends: {
          reqsHeader: "+/- 3.75 mm applied to Actual ID"
        }
      },
      outOfRoundness: {
        ends: {
          nominal: 0,
          tolerancePlus: 4.1
        },
        body: {
          nominal: 0,
          tolerancePlus: 6.5
        }
      },
      eccentricity: {
        ends: {
          reqsHeader: "Max 18% of the input of wall thickness",
          tolerancePlusFormula: (element) => {
            return element.thickness
              ? Math.max(
                element.thickness.leftEnd,
                element.thickness.rightEnd
              ) * 0.18
              : 0;
          }
        }
      },
      straightness: {
        body: {
          nominal: 0,
          tolerancePlus: 0.15,
          toleranceUnits: "%"
        },
        ends: {
          nominal: 0,
          tolerancePlus: 3.2
        }
      },
      squareness: {
        ends: {
          nominal: 0,
          tolerancePlus: 1.6
        }
      },
      length: {
        nominalFormula: (element, poItemNumber) => {
          switch (poItemNumber) {
            case PO_ITEMS.PO_ITEM_1:
              return 11.6;
            case PO_ITEMS.PO_ITEM_2:
              return 10.5;
            default:
              return "";
          }
        },
        toleranceMinus: 0,
        tolerancePlusFormula: (element, poItemNumber) => {
          switch (poItemNumber) {
            case PO_ITEMS.PO_ITEM_1:
              return 0.2;
            case PO_ITEMS.PO_ITEM_2:
              return 0.8;
            default:
              return "";
          }
        }
      },
      weight: {
        toleranceMinusFormula: (element, poItem, nominal) => {
          return nominal ? nominal * 0.035 : 0;
        },
        tolerancePlusFormula: (element, poItemNumber, nominal) => {
          switch (poItemNumber) {
            case PO_ITEMS.PO_ITEM_1:
              return nominal ? nominal * 0.1 : 0;
            case PO_ITEMS.PO_ITEM_2:
              return nominal ? nominal * 0.15 : 0;
            default:
              return "";
          }
        }
      }
    }
  },
  "QCP 5983 Rev. 5": {
    company: ["Tenaris", "TenarisLab", "Turcotte Group", "Tremblay Inc."],
    units: TEST_UNITS.METRIC,
    data: {
      externalDiameter: {
        body: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 508;
              case PO_ITEMS.PO_ITEM_2:
                return 508;
              case PO_ITEMS.PO_ITEM_3:
                return 457.2;
              case PO_ITEMS.PO_ITEM_4:
                return 457.2;
              default:
                return "";
            }
          },
          toleranceMinusFormula: (element, poItemNumber, nominal) => {
            return nominal * 0.0075;
          },
          tolerancePlusFormula: (element, poItemNumber, nominal) => {
            return nominal * 0.0075;
          }
        },
        ends: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 508;
              case PO_ITEMS.PO_ITEM_2:
                return 508;
              case PO_ITEMS.PO_ITEM_3:
                return 457.2;
              case PO_ITEMS.PO_ITEM_4:
                return 457.2;
              default:
                return "";
            }
          },
          toleranceMinusFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_2:
                return 3.81;
              case PO_ITEMS.PO_ITEM_4:
                return 3.43;
              default:
                return "";
            }
          },
          tolerancePlusFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_2:
                return 3.81;
              case PO_ITEMS.PO_ITEM_4:
                return 3.43;
              default:
                return "";
            }
          }
        }
      },
      thickness: {
        body: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 30.48;
              case PO_ITEMS.PO_ITEM_2:
                return 33.1;
              case PO_ITEMS.PO_ITEM_3:
                return 30.48;
              case PO_ITEMS.PO_ITEM_4:
                return 33.1;
              default:
                return "";
            }
          }
        },
        ends: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 30.48;
              case PO_ITEMS.PO_ITEM_2:
                return 33.1;
              case PO_ITEMS.PO_ITEM_3:
                return 30.48;
              case PO_ITEMS.PO_ITEM_4:
                return 33.1;
              default:
                return "";
            }
          },
          toleranceMinusFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 3.05;
              case PO_ITEMS.PO_ITEM_2:
                return 3.31;
              case PO_ITEMS.PO_ITEM_3:
                return 3.51;
              case PO_ITEMS.PO_ITEM_4:
                return 3.31;
              default:
                return "";
            }
          },
          tolerancePlusFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 3.05;
              case PO_ITEMS.PO_ITEM_2:
                return 3.31;
              case PO_ITEMS.PO_ITEM_3:
                return 3.51;
              case PO_ITEMS.PO_ITEM_4:
                return 3.81;
              default:
                return "";
            }
          }
        }
      },
      internalDiameter: {
        body: {
          nominalFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 447.04;
              case PO_ITEMS.PO_ITEM_2:
                return 441.8;
              case PO_ITEMS.PO_ITEM_3:
                return 396.24;
              case PO_ITEMS.PO_ITEM_4:
                return 391.0;
              default:
                return "";
            }
          }
        }
      },
      outOfRoundnessInternal: {
        ends: {
          nominal: 0,
          tolerancePlusFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
                return 6.5;
              case PO_ITEMS.PO_ITEM_3:
                return 2.4;
              default:
                return "";
            }
          }
        }
      },
      outOfRoundness: {
        ends: {
          nominal: 0,
          tolerancePlusFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_2:
                return 7.62;
              case PO_ITEMS.PO_ITEM_4:
                return 6.86;
              default:
                return "";
            }
          }
        },
        body: {
          nominal: 0,
          tolerancePlusFormula: (element, poItemNumber) => {
            switch (poItemNumber) {
              case PO_ITEMS.PO_ITEM_1:
              case PO_ITEMS.PO_ITEM_2:
                return 7.62;
              case PO_ITEMS.PO_ITEM_3:
              case PO_ITEMS.PO_ITEM_4:
                return 6.86;
              default:
                return "";
            }
          }
        }
      },
      eccentricity: {
        ends: {
          reqsHeader: "Max 18% of the input of wall thickness",
          tolerancePlusFormula: (element) => {
            return element.thickness
              ? Math.max(
                element.thickness.leftEnd,
                element.thickness.rightEnd
              ) * 0.18
              : 0;
          }
        }
      },
      straightness: {
        body: {
          nominal: 0,
          tolerancePlus: 0.15,
          toleranceUnits: "%"
        },
        ends: {
          nominal: 0,
          tolerancePlus: 3.2
        }
      },
      squareness: {
        ends: {
          nominal: 0,
          tolerancePlus: 1.6
        }
      },
      length: {
        nominalFormula: (element, poItemNumber) => {
          switch (poItemNumber) {
            case PO_ITEMS.PO_ITEM_1:
              return 10.2;
            case PO_ITEMS.PO_ITEM_2:
              return 9.0;
            case PO_ITEMS.PO_ITEM_3:
              return 11.6;
            case PO_ITEMS.PO_ITEM_4:
              return 9.0;
            default:
              return "";
          }
        },
        toleranceMinus: 0,
        tolerancePlusFormula: (element, poItemNumber) => {
          switch (poItemNumber) {
            case PO_ITEMS.PO_ITEM_1:
              return 1;
            case PO_ITEMS.PO_ITEM_2:
              return 2.2;
            case PO_ITEMS.PO_ITEM_3:
              return 0.2;
            case PO_ITEMS.PO_ITEM_4:
              return 2.6;
            default:
              return "";
          }
        },
        // PO Item 1:
        // single pipe: 10.2 - 11.2 m (included)
        // average of all pipes shall be >= 10.9 m
        getIsAcceptable: (elements, min, max, nominal, poItem) => {
          if (!elements) return;

          const acceptable = elements.every((element) =>
            isAcceptable(element, min, max)
          );

          if (!acceptable) return false;

          if (poItem === PO_ITEMS.PO_ITEM_1) {
            const averageLength = getMean(
              elements.map((element) => element.value)
            );

            return averageLength >= 10.9;
          }

          return true;
        }
      },
      weight: {
        toleranceMinusFormula: (element, poItem, nominal) => {
          return nominal ? nominal * 0.035 : 0;
        },
        tolerancePlusFormula: (element, poItemNumber, nominal) => {
          return nominal ? nominal * 0.1 : 0;
        }
      }
    }
  }
};
