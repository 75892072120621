import {ROUTES} from "@core/api/routes";
import {TEST_CATEGORIES_LABELS} from "@core/constants/test";
import {ACTIONS} from "@core/constants/api";
import axios from "axios";
import {pick} from "ramda";

export const TABS = {
  DASHBOARD: "DASHBOARD",
  TESTS: "TESTS",
  CONCESSION_REQUESTS: "CONCESSION_REQUESTS",
  BASE_MATERIALS: "BASE_MATERIALS",
  TRACEABILITY: "TRACEABILITY",
  UPLOADS: "UPLOADS"
};

export const TABS_TITLES = {
  [TABS.DASHBOARD]: "Dashboard",
  [TABS.TESTS]: "Test(s) & Analyses",
  [TABS.CONCESSION_REQUESTS]: "Concession Requests",
  [TABS.BASE_MATERIALS]: "Base Materials",
  [TABS.TRACEABILITY]: "Traceability",
  [TABS.UPLOADS]: "File(s)"
};

export const TABS_TOOLTIPS = {
  [TABS.DASHBOARD]: "Dashboard",
  [TABS.TESTS]: "Test(s) & Analyses",
  [TABS.CONCESSION_REQUESTS]: "Concession Requests",
  [TABS.BASE_MATERIALS]: "Base Materials",
  [TABS.TRACEABILITY]: "Traceability",
  [TABS.UPLOADS]: "File(s)"
};

export const SUB_TABS_BY_TAB = {
  [TABS.TESTS]: async (campaignId) => {
    const {data: subTabs} = await axios.get(ROUTES.SINGLE_MATERIAL_CAMPAIGN_TESTS[ACTIONS.TYPES](campaignId));
    const filledSubTabs = subTabs.filter((tab) => !!tab);  // remove this line when backend is fixed. use subTabs instead

    return pick(filledSubTabs, TEST_CATEGORIES_LABELS);
  },
};