import React, {useState} from "react";
import {useFormikContext} from "formik";
import {MuiAutocomplete} from "@core/components/Form";
import CreateProject from "@core/components/CreateProjectModal";
import useStores from "../../../useStores";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

const ProjectIdAutocomplete = ({name, disabled, label}) => {
  const {ProjectStore} = useStores();
  const [addProjectOpen, setAddProjectOpen] = useState(false);

  const {setFieldValue} = useFormikContext();

  return (
    <>
      <MuiAutocomplete
        disabled={disabled}
        url={ROUTES.PROJECT[ACTIONS.ALL]}
        inputProps={{
          label,
          name,
          required: false
        }}
        formatOptions={(data) =>
          data.map((item) => ({
            label: item.name,
            value: item._id
          }))
        }
        onCreateNew={() => setAddProjectOpen(true)}
      />
      <CreateProject
        createProject={async (data) => {
          const project = await ProjectStore.createProject(data);
          setFieldValue(name, project._id);
        }}
        open={addProjectOpen}
        setOpen={setAddProjectOpen}
      />
    </>
  );
};
export default ProjectIdAutocomplete;
