import React, {useEffect, useMemo, useRef} from "react";
import {Chart as ChartJS} from "chart.js";
import {times} from "ramda";
import {COLORS, PLOT_TYPES, PLOT_TYPES_TITLES} from "@core/components/Chart/сonstants";
import {usePlotOptions} from "../../plotContext";
import {getScale} from "../../services";

const RadarPlot = ({plotData, isFullScreen}) => {
  const canvas = useRef(null);
  const plot = useRef(null);

  const {options: plotOptions, paramType} = usePlotOptions();

  const data = plotData.plot.datasets;
  const labels = plotData.plot.labels;

  const values = useMemo(() => data.reduce((values, dataset) => values.concat(dataset.data), []), []);
  const {min: minAxisR , max: maxAxisR} = useMemo(() => getScale(values, plotOptions.showAcceptanceCriteria, plotData.minmax), [plotOptions.showAcceptanceCriteria, plotData.minmax]);

  const acceptanceCriteriaDatasets = useMemo(() => {
    const datasets = [];

    if(plotData.minmax.minY) {
      datasets.push({
        data: times(() => plotData.minmax.minY, labels.length),
        borderColor: COLORS.red.backgroundColor,
        backgroundColor: COLORS.red.backgroundColor,
        borderWidth: 2,
      }); 
    }

    if(plotData.minmax.maxY) {
      datasets.push({
        data: times(() => plotData.minmax.maxY, labels.length),
        borderColor: COLORS.red.backgroundColor,
        backgroundColor: COLORS.red.backgroundColor,
        borderWidth: 2,
      });
    }

    return datasets;
  }, []);

  const formattedData = useMemo(() => {
    return data.map(({data, label}) => ({
      data,
      label,
      showLine: false,
      backgroundColor: COLORS.blue.borderColor,
    }));
  }, []);

  const datasets = useMemo(() => {
    if(!plotOptions.showAcceptanceCriteria) return formattedData;

    return [...formattedData, ...acceptanceCriteriaDatasets];
  }, [formattedData, plotOptions.showAcceptanceCriteria]);

  useEffect(() => {
    const chartConfig = {
      type: "radar",
      data: {
        labels,
        datasets,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            filter: (context) => context.dataset.borderColor !== COLORS.red.backgroundColor,
            callbacks: {
              label: (context) => `${context.dataset.label}: ${context.parsed.r + plotData.units}`,
              title: (items) => {
                if (!items.length) {
                  return "";
                }

                return `Pos: ${items[0].label}`;
              }
            }
          },
          title: {
            display: true,
            text: PLOT_TYPES_TITLES[PLOT_TYPES.RADAR],
          },
          legend: {
            labels: {
              filter: () => false,
            },
            title: {
              display: true,
              text: paramType
            },
          }
        },
        scales: {
          r: {
            max: maxAxisR,
            min: minAxisR,
            ticks: {
              callback: (value) => `${value.toFixed(2)}          `,
            }
          },
        }
      },
    };
    plot.current = new ChartJS(canvas.current, chartConfig);

    return () => {
      plot.current.destroy();
    };
  }, [datasets, isFullScreen]);

  return (
    <canvas ref={canvas} />
  );
};

export default RadarPlot;
