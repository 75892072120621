import {Chip} from "@mui/material";
import React from "react";
import {observer} from "mobx-react-lite";
import {STATUSES, STATUSES_DESCRIPTION, THEME_BY_STATUS} from "@core/constants/test";
import {WITNESS_STATUSES} from "@core/constants/witnessStatuses";
import modules from "@core/constants/modules";
import useStores from "../../../useStores";

const Status = observer(({test}) => {
  const {UserStore} = useStores();
  
  const statusTheme = THEME_BY_STATUS[test.status];

  const status = STATUSES_DESCRIPTION[test.status];

  const isWitnessed = test.witnesses?.length;
  const isInspected = test.status === STATUSES.INSPECTED;
  const isAccepted = test.witnesses?.find((witness) => witness?.status === WITNESS_STATUSES.ACCEPTED);
  const inspectionNotPassed = isWitnessed && isInspected  && !isAccepted;
  const inspectionNotPassedText = inspectionNotPassed ? "Inspection not passed" : null;

  const wasWitnessed = test.status === STATUSES.INSPECTING && test.witnesses?.some((witness) => witness?.status !== WITNESS_STATUSES.UNSET);
  const wasWitnessedText = wasWitnessed ? "To be endorsed by inspector" : null;

  const [module] = UserStore.user.data.company.modules;
  
  return (
    <Chip
      size="small"
      label={module === modules.WITNESS ?
        inspectionNotPassedText || wasWitnessedText || status :
        status}
      sx={{
        color: statusTheme?.text,
        backgroundColor: statusTheme?.backgroundColor
      }}
    />
  );
});

export default Status;