import React from "react";
import {Box, Divider, Grid} from "@mui/material";
import TestSummaryTable from "@core/components/TestSummaryTable";
import Test from "@core/components/Test";
import {useTheme} from "@mui/material/styles";

const TestsList = ({allTests, tests, productId, isLoading, testsRefs, setFilteredTestIds, onTestClick}) => {
  const theme = useTheme();
  
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box sx={{marginBottom: theme.spacing()}}>
          <h3>Overview</h3>
        </Box>
        <TestSummaryTable
          tests={tests}
          setFilteredTestIds={setFilteredTestIds}
          onTestClick={onTestClick}
          isLoaded={!isLoading}
          allTests={allTests}
        />
      </Grid>
      {(!isLoading && !!tests.length) && (
        <>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{marginBottom: theme.spacing()}}>
              <h3>Test Reports</h3>
            </Box>
            {tests.map((test, idx) => (
              <Box
                key={test._id}
                ref={(element) => testsRefs.current[test._id] = element}
                sx={{
                  marginBottom: theme.spacing(2),
                  "&:lastOfType": {
                    margin: 0,
                  }
                }}
              >
                <Test
                  test={test}
                  isLast={idx === tests.length - 1}
                  isFirst={!idx}
                  disabled
                  hideSign
                  productId={productId}
                  timeline={false}
                />
              </Box>
            ))}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default TestsList;