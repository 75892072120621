import {ROUTES} from "@core/api/routes";
import {Select} from "@core/components/Form";
import {ACTIONS} from "@core/constants/api";
import {Grid, MenuItem} from "@mui/material";
import {observer} from "mobx-react-lite";
import axios from "axios";
import {keys, equals} from "ramda";
import React, {useState} from "react";
import ImportWrapper from "@core/components/ImportWrapper";
import useStores from "../../../../useStores";
import {SOURCES} from "@core/constants/importTest";
import {QCPS} from "@core/constants/qcps";

const ImportData = ({touched, ...props}) => {
  const initialFormData = {
    source: SOURCES.SPTS,
    qcp: "",
    qcpItem: null,
    file: ""
  };

  const [qcpItems, setQcpItems] = useState([]);
  const [formData, setFormData] = useState(initialFormData);

  const {UserStore} = useStores();

  // const qcps = QcpStore.qcps.data;

  // const uniqQcps = uniqBy(prop("name"), qcps);
  // const qcpVersions = qcps.filter((qcp) => qcp.name === formData.qcp);
  // const qcpItems = qcpVersions.map((qcp) => qcp.qcpItem).sort();

  touched.current = !equals(formData, initialFormData);

  // useEffect(() => {
  //   QcpStore.qcps.load({limit: 100, offset: 0});
  // }, []);

  const companyQcps = keys(QCPS).filter((qcp) =>
    QCPS[qcp].companies.includes(UserStore.user.data.company.name)
  );

  const handleChangeQcp = (event) => {
    const qcpName = event.target.value;

    setFormData({...formData, qcp: qcpName, qcpItem: null});

    const poItems = QCPS[qcpName]?.poItems;

    if (poItems) {
      setQcpItems(poItems);
    } else {
      setQcpItems([]);
    }
  };

  const parseFile = (data) => {
    data.append("source", formData.source);
    data.append("qcp", formData.qcp);

    if (formData.qcpItem) {
      data.append("qcpItem", formData.qcpItem);
    }

    return axios.post(ROUTES.CERTIFICATE[ACTIONS.PARSE], data);
  };

  return (
    <ImportWrapper
      parseFile={parseFile}
      formData={formData}
      setFormData={setFormData}
      disabled={
        !formData.source ||
        !formData.qcp ||
        (!!qcpItems.length && !formData.qcpItem)
      }
      defaultData={{certificates: []}}
      {...props}
    >
      <Grid item xs={2}>
        <Select
          label="Source"
          required
          value={formData.source}
          onChange={(e) => setFormData({...formData, source: e.target.value})}
        >
          {keys(SOURCES).map((source) => (
            <MenuItem key={source} value={SOURCES[source]}>
              {source}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={2}>
        <Select
          label="QCP/ITP"
          required
          value={formData.qcp}
          onChange={handleChangeQcp}
        >
          {companyQcps.map((qcp) => (
            <MenuItem key={qcp} value={qcp}>
              {qcp}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      {!!qcpItems.length && (
        <Grid item xs={2}>
          <Select
            label="QCP/ITP Item"
            required
            value={formData.qcpItem}
            onChange={(e) =>
              setFormData({...formData, qcpItem: e.target.value})
            }
          >
            {qcpItems.map((qcpItem) => (
              <MenuItem key={qcpItem} value={qcpItem}>
                {qcpItem}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      )}
    </ImportWrapper>
  );
};

export default observer(ImportData);
