import {ROUTES} from "@core/api/routes";
import {MuiAutocomplete} from "@core/components/Form";
import TextField from "@core/components/FormikTextField";
import {ACTIONS} from "@core/constants/api";
import {ROLES} from "@core/constants/externalUsersRoles";
import {useFormikContext} from "formik";
import React from "react";

const ClientField = ({isFromProducer}) => {
  const formik = useFormikContext();

  if (isFromProducer) {
    return <TextField disabled name="client" label="Client" required />;
  }

  return (
    <MuiAutocomplete
      inputProps={{
        label: "Client",
        name: "client",
        required: true
      }}
      url={`${ROUTES.EXTERNAL_USER[ACTIONS.ALL]}?label=${ROLES.CUSTOMER}`}
      onChange={(value) => {
        formik.setFieldValue("client", value);

        if (formik.touched.client) return;

        formik.setFieldTouched("client", true, false);
      }}
      formatOptions={(items) =>
        items?.map((item) => ({
          label: `${item.lastname} - ${item.company}`,
          value: item.company
        }))
      }
    />
  );
};

export default ClientField;
