import {Typography} from "@mui/material";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import {isEmpty} from "ramda";
import axios from "axios";
import {observer} from "mobx-react";
import userService from "@core/api/user-service";
import {useHistory} from "react-router-dom";
import Loader from "@core/components/Loader";
import Form from "../components/Form";
import {NotificationStore} from "@core/components/Modal/stores";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";
import {ConfirmationOnUrlChangeContext} from "@core/components/ConfirmationOnUrlChange/context";
import {SHOULD_BLOCK_DEFAULT} from "@core/constants/shouldBlockDefault";
import useDetachedExecutionAfterSubmit from "@core/hooks/useDetachedExecutionAfterSubmit";

const Create = observer(() => {
  const [user, setUser] = useState(null);
  const formRef = useRef(null);
  const history = useHistory();
  const {setShouldBlock} = useContext(ConfirmationOnUrlChangeContext);

  const customBlocked = useCallback(
    () => !isEmpty(formRef.current?.touched),
    []
  );
  const {navigateAway} = useDetachedExecutionAfterSubmit();

  useEffect(() => {
    userService.getUserFromCache().then(function (userData) {
      setUser(userData);
    });

    setShouldBlock(() => customBlocked);

    return () => {
      setShouldBlock(() => SHOULD_BLOCK_DEFAULT);
    };
  }, []);

  if (!user) return <Loader />;

  const formAction = async (data) => {
    const {data: id} = await axios.post(
      ROUTES.CERTIFICATE[ACTIONS.CREATE],
      data
    );
    NotificationStore.showInfo("Successfully created!");
    setShouldBlock(() => SHOULD_BLOCK_DEFAULT);
    navigateAway(() => history.push(`/certificates/${id}`));
  };

  return (
    <div className="content padded-container">
      <Typography variant="h4" fontSize="1.8rem" marginBottom={3}>
        Add new certificate
      </Typography>
      <Form formRef={formRef} user={user} onSave={formAction} />
    </div>
  );
});

export default Create;
