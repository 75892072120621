import {Typography, Box} from "@mui/material";
import React from "react";

const InfoPlot = ({text}) => {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <img width="100%" height="100%" src="/images/chart-placeholder.png" />
      <Typography
        variant="subtitle1"
        sx={{
          position: "absolute",
          maxWidth: "90%",
          textAlign: "center",
          fontWeight: (theme) => theme.typography.fontWeightMedium
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default InfoPlot;
