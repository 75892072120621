import React, {useEffect, useRef} from "react";
import {sortBy, prop} from "ramda";
import {Chart as ChartJS} from "chart.js";
import {COLORS, PLOT_TYPES} from "@core/components/Chart/сonstants";
import {usePlotOptions} from "../../plotContext";
import {getLabel, getPlugins, getScale} from "../../services";

const MIN_SIGNIFICANT_AVERAGE_AMOUNT = 10;

const ScatterPlot = ({testType, plotData, testConfig, isFullScreen, isFullPlotView}) => {
  const canvas = useRef(null);
  const plot = useRef(null);

  const {options: plotOptions, paramType} = usePlotOptions();
  const {minY: min = 0, maxY: max = 0} = plotData.minmax ?? {};

  const yAxisLabel = getLabel(plotData.units, testType, paramType, testConfig);

  const dataset = plotData.plot.datasets;
  const yValues = dataset.map((points) => points.y);
  const {min: minAxisY , max: maxAxisY} = getScale(yValues, plotOptions.showAcceptanceCriteria, plotData.minmax);

  const sortedDataset = sortBy(prop("x"), dataset);

  const averageDataset = sortedDataset.reduce((acc, point, index) => {
    if(point.x < MIN_SIGNIFICANT_AVERAGE_AMOUNT) return acc;

    const dataset = sortedDataset.slice(0, index + 1);
    const sum = dataset.reduce((acc, point) => acc + point.y, 0);
    const average = sum / dataset.length;

    acc.push({x: point.x, y: average});

    return acc;
  }, []);

  useEffect(() => {
    const additionalPlugins = {
      legend: false,
      tooltip: {
        callbacks: {
          label: (context) => context.parsed.y
        }
      }
    };

    const plugins = {
      ...getPlugins(plotOptions.showAcceptanceCriteria, {y: {min, max}}, isFullPlotView, PLOT_TYPES.SCATTER, testType),
      ...additionalPlugins,
    };

    const chartConfig = {
      data: {
        datasets: [{
          type: "scatter",
          data: dataset,
          backgroundColor: COLORS.blue.backgroundColor,
        }, {
          type: "line",
          data: averageDataset,
          borderColor: COLORS.violet.borderColor,
          elements: {
            point:{
              radius: 0
            }
          }
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins,
        scales: {
          y: {
            min: minAxisY,
            max: maxAxisY,
            title: {
              display: isFullPlotView,
              text: yAxisLabel,
            },
          },
          x: {
            beginAtZero: true,
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              stepSize: 5
            }
          },
        }
      },
    };
    plot.current = new ChartJS(canvas.current, chartConfig);

    return () => {
      plot.current.destroy();
    };
  }, [plotData, plotOptions.showAcceptanceCriteria, min, max, isFullScreen]);

  return (
    <canvas ref={canvas} />
  );
};

export default ScatterPlot;
