import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  MenuItem
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {Formik} from "formik";
import * as yup from "yup";
import {keys, values} from "ramda";
import TextField from "@core/components/FormikTextField";
import SelectField from "@core/components/FormikSelect";
import BaseProductsList from "../../../Campaigns/components/BaseProductsList";
import BaseProductsUpload from "../../../Campaigns/components/BaseProductsUpload";
import {QCPS} from "@core/constants/qcps";
import modules from "@core/constants/modules";
import {INTERFACE_TYPE} from "@core/constants/transfer";
import {CAMPAIGN_TYPES} from "@core/constants/campaign";
import styles from "./styles";
import useStores from "../../../useStores";
import {MuiAutocomplete} from "@core/components/Form";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";
import {useLocation} from "react-router-dom/cjs/react-router-dom.min";

const initialValues = {
  name: "",
  type: "",
  manufacturer: "",
  customer: "",
  purchaseOrder: "",
  purchaseOrderItem: "",
  qcp: "",
  direction: "ascending",
  products: []
};

const validationSchema = yup.object().shape({
  name: yup.string().required("This field is required!"),
  manufacturer: yup.string().required("This field is required!"),
  customer: yup.string().required("This field is required!"),
  purchaseOrder: yup.string().required("This field is required!"),
  purchaseOrderItem: yup.string().required("This field is required!"),
  qcp: yup.string().required("This field is required!"),
  internalWorkOrder: yup.string(),
  products: yup
    .array()
    .of(
      yup.object().shape({
        manufacturer: yup.string().required(),
        heat: yup.string().required(),
        pipeNumber: yup.string().required(),
        description: yup.string()
      })
    )
    .required()
});

const CampaignCreate = observer(({classes, open, close, onCreated}) => {
  const {CampaignStore, TransferStore, UserStore, NotificationStore} =
    useStores();
  const location = useLocation();

  const TRANSFER_QUERY_BY_MODULE = {
    [modules.PRODUCER]: `${ROUTES.TRANSFER[ACTIONS.ALL]}?status=open&type=${location.pathname.includes("customers") ? INTERFACE_TYPE.SENDER : INTERFACE_TYPE.RECEIVER}`,
    [modules.END_OWNER]: `${ROUTES.TRANSFER[ACTIONS.ALL]}?status=open&type=${INTERFACE_TYPE.RECEIVER}`
  };

  const [parsedProducts, setParsedProducts] = useState([]);
  const [purchaseOrderUrlVersion, setPurchaseOrderUrlVersion] = useState(1);
  const [module] = UserStore.user.data.company.modules;

  useEffect(() => {
    if (location.pathname.includes("customer")) {
      TransferStore.getTransfersReceivers([
        modules.PRODUCER,
        modules.END_OWNER
      ]);
    } else {
      TransferStore.getTransfersReceivers([modules.PRODUCER]);
    }
  }, [location.pathname]);

  const onSubmit = async (values) => {
    await CampaignStore.createCampaign(values);
    onCreated(CampaignStore.campaign);
  };

  const producers = TransferStore.receivers;

  const companyQcps = keys(QCPS).filter((qcp) =>
    QCPS[qcp].companies?.includes(UserStore.user.data.company.name)
  );

  const [selectedCompany, setSelectedCompany] = useState(undefined);

  const handleSelectCompany = (props, e) => {
    setSelectedCompany(e);

    if (module.name === modules.PRODUCER) {
      if (location.pathname.includes("customers")) {
        props.setFieldValue("manufacturer", UserStore.user.data.company._id);
      } else {
        props.setFieldValue("customer", UserStore.user.data.company._id);
      }
    } else {
      props.setFieldValue("customer", UserStore.user.data.company._id);
    }
  };

  const handleOrderSelected = async (props, e) => {
    // Handles creation of a new order within campaign creation
    if (e?.label?.includes("Create new")) {
      const data = {
        orderNumber: e.value,
        sender: props.values.manufacturer,
        receiver: props.values.customer
      };

      await TransferStore.createTransfer(data);
      props.setFieldValue("purchaseOrder", TransferStore.transfer._id);
      NotificationStore.showSuccess("Customer order created!");
      setPurchaseOrderUrlVersion((v) => v + 1);

      return;
    }

    props.setFieldValue("purchaseOrder", e?.value ?? e ?? "");
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        close();
        setParsedProducts([]);
      }}
      maxWidth="md"
      fullWidth
      sx={{
        ".MuiDialog-container>.MuiPaper-root": {
          position: "unset"
        }
      }}
    >
      <DialogTitle>Create campaign</DialogTitle>
      <DialogContent>
        <Formik
          validateOnMount
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {(props) => {
            return (
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <TextField required label="Name" name="name" />
                </Grid>
                <Grid item xs={3}>
                  <SelectField name="type" label="Campaign type" required>
                    {values(CAMPAIGN_TYPES).map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </SelectField>
                </Grid>
                <Grid item xs={3}>
                  <SelectField
                    name={
                      module.name === modules.PRODUCER &&
                      location.pathname.includes("customers")
                        ? "customer"
                        : "manufacturer"
                    }
                    label={
                      module.name === modules.PRODUCER &&
                      location.pathname.includes("customers")
                        ? "Customer"
                        : "Supplier"
                    }
                    required
                  >
                    {producers.map((producer) => (
                      <MenuItem
                        key={producer._id}
                        value={producer._id}
                        onClick={() => handleSelectCompany(props, producer._id)}
                      >
                        {producer.name}
                      </MenuItem>
                    ))}
                  </SelectField>
                </Grid>
                <Grid item xs={3}>
                  <MuiAutocomplete
                    url={
                      TRANSFER_QUERY_BY_MODULE[module.name] +
                      `&v=${purchaseOrderUrlVersion}
                    `
                    }
                    disabled={
                      !props.values.manufacturer && !props.values.customer
                    }
                    inputProps={{
                      label: "Purchase Order",
                      name: "transferId",
                      required: true,
                      view: location.pathname.includes("customers")
                        ? "supplier"
                        : "customer",
                      showOnly: selectedCompany
                    }}
                    formatOptions={(items) =>
                      items.map((item) => ({
                        label: item.orderNumber,
                        value: item._id
                      }))
                    }
                    onCreateNew={(e) => handleOrderSelected(props, e)}
                    onChange={(e) => handleOrderSelected(props, e)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    required
                    label="PO Item"
                    name="purchaseOrderItem"
                  />
                </Grid>
                <Grid item xs={3}>
                  <SelectField required label="QCP/ITP" name="qcp">
                    {companyQcps.map((qcp) => (
                      <MenuItem key={qcp} value={qcp}>
                        {qcp}
                      </MenuItem>
                    ))}
                  </SelectField>
                </Grid>
                {!!parsedProducts.length && (
                  <Grid item xs={12}>
                    <h3 className={classes.productsHeader}>Base Products</h3>
                    <BaseProductsList
                      products={parsedProducts}
                      selectedProducts={props.values.products}
                      setSelectedProducts={(products) =>
                        props.setFieldValue("products", products)
                      }
                    />
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <BaseProductsUpload
                      required
                      products={parsedProducts}
                      selectedProducts={props.values.products}
                      setProducts={setParsedProducts}
                      setSelectedProducts={(products) =>
                        props.setFieldValue("products", products)
                      }
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={props.handleSubmit}
                      disabled={!props.isValid}
                    >
                      Start campaign
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
});

export default withStyles(CampaignCreate, styles);
