import React, {useCallback, useState, useEffect} from "react";
import axios from "axios";
import {Box, Button, Typography} from "@mui/material";
import {AUTH_TYPES} from "@core/constants/authTypes";
import {useHistory} from "react-router-dom";
import EmailLogin from "../EmailLogin";
import AuthAppLogin from "../AuthAppLogin";
import WS from "@core/api/socketConnection";
import useStores from "../../../useStores";
import {AuthService} from "@core/services";
import {ACCESSED_ROUTE} from "@core/constants/accessedRoute";

const CHANGE_VERIFICATION_TYPE_TEXT = {
  [AUTH_TYPES.AUTH_APP]: "Verify with email",
  [AUTH_TYPES.EMAIL]: "Verify with authenticator app"
};

const VerificationForm = ({user}) => {
  const {UserStore} = useStores();
  const history = useHistory();

  const [authType, setAuthType] = useState(user.default_totp);

  const login = useCallback((data) => {
    WS.connect(data.user);
    
    UserStore.setUser(data.user);

    localStorage.setItem("allowUserNotActiveMessage", !user.bc_address);

    AuthService.login(data);

    axios.defaults.headers.common["X-Token-Jwt"] = data.accessToken;

    const accessedRouteRaw = sessionStorage.getItem(ACCESSED_ROUTE);

    if (accessedRouteRaw) {
      history.push(JSON.parse(accessedRouteRaw));

      return;
    }

    history.push("/");
  }, [user.bc_address]);

  // TODO: This is a temporary solution. Fix not fetching issue on MenuList
  useEffect(() => {
    return () => {
      window.location.reload();
    };
  }, []);

  const changeAuthType = useCallback(() => {
    if (authType === AUTH_TYPES.EMAIL) setAuthType(AUTH_TYPES.AUTH_APP);
    else setAuthType(AUTH_TYPES.EMAIL);
  }, [authType]);

  return (
    <>
      <Box sx={{marginBottom: "20px"}}>
        <Typography component="h1" variant="h6">
          <b>Two-Factor Authentication</b>
        </Typography>
      </Box>
      {authType === AUTH_TYPES.AUTH_APP && (
        <AuthAppLogin
          user={user}
          login={login}
        />
      )}
      {authType === AUTH_TYPES.EMAIL && (
        <EmailLogin
          user={user}
          login={login}
        />
      )}
      <Button
        sx={{
          marginTop: "15px",
          "&:hover": {
            backgroundColor: "unset",
          }}
        }
        size="small"
        color="primary"
        onClick={changeAuthType}
      >
        {CHANGE_VERIFICATION_TYPE_TEXT[authType]}
      </Button>
    </>
  );
};

export default VerificationForm;