import React from "react";
import {Formik, FieldArray} from "formik";
import * as yup from "yup";
import {observer} from "mobx-react-lite";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  Typography
} from "@mui/material";
import TextField from "@core/components/FormikTextField";
import useStores from "../../../useStores";
import axios from "axios";
import MuiSelect from "@core/components/MuiSelect";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

const MEASUREMENTS = ["MM", "KG", "°", "IN", "KG/M"];

function camelize(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/[\s.]+/g, "");
}

const initialValues = {
  name: "",
  internalName: "",
  properties: []
};

const validationSchema = yup.object().shape({
  name: yup.string().required("The field is required!"),
  internalName: yup.string().required("The field is required!"),
  properties: yup.array().of(
    yup.object().shape({
      label: yup.string().required("The field is required!"),
      measurements: yup.string()
    })
  )
});

const ProductTypeModal = observer(
  ({open, onProductTypeCreated, close, productType}) => {
    const {NotificationStore, ProductTypesStore} = useStores();

    const createProduct = async (data) => {
      const {data: productType} = await axios.post(
        ROUTES.PRODUCT_TYPE[ACTIONS.CREATE],
        data
      );
      onProductTypeCreated(productType);
      NotificationStore.showSuccess("Product type successfully created");
    };

    const changeProductType = async (data) => {
      await ProductTypesStore.updateProduct(data, productType._id);
      await ProductTypesStore.productTypes.load();
    };

    const onSubmit = async (values) => {
      if (
        productType &&
        values.name === productType.name &&
        values.internalName === productType.internalName
      ) {
        close();
        NotificationStore.showSuccess("No changes");

        return;
      }

      const handler = productType ? changeProductType : createProduct;

      const properties = values.properties.map((property) => ({
        ...property,
        name: property.name || camelize(property.label)
      }));

      await handler({
        name: values.name,
        internalName: values.internalName,
        properties
      });

      close();
    };

    return (
      <Dialog maxWidth="md" fullWidth open={open} onClose={close}>
        <DialogTitle>
          {productType ? "Edit product type" : "Add product type"}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={productType || initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(props) => {
              return (
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <TextField
                      required
                      label="Name (will appear on certificate)"
                      name="name"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      required
                      label="Internal name/code"
                      name="internalName"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography component="h5" variant="h5">
                      Fields
                    </Typography>
                  </Grid>
                  <FieldArray name="properties">
                    {({remove}) =>
                      props.values.properties.map((property, index) => (
                        <>
                          <Grid item xs={4}>
                            <TextField
                              required
                              label="Label"
                              name={`properties.${index}.label`}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <MuiSelect
                              label="Measurement"
                              name={`properties.${index}.measurements`}
                              defaultOptions={MEASUREMENTS}
                            />
                          </Grid>
                          <Grid item xs container alignItems="flex-end">
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => remove(index)}
                            >
                              Remove
                            </Button>
                          </Grid>
                        </>
                      ))
                    }
                  </FieldArray>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        const property = {label: "", measurements: ""};
                        props.setFieldValue("properties", [
                          ...props.values.properties,
                          property
                        ]);
                      }}
                    >
                      Add
                    </Button>
                  </Grid>
                  <Grid item container justifyContent="flex-end">
                    <Button
                      disabled={!props.isValid}
                      onClick={props.handleSubmit}
                      color="primary"
                      variant="contained"
                      autoFocus
                    >
                      {productType ? "Save" : "Create"}
                    </Button>
                  </Grid>
                </Grid>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    );
  }
);

ProductTypeModal.defaultProps = {
  onProductTypeCreated: () => {}
};

export default ProductTypeModal;
