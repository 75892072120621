import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";
import {ALL_TESTS, TEST_CATEGORIES_LABELS} from "@core/constants/test";
import axios from "axios";
import {pickBy} from "ramda";
import {TABS} from "./constants";

export const SUB_TABS_HANDLER_BY_TAB = {
  [TABS.TESTS]: async (campaignId) => {
    const {data: subTabs} = await axios.get(ROUTES.SINGLE_MATERIAL_CAMPAIGN_TESTS[ACTIONS.TYPES](campaignId));
    const filledSubTabs = subTabs.filter((tab) => !!tab);  // remove this line when backend is fixed. use subTabs instead

    return pickBy((value, key) => [ALL_TESTS, ...filledSubTabs].includes(key), TEST_CATEGORIES_LABELS);
  },
};