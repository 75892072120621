/**
 * getLogoUrl
 * @param {object} companyData
 * @returns {string} logo url
 */
export const getLogoUrl = (companyData) => {
  let returnPath = "";

  if (companyData?.logo?.file) {
    returnPath = companyData?.logo.file.dir + companyData?.logo.file.name;
  }

  return returnPath;
};
