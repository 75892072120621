import {TEST_RESULTS} from "@core/constants/testResults";
import {getMean} from "@core/helpers";
import {exceptionsConfig} from "./data";

/**
 * @name dimensionFormulaValue
 * @param x - width
 * @param y - height
 * @returns {string} - visual formula + its result
 */
const dimensionFormulaValue = (x, y) => {
  const notchValue = 2;
  let formulaValue = "";

  if(typeof x === "number" && typeof y === "number") {
    formulaValue = `(${x} - ${notchValue}) * ${y} = ${(x - notchValue) * y}`;
  }

  return formulaValue;
};

/**
 * @name averageTemp
 * @param {string} t1 - value of temperature1
 * @param {string} t2 - value of temperature2
 * @param {string} t3 - value of temperature3
 * @returns {number}
 */
const averageTemp = (t1, t2, t3) => {
  return Number(((Number(t1) + Number(t2) + Number(t3)) / 3).toFixed(3));
};

/**
 * @name getTimesMin
 * @param {array} elements - elements from Impact test form
 * @param {number} single - single value of norm from config
 * @returns {number}
 * @summary - How many times a value is less than the acceptable from config
 */
const getTimesMin = (elements, single) => {
  return elements.filter((element) => element < single).length;
};

const getElementRequirements = (element, requirements = {}, acceptance, index) => {
  const acceptanceCurveTemperatures = exceptionsConfig[acceptance]?.temperature.temperatures || [];
  const {withRequirements = true} = acceptanceCurveTemperatures[index] || {};

  if(!withRequirements) return {};

  const elementRequirements = requirements?.[element.orientation] || requirements?.[element.location] || {};

  if(!elementRequirements.length) return elementRequirements;

  return elementRequirements.find((requirement) => !requirement.location || requirement.location === element.location) || {};
};

const isEnergyAcceptable = (elements, requirements, acceptance) => {
  const unAcceptableElementsCount = elements.reduce((acc, current, index) => {
    const elementRequirements = getElementRequirements(current, requirements, acceptance, index);
    const single = elementRequirements.single || 0;
    const timesMin = getTimesMin([current.energyJoule1, current.energyJoule2, current.energyJoule3], single);
    const count = timesMin > 1 ? 1 : 0;

    return acc + count;
  }, 0);

  const averageIsAcceptable = elements.every((el, index) => {
    const elementRequirements = getElementRequirements(el, requirements, acceptance, index);

    const average = elementRequirements.average || 0;

    return !average || averageTemp(el.energyJoule1, el.energyJoule2, el.energyJoule3) >= average;
  });

  return !unAcceptableElementsCount && averageIsAcceptable;
};

const isLeAcceptable = (elements, requirements, acceptance) => {
  const isAverageAcceptable = elements.every((el, index) => {
    const elementRequirements = getElementRequirements(el, requirements, acceptance, index);
    const average = elementRequirements.averageLateralExpansion || 0;

    return !average || averageTemp(el.lateralExpansion1, el.lateralExpansion2, el.lateralExpansion3) >= average;
  });
  const isSingleAcceptable = elements.every((el, index) => {
    const elementRequirements = getElementRequirements(el, requirements, acceptance, index);
    const single = elementRequirements.singleLateralExpansion || 0;

    return !single || el.lateralExpansion1 >= +single && el.lateralExpansion2 >= +single && el.lateralExpansion3 >= +single;
  });

  return isAverageAcceptable && isSingleAcceptable;
};

const isShearAreaAcceptable = (elements, requirements, acceptance) => {
  const averages = elements.map((el) => averageTemp(el.shearArea1, el.shearArea2, el.shearArea3));
  const averageOfAllElements = getMean(averages);
  
  const isAverageAcceptable = elements.every((el, index) => {
    const elementRequirements = getElementRequirements(el, requirements, acceptance, index);
    const average = elementRequirements.averageShearArea || 0;
    const averageOfAllElementsMin = elementRequirements.averageShearAreaAllSpecimens || 0;

    const isSingleAverageAcceptable = !average || averages[index] >= average;
    const isAverageOfAllElementsAcceptable = !averageOfAllElementsMin || averageOfAllElements >= averageOfAllElementsMin;

    return isSingleAverageAcceptable && isAverageOfAllElementsAcceptable;
  });

  const isSingleAcceptable = elements.every((el, index) => {
    const elementRequirements = getElementRequirements(el, requirements, acceptance, index);
    const single = elementRequirements.singleShearArea || 0;

    return !single || el.shearArea1 >= +single && el.shearArea2 >= +single && el.shearArea3 >= +single;
  });

  return isAverageAcceptable && isSingleAcceptable;
};

/**
 * @name getTestResult
 * @param {array} elements - elements from Impact test form
 * @param {number} single - single temperature value from config
 * @param {number} average - average temperature value from config
 * @param {number} averageLateralExpansion - averageLateralExpansion value from config
 * @param {number} singleLateralExpansion - singleLateralExpansion value from config
 * @param {number} singleShearArea - singleShearArea value from config
 * @param {number} averageShearArea - averageShearArea value from config
 * @returns {string} - Acceptable / Not Acceptable
 */
const getTestResult = ({elements, requirements, acceptance}) => {
  const energyAceptable = isEnergyAcceptable(elements, requirements, acceptance);
  const leAcceptable = isLeAcceptable(elements, requirements, acceptance);
  const shearAcceptable = isShearAreaAcceptable(elements, requirements, acceptance);

  const isTestAcceptable = energyAceptable && leAcceptable && shearAcceptable;

  return isTestAcceptable ? TEST_RESULTS.ACCEPTABLE : TEST_RESULTS.NOT_ACCEPTABLE;
};

const getMinValue = (actual, height) => {
  if(!actual) return null;

  return Math.round((10 * actual) / height);
};

const getActualValue = (minValue, height) => {
  if (!minValue) return null;

  return Math.round(minValue / 10 * height);
};

export {averageTemp, getTimesMin, getTestResult, dimensionFormulaValue, getMinValue, getActualValue, getElementRequirements};
