import {ROUTES} from "@core/api/routes";
import {Select} from "@core/components/Form";
import Loader from "@core/components/Loader";
import {ACTIONS} from "@core/constants/api";
import {TYPES} from "@core/constants/test";
import {Grid, MenuItem} from "@mui/material";
import axios from "axios";
import classNames from "classnames";
import {observer} from "mobx-react-lite";
import {isEmpty} from "ramda";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {withStyles} from "tss-react/mui";
import usePlot from "../../hooks/usePlot";
import usePlotFilter from "../../hooks/usePlotFilter";
import {PlotContextProvider} from "../../plotContext";
import {PLOT_TYPES, PLOT_TYPES_TITLES} from "../../сonstants";
import BoxPlot from "../BoxPlot";
import HistogramPlot from "../HistogramPlot";
import InfoPlot from "../InfoPlot";
import PlotSettings from "../PlotSettings";
import RadarPlot from "../RadarPlot";
import ScatterPlot from "../ScatterPlot";
import styles from "./styles";

const PLOT_COMPONENT_BY_PLOT_TYPE = {
  [PLOT_TYPES.BOX_PLOT]: BoxPlot,
  [PLOT_TYPES.VIOLIN]: BoxPlot,
  [PLOT_TYPES.HISTOGRAM]: HistogramPlot,
  [PLOT_TYPES.SCATTER]: ScatterPlot,
  [PLOT_TYPES.RADAR]: RadarPlot,
};

const TestPlot = observer(({classes, testConfig, tests, isFullPlotView = true}) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [availableParamTypes, setAvailableParamTypes] = useState([]);

  const plot = useRef(null);
  const displayName = tests[0]?.displayName;
  const plots = testConfig.plots || testConfig.plotsByDisplayName[displayName] || [];

  const {setPlotDataLoading, plotType, changePlotType, plotData, plotDataLoading, testType} = usePlot(testConfig, tests, plots, isFullPlotView);
  const {onNewParamType} = usePlotFilter();

  const plotName = useMemo(() => PLOT_TYPES_TITLES[plotType]?.replace(/([^ Plot])$/, "$1 Plot"), [plotType]);

  const testIds = tests.map((test) => test._id);

  const PlotComponent = PLOT_COMPONENT_BY_PLOT_TYPE[plotType] || null;

  useEffect(() => {
    const instance = plot.current;

    instance.addEventListener("fullscreenchange", onFullScreen);

    return () => instance.removeEventListener("fullscreenchange", onFullScreen);
  }, [isFullScreen]);

  useEffect(() => {
    if(!plotType) return;

    if(!testIds.length) setPlotDataLoading(false);
    else setTestParams();
  }, [plotType]);

  const onFullScreen = useCallback(() => {
    setIsFullScreen(!isFullScreen);
  }, [isFullScreen]);

  const setTestParams = async () => {
    // remove this line when backend can work with multiple tests properly
    const params = testType === TYPES.CHEMICAL ? {testIds} : {testId: testIds[0]};
    const response = await axios.get(ROUTES.TEST_CHART[ACTIONS.TYPES], {params: {type: plotType, ...params}});

    const paramsValues = response.data;

    setAvailableParamTypes(paramsValues);

    if(!paramsValues.length) setPlotDataLoading(false);
    else onNewParamType(paramsValues[0]);
  };

  return (
    <div ref={plot}>
      {isFullPlotView && (
        <Grid
          container
          wrap="wrap"
          className={classNames({
            [classes.fullScreenContainer]: isFullScreen
          })}
          alignItems="flex-end"
          spacing={3}
        >
          <Grid item>
            <Select
              menuProps={{
                container: isFullScreen ? plot.current : document.body,
              }}
              label="Chart type"
              value={plotType}
              onChange={(e) => {
                if(plotType === e.target.value) return;

                changePlotType(e.target.value);
                onNewParamType(undefined);
              }}
            >
              {plots.map((plotType) => (
                <MenuItem key={plotType} value={plotType}>
                  {PLOT_TYPES_TITLES[plotType]}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <PlotSettings
            testType={testType}
            plotType={plotType}
            plotData={plotData}
            plot={plot}
            isFullScreen={isFullScreen}
            availableParamTypes={availableParamTypes}
          />
        </Grid>
      )}
      <Grid
        item
        xs={12}
        className={classNames(classes.commonPlotContainer, {
          [classes.plotContainer]: !isFullScreen,
          [classes.fullScreenPlotContainer]: isFullScreen
        })}
      >
        {plotDataLoading ? (
          <Loader />
        ) : (
          <>
            {(!isEmpty(plotData) && PlotComponent) ? (
              <PlotComponent
                plotData={plotData}
                testType={testType}
                testConfig={testConfig}
                isFullScreen={isFullScreen}
                isFullPlotView={isFullPlotView}
              />
            ) : (
              <InfoPlot
                text={`Not enough data to generate an accurate ${plotName}`}
              />
            )}
          </>
        )}
      </Grid>
      {isFullScreen && (
        <img
          src="/images/logo-symbol-blue.svg"
          alt="Steeltrace"
          className={classes.logo}
        />
      )}
    </div>
  );
});

const TestPlotWithContext = (props) => (
  <PlotContextProvider>
    <TestPlot {...props} />
  </PlotContextProvider>
);

export default withStyles(TestPlotWithContext, styles);
