import React, {Component} from "react";
import {inject, observer} from "mobx-react";
import {compose, equals, isEmpty} from "ramda";
import WS from "@core/api/socketConnection";
import {STATUSES, TYPES} from "@core/constants/test";
import testsConfig from "@core/configs/test";
import {Grid, Button, Typography} from "@mui/material";
import {withStyles} from "tss-react/mui";
import StatusMarks from "./components/StatusMarks";
import TestInformation from "./components/TestInformation";
import EntityNotFound from "@core/components/EntityNotFound";
import Loader from "@core/components/Loader";
import {MultipleSelect} from "@core/components/Form";
import styles from "./styles";
import NavigationBlockerConsumer from "./components/NavigationBlockerConsumer";

class SingleTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedInstruments: [],
      noInstrumentsUsed: false,
      isLoaded: false
    };
    this.formRef = React.createRef();
  }

  componentWillUnmount() {
    WS.remove("transaction:test:witness");
    WS.remove("transaction:test:submit");
  }

  async componentDidMount() {
    const {
      NotificationStore,
      SigningStore,
      TestStore,
      match: {params},
      InstrumentStore,
      CertificateStore,
      CompanyStore,
      ConditionStore
    } = this.props;
    await TestStore.getTestById(params.id);
    await CertificateStore.getCertificateByTestId(params.id);
    await InstrumentStore.findAll({offset: 0, limit: 100});
    const certificate = CertificateStore.certificate.data;

    if (certificate._id)
      await CompanyStore.getCompanyById(certificate.company._id);

    await ConditionStore.getConditionByTestId(params.id);

    WS.listen("transaction:test:witness", (res) => {
      SigningStore.closeSigner();

      if (res.status === "DECLINED") {
        NotificationStore.showError("Something went wrong!");

        return;
      }

      TestStore.changeTest({status: STATUSES.SUBMITTED});
      NotificationStore.showSuccess("Successfully witnessed!");
      TestStore.getTestById(params.id);
    });

    WS.listen("transaction:test:submit", (res) => {
      SigningStore.closeSigner();

      if (res.status === "DECLINED") {
        NotificationStore.showError("Something went wrong!");

        return;
      }

      TestStore.changeTest({status: STATUSES.SUBMITTED});
      NotificationStore.showSuccess("Successfully submitted!");
      TestStore.getTestById(params.id);
    });
  }

  componentDidUpdate() {
    const {TestStore, InstrumentStore} = this.props;

    if (
      TestStore.test.isLoaded &&
      InstrumentStore.instruments.isLoaded &&
      !this.state.isLoaded
    ) {
      this.setState({
        isLoaded: true,
        selectedInstruments: this.setInitialInstruments()
      });
    }
  }

  /**
   * @name setInitialInstruments
   * @returns {array}
   * @summary
   *  - if Store.instruments doesn't exist, returns array of default instruments for that test, depending on test.type
   * and Store.instruments.defaultForTests
   *  - if Store.instruments exists and it is [], returns [{equipment: 'None', defaultForTests: []}]
   *  - in other cases returns test's instrument from Store.instruments
   */
  setInitialInstruments = () => {
    const {TestStore, InstrumentStore} = this.props;
    const instruments = TestStore.test.data.instruments;

    if (instruments && instruments.length === 0) {
      const defaultInstruments = InstrumentStore.instruments.data.filter((I) =>
        I.defaultForTests.includes(TestStore.test.data.type)
      );

      return defaultInstruments;
    }

    return instruments;
  };

  /**
   * @name changeInstrument
   * @param values {array} - click event from selector (array of instrument names)
   * @summary
   * if user selects "None" option, drops all values and sets only 'none'
   * in "None" is selected and user clicks another one, it removes 'none' and sets clicked one
   */
  changeInstrument = (values) => {
    const instrumentsFromStore = this.props.InstrumentStore.instruments.data;

    if (values[values.length - 1] === "None") {
      this.setState({selectedInstruments: []});
    } else {
      const withoutNone = values.filter((e) => e !== "None");
      const correctNaming = withoutNone.map((el) =>
        el.replace(" (Default)", "")
      );
      const instrumentObjects = instrumentsFromStore.filter((I) =>
        correctNaming.includes(I.equipment)
      );
      this.setState({selectedInstruments: instrumentObjects});
    }
  };

  saveTest = async (properties, type) => {
    const {NotificationStore, TestStore, InstrumentStore} = this.props;
    const {selectedInstruments} = this.state;

    const changes = {
      instruments: selectedInstruments.map((selected) => selected._id),
      status: STATUSES.FILLED,
      properties
    };

    if (type && TestStore.test.data.type !== type) {
      changes.type = type;

      const defaultInstrumentsNewType = InstrumentStore.instruments.data.filter(
        (i) => i.defaultForTests.includes(type)
      );
      const defaultInstrumentsNewTypeIds = defaultInstrumentsNewType.map(
        (i) => i._id
      );

      const defaultInstrumentsOldType = InstrumentStore.instruments.data.filter(
        (i) => i.defaultForTests.includes(TestStore.test.data.type)
      );
      const defaultInstrumentsOldTypeIds = defaultInstrumentsOldType.map(
        (i) => i._id
      );

      const selectedInstruments = this.state.selectedInstruments.filter(
        (i) => !defaultInstrumentsOldTypeIds.includes(i._id)
      );
      const selectedInstrumentsIds = selectedInstruments.map((i) => i._id);

      changes.instruments = selectedInstrumentsIds.concat(
        defaultInstrumentsNewTypeIds
      );
      this.setState({
        selectedInstruments: selectedInstruments.concat(
          defaultInstrumentsNewType
        )
      });
    }

    await TestStore.update(changes, TestStore.test.data._id);
    NotificationStore.showInfo("The test has been saved");
  };

  setDefaultInstruments = () => {
    const {InstrumentStore, TestStore} = this.props;
    const defaultInstruments = InstrumentStore.instruments.data.filter((I) =>
      I.defaultForTests.includes(TestStore.test.data.type)
    );
    this.setState({selectedInstruments: defaultInstruments});
  };

  addDefault = (elements) => {
    const test = this.props.TestStore.test.data;

    return elements.map((I) =>
      I.defaultForTests.includes(test.type)
        ? {...I, equipment: `${I.equipment} (Default)`}
        : I
    );
  };

  saveTestOnUrlChange = () => {
    const handler = this.formRef.current.handleSubmit || this.saveTest;
    handler(this.formRef.current.values);
  };

  shouldBlock = () => {
    const test = this.props.TestStore.test.data;

    const currentInstrumentsIds = this.state.selectedInstruments
      .map((instrument) => instrument._id)
      .sort();
    const previousInstrumentIds = this.setInitialInstruments()
      .map((instrument) => instrument._id)
      .sort();

    const instrumentsChanged = !equals(
      currentInstrumentsIds,
      previousInstrumentIds
    );

    const filledTestFormChanged = !equals(
      this.formRef.current.status,
      this.formRef.current.touched
    );
    const emptyTestFormChanged = !isEmpty(this.formRef.current.touched);

    if (test.status === STATUSES.FILLED)
      return instrumentsChanged || filledTestFormChanged;

    if ([STATUSES.EMPTY, STATUSES.ASSIGNED].includes(test.status))
      return instrumentsChanged || emptyTestFormChanged;

    return false;
  };

  render() {
    const {
      classes,
      TestStore,
      InstrumentStore,
      CertificateStore,
      CompanyStore,
      UserStore
    } = this.props;

    const test = TestStore.test.data;
    const certificate = CertificateStore.certificate.data;

    if (
      !TestStore.test.isLoaded ||
      !InstrumentStore.instruments.isLoaded ||
      !CertificateStore.certificate.isLoaded ||
      (certificate._id && !CompanyStore.company.isLoaded)
    ) {
      return <Loader />;
    }

    if (TestStore.test.isEmpty) {
      return <EntityNotFound entity="Test" />;
    }

    const isEditable = [
      STATUSES.ASSIGNED,
      STATUSES.EMPTY,
      STATUSES.FILLED
    ].includes(test.status);

    const isSubmitted = test.status === STATUSES.SUBMITTED;
    const isInspected = test.status === STATUSES.INSPECTED;
    const saveDisabled = isSubmitted || !isEditable || isInspected;
    const testConfig = testsConfig[test.type];
    const instruments = InstrumentStore.instruments.data;
    const {selectedInstruments} = this.state;
    const isTestHaveInstruments = [
      TYPES.SUPPLEMENT,
      TYPES.SPLIT,
      TYPES.TREATMENT,
      TYPES.OTHER,
      TYPES.NOTES
    ].includes(test.type);

    const client = test.company._id !== test.assignee._id ? test.company : {};

    const selectedNamesWithDefault = () => {
      return this.addDefault(selectedInstruments).map((I) => I.equipment);
    };
    const extendedInstrumentNames = () => [
      ...this.addDefault(instruments).map((I) => I.equipment),
      "None"
    ];

    return (
      <div className={classes.mainContainer}>
        <Grid container spacing={8} justifyContent="space-between">
          <Grid item xs className={classes.titleSection}>
            <Typography variant="h4" fontSize="1.8rem">
              {testConfig.title}
            </Typography>
            <StatusMarks status={test.status} />
          </Grid>
        </Grid>
        {certificate && <TestInformation />}
        {!isTestHaveInstruments && (
          <div className={classes.instrumentSection}>
            <MultipleSelect
              label={"Instruments used"}
              required
              disabled={saveDisabled}
              value={selectedNamesWithDefault()}
              elements={extendedInstrumentNames()}
              onChange={(value) => this.changeInstrument(value)}
            />
            <Button
              color={"primary"}
              variant={"contained"}
              size={"large"}
              disabled={saveDisabled}
              onClick={this.setDefaultInstruments}
            >
              default
            </Button>
          </div>
        )}
        <NavigationBlockerConsumer shouldBlock={this.shouldBlock}>
          {testConfig.testForm &&
            React.cloneElement(testConfig.testForm, {
              test,
              certificate,
              inspectedCertificates: !isEmpty(certificate) ? [certificate] : [],
              saveDisabled,
              saveTest: this.saveTest,
              user: UserStore.user.data,
              client,
              formRef: this.formRef
            })}
        </NavigationBlockerConsumer>
      </div>
    );
  }
}

export default compose(
  inject(
    "TestStore",
    "SigningStore",
    "NotificationStore",
    "UserStore",
    "InstrumentStore",
    "CertificateStore",
    "CompanyStore",
    "ConditionStore"
  )
)(withStyles(observer(SingleTest), styles));
