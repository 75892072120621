import React from "react";
import {
  Button,
  Grid,
  InputLabel,
  MenuItem,
  TextField as DateField
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {observer} from "mobx-react";
import TestMarker from "@core/components/TestMarker";
import {TestService} from "@core/services";
import useStores from "../../../../../useStores";
import styles from "./styles";
import {any, isEmpty, filter, omit, propEq} from "ramda";
import {Formik} from "formik";
import * as yup from "yup";
import SelectField from "@core/components/FormikSelect";
import {MultipleSelect} from "@core/components/Form";
import TextField from "@core/components/FormikTextField";
import modules from "@core/constants/modules";

const EmptyLabTest = observer(
  ({
    classes,
    test,
    deleteTest,
    updateTest,
    disabled,
    certificate,
    shareLink
  }) => {
    const initialValues = {
      assignee: test.assignee,
      witnesses: [],
      notes: "",
      inspectionDate: "",
      inspectorJobNumber: ""
    };

    const {CompanyStore, TestStore} = useStores();

    const assignTest = (values) => {
      TestService.assign([{...test, ...values}], certificate);
      const changes = filter(
        (value) => Boolean(value),
        omit(["assignee", "witnesses"], values)
      );

      if (!isEmpty(changes)) TestStore.update(changes, test._id);
    };

    const validationSchema = yup.object().shape({
      assignee: yup.object().required("This field is required"),
      witnesses:
        certificate.certificateType === "3.2"
          ? yup.array().of(yup.object()).required("This field is required")
          : yup.array().of(yup.object()),
      notes: yup.string(),
      inspectionDate: yup.string(),
      inspectorJobNumber: yup.string()
    });

    const laboratories = CompanyStore.meta.filter((company) => {
      return any(propEq(modules.LAB_INTERNAL, "name"), company.modules);
    });

    const witnessCompanies = CompanyStore.meta.filter((company) => {
      return any(propEq(modules.WITNESS, "name"), company.modules);
    });

    return (
      <>
        <TestMarker
          test={test}
          deleteTest={deleteTest}
          updateTest={updateTest}
          disabled={disabled}
          shareLink={shareLink}
        />
        <div className={classes.emptyForm}>
          <Grid container spacing={3}>
            <Formik
              validateOnMount
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={assignTest}
            >
              {(props) => (
                <>
                  <Grid item xs={4}>
                    {test.assignee ? (
                      <TextField
                        required
                        name="assignee.name"
                        label="Laboratory"
                        disabled
                      />
                    ) : (
                      <SelectField name="assignee" label="Laboratory" required>
                        {laboratories.map((lab) => (
                          <MenuItem key={lab._id} value={lab}>
                            {lab.name}
                          </MenuItem>
                        ))}
                      </SelectField>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <MultipleSelect
                      label="Witnesses"
                      value={props.values.witnesses.map((c) => c.name)}
                      elements={witnessCompanies.map((c) => c.name)}
                      onChange={(values) => {
                        const witnesses = witnessCompanies.filter((c) =>
                          values.includes(c.name)
                        );
                        props.setFieldValue("witnesses", witnesses);
                      }}
                    />
                  </Grid>
                  {!isEmpty(props.values.witnesses) && (
                    <Grid item xs={4}>
                      <div className={classes.dateContainer}>
                        <InputLabel className={classes.dateLabel}>
                          Inspection Date
                        </InputLabel>
                        <DateField
                          className={classes.dateField}
                          type="date"
                          InputLabelProps={{shrink: true}}
                          value={props.values.inspectionDate}
                          onChange={(event) =>
                            props.setFieldValue(
                              "inspectionDate",
                              event.target.value
                            )
                          }
                        />
                      </div>
                    </Grid>
                  )}
                  <Grid item xs={!isEmpty(props.values.witnesses) ? 8 : 4}>
                    <TextField rows={2} multiline label="Notes" name="notes" />
                  </Grid>
                  {!isEmpty(props.values.witnesses) && (
                    <Grid item xs={4}>
                      <TextField
                        label="Inspector Job Number"
                        name="inspectorJobNumber"
                      />
                    </Grid>
                  )}
                  <Grid container item justifyContent="flex-end">
                    <Grid item>
                      <Button
                        disabled={!props.isValid || disabled}
                        size="large"
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={props.handleSubmit}
                      >
                        Sign
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </Formik>
          </Grid>
        </div>
      </>
    );
  }
);

export default withStyles(EmptyLabTest, styles);
