import {pipe, filter, prop} from "ramda";
import {useEffect, useMemo, useState, useCallback} from "react";
import {NOT_PLOTTABLE_TEST_STATUSES, TYPES} from "@core/constants/test";
import axios from "axios";
import {usePlotOptions} from "../plotContext";
import {PLOT_TYPES} from "../сonstants";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

const CUSTOM_TEST_OPTIONS_BY_TEST_TYPE = {
  [TYPES.HARDNESS]: {
    splitByZones: true,
  },
};

// If more than 5 tests then default plot type is histogram, otherwise scatter
const FULL_VIEW_HIGHER_PRIORITY_PLOTS = [PLOT_TYPES.HISTOGRAM, PLOT_TYPES.SCATTER];

// for SMC test&analyses tab scatter is default plot type
const DASHBOARD_HIGHER_PRIORITY_PLOTS = [PLOT_TYPES.SCATTER, PLOT_TYPES.HISTOGRAM];

const MIN_HISTOGRAM_TEST_NUMBER = 5;
const PLOTS_CONDITION = {
  [PLOT_TYPES.HISTOGRAM]: (testNumber) => testNumber >= MIN_HISTOGRAM_TEST_NUMBER,
};
const getDefaultPlotType = (testPlots, testNumber, isFullPlotView) => {
  const plotPriority = isFullPlotView ? FULL_VIEW_HIGHER_PRIORITY_PLOTS : DASHBOARD_HIGHER_PRIORITY_PLOTS;

  const higherPriorityPlot = plotPriority.find((plotType) => {
    const condition = PLOTS_CONDITION[plotType];
    const canBeVisualized = !condition || condition(testNumber);

    return testPlots.includes(plotType) && canBeVisualized;
  });

  return higherPriorityPlot || testPlots[0];
};

const usePlot = (testConfig, tests = [], plots = [], isFullPlotView) => {
  const [plotData, setPlotData] = useState({});
  const [plotDataLoading, setPlotDataLoading] = useState(true);

  const {setPlotOptions, ...plotOptions} = usePlotOptions();
  const {plotType} = plotOptions;

  const testType = testConfig.type;

  const plottableTests = useMemo(() => {
    return pipe(
      filter((t) => !NOT_PLOTTABLE_TEST_STATUSES.includes(t.status)),
      filter((t) => t.type === testType)
    )(tests);
  }, [tests, testConfig]);

  const plottableTestsIds = useMemo(() => plottableTests.map(prop("_id")), [plottableTests]);

  useEffect(() => {
    const defaultPlotType = getDefaultPlotType(plots, tests.length, isFullPlotView);
    changePlotType(defaultPlotType);
  }, []);

  useEffect(() => {
    if(!plotOptions.paramType) return;

    if (plottableTests?.length && testConfig && plotType) {
      getPlotData(testType, plottableTestsIds, plotOptions)
        .then((data) => setPlotData(data))
        .finally(() => setTimeout(() =>  setPlotDataLoading(false)));
    }
  }, [testConfig, plotOptions.plotType, plotOptions.splitByZones, plotOptions.paramType]);

  const changePlotType = useCallback((plotType) => {
    const testOptions = CUSTOM_TEST_OPTIONS_BY_TEST_TYPE[testType] || {};

    setPlotOptions((prev) => ({
      ...prev,
      plotType,
      options: {},
      ...testOptions
    }));
    setPlotData({});
    setPlotDataLoading(true);
  }, []);

  const getPlotData = useCallback(async (testType, testIds, properties) => {
    const params = {
      tests: testIds,
      properties,
      testType,
    };

    return await axios.get(ROUTES.TEST_CHART[ACTIONS.SINGLE], {params})
      .then((res) => res.data);
  }, []);

  return {
    plotType,
    changePlotType,
    getPlotData,
    plotData,
    plotDataLoading,
    setPlotDataLoading,
    setPlotData,
    plottableTests,
    testType,
  };
};

export default usePlot;
