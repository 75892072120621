/**
 * Checks if the element is missing in the PTS file
 * @param {ChemicalCompositionElement} element
 * @returns {ChemicalCompositionElement} updatedElement
 * It returns the original element with the original value if it exists, and "Empty" value if the element is missing.
 */
export const MissingElementResult = "Empty";

export const checkMissingElement = (element) => {
  if (element.value === -100) {
    element.value = MissingElementResult;
  }

  return element;
};
