/* eslint-disable no-unused-vars */
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {useTheme} from "@mui/material/styles";

import OutlinedInput from "@mui/material/OutlinedInput";

import React from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 150
    }
  }
};

function getStyles(theme) {
  return {
    fontWeight: theme.typography.fontWeightRegular
  };
}

const MultipleSelectPlaceholder = ({options, onSelect}) => {
  const theme = useTheme();
  //   const [currentOption, setCurrentOption] = useState(options.at(-1).name);

  const handleChange = (event) => {
    const {
      target: {value}
    } = event;

    // setCurrentOption(value);
    onSelect(value);
  };

  return (
    <div>
      <FormControl
        sx={{
          m: 1,
          width: 150,
          mt: 3,
          ".MuiOutlinedInput-notchedOutline": {
            border: 0
          }
        }}
      >
        <Select
          // multiple
          displayEmpty
          defaultValue={options.length - 1}
          onChange={handleChange}
          input={<OutlinedInput />}
          MenuProps={MenuProps}
          inputProps={{"aria-label": "Without label"}}
          sx={{
            backgroundColor: "white"
          }}
        >
          {options.map((option, index) => (
            <MenuItem key={index} value={option.value} style={getStyles(theme)}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default MultipleSelectPlaceholder;
