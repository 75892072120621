import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";
import {CAMPAIGN_TYPES} from "@core/constants/campaign";
import {TableStore} from "@core/stores";
import axios from "axios";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {
  append,
  concat,
  flatten,
  fromPairs,
  indexBy,
  isEmpty,
  keys,
  mergeDeepWith,
  prop,
  uniq,
  values
} from "ramda";
import {getStalkEndWeldNumber} from "../Campaign/components/Welding/helpers";
import {
  getAsBuiltActivitiesByStalkNumber,
  getPipeTotalActual
} from "../Campaign/helpers";

class CampaignStore {
  constructor() {
    makeObservable(this, {
      campaigns: observable,
      campaign: observable,
      stalkNumbers: computed,
      campaignProductsById: computed,
      coatingTypesByPipeNumber: computed,
      coatingCombinations: computed,
      longestCoatingCombination: computed,
      baseMaterialsTypes: computed,
      asBuildRecordTestByStalkNumber: computed,
      pipeLengthByWeldId: computed,
      transfers: observable,
      transfersLoading: observable,
      removeCampaign: action,
      createCampaign: action,
      getCampaignById: action,
      updateCampaignById: action,
      uploadTests: action,
      updateTestById: action,
      deleteCampaignById: action,
      createRawMaterial: action,
      updateRawMaterialById: action,
      deleteRawMaterialById: action,
      getRelatedTransfers: action,
      removeWelds: action
    });
  }
  campaigns = new TableStore({
    loader: (queryParams) => {
      const newQueryParams = {...this.campaigns.fetch, ...queryParams};
      const {offset, limit, search, sort, access, view} = newQueryParams;

      const params = {
        sort,
        offset,
        limit,
        search: isEmpty(search) ? null : {value: search},
        access,
        view
      };

      this.campaigns.setFetch(newQueryParams);

      return axios.get(ROUTES.CAMPAIGN[ACTIONS.ALL], {params});
    }
  });

  campaign = observable.object({}, {deep: true});

  transfers = [];
  transfersLoading = false;

  get campaignProductsById() {
    return indexBy(prop("_id"), this.campaign.products);
  }

  get coatingTypesByPipeNumber() {
    const campaigns = flatten(
      this.transfers.map((transfer) => transfer.campaigns)
    ).filter((campaign) => campaign.type === CAMPAIGN_TYPES.COATING);

    return campaigns.reduce((acc, campaign, index) => {
      const coatingByPipeNumber = fromPairs(
        campaign.products.map((product) => [
          product.pipeNumber,
          [campaign.coatingType || `Coating ${index + 1}`]
        ])
      );

      return mergeDeepWith(concat, acc, coatingByPipeNumber);
    }, {});
  }

  get coatingCombinations() {
    return uniq(values(this.coatingTypesByPipeNumber));
  }

  get longestCoatingCombination() {
    const lengths = this.coatingCombinations.map(
      (combinations) => combinations.length
    );
    const index = lengths.indexOf(Math.max(...lengths));

    return this.coatingCombinations[index];
  }

  get baseMaterialsTypes() {
    return uniq(
      this.campaign.products.reduce(
        (acc, product) => (product.type ? append(product.type, acc) : acc),
        []
      )
    );
  }

  get stalkNumbers() {
    return keys(indexBy(prop("stalkNumber"), this.campaign.welds));
  }

  get asBuildRecordTestByStalkNumber() {
    return this.stalkNumbers.reduce(
      (asBuildRecordTestByStalkNumber, stalkNumber) => {
        asBuildRecordTestByStalkNumber[stalkNumber] =
          getAsBuiltActivitiesByStalkNumber(this.campaign.tests, stalkNumber);

        return asBuildRecordTestByStalkNumber;
      },
      {}
    );
  }

  get pipeLengthByWeldId() {
    const pipeLengthByWeldId = this.campaign.welds.reduce(
      (pipeLengthByWeldNumber, weld) => {
        const asBuildRecordTest =
          this.asBuildRecordTestByStalkNumber[weld.stalkNumber];

        if (asBuildRecordTest) {
          pipeLengthByWeldNumber[weld._id] = getPipeTotalActual(
            asBuildRecordTest.properties.activities,
            weld.weldNumber
          );
        }

        return pipeLengthByWeldNumber;
      },
      {}
    );

    this.stalkNumbers.forEach((stalkNumber) => {
      const asBuildRecordTest =
        this.asBuildRecordTestByStalkNumber[stalkNumber];

      if (!asBuildRecordTest) return;

      const endWeldNumber = getStalkEndWeldNumber(stalkNumber);
      const endPipeLength = getPipeTotalActual(
        asBuildRecordTest.properties.activities,
        endWeldNumber
      );

      if (endPipeLength) pipeLengthByWeldId[endWeldNumber] = endPipeLength;
    });

    return pipeLengthByWeldId;
  }

  removeCampaign() {
    this.campaign = {};
  }

  async createCampaign(data) {
    const response = await axios.post(ROUTES.CAMPAIGN[ACTIONS.CREATE], data);

    this.campaigns.data = [response.data, ...this.campaigns.data];
    this.campaign = response.data;
  }

  async getCampaignById(id) {
    const response = await axios.get(ROUTES.CAMPAIGN[ACTIONS.SINGLE](id));

    runInAction(() => {
      this.campaign = {...response.data};
    });
  }

  updateCampaign(changes) {
    this.campaign = {...this.campaign, ...changes};
  }

  async updateCampaignById(id, data, changes) {
    const response = await axios.patch(
      ROUTES.CAMPAIGN[ACTIONS.UPDATE](id),
      data,
      {
        params: !changes ? {fields: keys(data)} : {}
      }
    );

    this.updateCampaign(changes || response.data);
  }

  async uploadTests(id, tests, rawMaterials, welds, direction) {
    await axios.post(
      ROUTES.CAMPAIGN[ACTIONS.IMPORT](id),
      {tests, rawMaterials, welds, direction},
      {successMessage: "File was successfully imported!"}
    );

    await this.getCampaignById(id);
  }

  updateTestById(testId, changes) {
    this.campaign.tests = this.campaign.tests.map((test) =>
      test._id === testId ? {...test, ...changes} : test
    );
  }

  async deleteTest(id, testId) {
    await axios.delete(ROUTES.CAMPAIGN[ACTIONS.REMOVE_TEST](id, testId));

    const tests = this.campaign.tests.filter((test) => test._id !== testId);

    this.updateCampaign({tests});
  }

  async deleteCampaignById(id) {
    await axios.delete(ROUTES.CAMPAIGN[ACTIONS.REMOVE](id));

    this.campaigns.data = this.campaigns.data.filter(
      (campaign) => campaign._id !== id
    );
    this.campaigns.total = this.campaigns.total - 1;
  }

  async createRawMaterial(campaignId, data) {
    const response = await axios.post(
      ROUTES.RAW_MATERIAL[ACTIONS.CREATE],
      data
    );

    const rawMaterialsIds = this.campaign.rawMaterials.map(
      (material) => material._id
    );
    await this.updateCampaignById(campaignId, {
      rawMaterials: [...rawMaterialsIds, response.data._id]
    });
  }

  async updateRawMaterialById(campaignId, materialId, data) {
    const response = await axios.patch(
      ROUTES.RAW_MATERIAL[ACTIONS.UPDATE](materialId),
      data
    );

    this.campaign.rawMaterials = this.campaign.rawMaterials.map((material) =>
      material._id === materialId ? response.data : material
    );
  }

  async deleteRawMaterialById(campaignId, materialId) {
    await axios.delete(ROUTES.RAW_MATERIAL[ACTIONS.REMOVE](materialId));

    const rawMaterials = this.campaign.rawMaterials.filter(
      (material) => material._id !== materialId
    );
    const rawMaterialsIds = rawMaterials.map((material) => material._id);
    await this.updateCampaignById(campaignId, {
      rawMaterials: rawMaterialsIds
    });
  }

  async removeWelds(campaignId, welds) {
    await axios.delete(ROUTES.WELD[ACTIONS.REMOVE](campaignId), {
      params: {weldIds: welds}
    });
  }

  async getRelatedTransfers(heats) {
    this.transfersLoading = true;

    try {
      const response = await axios.get(
        ROUTES.TRANSFER_CAMPAIGN_PRODUCT[ACTIONS.ALL],
        {params: {heats}}
      );
  
      this.transfers = response.data;
    } catch (error) {
      console.error({getRelatedTransfers: error});
    }
    
    this.transfersLoading = false;
  }
}

export default new CampaignStore();
