import React, {useState} from "react";
import {NavLink, useHistory} from "react-router-dom/cjs/react-router-dom.min";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import {Box} from "@mui/material";
import IconistsIcon from "@core/components/IconistsIcon";

import "./menuItem.css";

const MenuItem = ({menuItem, expanded}) => {
  const history = useHistory();
  // Expand sub items of the menu
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const hasSubMenu = menuItem.items && menuItem.items.length > 0;

  const handleMenuButtonClick = (e) => {
    e.preventDefault();

    if (hasSubMenu) {
      setIsSubMenuOpen(!isSubMenuOpen);
    } else {
      history.push(menuItem.linkTo);
    }
  };

  const exact = menuItem.linkTo === "/" || menuItem.linkTo === "/admin";

  return (
    <Box sx={{}}>
      <NavLink
        id="menu"
        exact={exact}
        to={!hasSubMenu && menuItem.linkTo}
        onClick={handleMenuButtonClick}
        className={({isActive}) => (isActive ? "active" : "menu-item")}
      >
        <IconistsIcon width="24px" icon={menuItem.icon} color="#AFAFAF" />

        {expanded && menuItem.title}
        {expanded && hasSubMenu && (
          <Box
            sx={{
              display: "flex",
              marginLeft: "auto"
            }}
          >
            {isSubMenuOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
          </Box>
        )}
      </NavLink>
      {expanded && isSubMenuOpen && menuItem.items && (
        <Box
          sx={{
            marginTop: "8px",
            marginLeft: "24px",
            display: "flex",
            flexDirection: "column",
            gap: "8px"
          }}
        >
          {menuItem.items.map((item, index) => (
            <MenuItem menuItem={item} expanded={expanded} key={index} />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default MenuItem;
