import TestSlideOut from "./components/TestSlideOut";
import {TEST_CATEGORIES_LABELS} from "@core/constants/test";
import {observer} from "mobx-react-lite";
import React, {useState} from "react";
import {Grid, Box, Typography} from "@mui/material";
import TestSummaryTable from "@core/components/TestSummaryTable";
import useStores from "../../../../../../useStores";
import TestsProgressBar from "@core/components/TestsProgressBar";

const TestsOverview = ({category, isLoaded}) => {
  const [openTestId, setOpenTestId] = useState(null);
  const [slideOutOpen, setSlideOutOpen] = useState(false);

  const {SingleMaterialCampaignStore} = useStores();

  const tests = SingleMaterialCampaignStore.testsByCategory[category] || [];

  const onTestClick = (tests) => {
    const [test] = tests;

    setOpenTestId(test._id);
    setSlideOutOpen(true);
  };

  return (
    <Grid item xs={12}>
      <Typography variant="h6" marginBottom={1}>
        {TEST_CATEGORIES_LABELS[category]} Overview
      </Typography>

      <Box marginY={3}>
        <TestsProgressBar
          isLoaded={isLoaded}
          tests={tests}
        />
      </Box>

      <TestSummaryTable
        isLoaded={isLoaded}
        tests={tests}
        onTestClick={onTestClick}
      />
      <TestSlideOut
        close={() => setSlideOutOpen(false)}
        open={slideOutOpen}
        testId={openTestId}
      />
    </Grid>
  );
};

export default observer(TestsOverview);