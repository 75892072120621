export default () => ({
  inputCell: {
    padding: "8px 0 8px",
    "&:last-child": {
      paddingRight: 0,
    },
    "&:firstChild": {
      paddingLeft: 0,
    },
    "& input": {
      textAlign: "center",
    }
  },
  resultCell: {
    fontSize: 18,
  }
});

