import React, {useEffect} from "react";
import {Box, Grid, Typography} from "@mui/material";
import {observer} from "mobx-react";
import {STATUSES} from "@core/constants/test";
import modules from "@core/constants/modules";
import {ICONISTS_ICONS} from "@core/constants/iconistsIcons";
import DashboardTable from "../Table";
import StatisticsSection from "../../../StatisticsSection";
import useStores from "../../../../../useStores";
import {testColumns, certificateColumns, orderColumns} from "./columns";

const DashboardProducer = observer(({userCompanyId}) => {
  const {StatisticStore} = useStores();

  useEffect(() => {
    StatisticStore.getCompanyStatistics(userCompanyId);
  }, []);

  const statistic = StatisticStore.statistic;

  return (
    <Box sx={{padding: 4}}>
      <Typography variant="h4" fontSize="1.8rem" marginBottom={3}>
        Dashboard
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StatisticsSection
            isLoading={!statistic.isLoaded}
            data={[
              {
                number: statistic.data.created || 0,
                description: "Certificates created",
                link: "/certificates/create",
                icon: ICONISTS_ICONS.sketchbook
              },
              {
                number: statistic.data.owned || 0,
                description: "Certificates owned",
                link: "/customers/certificates",
                icon: ICONISTS_ICONS.sketchbook
              },
              {
                number: statistic.data.transferred || 0,
                description: "Certificates transferred",
                link: "/customers/certificates/archive/",
                icon: ICONISTS_ICONS.archive
              },
              {
                number: statistic.data.withAssignedTest || 0,
                description: "Certificates with pending tests",
                link: "/customers/certificates?view=open",
                icon: ICONISTS_ICONS.sketchbook
              },
              {
                number: statistic.data.tests || 0,
                description: "Tests performed",
                link: "/customers/certificates?view=attest",
                icon: ICONISTS_ICONS.clipboard
              },
              {
                number: statistic.data.openOrdersToSupplier || 0,
                description: "Open orders to supplier",
                link: "/suppliers/orders?status=open",
                icon: ICONISTS_ICONS.folderPaper
              }
            ]}
          />
        </Grid>
        <Grid item xs={12} lg={8}>
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <DashboardTable
                title="Open orders"
                noFoundText="No orders found."
                type="orders"
                columns={orderColumns}
                params={{module: modules.PRODUCER}}
                onRowClick={(transfer) =>
                  window.open(`/transfers/${transfer._id}`, "_self")
                }
              />
            </Grid>
            <Grid item xs={12}>
              <DashboardTable
                title="Tests awaiting approval"
                noFoundText="No tests found."
                type="tests"
                columns={testColumns}
                params={{statuses: [STATUSES.SUBMITTED, STATUSES.INSPECTED]}}
                onRowClick={(test) =>
                  window.open(
                    `/certificates/${test.certificateId}#${test._id}`,
                    "_self"
                  )
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Grid container direction="column" spacing={4}>
            <Grid item xs={12}>
              <DashboardTable
                title="Certificates ready for transfer"
                noFoundText="No certificates found."
                type="certificates"
                columns={certificateColumns}
                onRowClick={(certificate) =>
                  window.open(`/certificates/${certificate._id}`, "_self")
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
});

export default DashboardProducer;
