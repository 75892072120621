import React, {useState} from "react";
import {Grid} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {Link} from "react-router-dom";
import {observer} from "mobx-react";
import {findIndex, indexBy, isNil, prop, propEq} from "ramda";
import classNames from "classnames";
import {QRCodeCanvas} from "qrcode.react";
import {KeyboardArrowUp, Visibility, DeleteOutline} from "@mui/icons-material";
import Image from "@core/components/Image";
import {host} from "Config";
import {
  isTestCompleted,
  isCertificateTransferred,
  getLinkToCertificate
} from "@core/helpers";
import TestsDrawer from "@core/components/TestsDrawer";
import TestSummaryTable from "@core/components/TestSummaryTable";
import {INTERFACE_TYPE} from "@core/constants/transfer";
import useStores from "../../../../../useStores";
import styles from "./styles";
import {getLogoUrl} from "../../../../helpers/getLogoUrl";

const getCertificateStatus = (tests) => {
  const noTestErrors =
    tests.length > 0 ? !!tests.every((test) => isTestCompleted(test)) : false;

  if (noTestErrors) return "COMPLETE";

  return "NEED ATTENTION";
};

const SectionExpanded = observer(
  ({
    classes,
    certificate,
    deselectCertificate,
    handleExpand,
    interfaceType
  }) => {
    const [testToViewIndex, setTestToViewIndex] = useState(null);
    const [filteredTestIds, setFilteredTestIds] = useState([]);

    const {TransferStore} = useStores();

    const isTransferred = isCertificateTransferred(
      certificate,
      TransferStore.transfer.sender._id
    );

    const linkToCertificate = getLinkToCertificate(
      interfaceType,
      isTransferred,
      TransferStore.transfer._id,
      certificate._id
    );

    const testsById = indexBy(prop("_id"), certificate.tests);

    const filteredTests = filteredTestIds.length
      ? filteredTestIds.map((id) => testsById[id])
      : certificate.tests;

    const testOpen = !isNil(testToViewIndex);

    return (
      <div className={classes.containerExpanded}>
        <Grid container spacing={2}>
          <Grid item xs={4} onClick={(event) => event.stopPropagation()}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid container direction={"column"} spacing={2}>
                  <Grid item xs={12}>
                    <QRCodeCanvas
                      value={`${host}/certificates/${certificate._id}`}
                      imageSettings={{
                        src: "/images/logo-symbol-only-black.png",
                        height: 50,
                        width: 50
                      }}
                      size={150}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <h4 className={classes.textBold}>Product Location</h4>
                    <p className={classes.text}>
                      {certificate.company.name} <br />
                      {certificate.company.mainLocation.street}{" "}
                      {certificate.company.mainLocation.house}
                      <br />
                      {certificate.company.mainLocation.postalCode}{" "}
                      {certificate.company.mainLocation.city}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container direction={"column"} spacing={3}>
                  <Grid item xs={12}>
                    <h4 className={classes.textBold}>Heat</h4>
                    <p className={classes.text}>{certificate.heat}</p>
                  </Grid>
                  <Grid item xs={12}>
                    <h4 className={classes.textBold}>Material</h4>
                    <p className={classes.text}>
                      {certificate.properties.norm}
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <h4 className={classes.textBold}>Grade</h4>
                    <p className={classes.text}>
                      {certificate.properties.grade}
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <h4 className={classes.textBold}>Certificate Id</h4>
                    <p className={classes.text}>{certificate._id}</p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* ---- Marking ---- */}
          <Grid item xs={3} onClick={(event) => event.stopPropagation()}>
            <h4 className={classes.textBold}>Marking</h4>
            <div className={classes.logo}>
              {Boolean(certificate.owner?.logo) && (
                <Image
                  src={getLogoUrl(certificate.owner)}
                  alt="Certificate owner logo"
                />
              )}
            </div>
            <p className={classes.markingText}>{certificate.markingText}</p>
          </Grid>

          {/* ---- Statuses ---- */}
          <Grid item xs={3} onClick={(event) => event.stopPropagation()}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <h4 className={classes.statusTextBold}>Status</h4>
                <p
                  className={classNames(
                    getCertificateStatus(certificate.tests) === "COMPLETE"
                      ? classes.green
                      : classes.orange,
                    classes.text
                  )}
                >
                  {getCertificateStatus(certificate.tests)}
                </p>
              </Grid>
            </Grid>
          </Grid>

          {/* ---- Actions ---- */}
          <Grid item xs={2}>
            <div
              className={classes.actionContainer}
              onClick={(event) => {
                event.stopPropagation();
                handleExpand();
              }}
            >
              <KeyboardArrowUp />
              &nbsp;Hide details
            </div>
            <div
              className={classNames(classes.actionContainer, classes.iconView)}
              onClick={(event) => event.stopPropagation()}
            >
              <Link to={linkToCertificate} target="_blank">
                <Visibility fontSize={"small"} className={classes.icon} />
                View
              </Link>
            </div>
            {!isTransferred && interfaceType === INTERFACE_TYPE.SENDER && (
              <div
                className={classNames(
                  classes.actionContainer,
                  classes.iconRemove
                )}
                onClick={(event) => {
                  event.stopPropagation();
                  deselectCertificate(certificate);
                  handleExpand();
                }}
              >
                <DeleteOutline fontSize={"small"} className={classes.icon} />
                Remove from selection
              </div>
            )}
          </Grid>

          <Grid item xs={12}>
            <h4 className={classes.statusTextBold}>Related Tests</h4>
            <TestSummaryTable
              onTestClick={(_, testId) => {
                const index = findIndex(propEq(testId, "_id"))(filteredTests);
                setTestToViewIndex(index);
              }}
              setFilteredTestIds={setFilteredTestIds}
              tests={filteredTests}
            />
          </Grid>
        </Grid>
        <TestsDrawer
          open={testOpen}
          tests={testOpen ? [filteredTests[testToViewIndex]] : []}
          close={() => setTestToViewIndex(null)}
        />
      </div>
    );
  }
);

export default withStyles(SectionExpanded, styles);
