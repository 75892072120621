export const createPillVertices = (zoomRef) => {
  const zoomFactor = zoomRef.current;
  const pillWidthScaled = 5 * zoomFactor;
  const pillHeightScaled = 32 * zoomFactor;

  return [
    ...Array.from({length: 20}, (_, i) => {
      const angle = (Math.PI * i) / 20;

      return [
        2.5 * zoomFactor * Math.cos(angle),
        16 * zoomFactor - 2.5 + 2.5 * zoomFactor * Math.sin(angle),
      ];
    }).flat(),
    -pillWidthScaled / 2,
    -pillHeightScaled / 2 + 2.5 * zoomFactor,
    pillWidthScaled / 2,
    -pillHeightScaled / 2 + 2.5 * zoomFactor,
    pillWidthScaled / 2,
    pillHeightScaled / 2 - 2.5 * zoomFactor,
    -pillWidthScaled / 2,
    pillHeightScaled / 2 - 2.5 * zoomFactor,
    ...Array.from({length: 20}, (_, i) => {
      const angle = Math.PI + (Math.PI * i) / 20;

      return [
        2.5 * zoomFactor * Math.cos(angle),
        -16 * zoomFactor +
          2.5 * zoomFactor +
          2.5 * zoomFactor * Math.sin(angle),
      ];
    }).flat(),
  ];
};

export const drawArrow = (
  overlayCanvas,
  zoomRef,
  startX,
  startY,
  endX,
  endY,
  rowSpacing,
  weldLabel,
) => {
  const ctx = overlayCanvas.getContext("2d");
  const zoomFactor = zoomRef.current;

  const middleY = startY + rowSpacing / 2;
  const cornerRadius = 8 * zoomFactor;

  endY -= 12 * zoomFactor; // offset

  // Arrow line
  ctx.lineWidth = 1 * zoomFactor;
  ctx.beginPath();
  ctx.moveTo(startX, startY + 3 * zoomFactor);
  ctx.lineTo(startX, middleY - cornerRadius);
  ctx.arcTo(startX, middleY, endX + cornerRadius, middleY, cornerRadius);
  ctx.lineTo(endX + cornerRadius, middleY);
  ctx.arcTo(endX, middleY, endX, endY, cornerRadius);
  ctx.lineTo(endX, endY);
  ctx.stroke();

  // Arrow head
  const arrowHeadWidth = 3 * zoomFactor;
  const arrowHeadHeight = 6 * zoomFactor;

  ctx.beginPath();
  ctx.moveTo(endX, endY);
  ctx.lineTo(endX - arrowHeadWidth, endY - arrowHeadHeight);
  ctx.lineTo(endX + arrowHeadWidth, endY - arrowHeadHeight);
  ctx.closePath();
  ctx.fillStyle = "black";
  ctx.fill();

  // TIE IN WELD LABEL
  const labelPaddingX = 8 * zoomFactor;
  const labelPaddingY = 4 * zoomFactor;
  const labelBorderColor = "gray";
  const labelBorderWidth = 1 * zoomFactor;
  const labelCornerRadius = 4 * zoomFactor;

  const midX = (startX + endX) / 2;
  const midY = startY + rowSpacing / 2;

  const textWidth = ctx.measureText(weldLabel).width;
  const labelHeight = 14 * zoomFactor + labelPaddingY;

  const labelX = midX - textWidth / 2 - labelPaddingX / 2;
  const labelY = midY - labelHeight / 2;
  const labelWidth = textWidth + labelPaddingX;

  ctx.beginPath();
  ctx.strokeStyle = labelBorderColor;
  ctx.lineWidth = labelBorderWidth;

  ctx.roundRect(labelX, labelY, labelWidth, labelHeight, labelCornerRadius);
  ctx.stroke();
  ctx.fillStyle = "rgb(253, 253, 150)";
  ctx.fill();

  ctx.fillStyle = "black";
  ctx.fillText(weldLabel, midX, middleY + 1 * zoomFactor);
};

export const drawDetailedView = (x, y, zoomRef, overlayCanvas, stalks) => {
  const zoomFactor = zoomRef.current;
  const startX = x;
  const ctx = overlayCanvas.getContext("2d");

  const canvasWidth = overlayCanvas.width;

  const drawWeld = (x, y, width) => {
    const radius = 4 * zoomFactor;
    const height = 30 * zoomFactor;

    ctx.beginPath();
    ctx.roundRect(x, y, width, height, radius);
    ctx.fillStyle = "rgb(78, 92, 106)";
    ctx.fill();
  };

  const drawCoatedPipe = (row, column, x, y, width, height) => {
    const radius = 4 * zoomFactor;

    const halfHeight = height / 2;

    // Top half of coated pipe
    ctx.beginPath();
    ctx.roundRect(x, y, width, halfHeight, [radius, radius, 0, 0]);
    ctx.fillStyle = "rgb(104, 189, 144)";
    ctx.fill();

    // Bottom half of coated pipe
    ctx.beginPath();
    ctx.roundRect(x, y + halfHeight, width, halfHeight, [0, 0, radius, radius]);
    ctx.fillStyle = "rgb(60, 136, 93)";
    ctx.fill();
  };

  const gap = 4.5 * zoomFactor;
  const rowSpacing = 60 * zoomFactor;
  const pipeHeight = 30 * zoomFactor;
  const pipeWidth = 60 * zoomFactor;
  const weldWidth = 15 * zoomFactor;

  const pillPositions = [];
  const pipeWidths = [];
  const pipeHeights = [];

  const calculatedPillsPerRow =
    Math.floor(canvasWidth / ((pipeWidth + gap) / zoomFactor)) + 8;

  stalks.map((stalk, row) => {
    let i = 1;
    stalk.map((item, column) => {
      if (item.type === "pipe") {
        drawCoatedPipe(row, column, x, y, pipeWidth, pipeHeight);
        pillPositions.push(x, y);
        pipeWidths.push(pipeWidth);
        pipeHeights.push(pipeHeight);

        if (column !== stalk.length - 1) {
          x += pipeWidth + gap;
          i++;
        }
      } else if (item.type === "weld") {
        if (i < calculatedPillsPerRow) {
          drawWeld(x, y, weldWidth);
          pillPositions.push(x, y);
          x += weldWidth + gap;
        } else {
          pillPositions.push("x", "x");

          const prevPipeX = x - pipeWidth - gap;
          drawArrow(
            overlayCanvas,
            zoomRef,
            prevPipeX + pipeWidth / 2,
            y + pipeHeight,
            startX + pipeWidth / 2,
            y + rowSpacing + pipeHeight,
            rowSpacing,
            item.id,
          );
          i = 0;
          y += rowSpacing + pipeHeight;
          x = startX;
        }

        pipeWidths.push(weldWidth);
        pipeHeights.push(pipeHeight);
        i++;
      }
    });

    if (row !== stalks.length - 1) {
      drawArrow(
        overlayCanvas,
        zoomRef,
        x + pipeWidth / 2,
        y + pipeHeight,
        startX + pipeWidth / 2,
        y + rowSpacing + pipeHeight,
        rowSpacing,
        "T-01",
      );
    }

    x = startX;
    y += rowSpacing + pipeHeight;
  });

  return {pillPositions, pipeWidths, pipeHeights};
};
