import AcceptanceCriteriaLabel from "@core/components/AcceptanceCriteriaLabel";
import TestResultTableCell from "@core/components/TestResultTableCell";
import React from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import File from "@core/components/File";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import styles from "./styles";

const HydrogenInducedCracking = ({classes, test}) => {
  const hasControlSample = test.properties.elements.some(
    (e) => e.controlSample === true
  );
  const sourceKeys = Object.keys(test.properties.requirements);
  const hasCustomRequirements = test.properties.elements.some((e) => {
    const elementKeys = Object.keys(e.requirements);

    if (sourceKeys.length !== elementKeys.length) return true;

    return sourceKeys.some(
      (r) => test.properties.requirements[r] !== e.requirements[r]
    );
  });

  const withPosition = test.properties.elements.some((e) => e.position);
  const withOrientation = test.properties.elements.some((e) => e.orientation);
  const withSpecimenLength = test.properties.elements.some(
    (e) => e.specimenLength
  );
  const withSpecimenWidth = test.properties.elements.some(
    (e) => e.specimenWidth
  );
  const withSpecimenThickness = test.properties.elements.some(
    (e) => e.specimenThickness
  );

  return (
    <Grid container spacing={1}>
      <Grid item container alignContent="space-between" spacing={5}>
        {(test.company?.name || test.properties.client) && (
          <Grid item xs={3}>
            <h4 className={classes.name}>Client</h4>
            <p className={classes.value}>
              {test.company?.name || test.properties.client}
            </p>
          </Grid>
        )}
        {(test.assignee?.name || test.properties.lab) && (
          <Grid item xs={3}>
            <h4 className={classes.name}>Laboratory</h4>
            <p className={classes.value}>
              {test.assignee?.name || test.properties.lab}
            </p>
          </Grid>
        )}
      </Grid>
      <Grid item container alignContent="space-between" spacing={5}>
        {test.properties.testStandard && (
          <Grid item xs={3}>
            <h4 className={classes.name}>Test standard</h4>
            <p className={classes.value}>{test.properties.testStandard}</p>
          </Grid>
        )}
        {test.properties.solution && (
          <Grid item xs={3}>
            <h4 className={classes.name}>Test solution</h4>
            <p className={classes.value}>{test.properties.solution}</p>
          </Grid>
        )}
        {test.properties.acceptance && (
          <Grid item xs={3}>
            <h4 className={classes.name}>Acceptance criteria</h4>
            <p className={classes.value}>{test.properties.acceptance}</p>
          </Grid>
        )}
        <Grid item xs={3}>
          <h4 className={classes.name}>Sample ID</h4>
          <p className={classes.value}>{test.properties.sampleID}</p>
        </Grid>
      </Grid>
      <Grid item container alignContent="space-between" spacing={5}>
        {test.properties.gasMixture && (
          <Grid item xs={3}>
            <h4 className={classes.name}>Gas mixture</h4>
            <p className={classes.value}>{test.properties.gasMixture}</p>
          </Grid>
        )}
        {test.properties.duration && (
          <Grid item xs={3}>
            <h4 className={classes.name}>Test duration</h4>
            <p className={classes.value}>{test.properties.duration} hrs</p>
          </Grid>
        )}
        {test.properties.temperature && (
          <Grid item xs={3}>
            <h4 className={classes.name}>Test temperature</h4>
            <p
              className={classes.value}
            >{`${test.properties.temperature} +/- ${test.properties.temperatureTolerance} °C`}</p>
          </Grid>
        )}
        {test.properties.pressure && (
          <Grid item xs={3}>
            <h4 className={classes.name}>Test pressure</h4>
            <p className={classes.value}>{`${test.properties.pressure} bar`}</p>
          </Grid>
        )}
      </Grid>
      {test.properties.solutionPH && (
        <>
          <Grid item container spacing={5}>
            <Grid item xs={12}>
              <h3>Solution pH</h3>
            </Grid>
          </Grid>
          <Grid item container alignContent="space-between" spacing={3}>
            {test.properties.solutionPH.before && (
              <Grid item xs={3}>
                <h4 className={classes.name}>
                  Initial&nbsp;
                  <Tooltip title="Before H2S introduction">
                    <HelpOutlineIcon fontSize="small" />
                  </Tooltip>
                </h4>
                <p className={classes.value}>
                  {test.properties.solutionPH.before}
                </p>
              </Grid>
            )}
            {test.properties.solutionPH.after && (
              <Grid item xs={3}>
                <h4 className={classes.name}>After H2S introduction</h4>
                <p className={classes.value}>
                  {test.properties.solutionPH.after}
                </p>
              </Grid>
            )}
            {test.properties.solutionPH.final && (
              <Grid item xs={3}>
                <h4 className={classes.name}>Final</h4>
                <p className={classes.value}>
                  {test.properties.solutionPH.final}
                </p>
              </Grid>
            )}
          </Grid>
        </>
      )}
      {test.properties.h2sConcentration && (
        <>
          <Grid item container spacing={5}>
            <Grid item xs={12}>
              <h3>H2S concentration</h3>
            </Grid>
          </Grid>
          <Grid item container alignContent="space-between" spacing={3}>
            {test.properties.h2sConcentration.initial && (
              <Grid item xs={3}>
                <h4 className={classes.name}>Initial</h4>
                <p className={classes.value}>
                  {test.properties.h2sConcentration.initial} ppm
                </p>
              </Grid>
            )}
            {test.properties.h2sConcentration.final && (
              <Grid item xs={3}>
                <h4 className={classes.name}>Final</h4>
                <p className={classes.value}>
                  {test.properties.h2sConcentration.final} ppm
                </p>
              </Grid>
            )}
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Table className="styled-table">
          <TableHead>
            <TableRow>
              <TableCell padding="none">Spec. ID</TableCell>
              {withPosition && <TableCell padding="none">Position</TableCell>}
              {withOrientation && (
                <TableCell padding="none">Orientation</TableCell>
              )}
              {withSpecimenLength && (
                <TableCell padding="none">Specimen Length [mm]</TableCell>
              )}
              {withSpecimenWidth && (
                <TableCell padding="none">Specimen Width [mm]</TableCell>
              )}
              {withSpecimenThickness && (
                <TableCell padding="none">Specimen Thickness [mm]</TableCell>
              )}
              {hasControlSample && (
                <TableCell width="100" padding="none">
                  Control Sample
                </TableCell>
              )}
              <TableCell width="70" padding="none">
                CLR [%]
              </TableCell>
              <TableCell width="70" padding="none">
                CTR [%]
              </TableCell>
              <TableCell width="70" padding="none">
                CSR [%]
              </TableCell>
              {test.properties.elements.some(
                (e) => e.files && e.files.length > 0
              ) && <TableCell padding="none">File(s)</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {!hasCustomRequirements && (
              <>
                <TableRow>
                  <TableCell padding="none">
                    <AcceptanceCriteriaLabel />
                  </TableCell>
                  {withPosition && <TableCell />}
                  {withOrientation && <TableCell />}
                  {withSpecimenLength && <TableCell />}
                  {withSpecimenWidth && <TableCell />}
                  {withSpecimenThickness && <TableCell />}
                  {hasControlSample && <TableCell />}
                  <TableCell padding="none">
                    {test.properties.requirements.crackLengthRatioMax &&
                      `\u2264 ${test.properties.requirements.crackLengthRatioMax}`}
                  </TableCell>
                  <TableCell padding="none">
                    {test.properties.requirements.crackThicknessRatioMax &&
                      `\u2264 ${test.properties.requirements.crackThicknessRatioMax}`}
                  </TableCell>
                  <TableCell padding="none">
                    {test.properties.requirements.crackSensitivityRatioMax &&
                      `\u2264 ${test.properties.requirements.crackSensitivityRatioMax}`}
                  </TableCell>
                </TableRow>
              </>
            )}
            {test.properties.elements.map((element) => {
              const crackLengthRatioMax =
                element.requirements.crackLengthRatioMax ||
                test.properties.requirements.crackLengthRatioMax;
              const crackThicknessRatioMax =
                element.requirements.crackThicknessRatioMax ||
                test.properties.requirements.crackThicknessRatioMax;
              const crackSensitivityRatioMax =
                element.requirements.crackSensitivityRatioMax ||
                test.properties.requirements.crackSensitivityRatioMax;

              return (
                <>
                  <TableRow>
                    {hasCustomRequirements ? (
                      <TableCell
                        padding="none"
                        rowSpan={
                          crackLengthRatioMax ||
                          crackThicknessRatioMax ||
                          crackSensitivityRatioMax
                            ? 2
                            : 1
                        }
                      >
                        {element.specimenId}
                      </TableCell>
                    ) : (
                      <TableCell padding="none">{element.specimenId}</TableCell>
                    )}
                    {withPosition && (
                      <TableCell padding="none">
                        {element.position || "-"}
                      </TableCell>
                    )}
                    {withOrientation && (
                      <TableCell padding="none">
                        {element.orientation || "-"}
                      </TableCell>
                    )}
                    {withSpecimenLength && (
                      <TableCell padding="none">
                        {element.specimenLength || "-"}
                      </TableCell>
                    )}
                    {withSpecimenWidth && (
                      <TableCell padding="none">
                        {element.specimenWidth || "-"}
                      </TableCell>
                    )}
                    {withSpecimenThickness && (
                      <TableCell padding="none">
                        {element.specimenThickness || "-"}
                      </TableCell>
                    )}
                    {hasControlSample && (
                      <TableCell width="100" padding="none">
                        {element.controlSample.toString()}
                      </TableCell>
                    )}
                    <TableCell width="70" padding="none">
                      <TestResultTableCell
                        value={element.crackLengthRatio}
                        max={crackLengthRatioMax}
                      />
                    </TableCell>
                    <TableCell width="70" padding="none">
                      <TestResultTableCell
                        value={element.crackThicknessRatio}
                        max={crackThicknessRatioMax}
                      />
                    </TableCell>
                    <TableCell width="70" padding="none">
                      <TestResultTableCell
                        value={element.crackSensitivityRatio}
                        max={crackSensitivityRatioMax}
                      />
                    </TableCell>
                    {!!element.files?.length && (
                      <TableCell padding="none">
                        <Grid container xs={12} spacing={5}>
                          {element.files.map((image) => (
                            <Grid
                              item
                              xs={4}
                              container
                              alignItems="center"
                              key={image}
                            >
                              <File file={image} />
                            </Grid>
                          ))}
                        </Grid>
                      </TableCell>
                    )}
                  </TableRow>
                  {hasCustomRequirements && (
                    <>
                      <TableRow>
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        {hasControlSample && <TableCell />}
                        <TableCell padding="none">
                          {crackLengthRatioMax &&
                            `\u2264 ${crackLengthRatioMax}`}
                        </TableCell>
                        <TableCell padding="none">
                          {crackThicknessRatioMax &&
                            `\u2264 ${crackThicknessRatioMax}`}
                        </TableCell>
                        <TableCell padding="none">
                          {crackSensitivityRatioMax &&
                            `\u2264 ${crackSensitivityRatioMax}`}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </>
              );
            })}
          </TableBody>
        </Table>
      </Grid>
      {test.properties.notes && (
        <Grid item container spacing={5}>
          <Grid item xs={12}>
            <p
              className={classes.notes}
              dangerouslySetInnerHTML={{
                __html: test.properties.notes.split("\n").join("</br>")
              }}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(HydrogenInducedCracking, styles);
