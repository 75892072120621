import MicrographicForm from "@core/components/TestForms/Micrographic";
import React from "react";
import {observer} from "mobx-react-lite";
import {Grid, Table, TableHead, TableRow, TableCell, TableBody, Dialog, DialogContent} from "@mui/material";
import {withStyles} from "tss-react/mui";
import useStores from "../../../../useStores";
import styles from "./styles";
import File from "@core/components/File";

const Micrographic = ({classes, addDataOpen, dataDialogClose, formRef, updateTest, test}) => {
  const {UserStore} = useStores();

  const user = UserStore.user.data;
  const withFiles = test.properties.elements?.some((element) => element?.files && element?.files.length > 0);
  const withPosition = test.properties.elements?.some((element) => element?.position);
  const withOrientation = test.properties.elements?.some((element) => element?.orientation);
  const withEtchingSolution = test.properties.elements?.some((element) => element?.etchingSolution);
  
  return (
    <>
      <Grid container spacing={1}>
        <Grid item container spacing={3}>
          {test.properties.client && (
            <Grid item xs={3} className={classes.section}>
              <h4>Client</h4>
              <p>{test.properties.client}</p>
            </Grid>
          )}
          {test.properties.lab && (
            <Grid item xs={3} className={classes.section}>
              <h4>Lab</h4>
              <p>{test.properties.lab}</p>
            </Grid>
          )}
        </Grid>
        <Grid item container spacing={3}>
          <Grid item xs={3} className={classes.section}>
            <h4>Test standard</h4>
            <p>{test.properties.norm}</p>
          </Grid>
          {test.properties.acceptanceCriteria && (
            <Grid item xs={3} className={classes.section}>
              <h4>Acceptance criteria</h4>
              <p>{test.properties.acceptanceCriteria}</p>
            </Grid>
          )}
        </Grid>
        {test.properties.elements && (
          <Grid item container spacing={3}>
            <Grid item xs={12}>
              <Table className="styled-table">
                <TableHead>
                  <TableRow>
                    <TableCell padding="none" width="70">Spec. ID</TableCell>
                    {withPosition && <TableCell padding="none">Position</TableCell>}
                    {withOrientation && <TableCell padding="none">Orientation</TableCell>}
                    <TableCell padding="none">Etching standard</TableCell>
                    {withEtchingSolution && <TableCell padding="none">Etching solution</TableCell>}
                    <TableCell padding="none">Magnification</TableCell>
                    {withFiles && <TableCell padding="none" width="70">File(s)</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {test.properties.elements.map((element) => (
                    <TableRow key={element.specimenId}>
                      <TableCell padding="none" width="70">{element.specimenId}</TableCell>
                      {withPosition && <TableCell padding="none">{element.position}</TableCell>}
                      {withOrientation && <TableCell padding="none">{element.orientation}</TableCell>}
                      <TableCell padding="none">{element.etchingStandard}</TableCell>
                      {withEtchingSolution && (
                        <TableCell padding="none" className={classes.etchingSolution}>
                          {element.etchingSolution}{element.electrolytic && " (Electrolytic)"}
                        </TableCell>
                      )}
                      <TableCell padding="none">{element.magnification}</TableCell>
                      {withFiles && (
                        <TableCell padding="none" width="70">
                          {element.files && (
                            <Grid container xs={12} spacing={5}>
                              {element.files.map((image) =>
                                <Grid item xs={4} container alignItems="center" key={image}>
                                  <File file={image} />
                                </Grid>
                              )}
                            </Grid>
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        )}
        {test.properties.notes && (
          <Grid item xs={12} className={classes.section}>
            <h6
              dangerouslySetInnerHTML={{__html: test.properties.notes.split("\n").join("</br>")}}
            />
          </Grid>
        )}
      </Grid>
      <Dialog
        open={addDataOpen}
        onClose={dataDialogClose}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent>
          <MicrographicForm
            formRef={formRef}
            saveTest={updateTest}
            test={test}
            isProducerTest
            user={user}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default withStyles(observer(Micrographic), styles);
