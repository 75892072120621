import React, {useEffect, useMemo, useRef, useState} from "react";
import axios from "axios";
import {useField} from "formik";
import {map, pathOr, prop} from "ramda";
import {StyledAutocomplete} from "./styles";
import {defaultFormatOptions, getLabel} from "./helpers";
import MuiAutocompleteInput from "@core/components/MuiAutocompleteInput";
import MuiAutocompletePaper from "@core/components/MuiAutocompletePaper";
import {createFilterOptions} from "@mui/material/Autocomplete";
import {getAutocompleteOptionLabel} from "@core/helpers";
import MuiAutocompleteOption from "@core/components/MuiAutocompleteOption";
import {DEFAULT_LIMIT} from "./constants";

const defaultFilterOptions = createFilterOptions({
  ignoreCase: true,
  ignoreAccents: true
});

function MuiAutocomplete({
  url,
  inputProps,
  disabled,
  getParams,
  onCreateNew,
  formatOptions,
  filterOptions,
  noOptionsMessage,
  onChange,
  onLoad
}) {
  const [field, meta] = useField(inputProps.name);
  const [originalOptions, setOriginalOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [newCreatedValue, setNewCreatedValue] = useState();
  const componentDidMount = useRef(false);
  const [loading, setLoading] = useState(false);

  const showError = Boolean(meta.error) && meta.touched;

  const loadOptions = async (query = "") => {
    const params = getParams(query);

    setLoading(true);
    const response = await axios.get(url, {
      params: {
        limit: DEFAULT_LIMIT,
        ...params
      }
    });

    let options =
      response.data?.items ??
      response.data?.filter(
        (item) =>
          item.receiver?._id === inputProps.showOnly ||
          item.sender?._id === inputProps.showOnly
      );

    if (inputProps.view) {
      if (inputProps.view === "customer") {
        options = options.filter(
          (item) => item.sender._id === inputProps.showOnly
        );
      } else if (inputProps.view === "supplier") {
        options = options.filter(
          (item) => item.receiver._id === inputProps.showOnly
        );
      }
    }

    options = formatOptions(options);

    options = options?.map((option) => ({
      ...option,
      uniqueKey: Math.random().toString(36).substring(2)
    }));

    setOptions(options || []);
    setOriginalOptions(response.data?.items || []);
    setLoading(false);
    onLoad && onLoad();
  };

  const onFilterOptions = (options, state) => {
    const filterHandler = filterOptions || defaultFilterOptions;
    const filteredOptions = filterHandler(options, state);

    const optionExists = filteredOptions.find(
      (option) => option.label === state.inputValue
    );

    const CREATE_NEW_OPTION_PO = {
      label: `Create new purchase order: ${state.inputValue}`,
      value: state.inputValue
    };

    if (onCreateNew && state.inputValue && !optionExists)
      filteredOptions.push(CREATE_NEW_OPTION_PO);

    return filteredOptions;
  };

  const fieldValueAsString = JSON.stringify(field?.value);

  const value = useMemo(() => {
    return pathOr(field.value, ["value"], field?.value);
  }, [fieldValueAsString]);

  const optionsDependency = JSON.stringify(options);

  const label = useMemo(() => {
    if (newCreatedValue) return newCreatedValue;

    return getLabel(value, options);
  }, [value, optionsDependency, newCreatedValue]);

  const labelValue = useMemo(() => ({label, value}), [label, value]);

  // TODO add AbortController cancellation!
  useEffect(() => {
    loadOptions();
  }, [url, inputProps.showOnly, onAutoCompleteChange]);

  useEffect(() => {
    if (componentDidMount.current) {
      const values = map(prop("label"), options);

      if (values.includes(value) || !value) return;

      loadOptions();
    }
  }, [value]);

  useEffect(() => {
    componentDidMount.current = true;
  }, []);

  const onAutoCompleteChange = (event, selectedOption) => {
    setNewCreatedValue(undefined);

    const selectedValue = selectedOption?.value?._id ?? selectedOption?.value;

    const option =
      options.find((o) => o.uniqueKey === selectedOption?.uniqueKey) ??
      originalOptions.find((o) => o._id === selectedValue);

    if (onCreateNew && !option) {
      onCreateNew(selectedOption);
      setNewCreatedValue(selectedOption?.value);
    }

    if (onChange) {
      onChange(selectedOption?.value, option ?? {});
    }
  };

  return (
    <StyledAutocomplete
      value={labelValue}
      fullWidth={true}
      disabled={disabled || loading}
      filterOptions={onFilterOptions}
      noOptionsText={noOptionsMessage}
      PaperComponent={MuiAutocompletePaper}
      renderOption={(props, option) => (
        <MuiAutocompleteOption
          {...props}
          option={option}
          key={option?.uniqueKey}
        />
      )}
      renderInput={(params) => {
        return (
          <MuiAutocompleteInput
            isLoading={loading}
            inputLabel={inputProps?.label}
            inputProps={params.inputProps}
            InputProps={params.InputProps}
            required={inputProps?.required}
            showError={showError}
            errorMessage={showError ? meta.error : null}
          />
        );
      }}
      getOptionLabel={getAutocompleteOptionLabel}
      options={options}
      onChange={onAutoCompleteChange}
      freeSolo
      forcePopupIcon
    />
  );
}

MuiAutocomplete.defaultProps = {
  formatOptions: defaultFormatOptions,
  getParams: (query) => ({query}),
  noOptionsMessage: "No options"
};

export default MuiAutocomplete;
