export const VIEWS = {
  REPORT: "report",
  GLOBAL: "global",
  AS_BUILT: "as_built"
};

export const VIEWS_LABELS = {
  [VIEWS.REPORT]: "Report view",
  [VIEWS.GLOBAL]: "Global view",
  [VIEWS.AS_BUILT]: "As-Built view"
};

export const ENTITIES = {
  PIPE: "pipe",
  WELD: "weld",
};

export const FILE_PIPE_DIRECTION = {
  ASC: {
    label: "↓ Ascending",
    value: "ascending"
  },
  DESC: {
    label: "↑ Descending",
    value: "descending"
  }
};