export const ELEMENT = {
  etchingStandard: "",
  etchingSolution: "",
  position: "",
  orientation: "",
  specimenId: "",
  magnification: "",
  electrolytic: false,
  files: [],
  result: ""
};

export const TEST_STANDARDS = [
  "ASTM A923 (2014) Method A",
  "ASTM E45 (2018a)",
  "ASTM E112 (2013)",
  "ASTM E3-11(2017)",
  "ASTM B657-23",
  "ISO 643:2019",
  "ISO 4967:2013",
  "UNI 3137:1965"
];

export const ACCEPTANCE_CRITERIA = {
  "QCP ITP-01123-124277-BuBi MLP (NOA-BUT-00011)": {
    company: ["Tremblay Inc.", "Test Lab"],
    elements: [
      {
        ...ELEMENT,
        magnification: "100x"
      },
      {
        ...ELEMENT,
        magnification: "500x"
      },
      {
        ...ELEMENT,
        result:
          "The microstructure of the CRA liner, examined at 100x and 500X shows material free from sensitizing grain boundary particles, free from harmful precipitates and phases that could affect the corrosion resistance and / or mechanical properties of the material. The microstructure of the bond line between the carbon steel and the CRA overlay (examined at 500X) shows complete fuzion and is free from any detrimental oxides / inclusions that could detrimentally affect the corrosion resistance and / or mechanical properties of the material"
      }
    ]
  },
  "QCP CPW-ITP-21-23-HFW (YGG-COP-00005)": {
    company: ["Tremblay Inc.", "Test Lab"],
    elements: [
      {
        ...ELEMENT,
        magnification: "100x"
      },
      {
        ...ELEMENT,
        magnification: "500x"
      }
    ]
  },
  "QCP 9348 (YGG-TGS-00010 - GL Reelable)": {
    company: [],
    elements: [
      {
        ...ELEMENT,
        magnification: "100x",
        notes: "performed on hardness specimens.",
        result:
          "No banded structure (with regards to precipitate) or untempered bainite or untempered martensite"
      }
    ]
  },
  "QCP 9348 (YGG-TGS-00010 - GL Reelable) - FDPT": {
    company: [],
    elements: [
      {
        ...ELEMENT,
        magnification: "100x",
        notes: "performed on hardness specimens.",
        result:
          "No banded structure (with regards to precipitate) or untempered bainite or untempered martensite"
      }
    ]
  },
  "QCP 10378 (YGG-TGS-00045 - GL Pipe)": {
    company: [],
    elements: [
      {
        ...ELEMENT,
        magnification: "100x",
        notes: "performed on hardness specimens.",
        result:
          "No banded structure (with regards to precipitate) or untempered bainite or untempered martensite"
      }
    ]
  },
  "QCP 10378 (YGG-TGS-00045 - GL Pipe) - FDPT": {
    company: [],
    elements: [
      {
        ...ELEMENT,
        magnification: "100x",
        notes: "performed on hardness specimens.",
        result:
          "No banded structure (with regards to precipitate) or untempered bainite or untempered martensite"
      }
    ]
  },
  "QCP 8263 Rev. 6": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    elements: [
      {
        ...ELEMENT,
        magnification: "100x"
      }
    ],
    notes:
      "No untempered bainite or untempered martensite shall be present. No full ferrite and perlite structure shall be observed."
  },
  "QCP 8252 Rev. 6": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    elements: [
      {
        ...ELEMENT,
        magnification: "100x"
      }
    ],
    notes:
      "No untempered bainite or untempered martensite shall be present. No full ferrite and perlite structure shall be observed."
  },
  "ITP 011/23/124277 Rev. 05 - CRA Liner": {
    company: ["Tremblay Inc."],
    elements: [
      {
        ...ELEMENT,
        magnification: "100x"
      },
      {
        ...ELEMENT,
        magnification: "500x"
      }
    ],
    notes:
      "The microstructure of the CRA liner shall show material free from sensitizing grain boundary particles, free from harmful precipitates and phases that could affect the corrosion resistance and / or mechanical properties of the material."
  },
  "ITP 011/23/124277 Rev. 05 - Bond line": {
    company: ["Tremblay Inc."],
    elements: [
      {
        ...ELEMENT,
        magnification: "500x"
      }
    ],
    notes:
      "The microstructure of the bond line between the carbon steel and the CRA overlay shall show complete fusion and shall be free of any detrimental oxides / inclusions that could detrimentally affect the corrosion resistance or the mechanical properties of the material."
  },
  get "ITP 011/23/124277 Rev. 05 - CRA Liner MPQT"() {
    return this["ITP 011/23/124277 Rev. 05 - CRA Liner"];
  },
  get "ITP 011/23/124277 Rev. 05 - Bond line MPQT"() {
    return this["ITP 011/23/124277 Rev. 05 - Bond line"];
  },
  "CPW-ITP-24-23-SAWL Rev. 3": {
    company: ["Tremblay Inc."],
    elements: [
      {
        ...ELEMENT,
        magnification: "100x"
      },
      {
        ...ELEMENT,
        magnification: "500x"
      }
    ]
  },
  "CPW-ITP-24-23-SAWL Rev. 3 MPQT": {
    company: ["Tremblay Inc."],
    elements: [
      {
        ...ELEMENT,
        magnification: "100x"
      },
      {
        ...ELEMENT,
        magnification: "100x"
      },
      {
        ...ELEMENT,
        magnification: "500x"
      },
      {
        ...ELEMENT,
        magnification: "500x"
      }
    ]
  }
};
