import React, {useMemo, memo, useRef} from "react";
import {Group, Rect, Tag, Text} from "react-konva";
import {SPACING_BETWEEN_STALKS, STAGE_CONFIG} from "../../../../constants";
import {last} from "ramda";
import {MARGIN, LINE_WELD_CONFIG, REPAIRED_WELD_CONFIG, DEFECT_CONFIG} from "../../constants";
import CurvedLine from "../../../CurvedLine";
import {getTextScale} from "../../../../helpers";
import {getWeldIsRepaired} from "../../../../../../helpers";

const LineWeld = ({stalkChunks, settings, scale, weldNumber, totalLength, pipeLength, setOpenWeld, weldConfig, pipeConfig, defects = []}) => {
  const textRef = useRef();

  const lastChunkLength = useMemo(() => last(stalkChunks)?.length + 1, [stalkChunks]);

  const color = useMemo(() => {
    const isRepaired = getWeldIsRepaired(weldNumber);

    if(isRepaired && settings.highlightRepairedWelds) return REPAIRED_WELD_CONFIG.BACKGROUND_COLOR;

    if(!!defects.length && settings.highlightCoatingDefects) return DEFECT_CONFIG.BACKGROUND_COLOR;

    return weldConfig.BACKGROUND_COLOR;
  }, [settings.highlightRepairedWelds, weldNumber, weldConfig.color, settings.highlightCoatingDefects, defects.length]);

  const x = useMemo(() => {
    const pipeWidth = pipeConfig.WIDTH * lastChunkLength;
    const weldLength = weldConfig.WIDTH * (lastChunkLength - 1);
    const margin = MARGIN * (lastChunkLength - 1);

    if(settings.welds) {
      return pipeWidth + weldLength + 2 * margin;
    }
 
    const stalkLength = pipeWidth + margin;

    return stalkLength;
  }, [lastChunkLength, settings.welds]);

  const y = useMemo(() => {
    const chunksHeight = pipeConfig.HEIGHT * (stalkChunks.length - 1);
    const spacing = SPACING_BETWEEN_STALKS * (stalkChunks.length - 1);

    return chunksHeight + spacing + pipeConfig.HEIGHT / 2;
  }, [stalkChunks]);

  const textScale = useMemo(() => {
    return getTextScale(pipeConfig.TEXT_SCALE_BY, scale, pipeConfig.TEXT_MAX_SCALE, pipeConfig.TEXT_MIN_SCALE);
  }, [scale]);

  const nextY = useMemo(() => pipeConfig.HEIGHT + SPACING_BETWEEN_STALKS, []);
  const middlePointY = useMemo(() => nextY / 2, [nextY]);

  const rightLineOffsets = useMemo(() => [{x: -pipeConfig.WIDTH / 2, y: pipeConfig.HEIGHT / 2 + MARGIN}, {x: -pipeConfig.WIDTH / 2, y: middlePointY}], [middlePointY]);
  const rightPartOfLine = useMemo(() => rightLineOffsets.reduce((points, {x: xOffset, y: yOffset}) => points.concat([xOffset, yOffset]), []), [middlePointY]);

  const leftLineOffsets = useMemo(() => [{x: -pipeConfig.WIDTH / 2, y: middlePointY}, {x: -pipeConfig.WIDTH / 2, y: pipeConfig.HEIGHT}], [middlePointY]);
  const leftPartOfLine = useMemo(() => leftLineOffsets.reduce((points, {x: xOffset, y: yOffset}) => points.concat([-x - xOffset, nextY - yOffset]), []), [middlePointY, x]);

  const points = useMemo(() => [...rightPartOfLine, ...leftPartOfLine], [rightPartOfLine, leftPartOfLine]);
  
  const onMouseEnter = () => {
    document.body.style.cursor = "pointer";
    const data = {
      weld: weldNumber,
      length: pipeLength,
      totalLength,
    };
    textRef.current.setAttr("data", data);
  };
  
  if(!weldNumber) return null;

  return (
    <Group
      y={y}
      x={x}
      listening
      onClick={() => setOpenWeld(weldNumber)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={() => document.body.style.cursor = "default"}
    >
      <CurvedLine
        points={points}
        strokeWidth={LINE_WELD_CONFIG.STROKE_WIDTH}
        fill={color}
        stroke={color}
      />
      <Tag
        listening={false}
        x={points[points.length - 2]}
        y={points[points.length - 1] - MARGIN}
        fill={color}
        pointerDirection="down"
        pointerWidth={LINE_WELD_CONFIG.POINTER_WIDTH}
        pointerHeight={LINE_WELD_CONFIG.POINTER_HEIGHT}
        lineJoin="round"
      />
      <Rect
        listening={false}
        x={(-x - LINE_WELD_CONFIG.BACKGROUND_WIDTH) / 2}
        y={middlePointY - LINE_WELD_CONFIG.STROKE_WIDTH}
        width={LINE_WELD_CONFIG.BACKGROUND_WIDTH}
        height={LINE_WELD_CONFIG.STROKE_WIDTH * 2}
        fill={STAGE_CONFIG.BACKGROUND_COLOR}
      />
      {textRef.current && (
        <Rect
          listening={false}
          x={(-x - textRef.current.textWidth * textScale - 2 * LINE_WELD_CONFIG.RECTANGLE_PADDING) / 2}
          y={middlePointY - (LINE_WELD_CONFIG.FONT_SIZE * textScale + LINE_WELD_CONFIG.RECTANGLE_PADDING) / 2}
          width={textRef.current.textWidth * textScale + 2 * LINE_WELD_CONFIG.RECTANGLE_PADDING}
          height={LINE_WELD_CONFIG.FONT_SIZE * textScale + LINE_WELD_CONFIG.RECTANGLE_PADDING}
          fill={STAGE_CONFIG.BACKGROUND_COLOR}
          stroke={color}
          shadowForStrokeEnabled={false}
          hitStrokeWidth={0}
          strokeWidth={1}
          cornerRadius={4}
        />
      )}
      <Text
        id={weldNumber}
        ref={textRef}
        x={-x}
        y={middlePointY - LINE_WELD_CONFIG.FONT_SIZE * textScale / 2}
        width={x / textScale}
        align="center"
        fontSize={LINE_WELD_CONFIG.FONT_SIZE}
        fontFamily={STAGE_CONFIG.FONT_FAMILY}
        scale={{x: textScale, y: textScale}}
        text={weldNumber}
        fill={color}
      />
    </Group>
  );
};

export default memo(LineWeld);