export const PO_ITEMS = {
  PO_ITEM_1: 1,
  PO_ITEM_2: 2,
  PO_ITEM_3: 3,
  PO_ITEM_4: 4,
  PO_ITEM_5: 5,
  PO_ITEM_10: 10,
  PO_ITEM_11: 11,
  PO_ITEM_20: 20,
  PO_ITEM_21: 21,
  PO_ITEM_30: 30,
  PO_ITEM_31: 31,
  PO_ITEM_40: 40,
  PO_ITEM_50: 50,
  PO_ITEM_60: 60,
  PO_ITEM_70: 70,
  PO_ITEM_80: 80
};
