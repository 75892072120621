import React, {Component} from "react";
import {
  Button,
  Checkbox,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import {inject, observer} from "mobx-react";
import userService from "@core/api/user-service";
import {withStyles} from "tss-react/mui";
import classNames from "classnames";
import moment from "moment";
import Select from "@core/components/Form/Select";
import Avatar from "@core/components/Avatar";
import Confirmation from "@core/components/Modal/Confirmation/Confirmation";
import {DATE_TIME_FORMAT, DATE_FORMAT} from "@core/constants/dateFormats";
import styles from "./styles";
import RouterLink from "@core/components/RouterLink";
import {compose} from "ramda";

class Employees extends Component {
  constructor(props) {
    super(props);

    this.state = {
      actions: [
        {
          value: "delete",
          label: "delete"
        }
      ],
      action: "",
      openConfirmation: false,
      alertText: "",
      selected: [],
      users: [],
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const {CompanyStore, urlId} = this.props;

    await CompanyStore.getUsers(urlId);

    this.setState({
      users: CompanyStore.employees.data.map((x) => ({...x, checked: false}))
    });

  };

  onChangeSingle = (id) => () => {
    let {users} = this.state;
    users = users.map((user) => {
      if (user._id === id) {
        // eslint-disable-next-line no-param-reassign
        user.checked = !user.checked;
      }

      return user;
    });
    const selected = users.filter((user) => {
      return user.checked ? user : false;
    });
    this.setState({
      users,
      selected
    });
  };

  onChangeAll = (event, checked) => {
    let {users} = this.state;
    users = users.map((user) => {
      if (checked) {
        // eslint-disable-next-line no-param-reassign
        user.checked = true;
      } else {
        // eslint-disable-next-line no-param-reassign
        user.checked = false;
      }

      return user;
    });
    const selected = users.filter((user) => {
      return user.checked ? user : false;
    });
    this.setState({
      users,
      selected
    });
  };

  fireAction = (event) => {
    const {selected} = this.state;
    const that = this;

    if (selected.length > 0) {
      switch (event.target.value) {
        case "delete":
        // eslint-disable-next-line no-case-declarations
          let usernames = "";

          selected.map((user) => {
            usernames += `${user.username},`;

            return 0;
          });
          that.setState({
            alertText: `Are your sure you want to delete ${usernames}`,
            openConfirmation: true
          });
          break;
        default:
          break;
      }
    }
  };

  handleActionCancel = () => {
    this.setState({
      alertText: "",
      openConfirmation: false,
      action: "none"
    });
  };

  handleActionConfirm = () => {
    const {NotificationStore} = this.props;
    const {selected} = this.state;
    let isAdmin = false;

    const userIdArray = selected.map((user) => {
      if (user.company.admin === user._id) {
        isAdmin = true;
      }

      return user._id;
    });

    if (isAdmin) {
      NotificationStore.showInfo("You can't remove an admin");

      return;
    }

    userService.delete({users: userIdArray})
      .then(() => {
        this.setState({openConfirmation: false});
        NotificationStore.showSuccess("Successfully deleted!");
      })
      .catch((error) => NotificationStore.showError(error))
      .then(() => {
        this.setState({selected: []});
        this.getData();
      });
  };

  render() {
    const {classes} = this.props;
    const {actions, openConfirmation, alertText, selected, action, users} = this.state;
    const colors = [
      "orangeAvatar",
      "purpleAvatar",
      "greenAvatar",
      "blueAvatar"
    ];
    let colorCounter = 0;

    return (
      <div>
        <Typography variant="h4" fontSize="1.8rem">
          Employees
        </Typography>
        <Grid container spacing={3} justifyContent="flex-end">
          <Grid item xs={2}>
            <Select
              className={classNames(classes.actionSelect, selected.length === 0 ? classes.hidden : classes.visible)}
              label="Actions"
              value={action}
              name="action"
              onChange={this.fireAction}
            >
              <MenuItem value="none">None</MenuItem>
              {actions.map((E) => <MenuItem key={E.value} value={E.value}>{E.label}</MenuItem>)}
            </Select>
          </Grid>
        </Grid>
        {users.length === 0 ? <Typography>There are no employees in this company yet</Typography> :
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.thCheckbox}><Checkbox onChange={this.onChangeAll}
                    color="primary"/></TableCell>
                  <TableCell className={classes.thPadding}>User</TableCell>
                  <TableCell className={classes.thPadding}>Logins</TableCell>
                  <TableCell className={classes.thPadding}>Last login</TableCell>
                  <TableCell className={classes.thPadding}>Created</TableCell>
                  <TableCell>Role</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user, index) => {
                  // eslint-disable-next-line no-plusplus
                  colorCounter++;

                  if (colorCounter > 3) {
                    colorCounter = 0;
                  }

                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      checked={user.checked}
                      tabIndex={-1}
                      key={user._id}
                      className={user.bc_address ? null : classes.notActivated}
                    >
                      <TableCell className={classes.paddingForCheckbox}>
                        <Checkbox
                          color="primary"
                          checked={user.checked}
                          onChange={this.onChangeSingle(user._id)}
                          inputProps={{"aria-labelledby": labelId}}
                        />

                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Button component={RouterLink} to={`/users/${user._id}`} className={classes.userPadding}>
                          <div className={classes.logoAndName}>
                            <Avatar user={user} className={classNames(classes[colors[colorCounter]], classes.avatar)}/>

                            {`${user.username} | ${user.firstname}  ${user.lastname}`}

                          </div>
                        </Button>
                      </TableCell>
                      <TableCell>{user.loginCount || 0}</TableCell>
                      <TableCell>{user.lastLogin ? moment(user.lastLogin).format(DATE_TIME_FORMAT) : "Never logged in before"}</TableCell>
                      <TableCell>{moment(user.createdAt).format(DATE_FORMAT)}
                      </TableCell>
                      <TableCell className={classes.roleWidth}>{user.role}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        }
        <Confirmation
          open={openConfirmation}
          alertText={alertText}
          onCancel={this.handleActionCancel}
          onConfirm={this.handleActionConfirm}
        />
      </div>
    );
  }
}

export default compose(
  inject("CompanyStore", "NotificationStore"),
)(withStyles(observer(Employees), styles));
