export const CONFIG = [
  {
    id: 1,
    value: "ASTM A350 (2018)",
    grade: [
      {
        id: 1,
        value: "LF1 Class 1",
        temperature: [{
          value: -29,
          data: {
            orientation: "Transversal",
            average: 18,
            single: 14
          }
        }]
      },
      {
        id: 2,
        value: "LF2 Class 1",
        temperature: [{
          value: -46,
          data: {
            orientation: "Transversal",
            average: 20,
            single: 16
          }
        }]
      },
      {
        id: 3,
        value: "LF2 Class 2",
        temperature: [{
          value: -18,
          data: {
            orientation: "Transversal",
            average: 27,
            single: 20
          }
        }]
      },
      {
        id: 4,
        value: "LF3 Class 1",
        temperature: [{
          value: -101,
          data: {
            orientation: "Transversal",
            average: 20,
            single: 16
          }
        }]
      },
      {
        id: 5,
        value: "LF3 Class 2",
        temperature: [{
          value: -101,
          data: {
            orientation: "Transversal",
            average: 27,
            single: 20
          }
        }]
      },
      {
        id: 6,
        value: "LF5 Class 1",
        temperature: [{
          value: -59,
          data: {
            orientation: "Transversal",
            average: 20,
            single: 16
          }
        }]
      },
      {
        id: 7,
        value: "LF6 Class 1",
        temperature: [{
          value: -51,
          data: {
            orientation: "Longitudinal",
            average: 20,
            single: 16
          }
        }]
      },
      {
        id: 8,
        value: "LF6 Class 2",
        temperature: [{
          value: -51,
          data: {
            orientation: "Longitudinal",
            average: 27,
            single: 20
          }
        }]
      },
      {
        id: 9,
        value: "LF6 Class 3",
        temperature: [{
          value: -18,
          data: {
            orientation: "Longitudinal",
            average: 27,
            single: 20
          }
        }]
      },
      {
        id: 10,
        value: "LF9",
        temperature: [{
          value: -73,
          data: {
            orientation: "Longitudinal",
            average: 18,
            single: 14
          }
        }]
      },
      {
        id: 11,
        value: "LF787 Class 2",
        temperature: [{
          value: -59,
          data: {
            orientation: "Longitudinal",
            average: 20,
            single: 16
          }
        }]
      },
      {
        id: 12,
        value: "LF787 Class 3",
        temperature: [{
          value: -73,
          data: {
            orientation: "Longitudinal",
            average: 27,
            single: 20
          }
        }]
      },
      {
        id: 13,
        value: "LF5 Class 2",
        temperature: [{
          value: -59,
          data: {
            orientation: "Transversal",
            average: 20,
            single: 16
          }
        }]
      }
    ]
  },
  {
    id: 2,
    value: "EN 10028-6:2017",
    grade: [
      {
        id: 1,
        value: "P355Q",
        temperature: [
          {
            id: 1,
            value: 20,
            data: {
              orientation: "Transversal",
              average: 60,
              single: 60
            }
          },
          {
            id: 2,
            value: "0",
            data: {
              orientation: "Transversal",
              average: 40,
              single: 40
            }
          },
          {
            id: 3,
            value: -20,
            data: {
              orientation: "Transversal",
              average: 27,
              single: 27
            }
          }
        ]
      },
      {
        id: 2,
        value: "P355QH",
        temperature: [
          {
            id: 1,
            value: 20,
            data: {
              orientation: "Transversal",
              average: 60,
              single: 60
            }
          },
          {
            id: 2,
            value: "0",
            data: {
              orientation: "Transversal",
              average: 40,
              single: 40
            }
          },
          {
            id: 3,
            value: -20,
            data: {
              orientation: "Transversal",
              average: 27,
              single: 27
            }
          }
        ]
      },
      {
        id: 3,
        value: "P355QL1",
        temperature: [
          {
            id: 1,
            value: "0",
            data: {
              orientation: "Transversal",
              average: 60,
              single: 60
            }
          },
          {
            id: 2,
            value: -20,
            data: {
              orientation: "Transversal",
              average: 40,
              single: 40
            }
          },
          {
            id: 3,
            value: -40,
            data: {
              orientation: "Transversal",
              average: 27,
              single: 27
            }
          }
        ]
      },
      {
        id: 4,
        value: "P355QL2",
        temperature: [
          {
            id: 1,
            value: "0",
            data: {
              orientation: "Transversal",
              average: 80,
              single: 80
            }
          },
          {
            id: 2,
            value: -20,
            data: {
              orientation: "Transversal",
              average: 60,
              single: 60
            }
          },
          {
            id: 3,
            value: -40,
            data: {
              orientation: "Transversal",
              average: 40,
              single: 40
            }
          },
          {
            id: 4,
            value: -60,
            data: {
              orientation: "Transversal",
              average: 27,
              single: 27
            }
          }
        ]
      },
      {
        id: 5,
        value: "P460Q",
        temperature: [
          {
            id: 1,
            value: 20,
            data: {
              orientation: "Transversal",
              average: 60,
              single: 60
            }
          },
          {
            id: 2,
            value: "0",
            data: {
              orientation: "Transversal",
              average: 40,
              single: 40
            }
          },
          {
            id: 3,
            value: -20,
            data: {
              orientation: "Transversal",
              average: 27,
              single: 27
            }
          }
        ]
      },
      {
        id: 6,
        value: "P460QH",
        temperature: [
          {
            id: 1,
            value: 20,
            data: {
              orientation: "Transversal",
              average: 60,
              single: 60
            }
          },
          {
            id: 2,
            value: "0",
            data: {
              orientation: "Transversal",
              average: 40,
              single: 40
            }
          },
          {
            id: 3,
            value: -20,
            data: {
              orientation: "Transversal",
              average: 27,
              single: 27
            }
          }
        ]
      },
      {
        id: 7,
        value: "P460QL1",
        temperature: [
          {
            id: 1,
            value: "0",
            data: {
              orientation: "Transversal",
              average: 60,
              single: 60
            }
          },
          {
            id: 2,
            value: -20,
            data: {
              orientation: "Transversal",
              average: 40,
              single: 40
            }
          },
          {
            id: 3,
            value: -40,
            data: {
              orientation: "Transversal",
              average: 27,
              single: 27
            }
          }
        ]
      },
      {
        id: 8,
        value: "P460QL2",
        temperature: [
          {
            id: 1,
            value: "0",
            data: {
              orientation: "Transversal",
              average: 80,
              single: 80
            }
          },
          {
            id: 2,
            value: -20,
            data: {
              orientation: "Transversal",
              average: 60,
              single: 60
            }
          },
          {
            id: 3,
            value: -40,
            data: {
              orientation: "Transversal",
              average: 40,
              single: 40
            }
          },
          {
            id: 4,
            value: -60,
            data: {
              orientation: "Transversal",
              average: 27,
              single: 27
            }
          }
        ]
      },
      {
        id: 9,
        value: "P500Q",
        temperature: [
          {
            id: 1,
            value: 20,
            data: {
              orientation: "Transversal",
              average: 60,
              single: 60
            }
          },
          {
            id: 2,
            value: "0",
            data: {
              orientation: "Transversal",
              average: 40,
              single: 40
            }
          },
          {
            id: 3,
            value: -20,
            data: {
              orientation: "Transversal",
              average: 27,
              single: 27
            }
          }
        ]
      },
      {
        id: 10,
        value: "P500QH",
        temperature: [
          {
            id: 1,
            value: 20,
            data: {
              orientation: "Transversal",
              average: 60,
              single: 60
            }
          },
          {
            id: 2,
            value: "0",
            data: {
              orientation: "Transversal",
              average: 40,
              single: 40
            }
          },
          {
            id: 3,
            value: -20,
            data: {
              orientation: "Transversal",
              average: 27,
              single: 27
            }
          }
        ]
      },
      {
        id: 11,
        value: "P500QL1",
        temperature: [
          {
            id: 1,
            value: "0",
            data: {
              orientation: "Transversal",
              average: 60,
              single: 60
            }
          },
          {
            id: 2,
            value: -20,
            data: {
              orientation: "Transversal",
              average: 40,
              single: 40
            }
          },
          {
            id: 3,
            value: -40,
            data: {
              orientation: "Transversal",
              average: 27,
              single: 27
            }
          }
        ]
      },
      {
        id: 12,
        value: "P500QL2",
        temperature: [
          {
            id: 1,
            value: "0",
            data: {
              orientation: "Transversal",
              average: 80,
              single: 80
            }
          },
          {
            id: 2,
            value: -20,
            data: {
              orientation: "Transversal",
              average: 60,
              single: 60
            }
          },
          {
            id: 3,
            value: -40,
            data: {
              orientation: "Transversal",
              average: 40,
              single: 40
            }
          },
          {
            id: 4,
            value: -60,
            data: {
              orientation: "Transversal",
              average: 27,
              single: 27
            }
          }
        ]
      },
      {
        id: 13,
        value: "P690Q",
        temperature: [
          {
            id: 1,
            value: 20,
            data: {
              orientation: "Transversal",
              average: 60,
              single: 60
            }
          },
          {
            id: 2,
            value: "0",
            data: {
              orientation: "Transversal",
              average: 40,
              single: 40
            }
          },
          {
            id: 3,
            value: -20,
            data: {
              orientation: "Transversal",
              average: 27,
              single: 27
            }
          }
        ]
      },
      {
        id: 14,
        value: "P690QH",
        temperature: [
          {
            id: 1,
            value: 20,
            data: {
              orientation: "Transversal",
              average: 60,
              single: 60
            }
          },
          {
            id: 2,
            value: "0",
            data: {
              orientation: "Transversal",
              average: 40,
              single: 40
            }
          },
          {
            id: 3,
            value: -20,
            data: {
              orientation: "Transversal",
              average: 27,
              single: 27
            }
          }
        ]
      },
      {
        id: 15,
        value: "P690QL1",
        temperature: [
          {
            id: 1,
            value: "0",
            data: {
              orientation: "Transversal",
              average: 60,
              single: 60
            }
          },
          {
            id: 2,
            value: -20,
            data: {
              orientation: "Transversal",
              average: 40,
              single: 40
            }
          },
          {
            id: 3,
            value: -40,
            data: {
              orientation: "Transversal",
              average: 27,
              single: 27
            }
          }
        ]
      },
      {
        id: 16,
        value: "P690QL2",
        temperature: [
          {
            id: 1,
            value: "0",
            data: {
              orientation: "Transversal",
              average: 80,
              single: 80
            }
          },
          {
            id: 2,
            value: -20,
            data: {
              orientation: "Transversal",
              average: 60,
              single: 60
            }
          },
          {
            id: 3,
            value: -40,
            data: {
              orientation: "Transversal",
              average: 40,
              single: 40
            }
          },
          {
            id: 4,
            value: -60,
            data: {
              orientation: "Transversal",
              average: 27,
              single: 27
            }
          }
        ]
      }
    ]
  },
  {
    id: 3,
    value: "API 5L (2018) PSL2",
    grade: [
      {
        id: 1,
        value: "BR",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 2,
        value: "BN",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 3,
        value: "BQ",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 4,
        value: "BM",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 5,
        value: "X42R",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 6,
        value: "X42N",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 7,
        value: "X42Q",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 8,
        value: "X42M",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 9,
        value: "X46N",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 10,
        value: "X46Q",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 11,
        value: "X46M",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 12,
        value: "X52N",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 13,
        value: "X52Q",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 14,
        value: "X52M",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 15,
        value: "X56N",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 16,
        value: "X56Q",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 17,
        value: "X56M",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 18,
        value: "X60N",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 19,
        value: "X60Q",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 20,
        value: "X60M",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 21,
        value: "X65Q",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 54,
                single: 41
              },
              ">1422<=2134": {
                average: 54,
                single: 41
              }
            }
          }
        }]
      },
      {
        id: 22,
        value: "X65M",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 54,
                single: 41
              },
              ">1422<=2134": {
                average: 54,
                single: 41
              }
            }
          }
        }]
      },
      {
        id: 23,
        value: "X70Q",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 54,
                single: 41
              },
              ">1422<=2134": {
                average: 68,
                single: 51
              }
            }
          }
        }]
      },
      {
        id: 24,
        value: "X70M",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 54,
                single: 41
              },
              ">1422<=2134": {
                average: 68,
                single: 51
              }
            }
          }
        }]
      },
      {
        id: 25,
        value: "X80Q",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 40,
                single: 30
              },
              ">508<=762": {
                average: 40,
                single: 30
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 54,
                single: 41
              },
              ">1422<=2134": {
                average: 68,
                single: 51
              }
            }
          }
        }]
      },
      {
        id: 26,
        value: "X80M",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 40,
                single: 30
              },
              ">508<=762": {
                average: 40,
                single: 30
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 54,
                single: 41
              },
              ">1422<=2134": {
                average: 68,
                single: 51
              }
            }
          }
        }]
      },
      {
        id: 27,
        value: "X90Q",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 40,
                single: 30
              },
              ">508<=762": {
                average: 40,
                single: 30
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 54,
                single: 41
              },
              ">1422<=2134": {
                average: 81,
                single: 61
              }
            }
          }
        }]
      },
      {
        id: 28,
        value: "X90M",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 40,
                single: 30
              },
              ">508<=762": {
                average: 40,
                single: 30
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 54,
                single: 41
              },
              ">1422<=2134": {
                average: 81,
                single: 61
              }
            }
          }
        }]
      },
      {
        id: 29,
        value: "X100Q",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 40,
                single: 30
              },
              ">508<=762": {
                average: 40,
                single: 30
              },
              ">762<=914": {
                average: 54,
                single: 41
              },
              ">914<=1219": {
                average: 54,
                single: 41
              },
              ">1219<=1422": {
                average: 68,
                single: 51
              },
              ">1422<=2134": {
                average: 95,
                single: 71
              }
            }
          }
        }]
      },
      {
        id: 30,
        value: "X100M",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 40,
                single: 30
              },
              ">508<=762": {
                average: 40,
                single: 30
              },
              ">762<=914": {
                average: 54,
                single: 41
              },
              ">914<=1219": {
                average: 54,
                single: 41
              },
              ">1219<=1422": {
                average: 68,
                single: 51
              },
              ">1422<=2134": {
                average: 95,
                single: 71
              }
            }
          }
        }]
      },
      {
        id: 31,
        value: "X120M",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 40,
                single: 30
              },
              ">508<=762": {
                average: 40,
                single: 30
              },
              ">762<=914": {
                average: 54,
                single: 41
              },
              ">914<=1219": {
                average: 68,
                single: 51
              },
              ">1219<=1422": {
                average: 81,
                single: 61
              },
              ">1422<=2134": {
                average: 108,
                single: 81
              }
            }
          }
        }]
      },
      {
        id: 32,
        value: "BNS",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 33,
        value: "BNO",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 34,
        value: "BQS",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 35,
        value: "BQO",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 36,
        value: "BMS",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 37,
        value: "BMO",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 38,
        value: "X42NS",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 39,
        value: "X42NO",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 40,
        value: "X42QS",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 41,
        value: "X42QO",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 42,
        value: "X42MS",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 43,
        value: "X42MO",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 44,
        value: "X46NS",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 45,
        value: "X46NO",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 46,
        value: "X46QS",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 47,
        value: "X46QO",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 48,
        value: "X46MS",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 49,
        value: "X46MO",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 50,
        value: "X52NS",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 51,
        value: "X52NO",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 52,
        value: "X52QS",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 53,
        value: "X52QO",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 54,
        value: "X52MS",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 55,
        value: "X52MS",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 56,
        value: "X56QS",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 57,
        value: "X56QO",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 58,
        value: "X56MS",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 59,
        value: "X56MO",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 60,
        value: "X60QS",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 61,
        value: "X60QO",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 62,
        value: "X60MS",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 63,
        value: "X60MO",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 40,
                single: 30
              },
              ">1422<=2134": {
                average: 40,
                single: 30
              }
            }
          }
        }]
      },
      {
        id: 64,
        value: "X65QS",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 54,
                single: 41
              },
              ">1422<=2134": {
                average: 54,
                single: 41
              }
            }
          }
        }]
      },
      {
        id: 65,
        value: "X65QO",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 54,
                single: 41
              },
              ">1422<=2134": {
                average: 54,
                single: 41
              }
            }
          }
        }]
      },
      {
        id: 66,
        value: "X65QS/X65QO",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 54,
                single: 41
              },
              ">1422<=2134": {
                average: 54,
                single: 41
              }
            }
          }
        }]
      },
      {
        id: 67,
        value: "X65MS",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 54,
                single: 41
              },
              ">1422<=2134": {
                average: 54,
                single: 41
              }
            }
          }
        }]
      },
      {
        id: 68,
        value: "X65MO",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 54,
                single: 41
              },
              ">1422<=2134": {
                average: 54,
                single: 41
              }
            }
          }
        }]
      },
      {
        id: 69,
        value: "X70QS",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 54,
                single: 41
              },
              ">1422<=2134": {
                average: 68,
                single: 51
              }
            }
          }
        }]
      },
      {
        id: 70,
        value: "X70QO",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 54,
                single: 41
              },
              ">1422<=2134": {
                average: 68,
                single: 51
              }
            }
          }
        }]
      },
      {
        id: 71,
        value: "X70MS",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 54,
                single: 41
              },
              ">1422<=2134": {
                average: 68,
                single: 51
              }
            }
          }
        }]
      },
      {
        id: 72,
        value: "X70MO",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 27,
                single: 20
              },
              ">508<=762": {
                average: 27,
                single: 20
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 54,
                single: 41
              },
              ">1422<=2134": {
                average: 68,
                single: 51
              }
            }
          }
        }]
      },
      {
        id: 73,
        value: "X80QO",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 40,
                single: 30
              },
              ">508<=762": {
                average: 40,
                single: 30
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 54,
                single: 41
              },
              ">1422<=2134": {
                average: 68,
                single: 51
              }
            }
          }
        }]
      },
      {
        id: 74,
        value: "X80MO",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 40,
                single: 30
              },
              ">508<=762": {
                average: 40,
                single: 30
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 54,
                single: 41
              },
              ">1422<=2134": {
                average: 68,
                single: 51
              }
            }
          }
        }]
      },
      {
        id: 75,
        value: "X90QO",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 40,
                single: 30
              },
              ">508<=762": {
                average: 40,
                single: 30
              },
              ">762<=914": {
                average: 40,
                single: 30
              },
              ">914<=1219": {
                average: 40,
                single: 30
              },
              ">1219<=1422": {
                average: 54,
                single: 41
              },
              ">1422<=2134": {
                average: 81,
                single: 61
              }
            }
          }
        }]
      },
      {
        id: 76,
        value: "X100QO",
        temperature: [{
          value: "0",
          data: {
            orientation: "Longitudinal",
            diameterDependantValues: {
              "<=508": {
                average: 40,
                single: 30
              },
              ">508<=762": {
                average: 40,
                single: 30
              },
              ">762<=914": {
                average: 54,
                single: 41
              },
              ">914<=1219": {
                average: 54,
                single: 41
              },
              ">1219<=1422": {
                average: 68,
                single: 51
              },
              ">1422<=2134": {
                average: 95,
                single: 71
              }
            }
          }
        }]
      },
    ]
  },
  {
    id: 4,
    value: "ASTM A333 (2018)",
    grade: [
      {
        id: 1,
        value: "1",
        temperature: [
          {
            value: "-45",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 14,
                  average: 18
                },
                "7.5": {
                  single: 11,
                  average: 14
                },
                "6.67": {
                  single: 9,
                  average: 12
                },
                "5": {
                  single: 7,
                  average: 9
                },
                "3.33": {
                  single: 4,
                  average: 7
                },
                "2.5": {
                  single: 4,
                  average: 5
                }
              }
            }
          }
        ]
      },
      {
        id: 2,
        value: "3",
        temperature: [
          {
            value: "-100",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 14,
                  average: 18
                },
                "7.5": {
                  single: 11,
                  average: 14
                },
                "6.67": {
                  single: 9,
                  average: 12
                },
                "5": {
                  single: 7,
                  average: 9
                },
                "3.33": {
                  single: 4,
                  average: 7
                },
                "2.5": {
                  single: 4,
                  average: 5
                }
              }
            }
          }
        ]
      },
      {
        id: 3,
        value: "4",
        temperature: [
          {
            value: "-100",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 14,
                  average: 18
                },
                "7.5": {
                  single: 11,
                  average: 14
                },
                "6.67": {
                  single: 9,
                  average: 12
                },
                "5": {
                  single: 7,
                  average: 9
                },
                "3.33": {
                  single: 4,
                  average: 7
                },
                "2.5": {
                  single: 4,
                  average: 5
                }
              }
            }
          }
        ]
      },
      {
        id: 4,
        value: "6",
        temperature: [
          {
            value: "-45",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 14,
                  average: 18
                },
                "7.5": {
                  single: 11,
                  average: 14
                },
                "6.67": {
                  single: 9,
                  average: 12
                },
                "5": {
                  single: 7,
                  average: 9
                },
                "3.33": {
                  single: 4,
                  average: 7
                },
                "2.5": {
                  single: 4,
                  average: 5
                }
              }
            }
          }
        ]
      },
      {
        id: 5,
        value: "7",
        temperature: [
          {
            value: "-75",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 14,
                  average: 18
                },
                "7.5": {
                  single: 11,
                  average: 14
                },
                "6.67": {
                  single: 9,
                  average: 12
                },
                "5": {
                  single: 7,
                  average: 9
                },
                "3.33": {
                  single: 4,
                  average: 7
                },
                "2.5": {
                  single: 4,
                  average: 5
                }
              }
            }
          }
        ]
      },
      {
        id: 6,
        value: "8",
        temperature: [
          {
            value: "-195",
            data: {
              orientation: "Longitudinal",
              singleLateralExpansion: 0.38,
              averageLateralExpansion: 0.38
            }
          }
        ]
      },
      {
        id: 7,
        value: "9",
        temperature: [
          {
            value: "-75",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 14,
                  average: 18
                },
                "7.5": {
                  single: 11,
                  average: 14
                },
                "6.67": {
                  single: 9,
                  average: 12
                },
                "5": {
                  single: 7,
                  average: 9
                },
                "3.33": {
                  single: 4,
                  average: 7
                },
                "2.5": {
                  single: 4,
                  average: 5
                }
              }
            }
          }
        ]
      },
      {
        id: 8,
        value: "10",
        temperature: [
          {
            value: "-60",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 14,
                  average: 18
                },
                "7.5": {
                  single: 11,
                  average: 14
                },
                "6.67": {
                  single: 9,
                  average: 12
                },
                "5": {
                  single: 7,
                  average: 9
                },
                "3.33": {
                  single: 4,
                  average: 7
                },
                "2.5": {
                  single: 4,
                  average: 5
                }
              }
            }
          }
        ]
      },
      {
        id: 9,
        value: "11",
        temperature: [
          {
            value: "-195",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 14,
                  average: 18
                },
                "7.5": {
                  single: 11,
                  average: 14
                },
                "6.67": {
                  single: 9,
                  average: 12
                },
                "5": {
                  single: 7,
                  average: 9
                },
                "3.33": {
                  single: 4,
                  average: 7
                },
                "2.5": {
                  single: 4,
                  average: 5
                }
              }
            }
          }
        ]
      }
    ]
  },
  {
    id: 5,
    value: "ASTM A860 (2018)",
    grade: [
      {
        id: 1,
        value: "WPHY42",
        temperature: [
          {
            value: "-46",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 34,
                  average: 40,
                  singleLateralExpansion: 0.64,
                  averageLateralExpansion: 0.64
                },
                "7.5": {
                  single: 28,
                  average: 34,
                  singleLateralExpansion: 0.53,
                  averageLateralExpansion: 0.53
                },
                "5": {
                  single: 23,
                  average: 27,
                  singleLateralExpansion: 0.33,
                  averageLateralExpansion: 0.33
                }
              },
              average: 40,
              single: 34
            }
          }
        ]
      },
      {
        id: 2,
        value: "WPHY46",
        temperature: [
          {
            value: "-46",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 34,
                  average: 40,
                  singleLateralExpansion: 0.64,
                  averageLateralExpansion: 0.64
                },
                "7.5": {
                  single: 28,
                  average: 34,
                  singleLateralExpansion: 0.53,
                  averageLateralExpansion: 0.53
                },
                "5": {
                  single: 23,
                  average: 27,
                  singleLateralExpansion: 0.33,
                  averageLateralExpansion: 0.33
                }
              },
              average: 40,
              single: 34
            }
          }
        ]
      },
      {
        id: 3,
        value: "WPHY52",
        temperature: [
          {
            value: "-46",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 34,
                  average: 40,
                  singleLateralExpansion: 0.64,
                  averageLateralExpansion: 0.64
                },
                "7.5": {
                  single: 28,
                  average: 34,
                  singleLateralExpansion: 0.53,
                  averageLateralExpansion: 0.53
                },
                "5": {
                  single: 23,
                  average: 27,
                  singleLateralExpansion: 0.33,
                  averageLateralExpansion: 0.33
                }
              },
              average: 40,
              single: 34
            }
          }
        ]
      },
      {
        id: 4,
        value: "WPHY60",
        temperature: [
          {
            value: "-46",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 34,
                  average: 40,
                  singleLateralExpansion: 0.64,
                  averageLateralExpansion: 0.64
                },
                "7.5": {
                  single: 28,
                  average: 34,
                  singleLateralExpansion: 0.53,
                  averageLateralExpansion: 0.53
                },
                "5": {
                  single: 23,
                  average: 27,
                  singleLateralExpansion: 0.33,
                  averageLateralExpansion: 0.33
                }
              },
              average: 40,
              single: 34
            }
          }
        ]
      },
      {
        id: 5,
        value: "WPHY65",
        temperature: [
          {
            value: "-46",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 34,
                  average: 40,
                  singleLateralExpansion: 0.64,
                  averageLateralExpansion: 0.64
                },
                "7.5": {
                  single: 28,
                  average: 34,
                  singleLateralExpansion: 0.53,
                  averageLateralExpansion: 0.53
                },
                "5": {
                  single: 23,
                  average: 27,
                  singleLateralExpansion: 0.33,
                  averageLateralExpansion: 0.33
                }
              },
              average: 40,
              single: 34
            }
          }
        ]
      },
      {
        id: 6,
        value: "WPHY70",
        temperature: [
          {
            value: "-46",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 34,
                  average: 40,
                  singleLateralExpansion: 0.64,
                  averageLateralExpansion: 0.64
                },
                "7.5": {
                  single: 28,
                  average: 34,
                  singleLateralExpansion: 0.53,
                  averageLateralExpansion: 0.53
                },
                "5": {
                  single: 23,
                  average: 27,
                  singleLateralExpansion: 0.33,
                  averageLateralExpansion: 0.33
                }
              },
              average: 40,
              single: 34
            }
          }
        ]
      }
    ]
  },
  {
    id: 6,
    value: "EN 10025-2 (2019)",
    grade: [
      {
        id: 1,
        value: "S235JR",
        temperature: [
          {
            value: "20",
            data: {
              orientation: "Longitudinal",
              average: 27,
              single: 27
            }
          }
        ]
      },
      {
        id: 2,
        value: "S235J0",
        temperature: [
          {
            value: "0",
            data: {
              orientation: "Longitudinal",
              average: 27,
              single: 27
            }
          }
        ]
      },
      {
        id: 3,
        value: "S235J2",
        temperature: [
          {
            value: "-20",
            data: {
              orientation: "Longitudinal",
              average: 27,
              single: 27
            }
          }
        ]
      },
      {
        id: 4,
        value: "S275JR",
        temperature: [
          {
            value: "20",
            data: {
              orientation: "Longitudinal",
              average: 27,
              single: 27
            }
          }
        ]
      },
      {
        id: 4,
        value: "S275J0",
        temperature: [
          {
            value: "0",
            data: {
              orientation: "Longitudinal",
              average: 27,
              single: 27
            }
          }
        ]
      },
      {
        id: 4,
        value: "S275J2",
        temperature: [
          {
            value: "-20",
            data: {
              orientation: "Longitudinal",
              average: 27,
              single: 27
            }
          }
        ]
      },
      {
        id: 5,
        value: "S355JR",
        temperature: [
          {
            value: "20",
            data: {
              orientation: "Longitudinal",
              average: 27,
              single: 27
            }
          }
        ]
      },
      {
        id: 6,
        value: "S355J0",
        temperature: [
          {
            value: "0",
            data: {
              orientation: "Longitudinal",
              average: 27,
              single: 27
            }
          }
        ]
      },
      {
        id: 7,
        value: "S355J2",
        temperature: [
          {
            value: "-20",
            data: {
              orientation: "Longitudinal",
              average: 27,
              single: 27
            }
          }
        ]
      },
      {
        id: 8,
        value: "S355K2",
        temperature: [
          {
            value: "-20",
            data: {
              orientation: "Longitudinal",
              thicknessDependantValues: {
                "<=150": {
                  single: 40,
                  average: 40
                },
                ">150<=250": {
                  single: 33,
                  average: 33
                },
                ">250<=400": {
                  single: 33,
                  average: 33
                },
              }
            }
          }
        ]
      },
      {
        id: 9,
        value: "S460JR",
        temperature: [
          {
            value: "20",
            data: {
              orientation: "Longitudinal",
              thicknessDependantValues: {
                "<=150": {
                  single: 27,
                  average: 27
                }
              }
            }
          }
        ]
      },
      {
        id: 10,
        value: "S460J0",
        temperature: [
          {
            value: "0",
            data: {
              orientation: "Longitudinal",
              thicknessDependantValues: {
                "<=150": {
                  single: 27,
                  average: 27
                }
              }
            }
          }
        ]
      },
      {
        id: 11,
        value: "S460J2",
        temperature: [
          {
            value: "-20",
            data: {
              orientation: "Longitudinal",
              thicknessDependantValues: {
                "<=150": {
                  single: 27,
                  average: 27
                }
              }
            }
          }
        ]
      },
      {
        id: 12,
        value: "S460K2",
        temperature: [
          {
            value: "20",
            data: {
              orientation: "Longitudinal",
              thicknessDependantValues: {
                "<=150": {
                  single: 40,
                  average: 40
                }
              }
            }
          }
        ]
      },
      {
        id: 13,
        value: "S500J0",
        temperature: [
          {
            value: "0",
            data: {
              orientation: "Longitudinal",
              thicknessDependantValues: {
                "<=150": {
                  single: 27,
                  average: 27
                }
              }
            }
          }
        ]
      }
    ]
  },
  {
    id: 7,
    value: "ASTM A182 (2021)",
    grade: [
      {
        id: 1,
        value: "F3V",
        temperature: [
          {
            value: "0",
            data: {
              orientation: "Longitudinal",
              average: 54,
              single: 40
            }
          }
        ]
      },
      {
        id: 2,
        value: "F3VCb",
        temperature: [
          {
            value: "0",
            data: {
              orientation: "Longitudinal",
              average: 54,
              single: 40
            }
          }
        ]
      },
      {
        id: 3,
        value: "F22V",
        temperature: [
          {
            value: "0",
            data: {
              orientation: "Longitudinal",
              average: 54,
              single: 40
            }
          }
        ]
      }
    ]
  },
  {
    id: 8,
    value: "ASTM A240 (2020a)",
    grade: [
      {
        id: 1,
        value: "S31254",
        temperature: [
          {
            value: "",
            data: {
              orientation: "Longitudinal",
              singleLateralExpansion: 0.38,
              averageLateralExpansion: 0.38
            }
          }
        ]
      },
      {
        id: 2,
        value: "S31266",
        temperature: [
          {
            value: "",
            data: {
              orientation: "Longitudinal",
              singleLateralExpansion: 0.38,
              averageLateralExpansion: 0.38
            }
          }
        ]
      },
      {
        id: 3,
        value: "S31803",
        temperature: [
          {
            value: "",
            data: {
              orientation: "Longitudinal",
              singleLateralExpansion: 0.38,
              averageLateralExpansion: 0.38
            }
          }
        ]
      }
    ]
  },
  {
    id: 9,
    value: "ASTM A420 (2020)",
    grade: [
      {
        id: 1,
        value: "WPL6",
        temperature: [
          {
            value: "-45",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 14,
                  average: 18
                },
                "7.5": {
                  single: 11,
                  average: 14
                },
                "5": {
                  single: 7,
                  average: 9
                },
                "2.5": {
                  single: 4,
                  average: 5
                }
              },
              average: 18,
              single: 14
            }
          }
        ]
      }
    ]
  },
  {
    id: 10,
    value: "ASTM A707 (2019)",
    grade: [
      {
        id: 1,
        value: "L1 Class 1",
        temperature: [
          {
            value: "-29",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 33,
                  average: 41
                },
                "7.5": {
                  single: 28,
                  average: 34
                },
                "5": {
                  single: 22,
                  average: 27
                }
              },
              average: 41,
              single: 33
            }
          }
        ]
      },
      {
        id: 2,
        value: "L1 Class 2",
        temperature: [
          {
            value: "-29",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 43,
                  average: 54
                },
                "7.5": {
                  single: 36,
                  average: 45
                },
                "5": {
                  single: 29,
                  average: 36
                }
              },
              average: 54,
              single: 43
            }
          }
        ]
      },
      {
        id: 3,
        value: "L1 Class 3",
        temperature: [
          {
            value: "-29",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 54,
                  average: 68
                },
                "7.5": {
                  single: 45,
                  average: 57
                },
                "5": {
                  single: 36,
                  average: 45
                }
              },
              average: 68,
              single: 54
            }
          }
        ]
      },
      {
        id: 4,
        value: "L1 Class 4",
        temperature: [
          {
            value: "-29",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 54,
                  average: 68
                },
                "7.5": {
                  single: 45,
                  average: 57
                },
                "5": {
                  single: 36,
                  average: 45
                }
              },
              average: 68,
              single: 54
            }
          }
        ]
      },
      {
        id: 5,
        value: "L2 Class 1",
        temperature: [
          {
            value: "-46",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 33,
                  average: 41
                },
                "7.5": {
                  single: 28,
                  average: 34
                },
                "5": {
                  single: 22,
                  average: 27
                }
              },
              average: 41,
              single: 33
            }
          }
        ]
      },
      {
        id: 6,
        value: "L2 Class 2",
        temperature: [
          {
            value: "-46",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 43,
                  average: 54
                },
                "7.5": {
                  single: 36,
                  average: 45
                },
                "5": {
                  single: 29,
                  average: 36
                }
              },
              average: 54,
              single: 43
            }
          }
        ]
      },
      {
        id: 7,
        value: "L2 Class 3",
        temperature: [
          {
            value: "-46",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 54,
                  average: 68
                },
                "7.5": {
                  single: 45,
                  average: 57
                },
                "5": {
                  single: 36,
                  average: 45
                }
              },
              average: 68,
              single: 54
            }
          }
        ]
      },
      {
        id: 8,
        value: "L2 Class 4",
        temperature: [
          {
            value: "-46",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 54,
                  average: 68
                },
                "7.5": {
                  single: 45,
                  average: 57
                },
                "5": {
                  single: 36,
                  average: 45
                }
              },
              average: 68,
              single: 54
            }
          }
        ]
      },
      {
        id: 9,
        value: "L3 Class 1",
        temperature: [
          {
            value: "-46",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 33,
                  average: 41
                },
                "7.5": {
                  single: 28,
                  average: 34
                },
                "5": {
                  single: 22,
                  average: 27
                }
              },
              average: 41,
              single: 33
            }
          }
        ]
      },
      {
        id: 10,
        value: "L3 Class 2",
        temperature: [
          {
            value: "-46",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 43,
                  average: 54
                },
                "7.5": {
                  single: 36,
                  average: 45
                },
                "5": {
                  single: 29,
                  average: 36
                }
              },
              average: 54,
              single: 43
            }
          }
        ]
      },
      {
        id: 11,
        value: "L3 Class 3",
        temperature: [
          {
            value: "-46",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 54,
                  average: 68
                },
                "7.5": {
                  single: 45,
                  average: 57
                },
                "5": {
                  single: 36,
                  average: 45
                }
              },
              average: 68,
              single: 54
            }
          }
        ]
      },
      {
        id: 12,
        value: "L3 Class 4",
        temperature: [
          {
            value: "-46",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 54,
                  average: 68
                },
                "7.5": {
                  single: 45,
                  average: 57
                },
                "5": {
                  single: 36,
                  average: 45
                }
              },
              average: 68,
              single: 54
            }
          }
        ]
      },
      {
        id: 13,
        value: "L4 Class 1",
        temperature: [
          {
            value: "-62",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 33,
                  average: 41
                },
                "7.5": {
                  single: 28,
                  average: 34
                },
                "5": {
                  single: 22,
                  average: 27
                }
              },
              average: 41,
              single: 33
            }
          }
        ]
      },
      {
        id: 14,
        value: "L4 Class 2",
        temperature: [
          {
            value: "-62",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 43,
                  average: 54
                },
                "7.5": {
                  single: 36,
                  average: 45
                },
                "5": {
                  single: 29,
                  average: 36
                }
              },
              average: 54,
              single: 43
            }
          }
        ]
      },
      {
        id: 15,
        value: "L4 Class 3",
        temperature: [
          {
            value: "-62",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 54,
                  average: 68
                },
                "7.5": {
                  single: 45,
                  average: 57
                },
                "5": {
                  single: 36,
                  average: 45
                }
              },
              average: 68,
              single: 54
            }
          }
        ]
      },
      {
        id: 16,
        value: "L4 Class 4",
        temperature: [
          {
            value: "-62",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 54,
                  average: 68
                },
                "7.5": {
                  single: 45,
                  average: 57
                },
                "5": {
                  single: 36,
                  average: 45
                }
              },
              average: 68,
              single: 54
            }
          }
        ]
      },
      {
        id: 17,
        value: "L5 Class 1",
        temperature: [
          {
            value: "-62",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 33,
                  average: 41
                },
                "7.5": {
                  single: 28,
                  average: 34
                },
                "5": {
                  single: 22,
                  average: 27
                }
              },
              average: 41,
              single: 33
            }
          }
        ]
      },
      {
        id: 18,
        value: "L5 Class 2",
        temperature: [
          {
            value: "-62",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 43,
                  average: 54
                },
                "7.5": {
                  single: 36,
                  average: 45
                },
                "5": {
                  single: 29,
                  average: 36
                }
              },
              average: 54,
              single: 43
            }
          }
        ]
      },
      {
        id: 19,
        value: "L5 Class 3",
        temperature: [
          {
            value: "-62",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 54,
                  average: 68
                },
                "7.5": {
                  single: 45,
                  average: 57
                },
                "5": {
                  single: 36,
                  average: 45
                }
              },
              average: 68,
              single: 54
            }
          }
        ]
      },
      {
        id: 20,
        value: "L5 Class 4",
        temperature: [
          {
            value: "-62",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 54,
                  average: 68
                },
                "7.5": {
                  single: 45,
                  average: 57
                },
                "5": {
                  single: 36,
                  average: 45
                }
              },
              average: 68,
              single: 54
            }
          }
        ]
      },
      {
        id: 21,
        value: "L6 Class 1",
        temperature: [
          {
            value: "-62",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 33,
                  average: 41
                },
                "7.5": {
                  single: 28,
                  average: 34
                },
                "5": {
                  single: 22,
                  average: 27
                }
              },
              average: 41,
              single: 33
            }
          }
        ]
      },
      {
        id: 22,
        value: "L6 Class 2",
        temperature: [
          {
            value: "-62",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 43,
                  average: 54
                },
                "7.5": {
                  single: 36,
                  average: 45
                },
                "5": {
                  single: 29,
                  average: 36
                }
              },
              average: 54,
              single: 43
            }
          }
        ]
      },
      {
        id: 23,
        value: "L6 Class 3",
        temperature: [
          {
            value: "-62",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 54,
                  average: 68
                },
                "7.5": {
                  single: 45,
                  average: 57
                },
                "5": {
                  single: 36,
                  average: 45
                }
              },
              average: 68,
              single: 54
            }
          }
        ]
      },
      {
        id: 24,
        value: "L6 Class 4",
        temperature: [
          {
            value: "-62",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 54,
                  average: 68
                },
                "7.5": {
                  single: 45,
                  average: 57
                },
                "5": {
                  single: 36,
                  average: 45
                }
              },
              average: 68,
              single: 54
            }
          }
        ]
      },
      {
        id: 25,
        value: "L7 Class 1",
        temperature: [
          {
            value: "-73",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 33,
                  average: 41
                },
                "7.5": {
                  single: 28,
                  average: 34
                },
                "5": {
                  single: 22,
                  average: 27
                }
              },
              average: 41,
              single: 33
            }
          }
        ]
      },
      {
        id: 26,
        value: "L7 Class 2",
        temperature: [
          {
            value: "-73",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 43,
                  average: 54
                },
                "7.5": {
                  single: 36,
                  average: 45
                },
                "5": {
                  single: 29,
                  average: 36
                }
              },
              average: 54,
              single: 43
            }
          }
        ]
      },
      {
        id: 27,
        value: "L7 Class 3",
        temperature: [
          {
            value: "-73",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 54,
                  average: 68
                },
                "7.5": {
                  single: 45,
                  average: 57
                },
                "5": {
                  single: 36,
                  average: 45
                }
              },
              average: 68,
              single: 54
            }
          }
        ]
      },
      {
        id: 28,
        value: "L7 Class 4",
        temperature: [
          {
            value: "-73",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 54,
                  average: 68
                },
                "7.5": {
                  single: 45,
                  average: 57
                },
                "5": {
                  single: 36,
                  average: 45
                }
              },
              average: 68,
              single: 54
            }
          }
        ]
      },
      {
        id: 29,
        value: "L8 Class 1",
        temperature: [
          {
            value: "-73",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 33,
                  average: 41
                },
                "7.5": {
                  single: 28,
                  average: 34
                },
                "5": {
                  single: 22,
                  average: 27
                }
              },
              average: 41,
              single: 33
            }
          }
        ]
      },
      {
        id: 30,
        value: "L8 Class 2",
        temperature: [
          {
            value: "-73",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 43,
                  average: 54
                },
                "7.5": {
                  single: 36,
                  average: 45
                },
                "5": {
                  single: 29,
                  average: 36
                }
              },
              average: 54,
              single: 43
            }
          }
        ]
      },
      {
        id: 31,
        value: "L8 Class 3",
        temperature: [
          {
            value: "-73",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 54,
                  average: 68
                },
                "7.5": {
                  single: 45,
                  average: 57
                },
                "5": {
                  single: 36,
                  average: 45
                }
              },
              average: 68,
              single: 54
            }
          }
        ]
      },
      {
        id: 32,
        value: "L8 Class 4",
        temperature: [
          {
            value: "-73",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 54,
                  average: 68
                },
                "7.5": {
                  single: 45,
                  average: 57
                },
                "5": {
                  single: 36,
                  average: 45
                }
              },
              average: 68,
              single: 54
            }
          }
        ]
      }
    ]
  },
  {
    id: 10,
    value: "ASTM A589 (2014)",
    grade: [
      {
        id: 1,
        value: "A Class 1",
        temperature: [
          {
            value: "-45",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 20,
                  average: 27
                },
                "7.5": {
                  single: 17,
                  average: 23
                },
                "5": {
                  single: 13,
                  average: 18
                }
              },
              average: 27,
              single: 20
            }
          }
        ]
      },
      {
        id: 2,
        value: "A Class 2",
        temperature: [
          {
            value: "-45",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 20,
                  average: 27
                },
                "7.5": {
                  single: 17,
                  average: 23
                },
                "5": {
                  single: 13,
                  average: 18
                }
              },
              average: 27,
              single: 20
            }
          }
        ]
      },
      {
        id: 3,
        value: "B",
        temperature: [
          {
            value: "18",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 100,
                  average: 110
                },
                "7.5": {
                  single: 83,
                  average: 92
                },
                "5": {
                  single: 67,
                  average: 73
                }
              },
              average: 110,
              single: 100
            }
          },
          {
            value: "-85",
            data: {
              orientation: "Longitudinal",
              dependantValues: {
                "10": {
                  single: 75,
                  average: 80
                },
                "7.5": {
                  single: 63,
                  average: 67
                },
                "5": {
                  single: 50,
                  average: 53
                }
              },
              average: 80,
              single: 75
            }
          },
        ]
      }
    ]
  },
  {
    id: 11,
    value: "EN 10222-2 (2017)",
    grade: [
      {
        id: 1,
        value: "11CrMo9-10",
        temperature: [
          {
            value: "20",
            data: {
              orientationDependantValues: {
                "Longitudinal": {
                  thicknessDependantValues: {
                    "<=200": {
                      single: 40,
                      average: 60
                    },
                    ">200<=500": {
                      single: 40,
                      average: 50
                    }
                  }
                },
                "Transversal": {
                  thicknessDependantValues: {
                    "<=200": {
                      single: 27,
                      average: 50
                    },
                    ">200<=500": {
                      single: 27,
                      average: 34
                    }
                  }
                },
              }
            }
          }
        ]
      },
      {
        id: 2,
        value: "13CrMo4-5",
        temperature: [
          {
            value: "20",
            data: {
              orientationDependantValues: {
                "Longitudinal": {
                  thicknessDependantValues: {
                    "<=35": {
                      single: 44,
                      average: 44
                    },
                    ">35<=70": {
                      single: 44,
                      average: 44
                    },
                    ">70<=100": {
                      single: 44,
                      average: 44
                    },
                    ">100<=250": {
                      single: 44,
                      average: 44
                    },
                    ">250<=500": {
                      single: 44,
                      average: 44
                    },
                  }
                },
                "Transversal": {
                  thicknessDependantValues: {
                    "<=35": {
                      single: 27,
                      average: 27
                    },
                    ">35<=70": {
                      single: 27,
                      average: 27
                    },
                    ">70<=100": {
                      single: 27,
                      average: 27
                    },
                    ">100<=250": {
                      single: 27,
                      average: 27
                    },
                    ">250<=500": {
                      single: 27,
                      average: 27
                    },
                  }
                },
              }
            }
          }
        ]
      },
      {
        id: 3,
        value: "16Mo3",
        temperature: [
          {
            value: "20",
            data: {
              orientationDependantValues: {
                "Longitudinal": {
                  thicknessDependantValues: {
                    "<=35": {
                      single: 50,
                      average: 50
                    },
                    ">35<=70": {
                      single: 50,
                      average: 50
                    },
                    ">70<=100": {
                      single: 50,
                      average: 50
                    },
                    ">100<=250": {
                      single: 50,
                      average: 50
                    },
                    ">250<=500": {
                      single: 50,
                      average: 50
                    },
                  }
                },
                "Transversal": {
                  thicknessDependantValues: {
                    "<=35": {
                      single: 31,
                      average: 31
                    },
                    ">35<=70": {
                      single: 31,
                      average: 31
                    },
                    ">70<=100": {
                      single: 31,
                      average: 31
                    },
                    ">100<=250": {
                      single: 31,
                      average: 31
                    },
                    ">250<=500": {
                      single: 31,
                      average: 31
                    },
                  }
                },
              }
            }
          }
        ]
      },
      {
        id: 4,
        value: "P245GH",
        temperature: [
          {
            value: "20",
            data: {
              orientationDependantValues: {
                "Longitudinal": {
                  thicknessDependantValues: {
                    "<=35": {
                      single: 40,
                      average: 40
                    },
                    ">35<=60": {
                      single: 40,
                      average: 40
                    },
                    ">60<=160": {
                      single: 40,
                      average: 40
                    }
                  }
                },
                "Transversal": {
                  thicknessDependantValues: {
                    "<=35": {
                      single: 27,
                      average: 27
                    },
                    ">35<=60": {
                      single: 27,
                      average: 27
                    },
                    ">60<=160": {
                      single: 27,
                      average: 27
                    }
                  }
                }
              }
            }
          }
        ]
      },
      {
        id: 5,
        value: "P280GH",
        temperature: [
          {
            value: "20",
            data: {
              orientationDependantValues: {
                "Longitudinal": {
                  thicknessDependantValues: {
                    "<=35": {
                      single: 48,
                      average: 48
                    },
                    ">35<=160": {
                      single: 48,
                      average: 48
                    }
                  }
                },
                "Transversal": {
                  thicknessDependantValues: {
                    "<=35": {
                      single: 27,
                      average: 27
                    },
                    ">35<=160": {
                      single: 27,
                      average: 27
                    }
                  }
                }
              }
            }
          }
        ]
      },
      {
        id: 6,
        value: "X20CrMov11-1",
        temperature: [
          {
            value: "20",
            data: {
              orientationDependantValues: {
                "Longitudinal": {
                  thicknessDependantValues: {
                    "<=100": {
                      single: 39,
                      average: 39
                    },
                    ">100<=250": {
                      single: 31,
                      average: 31
                    },
                    ">250<=330": {
                      single: 27,
                      average: 27
                    },
                  }
                },
                "Transversal": {
                  thicknessDependantValues: {
                    "<=100": {
                      single: 27,
                      average: 27
                    },
                    ">100<=250": {
                      single: 27,
                      average: 27
                    },
                    ">250<=330": {
                      single: 27,
                      average: 27
                    }
                  }
                }
              }
            }
          }
        ]
      }
    ]
  },
  {
    id: 12,
    value: "VDTUV 377-3 (2010)",
    grade: [
      {
        id: 1,
        value: "15NiCuMoNb5-6-4",
        temperature: [
          {
            value: "20",
            data: {
              orientationDependantValues: {
                "Longitudinal": {
                  single: 48,
                  average: 48
                },
                "Transversal": {
                  single: 40,
                  average: 40
                }
              }
            }
          },
          {
            value: "0",
            data: {
              orientationDependantValues: {
                "Longitudinal": {
                  single: 39,
                  average: 39
                },
                "Transversal": {
                  single: 31,
                  average: 31
                }
              }
            }
          }
        ]
      }
    ]
  },
  {
    id: 13,
    value: "CSA Z245.1 (2018)",
    grade: [
      {
        id: 1,
        value: "359 Category II - SS M18C",
      }
    ]
  },
];