import React from "react";
import {Grid} from "@mui/material";
import {withStyles} from "tss-react/mui";
import useStores from "../../../useStores";
import styles from "./styles";
import {STATUSES} from "@core/constants/test";
import {WITNESS_STATUSES} from "@core/constants/witnessStatuses";

const getPureText = (html) => {
  const tmp = document.createElement("div");
  tmp.innerHTML = html;

  return tmp.textContent || tmp.innerText || "";
};

const InspectorNotes = ({test, inspectorNotesStyles, classes}) => {
  const {TestStore} = useStores();

  const isShown = [STATUSES.INSPECTED, STATUSES.APPROVED].includes(test.status);

  const witness = test.witnesses?.find((witness) => witness.status !== WITNESS_STATUSES.UNSET);

  if (!isShown) return null;

  return (
    <>
      {(test.status === STATUSES.APPROVED && witness) && (
        <Grid item xs={12} container alignItems="center" className={inspectorNotesStyles}>
          <h4>Inspection result:</h4>
          <p>{witness.status === WITNESS_STATUSES.ACCEPTED ? "Inspection passed" : "Inspection not passed"}</p>
        </Grid>
      )}
      {(test.witnesses?.some((witness) => getPureText(witness.notes))) && (
        <Grid item xs={12} container alignItems="center" className={inspectorNotesStyles}>
          <h4>Inspection notes:</h4>
          {test.witnesses?.map((witness) => getPureText(witness.notes) && (
            <p dangerouslySetInnerHTML={{__html: witness.notes}} />
          ))}
        </Grid>
      )}
      {(test.witnesses?.some((witness) => witness.files?.length)) && (
        <Grid item xs={12}>
          <h3
            className={classes.inspectorFiles}
            onClick={() => TestStore.downloadWitnessFiles(test._id)}
          >
            Inspection files
          </h3>
        </Grid>
      )}
    </>
  );
};

export default withStyles(InspectorNotes, styles);