import {STATUSES, TYPES} from "@core/constants/test";
import CertificateBCService from "../../Blockchain/certificates-service";
import CertificateDBService from "Blockchain/certificates-service";
import utils from "Blockchain/utils";
import userService from "@core/api/user-service";
import referenceService from "@core/api/reference-service";
import {SigningStore, NotificationStore} from "@core/components/Modal/stores";
import {
  TRANSACTION_TYPES,
  TRANSACTION_ACTIONS_BY_TYPE
} from "@core/constants/transactions";
import UserStore from "../../Admin/stores/user";

const isProducersTest = (test) => {
  if (test.type !== TYPES.SPLIT) {
    return test.status === STATUSES.FILLED && !test.assignee;
  } else {
    return true;
  }
};

export default {
  /**
   * @param tests {array} tests
   * @param treatments {array} tests
   * @param certificate {object} certificate - a certificate which tests belong to
   * @summary creates a transaction to assign all new tests, treatments for condition and creates QR code to sign it.
   */
  assignTestsAndTreatments(tests, treatments, certificate) {
    const transactions = [];
    const dataHash = utils.computeHash(certificate).hash;

    if (!certificate.transactions?.length) {
      const transactionCertificate = CertificateDBService.addCertificateTX(
        certificate,
        dataHash,
        []
      );
      transactions.push({
        transactionObject: transactionCertificate,
        payload: {
          certificateId: certificate._id,
          type: TRANSACTION_TYPES.CERTIFICATE,
          action:
            TRANSACTION_ACTIONS_BY_TYPE[TRANSACTION_TYPES.CERTIFICATE].CREATE
        }
      });
    }

    tests.forEach((test) => {
      const dataHash = utils.computeHash(test.properties).hash;
      const labTransactionObject = CertificateDBService.createNewTestTX(
        certificate._id,
        test,
        dataHash,
        test.assignee._id
      );

      transactions.push({
        transactionObject: labTransactionObject,
        payload: {
          testId: test._id,
          type: TRANSACTION_TYPES.TEST,
          action: TRANSACTION_ACTIONS_BY_TYPE[TRANSACTION_TYPES.TEST].ASSIGN,
          historyData: {
            lab: test.assignee.name,
            testType: test.type,
            ...(test.type === TYPES.CUSTOM && {
              testDisplayName: test.displayName
            })
          },
          laboratory: test.assignee,
          status: STATUSES.ASSIGNED
        }
      });

      if (!test.witnesses?.length) return;

      const inspectingCompanies = test.witnesses.map((el) => el.company._id);
      const witnessTransactionObject =
        CertificateBCService.addInspectingCompanies(
          test._id,
          inspectingCompanies
        );

      transactions.push({
        transactionObject: witnessTransactionObject,
        payload: {
          testId: test._id,
          type: TRANSACTION_TYPES.TEST,
          action: TRANSACTION_ACTIONS_BY_TYPE[TRANSACTION_TYPES.TEST].INSPECT,
          historyData: {
            witnesses: test.witnesses.map((W) => W.company.name).join(", "),
            testType: test.type,
            ...(test.type === TYPES.CUSTOM && {
              testDisplayName: test.displayName
            })
          },
          inspectingCompanies,
          status: STATUSES.ASSIGNED
        }
      });
    });

    treatments.forEach((test) => {
      const dataHash = utils.computeHash(test.properties).hash;
      const transactionObject = CertificateDBService.createNewTestTX(
        certificate._id,
        test,
        dataHash,
        certificate.company._id
      );

      transactions.push({
        transactionObject: transactionObject,
        payload: {
          testId: test._id,
          type: TRANSACTION_TYPES.TEST,
          action: TRANSACTION_ACTIONS_BY_TYPE[TRANSACTION_TYPES.TEST].APPROVE,
          historyData: {
            testType: test.type,
            ...(test.type === TYPES.CUSTOM && {
              testDisplayName: test.displayName
            })
          },
          status: STATUSES.APPROVED
        }
      });
    });

    let title = "";

    if (!certificate.transactions?.length)
      title = title + "Adding Certificate to the Blockchain. ";

    if (tests?.length) title = title + "Assigning responsible cooperators. ";

    if (treatments?.length) title = title + "Test approvement.";

    userService.getUserInfo().then((user) => {
      referenceService
        .create({
          creator: user._id,
          transactions
        })
        .then((reference) =>
          SigningStore.openSigner(
            {id: reference._id},
            {title, createdFor: "the certificate owner"}
          )
        )
        .catch(() => NotificationStore.showError("Something went wrong!"));
    });
  },

  /**
   * @param tests {array} tests
   * @param certificate {object} certificate - a certificate which tests belong to
   * @param type {string} type of entity (certificate or campaign)
   * @summary creates a transaction to assign all new tests and creates QR code to sign it.
   */
  assign(tests, certificate, type = TRANSACTION_TYPES.CERTIFICATE) {
    const dataHash = utils.computeHash(certificate).hash;

    const isCertificateSigned = certificate.transactions?.length;

    const transactionCertificate = !isCertificateSigned
      ? CertificateDBService.addCertificateTX(certificate, dataHash, [], type)
      : null;

    const transactionsLab = tests.map((test) => {
      const dataHash = utils.computeHash(test.properties).hash;

      const transactionObject = CertificateDBService.createNewTestTX(
        certificate._id,
        test,
        dataHash,
        test.assignee?._id ?? ""
      );

      return {
        transactionObject: transactionObject,
        payload: {
          testId: test._id,
          type: TRANSACTION_TYPES.TEST,
          action: TRANSACTION_ACTIONS_BY_TYPE[TRANSACTION_TYPES.TEST].ASSIGN,
          historyData: {
            lab: test.assignee.name,
            testType: test.type,
            ...(test.type === TYPES.CUSTOM && {
              testDisplayName: test.displayName
            })
          },
          laboratory: test.assignee,
          status: STATUSES.ASSIGNED
        }
      };
    });

    const transactionsWitnesses = tests.reduce((transactions, test) => {
      if (!test.witnesses?.length) return transactions;

      const inspectingCompanies = test.witnesses.map((witness) => witness?._id);
      const transactionObject = CertificateBCService.addInspectingCompanies(
        test._id,
        inspectingCompanies
      );

      transactions.push({
        transactionObject: transactionObject,
        payload: {
          testId: test._id,
          type: TRANSACTION_TYPES.TEST,
          action: TRANSACTION_ACTIONS_BY_TYPE[TRANSACTION_TYPES.TEST].INSPECT,
          historyData: {
            witnesses: test.witnesses
              .map((witness) => witness?.name)
              .join(", "),
            testType: test.type,
            ...(test.type === TYPES.CUSTOM && {
              testDisplayName: test.displayName
            })
          },
          inspectingCompanies,
          status: STATUSES.ASSIGNED
        }
      });

      return transactions;
    }, []);

    let transactions =
      transactionCertificate !== null
        ? [
          {
            transactionObject: transactionCertificate,
            payload: {
              [`${type}Id`]: certificate._id,
              type,
              action:
                  TRANSACTION_ACTIONS_BY_TYPE[TRANSACTION_TYPES.CERTIFICATE]
                    .CREATE
            }
          }
        ]
        : [];

    transactions = [
      ...transactions,
      ...transactionsLab,
      ...transactionsWitnesses
    ];

    userService.getUserInfo().then((user) => {
      referenceService
        .create({
          creator: user._id,
          transactions
        })
        .then((reference) =>
          SigningStore.openSigner(
            {id: reference._id},
            {
              title:
                (transactionCertificate !== null
                  ? `Adding ${type} to the Blockchain and `
                  : "") + "Assigning responsible cooperators",
              createdFor: `the ${type} owner`
            }
          )
        )
        .catch(() => NotificationStore.showError("Something went wrong!"));
    });
  },
  assignTestsToMultipleCertificates(data, testDataByType) {
    const transactions = data.reduce((acc, {certificate, tests}) => {
      if (!certificate.transactions?.length) {
        const dataHash = utils.computeHash(certificate).hash;

        const transactionCertificate = CertificateDBService.addCertificateTX(
          certificate,
          dataHash,
          []
        );
        acc.push({
          transactionObject: transactionCertificate,
          payload: {
            certificateId: certificate._id,
            type: TRANSACTION_TYPES.CERTIFICATE,
            action:
              TRANSACTION_ACTIONS_BY_TYPE[TRANSACTION_TYPES.CERTIFICATE].CREATE
          }
        });
      }

      tests.forEach((test) => {
        const data = testDataByType[test.type] || test;

        if (!data.assignee) return;

        const dataHash = utils.computeHash(test.properties).hash;
        const labTransactionObject = CertificateDBService.createNewTestTX(
          certificate._id,
          test,
          dataHash,
          data.assignee?._id ?? data.assignee
        );

        acc.push({
          transactionObject: labTransactionObject,
          payload: {
            testId: test._id,
            type: TRANSACTION_TYPES.TEST,
            action: TRANSACTION_ACTIONS_BY_TYPE[TRANSACTION_TYPES.TEST].ASSIGN,
            historyData: {
              lab: data.assignee.name,
              testType: test.type,
              ...(test.type === TYPES.CUSTOM && {
                testDisplayName: test.displayName
              })
            },
            laboratory: data.assignee,
            status: STATUSES.ASSIGNED
          }
        });

        if (!data.witnesses?.length) return;

        const inspectingCompanies = data.witnesses.map(
          (witness) => witness.company || witness
        );
        const inspectingCompaniesIds = inspectingCompanies.map(
          (witness) => witness._id
        );
        const witnessTransactionObject =
          CertificateBCService.addInspectingCompanies(
            test._id,
            inspectingCompaniesIds
          );

        acc.push({
          transactionObject: witnessTransactionObject,
          payload: {
            testId: test._id,
            type: TRANSACTION_TYPES.TEST,
            action: TRANSACTION_ACTIONS_BY_TYPE[TRANSACTION_TYPES.TEST].INSPECT,
            historyData: {
              witnesses: inspectingCompanies
                .map((witness) => witness.name)
                .join(", "),
              testType: test.type,
              ...(test.type === TYPES.CUSTOM && {
                testDisplayName: test.displayName
              })
            },
            inspectingCompanies: inspectingCompaniesIds,
            status: STATUSES.ASSIGNED
          }
        });
      });

      return acc;
    }, []);

    userService.getUserInfo().then((user) => {
      referenceService
        .create({
          creator: user._id,
          transactions
        })
        .then((reference) =>
          SigningStore.openSigner(
            {id: reference._id},
            {
              title: "Assigning responsible cooperators",
              createdFor: "the certificate owner"
            }
          )
        )
        .catch(() => NotificationStore.showError("Something went wrong!"));
    });
  },
  assignInspector(
    tests,
    certificateByTestId,
    type = TRANSACTION_TYPES.CERTIFICATE
  ) {
    const transactions = tests.reduce((acc, test) => {
      const dataHash = utils.computeHash(test.properties).hash;
      const inspectingCompanies = test.witnesses.map((el) => el.company._id);
      const company = test.company._id;
      const certificate = certificateByTestId[test._id] || {};

      const transactionObjectCreateNewTest =
        CertificateDBService.createNewTestTX(
          certificate._id,
          test,
          dataHash,
          company
        );
      acc.push({
        transactionObject: transactionObjectCreateNewTest,
        payload: {
          testId: test._id,
          type: TRANSACTION_TYPES.TEST,
          action:
            TRANSACTION_ACTIONS_BY_TYPE[TRANSACTION_TYPES.TEST].WITNESS_REQUEST,
          historyData: {
            witnesses: test.witnesses.map((W) => W.company.name).join(", "),
            testType: test.type,
            ...(test.type === TYPES.CUSTOM && {
              testDisplayName: test.displayName
            })
          },
          status: STATUSES.INSPECTING
        }
      });

      const transactionObjectAddInspectingCompanies =
        CertificateBCService.addInspectingCompanies(
          test._id,
          inspectingCompanies
        );
      acc.push({
        transactionObject: transactionObjectAddInspectingCompanies,
        payload: {
          testId: test._id,
          type: TRANSACTION_TYPES.TEST,
          action: TRANSACTION_ACTIONS_BY_TYPE[TRANSACTION_TYPES.TEST].INSPECT,
          historyData: {
            witnesses: test.witnesses.map((W) => W.company.name).join(", "),
            testType: test.type,
            ...(test.type === TYPES.CUSTOM && {
              testDisplayName: test.displayName
            })
          },
          inspectingCompanies,
          status: STATUSES.INSPECTING
        }
      });

      const isCertificateSigned = certificate.transactions?.length;

      if (!isCertificateSigned) {
        const dataHash = utils.computeHash(certificate).hash;
        const transactionCertificate = CertificateDBService.addCertificateTX(
          certificate,
          dataHash,
          [],
          type
        );
        acc.unshift({
          transactionObject: transactionCertificate,
          payload: {
            [`${type}Id`]: certificate._id,
            type,
            action:
              TRANSACTION_ACTIONS_BY_TYPE[TRANSACTION_TYPES.CERTIFICATE].CREATE
          }
        });
      }

      return acc;
    }, []);

    userService.getUserInfo().then((user) => {
      referenceService
        .create({
          creator: user._id,
          transactions
        })
        .then((reference) =>
          SigningStore.openSigner(
            {id: reference._id},
            {
              title: "Test approvement and Assigning responsible cooperators",
              createdFor: `the ${type} owner`
            }
          )
        )
        .catch(() => NotificationStore.showError("Something went wrong!"));
    });
  },
  /**
   * \
   * @param tests {array} tests
   * @param certificate {object} certificate - a certificate which tests belong to
   * @param userId {string}
   * @param type {string} type of entity (certificate or campaign)
   * @summary creates a transaction to approve all tests and creates QR code to sign it.
   */
  approve(tests, certificate, userId, type = TRANSACTION_TYPES.CERTIFICATE) {
    const dataHash = utils.computeHash(certificate).hash;

    const isCertificateSigned = certificate.transactions?.length;

    const transactionCertificate = !isCertificateSigned
      ? CertificateDBService.addCertificateTX(certificate, dataHash, [], type)
      : null;

    const testTransactions = tests.map((test) => {
      const dataHash = utils.computeHash(test.properties).hash;
      const company = certificate.company || certificate.manufacturer;

      const transactionObject = isProducersTest(test)
        ? CertificateDBService.createNewTestTX(
          certificate._id,
          test,
          dataHash,
          company._id
        )
        : CertificateBCService.approveTestResultsTX(test, dataHash);

      return {
        transactionObject: transactionObject,
        payload: {
          testId: test._id,
          type: TRANSACTION_TYPES.TEST,
          action: TRANSACTION_ACTIONS_BY_TYPE[TRANSACTION_TYPES.TEST].APPROVE,
          historyData: {
            testType: test.type,
            ...(test.type === TYPES.CUSTOM && {
              testDisplayName: test.displayName
            })
          },
          status: STATUSES.APPROVED
        }
      };
    });

    let transactions =
      transactionCertificate !== null
        ? [
          {
            transactionObject: transactionCertificate,
            payload: {
              [`${type}Id`]: certificate._id,
              type,
              action:
                  TRANSACTION_ACTIONS_BY_TYPE[TRANSACTION_TYPES.CERTIFICATE]
                    .CREATE
            }
          }
        ]
        : [];

    transactions = transactions.concat(testTransactions);

    referenceService
      .create({
        creator: userId,
        transactions
      })
      .then((reference) => {
        SigningStore.openSigner(
          {id: reference._id},
          {
            title:
              (transactionCertificate !== null
                ? `Adding ${type} to the Blockchain and `
                : "") + "Test approvement",
            createdFor: `the ${type} owner`
          }
        );
      })
      .catch(() => NotificationStore.showError("Something went wrong!"));
  },

  /**
   *
   * @param tests {array} tests
   * @param userId {string}
   * @summary creates a transaction to submits tests results and shows QR to sign it
   */
  submit(tests, userId) {
    const transactions = tests.map((test) => {
      const dataHash = utils.computeHash(test.properties).hash;
      const transactionObject = CertificateBCService.submitTestResultsTX(
        test,
        dataHash
      );

      let newStatus = STATUSES.SUBMITTED;

      if (test.witnesses?.length)
        newStatus = test.inspectionWaivedDocument
          ? STATUSES.INSPECTED
          : STATUSES.INSPECTING;

      return {
        transactionObject: transactionObject,
        payload: {
          testId: test._id,
          type: TRANSACTION_TYPES.TEST,
          action: TRANSACTION_ACTIONS_BY_TYPE[TRANSACTION_TYPES.TEST].SUBMIT,
          historyData: {
            testType: test.type,
            ...(test.type === TYPES.CUSTOM && {
              testDisplayName: test.displayName
            })
          },
          status: newStatus
        }
      };
    });
    referenceService
      .create({
        creator: userId,
        transactions
      })
      .then((reference) => {
        SigningStore.openSigner(
          {id: reference._id},
          {title: "Test submission", createdFor: "the laboratory"}
        );
      })
      .catch(() => NotificationStore.showError("Something went wrong!"));
  },

  multipleSignAsProducer(tests, certificateByTestId, userId) {
    const certificateTransactions = tests.reduce((acc, test) => {
      if (test.certificateSigned || !test.certificateId) return acc;

      const certificate = certificateByTestId[test._id];
      const dataHash = utils.computeHash(certificate).hash;
      const transactionCertificate = CertificateDBService.addCertificateTX(
        certificate,
        dataHash,
        []
      );

      acc.push({
        transactionObject: transactionCertificate,
        payload: {
          certificateId: certificate._id,
          type: TRANSACTION_TYPES.CERTIFICATE,
          action:
            TRANSACTION_ACTIONS_BY_TYPE[TRANSACTION_TYPES.CERTIFICATE].CREATE
        }
      });

      return acc;
    }, []);

    const testsToApprove = tests.filter((test) => {
      const submittedByLab = test.status === STATUSES.SUBMITTED;
      const filledByProducer =
        test.status === STATUSES.FILLED &&
        !test.assignee &&
        !test.witnesses?.length;
      const isInspected = test.status === STATUSES.INSPECTED && test.assignee;

      return isInspected || submittedByLab || filledByProducer;
    });

    const approveTransactions = testsToApprove.map((test) => {
      const dataHash = utils.computeHash(test.properties).hash;
      const transactionObject = isProducersTest(test)
        ? CertificateDBService.createNewTestTX(
          test.certificateId,
          test,
          dataHash,
          test.company._id
        )
        : CertificateBCService.approveTestResultsTX(test, dataHash);

      return {
        transactionObject: transactionObject,
        payload: {
          testId: test._id,
          type: TRANSACTION_TYPES.TEST,
          action: TRANSACTION_ACTIONS_BY_TYPE[TRANSACTION_TYPES.TEST].APPROVE,
          historyData: {
            testType: test.type,
            ...(test.type === TYPES.CUSTOM && {
              testDisplayName: test.displayName
            })
          },
          status: STATUSES.APPROVED
        }
      };
    });

    const testsToInspect = tests.filter(
      (test) =>
        test.status === STATUSES.FILLED &&
        !!test.witnesses?.length &&
        !test.assignee
    );

    const inspectTransactions = testsToInspect.reduce((acc, test) => {
      const dataHash = utils.computeHash(test.properties).hash;
      const inspectingCompanies = test.witnesses.map((el) => el.company._id);
      const company = test.company._id;

      const transactionObjectCreateNewTest =
        CertificateDBService.createNewTestTX(
          test.certificateId,
          test,
          dataHash,
          company
        );
      acc.push({
        transactionObject: transactionObjectCreateNewTest,
        payload: {
          testId: test._id,
          type: TRANSACTION_TYPES.TEST,
          action:
            TRANSACTION_ACTIONS_BY_TYPE[TRANSACTION_TYPES.TEST].WITNESS_REQUEST,
          historyData: {
            witnesses: test.witnesses.map((W) => W.company.name).join(", "),
            testType: test.type,
            ...(test.type === TYPES.CUSTOM && {
              testDisplayName: test.displayName
            })
          },
          status: STATUSES.INSPECTING
        }
      });

      const transactionObjectAddInspectingCompanies =
        CertificateBCService.addInspectingCompanies(
          test._id,
          inspectingCompanies
        );
      acc.push({
        transactionObject: transactionObjectAddInspectingCompanies,
        payload: {
          testId: test._id,
          type: TRANSACTION_TYPES.TEST,
          action: TRANSACTION_ACTIONS_BY_TYPE[TRANSACTION_TYPES.TEST].INSPECT,
          historyData: {
            witnesses: test.witnesses.map((W) => W.company.name).join(", "),
            testType: test.type,
            ...(test.type === TYPES.CUSTOM && {
              testDisplayName: test.displayName
            })
          },
          inspectingCompanies,
          status: STATUSES.INSPECTING
        }
      });

      return acc;
    }, []);

    const transactions = [
      ...certificateTransactions,
      ...approveTransactions,
      ...inspectTransactions
    ];

    referenceService
      .create({
        creator: userId,
        transactions
      })
      .then((reference) => {
        SigningStore.openSigner(
          {id: reference._id},
          {title: "Multiple signing", createdFor: "the certificate owner"}
        );
      })
      .catch(() => NotificationStore.showError("Something went wrong!"));
  },

  /**
   * @param {array} tests
   * @param {array} statuses - statuses for each test
   * @summary creates a transaction to attest every test with corresponding status
   */
  attestSplit(statuses, tests) {
    const transactions = tests.map((test, index) => {
      const dataHash = utils.computeHash(test.properties).hash;
      const transactionObject = CertificateBCService.attest(
        test,
        statuses[index],
        dataHash
      );

      return {
        transactionObject: transactionObject,
        payload: {
          testId: test._id,
          type: TRANSACTION_TYPES.TEST,
          action: TRANSACTION_ACTIONS_BY_TYPE[TRANSACTION_TYPES.TEST].WITNESS,
          historyData: {
            witnesses: test.witnesses.map((W) => W.company.name).join(", "),
            testType: test.type,
            ...(test.type === TYPES.CUSTOM && {
              testDisplayName: test.displayName
            })
          },
          status: STATUSES.INSPECTED,
          company: UserStore.user.data.company._id,
          inspectingStatus: statuses[index]
        }
      };
    });

    referenceService
      .create({
        creator: UserStore.user.data._id,
        transactions
      })
      .then((reference) =>
        SigningStore.openSigner(
          {id: reference._id},
          {title: "Split witness", createdFor: "the inspector"}
        )
      )
      .catch(() => NotificationStore.showError("Something went wrong!"));
  },

  /**
   * @param tests {array}
   * @param companyId {string}
   * @param userId {string}
   * @summary creates a transaction to create tests from Lab module and shows QR to sign it
   */

  // TODO remove this logic and use the one from "frontend/src/core/services/getTestTxPayloadSubmitHash.js"
  create(tests, userId, companyId) {
    const transactions = tests.map((test) => {
      const dataHash = utils.computeHash(test.properties).hash;
      const transactionObject = CertificateBCService.createNewTestFromLabTX(
        test,
        dataHash,
        companyId
      );

      return {
        transactionObject: transactionObject,
        payload: {
          testId: test._id,
          type: TRANSACTION_TYPES.TEST,
          action: TRANSACTION_ACTIONS_BY_TYPE[TRANSACTION_TYPES.TEST].SUBMIT,
          historyData: {
            testType: test.type,
            ...(test.type === TYPES.CUSTOM && {
              testDisplayName: test.displayName
            })
          },
          status: STATUSES.SUBMITTED
        }
      };
    });
    referenceService
      .create({
        creator: userId,
        transactions
      })
      .then((reference) => {
        SigningStore.openSigner(
          {id: reference._id},
          {title: "Test submission", createdFor: "the laboratory"}
        );
      })
      .catch(() => NotificationStore.showError("Something went wrong!"));
  },

  attestTests(tests, companyId) {
    const transactions = tests.map((test) => {
      const dataHash = utils.computeHash(test.properties).hash;
      const status = test.witnesses.find(
        (witness) => witness.company._id === companyId
      ).status;

      const transactionObject = CertificateBCService.attest(
        test,
        status,
        dataHash
      );

      return {
        transactionObject: transactionObject,
        payload: {
          testId: test._id,
          type: TRANSACTION_TYPES.TEST,
          action: TRANSACTION_ACTIONS_BY_TYPE[TRANSACTION_TYPES.TEST].WITNESS,
          historyData: {
            witnesses: test.witnesses
              .map((witness) => witness.company.name)
              .join(", "),
            testType: test.type,
            ...(test.type === TYPES.CUSTOM && {
              testDisplayName: test.displayName
            })
          },
          status: test.assignee ? STATUSES.INSPECTED : STATUSES.APPROVED,
          company: UserStore.user.data.company._id,
          inspectingStatus: status
        }
      };
    });

    const text = tests?.length > 1 ? "Tests attestation" : "Test attestation";

    referenceService
      .create({
        creator: UserStore.user.data._id,
        transactions
      })
      .then((reference) => {
        SigningStore.openSigner(
          {id: reference._id},
          {title: text, createdFor: "the requested inspector"}
        );
      })
      .catch(() => NotificationStore.showError("Something went wrong!"));
  },

  addExistingTest(tests, certificate, userId) {
    const dataHash = utils.computeHash(certificate).hash;

    const transactionCertificate =
      certificate.transactions?.length == 0
        ? CertificateDBService.addCertificateTX(certificate, dataHash, [])
        : null;

    const testTransactions = tests.map((test) => {
      const transactionObject = CertificateDBService.addTestToCertificateTX(
        certificate._id,
        test._id
      );

      return {
        transactionObject: transactionObject,
        payload: {
          testId: test._id,
          type: TRANSACTION_TYPES.TEST,
          action: TRANSACTION_ACTIONS_BY_TYPE[TRANSACTION_TYPES.TEST].APPROVE,
          historyData: {
            testType: test.type,
            ...(test.type === TYPES.CUSTOM && {
              testDisplayName: test.displayName
            })
          },
          status: STATUSES.APPROVED
        }
      };
    });

    let transactions =
      transactionCertificate !== null
        ? [
          {
            transactionObject: transactionCertificate,
            payload: {
              certificateId: certificate._id,
              type: TRANSACTION_TYPES.CERTIFICATE,
              action:
                  TRANSACTION_ACTIONS_BY_TYPE[TRANSACTION_TYPES.CERTIFICATE]
                    .CREATE
            }
          }
        ]
        : [];

    transactions = transactions.concat(testTransactions);

    referenceService
      .create({
        creator: userId,
        transactions
      })
      .then((reference) => {
        SigningStore.openSigner(
          {id: reference._id},
          {
            title:
              (transactionCertificate !== null
                ? "Adding Certificate to the Blockchain and "
                : "") + "Test approvement",
            createdFor: "the certificate owner"
          }
        );
      })
      .catch(() => NotificationStore.showError("Something went wrong!"));
  }
};
