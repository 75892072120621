import {FEATURES} from "@core/constants/features";
import {ALL_TESTS} from "@core/constants/test";
import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import {Grid} from "@mui/material";
import {useParams} from "react-router-dom";
import useStores from "../../../../useStores";
import PendingActions from "./components/PendingActions";
import TestsOverview from "./components/TestsOverview";
import Charts from "./components/Charts";

const TestsAndAnalysesPage = ({activeSubTab}) => {
  const [loadedTestCategories, setLoadedTestCategories] = useState([]);

  const {SingleMaterialCampaignStore, UserStore} = useStores();
  const {id} = useParams();

  const makeRequestOnEachTab = UserStore.features[FEATURES.PERFORM_REQUEST_ON_EACH_TAB];

  const isLoaded = loadedTestCategories.includes(activeSubTab);

  const getTestsByCategory = async () => {
    const makeRequest = !loadedTestCategories.includes(activeSubTab) || makeRequestOnEachTab;

    if(!activeSubTab || !makeRequest) return;

    if(activeSubTab === ALL_TESTS) {
      await SingleMaterialCampaignStore.getAllTests(id);
    } else {
      await SingleMaterialCampaignStore.getTestsByCategory(id, activeSubTab);
    }

    setLoadedTestCategories((loadedTestCategories) => [...loadedTestCategories, activeSubTab]);
  };

  useEffect(() => {
    getTestsByCategory();
  }, [activeSubTab, id]);

  return (
    <Grid container spacing={4}>
      <PendingActions />
      <Charts
        category={activeSubTab}
        isLoaded={isLoaded}
      />
      <TestsOverview
        isLoaded={isLoaded}
        category={activeSubTab}
      />
    </Grid>
  );
};

export default observer(TestsAndAnalysesPage);