import {QCP_NAMES} from "./qcpNames";

/**
 * Gets a list of companies of a QCP
 * @param {string} qcpName
 * @returns {string[]} companies
 */
export const getCompanies = (qcpName) => {
  let companiesList;

  if (process.env.ENVIRONMENT === "development") {
    companiesList = [...LOCAL_COMPANIES, ...STAGE_COMPANIES];
  } else {
    companiesList = [];
  }

  switch (process.env.ENVIRONMENT) {
    case "development":
      companiesList = LOCAL_COMPANIES;
      break;

    case "staging":
      companiesList = STAGE_COMPANIES;
      break;

    case "demo":
      companiesList = DEMO_COMPANIES;
      break;

    default:
      companiesList = COMPANIES;
  }

  return (
    companiesList
      .filter((company) => !!company.qcps.find((qcp) => qcp === qcpName))
      .map((company) => company.name) ?? []
  );
};

export const COMPANIES = [
  {
    name: "Tenaris",
    qcps: [
      QCP_NAMES.QCP_9348_YGG_TGS_00010_GL_Reelable,
      QCP_NAMES.QCP_9348_YGG_TGS_00010_GL_Reelable_FDPT,
      QCP_NAMES.QCP_10378_YGG_TGS_00045_GL_Pipe,
      QCP_NAMES.QCP_10378_YGG_TGS_00045_GL_Pipe_FDPT,
      QCP_NAMES.QCP_5982_Rev_4,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_6916_Rev_3_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_6916_Rev_3,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_7928_Rev_2,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8262_Rev_6,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8262_Rev_6_MPQT_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8252_Rev_6,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8252_Rev_6_MPQT_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8251_Rev_6,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8251_Rev_6_MPQT_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8263_Rev_6,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8263_Rev_6_MPQT_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_5513_Rev_2_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_5513_Rev_2,
      // TODO: Add FDPT later
      // QCP_NAMES.QCP_5982_Rev_4_FDPT,
      QCP_NAMES.QCP_5987_Rev_2,
      QCP_NAMES.QCP_5987_Rev_2_FDPT,
      QCP_NAMES.QCP_5983_Rev_5,
      QCP_NAMES.QCP_5983_Rev_5_FDPT
    ]
  },
  {
    name: "Turcotte",
    qcps: [
      QCP_NAMES.QCP_ITP_00923_124277_NOA_BUT_00013_HRB_MPQT,
      QCP_NAMES.QCP_ITP_00923_124277_NOA_BUT_00013_HRB,
      QCP_NAMES.QCP_CPW_ITP_21_23_HFW_YGG_COP_00005_MPQT,
      QCP_NAMES.QCP_9348_YGG_TGS_00010_GL_Reelable,
      QCP_NAMES.QCP_9348_YGG_TGS_00010_GL_Reelable_FDPT,
      QCP_NAMES.QCP_10378_YGG_TGS_00045_GL_Pipe,
      QCP_NAMES.QCP_10378_YGG_TGS_00045_GL_Pipe_FDPT
    ]
  },
  {
    name: "Husteel",
    qcps: [
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.HS_D_ITP_22_02_02_Rev_5, QCP_NAMES.HS_D_ITP_23_01_06_Rev_3
    ]
  },
  {
    name: "H. Butting GmbH & Co. KG",
    qcps: [
      QCP_NAMES.QCP_ITP_01123_124277_BuBi_MLP_NOA_BUT_00011,
      QCP_NAMES.QCP_ITP_00923_124277_NOA_BUT_00013_HRB_MPQT,
      QCP_NAMES.QCP_ITP_00923_124277_NOA_BUT_00013_HRB,
      QCP_NAMES.QCP_QP_OST_HF_YGG_BUT_00068,
      QCP_NAMES.QCP_ITP_030_24_130786_YGG_BUT_00073,
      QCP_NAMES.QCP_4500256818_HFU_QP_VSB_YGG_BUT_00064_FDPT,
      QCP_NAMES.ITP_011_23_124277_Rev_05_MPQT
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_4500256818_HFU_QP_VSB_YGG_BUT_00064,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.ITP_011_23_124277_Rev_05,
    ]
  },
  {
    name: "Corinth Pipeworks S.A.",
    qcps: [
      QCP_NAMES.QCP_CPW_ITP_21_23_HFW_YGG_COP_00005
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.CPW_ITP_24_23_SAWL_Rev_3_MPQT
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.CPW_ITP_24_23_SAWL_Rev_3,
    ]
  },
  {
    name: "The Bayou Companies",
    qcps: [QCP_NAMES.BLFB1_SSGN_UFR_ITP_BAY_00000_00011_00]
  },
  {
    name: "Chevron",
    qcps: [
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.BLFB1_SSGN_UFR_ITP_BAY_00000_00011_00,
      QCP_NAMES.BLFB1_SSGN_QAC_ITP_SS7_00000_00001_00
    ]
  },
  {
    name: "Subsea7",
    qcps: [QCP_NAMES.BLFB1_SSGN_QAC_ITP_SS7_00000_00001_00]
  },
  {
    name: "Kennametal Inc.",
    qcps: [QCP_NAMES.KAR85_AM_K]
  }
];

const STAGE_COMPANIES = [
  {
    name: "Turcotte Group",
    qcps: [
      QCP_NAMES.QCP_ITP_01123_124277_BuBi_MLP_NOA_BUT_00011,
      QCP_NAMES.QCP_ITP_00923_124277_NOA_BUT_00013_HRB_MPQT,
      QCP_NAMES.QCP_ITP_00923_124277_NOA_BUT_00013_HRB,
      QCP_NAMES.QCP_QP_OST_HF_YGG_BUT_00068,
      QCP_NAMES.QCP_ITP_030_24_130786_YGG_BUT_00073,
      QCP_NAMES.QCP_CPW_ITP_21_23_HFW_YGG_COP_00005_MPQT,
      QCP_NAMES.QCP_CPW_ITP_21_23_HFW_YGG_COP_00005,
      QCP_NAMES.QCP_4500256818_HFU_QP_VSB_YGG_BUT_00064_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_4500256818_HFU_QP_VSB_YGG_BUT_00064,
      QCP_NAMES.QCP_9348_YGG_TGS_00010_GL_Reelable,
      QCP_NAMES.QCP_9348_YGG_TGS_00010_GL_Reelable_FDPT,
      QCP_NAMES.QCP_10378_YGG_TGS_00045_GL_Pipe,
      QCP_NAMES.QCP_10378_YGG_TGS_00045_GL_Pipe_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_6916_Rev_3_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_6916_Rev_3,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_7928_Rev_2,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8262_Rev_6,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8262_Rev_6_MPQT_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8252_Rev_6,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8252_Rev_6_MPQT_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8251_Rev_6,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8251_Rev_6_MPQT_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8263_Rev_6,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_8263_Rev_6_MPQT_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_5513_Rev_2_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_5513_Rev_2,
      // TODO: Add FDPT later
      // QCP_NAMES.QCP_5982_Rev_4_FDPT,
      QCP_NAMES.QCP_5982_Rev_4,
      QCP_NAMES.QCP_5987_Rev_2,
      QCP_NAMES.QCP_5987_Rev_2_FDPT,
      QCP_NAMES.QCP_5983_Rev_5,
      QCP_NAMES.QCP_5983_Rev_5_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.HS_D_ITP_22_02_02_Rev_5,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.HS_D_ITP_23_01_06_Rev_3,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.FX_FBE_Coating_ITP_Rev_0,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.FX_Spool_ITP_Rev_0,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.MTIP_WA0058_4400025974_H06_44004_Rev_6_MPQT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.MTIP_WA0058_4400025974_H06_44004_Rev_6,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.BLFB1_SSGN_UFR_ITP_BAY_00000_00011_00,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.BLFB1_SSGN_QAC_ITP_SS7_00000_00001_00,
      QCP_NAMES.ITP_BPM_22_20_Rev_6,
      QCP_NAMES.ITP_BPM_22_20_Rev_6_MPQT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.ITP_011_23_124277_Rev_05,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.ITP_011_23_124277_Rev_05_MPQT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.CPW_ITP_24_23_SAWL_Rev_3,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.CPW_ITP_24_23_SAWL_Rev_3_MPQT,
      QCP_NAMES.KAR85_AM_K
    ]
  },
  {
    name: "Sauer LLC",
    qcps: [QCP_NAMES.FP_ITP_Rev_0]
  },
  {
    name: "Chevron",
    qcps: [
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.BLFB1_SSGN_UFR_ITP_BAY_00000_00011_00,
      QCP_NAMES.BLFB1_SSGN_QAC_ITP_SS7_00000_00001_00
    ]
  }
];

const LOCAL_COMPANIES = [
  {
    name: "Test Producer 2",
    qcps: [
      QCP_NAMES.QCP_ITP_01123_124277_BuBi_MLP_NOA_BUT_00011,
      QCP_NAMES.QCP_ITP_00923_124277_NOA_BUT_00013_HRB_MPQT,
      QCP_NAMES.QCP_ITP_00923_124277_NOA_BUT_00013_HRB,
      QCP_NAMES.QCP_QP_OST_HF_YGG_BUT_00068,
      QCP_NAMES.QCP_ITP_030_24_130786_YGG_BUT_00073,
      QCP_NAMES.QCP_CPW_ITP_21_23_HFW_YGG_COP_00005_MPQT,
      QCP_NAMES.QCP_CPW_ITP_21_23_HFW_YGG_COP_00005,
      QCP_NAMES.QCP_4500256818_HFU_QP_VSB_YGG_BUT_00064_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_4500256818_HFU_QP_VSB_YGG_BUT_00064,
      QCP_NAMES.QCP_9348_YGG_TGS_00010_GL_Reelable,
      QCP_NAMES.QCP_9348_YGG_TGS_00010_GL_Reelable_FDPT,
      QCP_NAMES.QCP_10378_YGG_TGS_00045_GL_Pipe,
      QCP_NAMES.QCP_10378_YGG_TGS_00045_GL_Pipe_FDPT,
      QCP_NAMES.QCP_6916_Rev_3,
      QCP_NAMES.QCP_6916_Rev_3_FDPT,
      QCP_NAMES.QCP_5983_Rev_5_FDPT
    ]
  },
  {
    name: "END OWNER COMPANY",
    qcps: [QCP_NAMES.QCP_6916_Rev_3_FDPT]
  },
  {
    name: "TEST PROD COMPANY 3",
    qcps: [QCP_NAMES.QCP_6916_Rev_3_FDPT]
  },
  {
    name: "67458659caea4ff526d3d53f",
    qcps: [QCP_NAMES.QCP_6916_Rev_3_FDPT]
  },
  {
    name: "Test End Owner 1",
    qcps: [QCP_NAMES.QCP_6916_Rev_3_FDPT]
  },
  {
    name: "Manufacturer Company",
    qcps: [QCP_NAMES.QCP_6916_Rev_3_FDPT]
  },
  {
    name: "Supplier Company",
    qcps: [
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_6916_Rev_3_FDPT,
      QCP_NAMES.FP_ITP_Rev_0,
      QCP_NAMES.QCP_9348_YGG_TGS_00010_GL_Reelable_FDPT,
      QCP_NAMES.QCP_9348_YGG_TGS_00010_GL_Reelable,
      QCP_NAMES.QCP_10378_YGG_TGS_00045_GL_Pipe,
      QCP_NAMES.QCP_5983_Rev_5,
      QCP_NAMES.QCP_5983_Rev_5_FDPT,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.ITP_011_23_124277_Rev_05,
      QCP_NAMES.QCP_CPW_ITP_21_23_HFW_YGG_COP_00005
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.QCP_4500256818_HFU_QP_VSB_YGG_BUT_00064
    ]
  },
  {
    name: "TEST PROD COMPANY",
    qcps: [QCP_NAMES.BLFB1_SSGN_QAC_ITP_SS7_00000_00001_00]
  }
];

const DEMO_COMPANIES = [
  {
    name: "FoxPipe Producer",
    qcps: [
      QCP_NAMES.FP_ITP_Rev_0,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.FX_FBE_Coating_ITP_Rev_0,
      QCP_NAMES.FX_Spool_ITP_Rev_0
    ]
  },
  {
    name: "Ferry - Murphy",
    qcps: [QCP_NAMES.FP_ITP_Rev_0]
  },
  {
    name: "Triton Energy",
    qcps: [
      QCP_NAMES.FP_ITP_Rev_0,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.FX_FBE_Coating_ITP_Rev_0,
      QCP_NAMES.FX_Spool_ITP_Rev_0
    ]
  },
  {
    name: "Southwest Gas",
    qcps: [
      QCP_NAMES.FP_ITP_Rev_0,
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.FX_FBE_Coating_ITP_Rev_0,
      QCP_NAMES.FX_Spool_ITP_Rev_0
    ]
  },
  {
    name: "Sumitomo",
    qcps: [
      // TODO: Add migration for this QCP and uncomment its line
      // QCP_NAMES.MTIP_WA0058_4400025974_H06_44004_Rev_6_MPQT,
      QCP_NAMES.MTIP_WA0058_4400025974_H06_44004_Rev_6
    ]
  },
  {
    name: "Berg Pipe",
    qcps: [QCP_NAMES.ITP_BPM_22_20_Rev_6, QCP_NAMES.ITP_BPM_22_20_Rev_6_MPQT]
  }
];
