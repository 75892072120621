import {PO_ITEMS} from "@core/constants/poItems";

export const TEST_STANDARDS = ["BS 7448-1 (1991)"];

export const ACCEPTANCE_CRITERIA = {
  "QCP ITP-00923-124277 (NOA-BUT-00013) - HRB - MPQT (After reeling)": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    temperature: -10,
    requirements: {
      crackTipOpeningDisplacementAverageMin: 0.2
    }
  },
  "QCP ITP-00923-124277 (NOA-BUT-00013) - HRB - MPQT": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    temperature: -10,
    requirements: {
      Base: {
        crackTipOpeningDisplacementAverageMin: 0.38
      },
      HAZ: {
        crackTipOpeningDisplacementAverageMin: 0.2
      },
      Weld: {
        crackTipOpeningDisplacementAverageMin: 0.2
      }
    }
  },
  "QCP CPW-ITP-21-23-HFW (YGG-COP-00005) - MPQT": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    temperatureByPoItem: {
      [PO_ITEMS.PO_ITEM_10]: -20,
      [PO_ITEMS.PO_ITEM_20]: -20,
      [PO_ITEMS.PO_ITEM_30]: -10,
      [PO_ITEMS.PO_ITEM_40]: -10,
      [PO_ITEMS.PO_ITEM_50]: -10,
      [PO_ITEMS.PO_ITEM_60]: -10,
      [PO_ITEMS.PO_ITEM_70]: -21,
      [PO_ITEMS.PO_ITEM_80]: -21
    },
    requirements: {
      Base: {
        crackTipOpeningDisplacementAverageMin: 0.38
      },
      HAZ: {
        crackTipOpeningDisplacementAverageMin: 0.2
      },
      Weld: {
        crackTipOpeningDisplacementAverageMin: 0.2
      }
    }
  },
  "QCP CPW-ITP-21-23-HFW (YGG-COP-00005) - MPQT (After reeling)": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    temperatureByPoItem: {
      [PO_ITEMS.PO_ITEM_10]: -20,
      [PO_ITEMS.PO_ITEM_20]: -20,
      [PO_ITEMS.PO_ITEM_30]: -10,
      [PO_ITEMS.PO_ITEM_40]: -10,
      [PO_ITEMS.PO_ITEM_50]: -10,
      [PO_ITEMS.PO_ITEM_60]: -10,
      [PO_ITEMS.PO_ITEM_70]: -21,
      [PO_ITEMS.PO_ITEM_80]: -21
    },
    requirements: {
      Base: {
        crackTipOpeningDisplacementAverageMin: 0.38
      },
      HAZ: {
        crackTipOpeningDisplacementAverageMin: 0.2
      },
      Weld: {
        crackTipOpeningDisplacementAverageMin: 0.2
      }
    }
  },
  "ISO 15156-2 (2015) - NACE MR0175 Table B.1": {},
  "QCP 4500256818-HFU-QP-VSB (YGG-BUT-00064) - FDPT": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    temperature: -29,
    requirements: {
      crackTipOpeningDisplacementAverageMin: 0.38
    }
  },
  "QCP 4500256818-HFU-QP-VSB (YGG-BUT-00064) - FDPT (After reeling)": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    temperature: -29,
    requirements: {
      crackTipOpeningDisplacementAverageMin: 0.2
    }
  },
  "QCP 9348 (YGG-TGS-00010 - GL Reelable) - FDPT": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    temperatureByPoItem: {
      [PO_ITEMS.PO_ITEM_1]: -18,
      [PO_ITEMS.PO_ITEM_2]: -18,
      [PO_ITEMS.PO_ITEM_3]: -18
    },
    requirements: {
      crackTipOpeningDisplacementAverageMin: 0.38
    }
  },
  "QCP 9348 (YGG-TGS-00010 - GL Reelable) - FDPT (After reeling)": {
    company: ["TenarisLab", "Tremblay Inc.", "Test Lab"],
    temperatureByPoItem: {
      [PO_ITEMS.PO_ITEM_1]: -18,
      [PO_ITEMS.PO_ITEM_2]: -18,
      [PO_ITEMS.PO_ITEM_3]: -18
    },
    requirements: {
      crackTipOpeningDisplacementAverageMin: 0.2
    }
  },
  "QCP 6916 Rev. 3": {
    company: ["TenarisLab", "Tremblay Inc."],
    requirements: {
      crackTipOpeningDisplacementMin: 0.38,
      crackTipOpeningDisplacementAverageMin: 0.5
    },
    temperature: 0
  },
  "QCP 8251 Rev. 6": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    requirements: {
      crackTipOpeningDisplacementAverageMin: 0.38
    },
    temperature: -10
  },
  "QCP 8251 Rev. 6 (After reeling)": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    temperature: -10
  },
  "QCP 8262 Rev. 6": {
    company: ["Tenaris-Siderca", "Tremblay Inc."],
    requirements: {
      crackTipOpeningDisplacementAverageMin: 0.38
    },
    temperatureByPoItem: {
      [PO_ITEMS.PO_ITEM_1]: -18,
      [PO_ITEMS.PO_ITEM_2]: -18,
      [PO_ITEMS.PO_ITEM_3]: -10
    }
  },
  "ITP-BPM-22.20 MPQT": {
    company: ["Berg Pipe", "Tremblay Inc."],
    temperature: -18
  },
  "ITP 011/23/124277 Rev. 05 MPQT": {
    company: ["Tremblay Inc."],
    orientation: "longitudinal",
    requirements: {
      crackTipOpeningDisplacementMin: 0.2
    },
    temperature: -18
  },
  "QCP 5513 Rev. 2": {
    company: ["TenarisLab", "Tremblay Inc."],
    temperature: 2.8,
    requirements: {
      crackTipOpeningDisplacementMin: 0.38,
      crackTipOpeningDisplacementAverageMin: 0.5
    }
  },
  "QCP 5982 Rev. 4": {
    company: ["TenarisLab", "Tremblay Inc."],
    temperatureByPoItem: {
      [PO_ITEMS.PO_ITEM_1]: 3,
      [PO_ITEMS.PO_ITEM_2]: 3,
      [PO_ITEMS.PO_ITEM_3]: 3,
      [PO_ITEMS.PO_ITEM_4]: -3
    },
    requirements: {
      crackTipOpeningDisplacementMin: 0.38,
      crackTipOpeningDisplacementAverageMin: 0.5
    }
  },
  "QCP 5987 Rev. 2": {
    company: ["TenarisLab", "Tremblay Inc."],
    temperature: -3,
    requirements: {
      crackTipOpeningDisplacementMin: 0.38,
      crackTipOpeningDisplacementAverageMin: 0.5
    }
  },
  "QCP 5987 Rev. 2 (For information only)": {
    company: ["TenarisLab", "Tremblay Inc."],
    temperature: -12
  },
  "QCP 5983 Rev. 5": {
    company: ["TenarisLab", "Tremblay Inc."],
    temperature: 3,
    requirements: {
      crackTipOpeningDisplacementMin: 0.38,
      crackTipOpeningDisplacementAverageMin: 0.5
    }
  },
  "QCP 10378 (YGG-TGS-00045 - GL Pipe) - FDPT": {
    company: [],
    temperature: -18,
    requirements: {
      crackTipOpeningDisplacementAverageMin: 0.38
    }
  }
};
