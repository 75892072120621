/**
 * TO-DOs:
 * - Make al W's and T's visible in the pipeline
 * - Remove Welds from starting and ending positions on lines
 * - Make arrow labels dynamic
 */

/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, {useRef, useEffect, useCallback, useState, useMemo} from "react";
import MultipleSelectPlaceholder from "./Select";
import Legend from "./Legend";
import {Box} from "@mui/material";
import {createPillVertices, drawArrow, drawDetailedView} from "./helpers";
import {TOGGLE_DIRECTION, Toggle as ViewToggle} from "./Toggle";
import {PILLS, TOOLTIP, TOGGLE_AS_OPTIONS} from "../../constants";

const TOOLTIP_HEIGHT = TOOLTIP.HEIGHT;
const TOOLTIP_WIDTH = TOOLTIP.WIDTH;

const maxPillsPerRow = PILLS.MAX_PER_ROW;
const PILL_WIDTH = PILLS.WIDTH;
const PILL_HEIGHT = PILLS.HEIGHT;
const PILL_GAP = PILLS.GAP;
const ROW_SPACING = PILLS.ROW_SPACING;

const WebGLCanvas = ({stalks, baseMaterials}) => {
  const canvasRef = useRef(null);
  const overlayCanvasRef = useRef(null); // Overlay canvas for labels
  const hoveredPillRef = useRef(null);
  const tooltipRef = useRef(null);
  const numPills =
    stalks.reduce((sum, innerArray) => sum + innerArray.length, 0) +
    baseMaterials.length;

  const [hoveredPill, setHoveredPill] = useState();

  const [pipeType, setPipeType] = useState("");
  const [jointName, setJointName] = useState("");
  const [pipe, setPipe] = useState("");

  const tooltipPipePositionRef = useRef(null);

  const numPillRef = useRef(numPills);
  const scalesRef = useRef(new Float32Array(numPillRef.current).fill(1));
  const targetScalesRef = useRef(new Float32Array(numPillRef.current).fill(1));
  const hoverIndexRef = useRef(-1);
  const dirtyRef = useRef(true);
  const instancedExtRef = useRef(null);
  const boundariesRef = useRef([]);
  const isDraggingRef = useRef(false);
  const dragStartRef = useRef({x: 0, y: 0});
  const canvasOffsetRef = useRef({x: 120, y: 90}); // Start with top padding
  const zoomRef = useRef(1); // Zoom level

  const filteredStalksRef = useRef(stalks);
  const selectedStalkRef = useRef(null);

  const currentViewRef = useRef("global");
  const currentFabBuiltViewRef = useRef(TOGGLE_AS_OPTIONS.AsBuilt);

  const [pills] = useState([]);

  const stalksLength = useMemo(() => {
    const newStalks = [];

    stalks.forEach((stalk) => {
      newStalks.push(...stalk);
    });

    return newStalks.length;
  }, [stalks]);

  const chunkArray = (arr) => {
    const result = [];

    for (let i = 0; i < arr.length; i += maxPillsPerRow) {
      result.push(arr.slice(i, i + maxPillsPerRow));
    }

    return result;
  };

  const createPillPositions = (canvasWidth, canvasHeight) => {
    const zoomFactor = zoomRef.current;
    const pillWidth = PILL_WIDTH * zoomFactor;
    let pillHeight = PILL_HEIGHT * zoomFactor;
    const pillGap = PILL_GAP * zoomFactor;
    const rowSpacing = ROW_SPACING * zoomFactor;

    const offsetX = canvasOffsetRef.current.x;
    const offsetY = canvasOffsetRef.current.y;
    const pillsPerRow = maxPillsPerRow;

    let positions = [];
    const typeCodes = [];
    const boundaries = [];
    let widths = new Float32Array(numPillRef.current).fill(pillWidth);
    let heights = new Float32Array(numPillRef.current).fill(pillHeight);

    filteredStalksRef.current =
      selectedStalkRef.current === null
        ? stalks
        : [stalks[selectedStalkRef.current]];

    if (currentViewRef.current === "global") {
      if (stalks) {
        if (currentFabBuiltViewRef.current === TOGGLE_AS_OPTIONS.AsBuilt) {
          filteredStalksRef.current = filteredStalksRef.current.reduce(
            (acc, stalk) => [...acc, ...stalk],
            []
          );

          filteredStalksRef.current = chunkArray(filteredStalksRef.current);
        }

        filteredStalksRef.current.map((stalk, row) => {
          stalk.map((stalk, column) => {
            const x = column * (pillWidth + pillGap) + offsetX;
            const y = row * (pillHeight + rowSpacing) + offsetY;
            positions.push(x, y);
            typeCodes.push(stalk.type === "pipe" ? 0 : 1);
          });
        });
      }

      if (
        baseMaterials &&
        selectedStalkRef.current === null &&
        currentFabBuiltViewRef.current === TOGGLE_AS_OPTIONS.AsFabricated
      ) {
        const baseMaterialsStartY =
          stalks.length * (pillHeight + rowSpacing) + offsetY;

        baseMaterials.map((baseMaterial, index) => {
          const row = Math.floor(index / maxPillsPerRow);
          const column = index % maxPillsPerRow;
          const x = column * (pillWidth + pillGap) + offsetX;
          const y = baseMaterialsStartY + row * (pillHeight + rowSpacing);
          positions.push(x, y);
          typeCodes.push(baseMaterial.type === "pipe" ? 0 : 1);
        });
      }
    } else {
      const overlayCanvas = overlayCanvasRef.current;

      const {pillPositions, pipeWidths, pipeHeights} = drawDetailedView(
        canvasOffsetRef.current.x,
        canvasOffsetRef.current.y,
        zoomRef,
        overlayCanvas,
        filteredStalksRef.current
      );
      pillHeight = 30 * zoomFactor;

      positions = pillPositions;

      widths = pipeWidths;
      heights = pipeHeights;
    }

    for (let i = 0; i < positions.length / 2; i++) {
      const x = positions[i * 2] - canvasOffsetRef.current.x;
      const y = positions[i * 2 + 1] - canvasOffsetRef.current.y;
      boundaries.push(computePillBoundary(x, y, widths[i], heights[i]));
    }

    boundariesRef.current = boundaries;
    numPillRef.current = positions.length / 2;

    return {
      typeCodes,
      positions: new Float32Array(positions),
      pillsPerRow,
      widths,
      heights,
      rowSpacing
    };
  };

  const computePillBoundary = (x, y, pillWidth, pillHeight) => {
    const zoomFactor = zoomRef.current;
    const xMargin = 2 * zoomFactor;

    const boundaries = {
      left: x - xMargin,
      right: x + (pillWidth + xMargin),
      top: y,
      bottom: y + pillHeight + 1
    };

    return boundaries;
  };

  const drawLabels = (positions, pillsPerRow, widths, heights, rowSpacing) => {
    const overlayCanvas = overlayCanvasRef.current;
    const ctx = overlayCanvas?.getContext("2d");

    if (!ctx) {
      return;
    }

    const zoomFactor = zoomRef.current;
    const pillWidth = PILL_WIDTH * zoomFactor;
    const pillHeight = PILL_HEIGHT * zoomFactor;
    const pillGap = PILL_GAP * zoomFactor;

    ctx.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);

    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.font = `${10 * zoomFactor}px Arial`;
    ctx.fillStyle = "black";

    pills.length = 0;

    let index = 0;
    let lastAsBuiltPipeNumber = 0;

    filteredStalksRef.current.map((stalk, row) => {
      const rowIndex = Math.floor(row / maxPillsPerRow);
      const colIndex = index % maxPillsPerRow;
      const offsetX = canvasOffsetRef.current.x;
      const offsetY = canvasOffsetRef.current.y;
      const x = colIndex * (pillWidth + pillGap) + offsetX;
      const y = rowIndex * (pillHeight + rowSpacing) + offsetY;
      let pipeNumber = 0;

      if (currentFabBuiltViewRef.current === TOGGLE_AS_OPTIONS.AsBuilt) {
        if (row === 0) {
          ctx.fillText(
            `Spool ${rowIndex + 1}`,
            x - 50 * zoomFactor,
            y + heights[index] / 2
          );
        } else {
          pipeNumber = lastAsBuiltPipeNumber;
        }
      } else {
        ctx.fillText(
          selectedStalkRef.current === null
            ? `Stalk ${row + 1}`
            : `Stalk ${selectedStalkRef.current + 1}`,
          positions[index * 2] - 30 * zoomFactor,
          positions[index * 2 + 1] + heights[index] / 2
        );
      }

      stalk.map((item, column) => {
        const pillWidth = widths[index];
        const pillHeight = heights[index];
        const x = positions[index * 2];
        const y = positions[index * 2 + 1];

        if (item.type === "pipe") {
          if (
            pipeNumber === 0 ||
            column === stalk.length - 1 ||
            (pipeNumber + 1) % 10 === 0
          ) {
            const x = positions[index * 2] + widths[index] / 2;
            const y = positions[index * 2 + 1] - 5 * zoomFactor;
            ctx.fillText(`${pipeNumber + 1}`, x, y);
          }

          pipeNumber++;
        }

        pills.push({
          x,
          y,
          width: pillWidth,
          height: pillHeight,
          item
        });

        if (currentViewRef.current === "global") {
          if (
            row !== filteredStalksRef.current.length - 1 &&
            column === stalk.length - 1
          ) {
            drawArrow(
              overlayCanvasRef.current,
              zoomRef,
              positions[index * 2] + widths[index] / 2,
              positions[index * 2 + 1] + heights[index],
              positions[(index + 1) * 2] + widths[index] / 2,
              positions[(index + 1) * 2 + 1],
              rowSpacing,
              // TO-DO: Make arrow labels dynamic
              currentFabBuiltViewRef.current === TOGGLE_AS_OPTIONS.AsBuilt
                ? "W-XX"
                : "T-XX"
            );
          }
        }

        index++;
      });

      if (currentFabBuiltViewRef.current === TOGGLE_AS_OPTIONS.AsBuilt) {
        lastAsBuiltPipeNumber = pipeNumber;
      }
    });

    if (
      baseMaterials &&
      selectedStalkRef.current === null &&
      currentFabBuiltViewRef.current === TOGGLE_AS_OPTIONS.AsFabricated
    ) {
      const offsetX = 60 * zoomFactor;
      ctx.fillText(
        "BASE MATERIALS",
        positions[index * 2] - offsetX,
        positions[index * 2 + 1] + heights[index] / 2
      );

      baseMaterials.map((_, column) => {
        if (
          column === 0 ||
          (column + 1) % 10 === 0 ||
          column === baseMaterials.length - 1
        ) {
          const x = positions[index * 2] + widths[index] / 2;
          const y = positions[index * 2 + 1] - 5 * zoomFactor;
          ctx.fillText(`${column + 1}`, x, y);
        }

        index++;
      });
    }
  };

  const handleMouseOverPill = useCallback(
    (event) => {
      const rect = overlayCanvasRef.current.getBoundingClientRect();
      const mouseX = event.clientX - rect.left;
      const mouseY = event.clientY - rect.top;

      let foundPill = null;

      pills.forEach((pill) => {
        if (
          mouseX >= pill.x &&
          mouseX <= pill.x + pill.width &&
          mouseY >= pill.y &&
          mouseY <= pill.y + pill.height
        ) {
          foundPill = pill;
          setHoveredPill(pill);
        }
      });

      if (foundPill) {
        if (hoveredPillRef.current !== foundPill.item) {
          hoveredPillRef.current = foundPill.item;

          if (tooltipRef.current) {
            setPipeType(foundPill.item.type.toUpperCase());
            setJointName(foundPill.item.id);
            setPipe(foundPill.item.pipe);
          }
        }

        if (tooltipRef.current) {
          tooltipRef.current.style.display = "flex";
          tooltipRef.current.style.left =
            foundPill.x - TOOLTIP_WIDTH / 2.1 + "px";
          tooltipRef.current.style.top =
            event.offsetY - (TOOLTIP_HEIGHT + 20) + "px";
        }

        if (canvasRef.current) {
          canvasRef.current.style.cursor = "pointer";
        }
      } else {
        hoveredPillRef.current = null;

        if (tooltipRef.current) {
          tooltipRef.current.style.display = "none";
        }

        if (canvasRef.current) {
          canvasRef.current.style.cursor = "default";
        }
      }
    },
    [pills]
  );

  const hoveredPillRow = useMemo(() => {
    if (
      currentFabBuiltViewRef.current === TOGGLE_AS_OPTIONS.AsFabricated &&
      hoveredPill
    ) {
      return filteredStalksRef.current.find((row) =>
        row.find((weld) => weld.id === hoveredPill.item.id)
      );
    }
  }, [hoveredPill]);

  const pipePositionWidth = useCallback(() => {
    let lineLength = stalksLength;

    if (currentFabBuiltViewRef.current === TOGGLE_AS_OPTIONS.AsFabricated) {
      lineLength = hoveredPillRow?.length;
    }

    if (lineLength) {
      return Math.ceil(100 / lineLength);
    }
  }, [pills, hoveredPillRow]);

  const pillIndex = useMemo(() => {
    if (
      currentFabBuiltViewRef.current === TOGGLE_AS_OPTIONS.AsFabricated &&
      hoveredPillRow
    ) {
      return hoveredPillRow.findIndex((w) => w.id === hoveredPill.item.id);
    }

    return pills?.findIndex((p) => p?.item.id === hoveredPill?.item.id);
  }, [hoveredPill, pills]);

  const pipeLeftPosition = useCallback(() => {
    let lineLength = pills.length;
    let pillPosition = pillIndex;

    if (currentFabBuiltViewRef.current === TOGGLE_AS_OPTIONS.AsFabricated) {
      if (hoveredPillRow) {
        lineLength = hoveredPillRow.length;
        pillPosition = hoveredPillRow.findIndex(
          (w) => w.id === hoveredPill.item.id
        );
      }
    }

    if (hoveredPill) {
      return (pillIndex / lineLength) * 100;
    }
  }, [hoveredPill]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const gl = canvas.getContext("webgl");

    if (canvas) {
      canvas.addEventListener("mousemove", handleMouseOverPill);
    }

    if (!gl) {
      console.error("WebGL not supported in this browser");

      return;
    }

    const ext = gl.getExtension("ANGLE_instanced_arrays");

    if (!ext) {
      console.error("ANGLE_instanced_arrays not supported in this browser");

      return;
    }

    instancedExtRef.current = ext;

    const vertexShaderSource = `
        attribute vec2 a_position;
        attribute vec2 a_instancePosition;
        attribute float a_instanceScale;
        attribute float a_instanceType;
        uniform vec2 u_resolution;
        uniform float u_zoom;
        varying float v_instanceType;
        void main() {
          vec2 scaledPosition = a_position * a_instanceScale * u_zoom;
          vec2 position = a_instancePosition + scaledPosition ;
          vec2 zeroToClipSpace = ((position + vec2(2.5 * u_zoom, 16.0 * u_zoom)) / u_resolution) * 2.0 - vec2(1.0, 1.0);

          gl_Position = vec4(zeroToClipSpace * vec2(1, -1), 0, 1);
          v_instanceType = a_instanceType;
        }
      `;

    const fragmentShaderSource = `
        precision mediump float;
        varying float v_instanceType;
        uniform vec4 u_colorPipe;
        uniform vec4 u_colorWeld;
        void main() {
          gl_FragColor = v_instanceType < 0.5 ? u_colorPipe : u_colorWeld;
        }
      `;

    const createShader = (type, source) => {
      const shader = gl.createShader(type);
      gl.shaderSource(shader, source);
      gl.compileShader(shader);

      if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
        console.error(gl.getShaderInfoLog(shader));
        gl.deleteShader(shader);

        return null;
      }

      return shader;
    };

    const createProgram = (vertexShader, fragmentShader) => {
      const program = gl.createProgram();
      gl.attachShader(program, vertexShader);
      gl.attachShader(program, fragmentShader);
      gl.linkProgram(program);

      if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
        console.error("Program link failed", gl.getProgramInfoLog(program));
        gl.deleteProgram(program);

        return null;
      }

      return program;
    };

    const vertexShader = createShader(gl.VERTEX_SHADER, vertexShaderSource);
    const fragmentShader = createShader(
      gl.FRAGMENT_SHADER,
      fragmentShaderSource
    );
    const program = createProgram(vertexShader, fragmentShader);

    gl.useProgram(program);

    const aPosition = gl.getAttribLocation(program, "a_position");
    const aInstancePosition = gl.getAttribLocation(
      program,
      "a_instancePosition"
    );
    const aInstanceScale = gl.getAttribLocation(program, "a_instanceScale");
    const uResolution = gl.getUniformLocation(program, "u_resolution");
    const uColor = gl.getUniformLocation(program, "u_color");
    const uZoom = gl.getUniformLocation(program, "u_zoom");

    gl.uniform2f(uResolution, canvas.width, canvas.height);

    const updatePositions = () => {
      const {positions, pillsPerRow, widths, heights, rowSpacing, typeCodes} =
        createPillPositions(canvas.width, canvas.height);

      drawLabels(positions, pillsPerRow, widths, heights, rowSpacing); // Draw labels on the overlay canvas

      return {
        positions,
        pillsPerRow,
        widths,
        heights,
        typeCodes
      };
    };

    const {positions, pillsPerRow, widths, heights, typeCodes} =
      updatePositions();

    const aInstanceType = gl.getAttribLocation(program, "a_instanceType");

    const typeBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, typeBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(typeCodes), gl.STATIC_DRAW);

    gl.vertexAttribPointer(aInstanceType, 1, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(aInstanceType);
    instancedExtRef.current.vertexAttribDivisorANGLE(aInstanceType, 1);

    // CHANGE SHAPE DEPENDING ON VIEW?
    const shapeVertices = createPillVertices(zoomRef);

    const shapeBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, shapeBuffer);
    gl.bufferData(
      gl.ARRAY_BUFFER,
      new Float32Array(shapeVertices),
      gl.STATIC_DRAW
    );
    gl.vertexAttribPointer(aPosition, 2, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(aPosition);

    const instancePositionBuffer = gl.createBuffer();
    const updateInstancePositions = () => {
      const {positions, typeCodes} = updatePositions();
      gl.bindBuffer(gl.ARRAY_BUFFER, instancePositionBuffer);
      gl.bufferData(
        gl.ARRAY_BUFFER,
        new Float32Array(positions),
        gl.STATIC_DRAW
      );
      gl.bindBuffer(gl.ARRAY_BUFFER, typeBuffer);
      gl.bufferData(
        gl.ARRAY_BUFFER,
        new Float32Array(typeCodes),
        gl.STATIC_DRAW
      );
    };

    updateInstancePositions();
    gl.bindBuffer(gl.ARRAY_BUFFER, instancePositionBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(positions), gl.STATIC_DRAW);
    gl.vertexAttribPointer(aInstancePosition, 2, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(aInstancePosition);
    instancedExtRef.current.vertexAttribDivisorANGLE(aInstancePosition, 1);

    const instanceScaleBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, instanceScaleBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, scalesRef.current, gl.DYNAMIC_DRAW);
    gl.vertexAttribPointer(aInstanceScale, 1, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(aInstanceScale);
    instancedExtRef.current.vertexAttribDivisorANGLE(aInstanceScale, 1);

    const renderPipes = () => {
      if (!dirtyRef.current) return;

      gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

      const uColorPipe = gl.getUniformLocation(program, "u_colorPipe");
      const uColorWeld = gl.getUniformLocation(program, "u_colorWeld");

      gl.uniform4f(uColorPipe, 0.137, 0.396, 0.2, 1); // Blue for pipe
      gl.uniform4f(uColorWeld, 0, 0, 0, 1); // Red for weld

      gl.uniform1f(uZoom, zoomRef.current); // Apply zoom factor
      instancedExtRef.current.drawArraysInstancedANGLE(
        gl.TRIANGLE_FAN,
        0,
        shapeVertices.length / 2,
        numPillRef.current
      );

      dirtyRef.current = false;
    };

    const renderCoatedPipes = () => {
      if (!dirtyRef.current) return;

      gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

      const overlayCanvas = overlayCanvasRef.current;
      const zoomFactor = zoomRef.current;

      drawDetailedView(
        canvasOffsetRef.current.x,
        canvasOffsetRef.current.y,
        zoomRef,
        overlayCanvas,
        filteredStalksRef.current
      );
    };

    const updateScales = () => {
      let needsUpdate = false;
      for (let i = 0; i < numPillRef.current; i++) {
        const current = scalesRef.current[i];
        const target = targetScalesRef.current[i];

        if (Math.abs(target - current) > 0.01) {
          scalesRef.current[i] += (target - current) * 0.1;
          needsUpdate = true;
        } else {
          scalesRef.current[i] = target;
        }
      }

      if (needsUpdate) {
        gl.bindBuffer(gl.ARRAY_BUFFER, instanceScaleBuffer);
        gl.bufferSubData(gl.ARRAY_BUFFER, 0, scalesRef.current);
        dirtyRef.current = true;
      }
    };

    const animate = () => {
      updateInstancePositions();

      if (currentViewRef.current === "global") {
        renderPipes();
        updateScales();
      } else {
        renderCoatedPipes();
      }

      requestAnimationFrame(animate);
    };

    animate();

    return () => {
      gl.deleteProgram(program);
      gl.deleteBuffer(typeBuffer);
      gl.deleteBuffer(shapeBuffer);
      gl.deleteBuffer(instancePositionBuffer);
      gl.deleteBuffer(instanceScaleBuffer);
    };
  }, []);

  const handleMouseMove = (event) => {
    const rect = canvasRef.current.getBoundingClientRect();
    const mouseX = event.clientX - rect.left - canvasOffsetRef.current.x;
    const mouseY = event.clientY - rect.top - canvasOffsetRef.current.y;

    if (isDraggingRef.current) {
      const dx = event.clientX - dragStartRef.current.x;
      const dy = event.clientY - dragStartRef.current.y;
      canvasOffsetRef.current.x += dx;
      canvasOffsetRef.current.y += dy;
      dragStartRef.current.x = event.clientX;
      dragStartRef.current.y = event.clientY;
      dirtyRef.current = true;

      return;
    }

    let hoveredIndex = -1;
    boundariesRef.current.forEach((boundary, i) => {
      if (
        mouseX >= boundary.left &&
        mouseX <= boundary.right &&
        mouseY >= boundary.top &&
        mouseY <= boundary.bottom
      ) {
        hoveredIndex = i;
      }
    });

    if (hoveredIndex !== -1 && hoveredIndex !== hoverIndexRef.current) {
      hoverIndexRef.current = hoveredIndex;

      const currentRow = Math.floor(hoveredIndex / 100);
      const col = hoveredIndex % 100;
      for (let i = 0; i < numPillRef.current; i++) {
        const distance = Math.abs((i % 100) - col);
        targetScalesRef.current[i] =
          Math.floor(i / 100) === currentRow
            ? distance === 0
              ? 1.5
              : distance === 1
                ? 1.2
                : 1
            : 1;
      }

      dirtyRef.current = true;
    } else if (hoveredIndex === -1) {
      hoverIndexRef.current = -1;
      for (let i = 0; i < numPillRef.current; i++) {
        targetScalesRef.current[i] = 1;
      }

      dirtyRef.current = true;
    }
  };

  const handleMouseDown = (event) => {
    isDraggingRef.current = true;
    dragStartRef.current.x = event.clientX;
    dragStartRef.current.y = event.clientY;
  };

  const handleMouseUp = () => {
    isDraggingRef.current = false;
  };

  const handleWheel = (event) => {
    if (canvasRef.current.contains(event.target)) {
      event.preventDefault(); // Prevent default window scroll behavior
    }

    if (event.ctrlKey || event.metaKey) {
      const zoomFactor = 0.1;
      const rect = canvasRef.current.getBoundingClientRect();
      const mouseX = event.clientX - rect.left;
      const mouseY = event.clientY - rect.top;

      const oldZoom = zoomRef.current;
      const newZoom = Math.max(
        0.5,
        Math.min(2, oldZoom + (event.deltaY > 0 ? -zoomFactor : zoomFactor))
      );

      if (newZoom === oldZoom) return;

      // Calculate mouse position in content space
      const contentMouseX = (mouseX - canvasOffsetRef.current.x) / oldZoom;
      const contentMouseY = (mouseY - canvasOffsetRef.current.y) / oldZoom;

      // Adjust canvas offset to keep the zoom centered on the mouse position
      canvasOffsetRef.current.x -= contentMouseX * (newZoom - oldZoom);
      canvasOffsetRef.current.y -= contentMouseY * (newZoom - oldZoom);

      // Update zoom reference
      zoomRef.current = newZoom;
    } else {
      // Vertical scroll behavior
      const scrollSpeed = 50; // Adjust as needed
      canvasOffsetRef.current.y -= (event.deltaY * scrollSpeed) / 100;
      canvasOffsetRef.current.x -= (event.deltaX * scrollSpeed) / 100;
    }

    dirtyRef.current = true;
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const overlay = canvasRef.current;

    const handleCanvasWheel = (event) => {
      handleWheel(event);
    };

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      overlay.width = window.innerWidth - 296;
      zoomRef.current = 1;
      canvasOffsetRef.current = {x: 120, y: 90};

      dirtyRef.current = true;
    };

    canvas.addEventListener("wheel", handleCanvasWheel, {passive: false});
    window.addEventListener("resize", resizeCanvas, false);
    resizeCanvas();

    return () => {
      canvas.removeEventListener("wheel", handleCanvasWheel);
      window.removeEventListener("resize", resizeCanvas);
    };
  }, []);

  // Set up stalk filter options
  const stalkOptions = stalks.map((_, index) =>
    Object({name: `Stalk ${index + 1}`, value: index})
  );
  stalkOptions.push({name: "All stalks", value: stalkOptions.length});

  const handleResetView = useCallback(() => {
    canvasOffsetRef.current = {x: 120, y: 90};
    zoomRef.current = 1;
    dirtyRef.current = true;
  });

  const handleToggle = (view) => {
    handleResetView();
    currentViewRef.current = view;
  };

  const handleFabBuiltToggle = (view) => {
    handleResetView();
    currentFabBuiltViewRef.current = view;
  };

  return (
    <div style={{position: "relative", width: "100%", height: 800}}>
      <ViewToggle options={["global", "detailed"]} onToggle={handleToggle} />
      <ViewToggle
        options={[TOGGLE_AS_OPTIONS.AsFabricated, TOGGLE_AS_OPTIONS.AsBuilt]}
        onToggle={handleFabBuiltToggle}
        position={TOGGLE_DIRECTION.left}
        defaultValue={currentFabBuiltViewRef.current}
      />

      <canvas
        ref={canvasRef}
        width={window.innerWidth}
        height={800}
        style={{
          position: "absolute",
          zIndex: 0,
          cursor: isDraggingRef.current ? "grabbing" : "default",
          border: "1px solid black"
        }}
        onMouseMove={handleMouseMove}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      />
      <Box
        ref={tooltipRef}
        sx={{
          flexDirection: "column",
          position: "absolute",
          backgroundColor: "white",
          color: "black",
          padding: "8px 12px",
          height: `${TOOLTIP_HEIGHT}px`,
          width: `${TOOLTIP_WIDTH}px`,
          borderRadius: "8px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          fontSize: "14px",
          pointerEvents: "none",
          display: "none",
          zIndex: 1000,
          whiteSpace: "nowrap",
          textAlign: "left",

          "::after": {
            content: "''",
            position: "absolute",
            bottom: "-8px",
            left: "50%",
            transform: "translateX(-50%)",
            width: 0,
            height: 0,
            borderStyle: "solid",
            borderWidth: "8px 8px 0 8px",
            borderColor: "white transparent transparent transparent",
            zIndex: 1000
          }
        }}
      >
        <div style={{fontSize: "12px", color: "#757575", fontWeight: "bold"}}>
          {pipeType}
        </div>
        <div style={{fontSize: "16px", fontWeight: "bold", marginTop: "4px"}}>
          {jointName}
        </div>
        <div
          style={{
            fontSize: "12px",
            marginTop: "4px",
            color: "#757575",
            flex: 1
          }}
        >
          {pipe && `Pipe ID: ${pipe}`}
        </div>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "4px",
            backgroundColor: "#61b5e8",
            marginTop: "8px",
            borderRadius: "2px"
          }}
        >
          <div
            ref={tooltipPipePositionRef}
            style={{
              position: "absolute",
              backgroundColor: "black",
              height: "100%",
              left: pipeLeftPosition() + "%",
              width: pipePositionWidth() + "%"
            }}
          />
        </div>
      </Box>

      <canvas
        ref={overlayCanvasRef}
        width={window.innerWidth - 295}
        height={800}
        style={{
          position: "absolute",
          zIndex: 1,
          pointerEvents: "none" // Allow events to pass through to the WebGL canvas
        }}
      />
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          display: "flex",
          alignItems: "center"
        }}
      >
        <MultipleSelectPlaceholder
          options={stalkOptions}
          onSelect={(e) => {
            selectedStalkRef.current = e === stalkOptions.length - 1 ? null : e;
            dirtyRef.current = true;
          }}
        />
        <Legend />
      </Box>
    </div>
  );
};

export default WebGLCanvas;
