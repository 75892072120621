import {ROUTES} from "@core/api/routes";
import {Select} from "@core/components/Form";
import {ACTIONS} from "@core/constants/api";
import {CAMPAIGN_TYPES} from "@core/constants/campaign";
import {Grid, MenuItem} from "@mui/material";
import axios from "axios";
import {equals, keys} from "ramda";
import {observer} from "mobx-react-lite";
import React, {useState, useMemo} from "react";
import ImportWrapper from "@core/components/ImportWrapper";
import useStores from "../../../../../../../../useStores";
import {FILE_PIPE_DIRECTION} from "../../../../../../constants";

const TEST_SOURCES_BY_CAMPAIGN = {
  [CAMPAIGN_TYPES.WELDING]: {
    BevelTech: "bevelTech",
    SpoolFab: "spoolFab",
    ShawcorNDT: "ShawcorNDT"
  },
  [CAMPAIGN_TYPES.COATING]: {
    Bayou: "Bayou",
    Odysea: "Odysea"
  }
};

const TEST_SCHEMAS_BY_SOURCE = {
  [TEST_SOURCES_BY_CAMPAIGN[CAMPAIGN_TYPES.WELDING].SpoolFab]: [
    "FieldJointCoating",
    "AsBuiltRecord",
    "WeldExport",
    "AsLaidRecord"
  ],
  [TEST_SOURCES_BY_CAMPAIGN[CAMPAIGN_TYPES.WELDING].ShawcorNDT]: [
    "iLineWeldAdvisor"
  ]
};

const ImportData = ({touched, ...props}) => {
  const initialFormData = {source: "", schema: "", file: ""};
  const [formData, setFormData] = useState(initialFormData);

  // TEST
  const [error, setError] = useState(null);
  const {CampaignStore} = useStores();

  const campaign = CampaignStore.campaign;
  const sources = TEST_SOURCES_BY_CAMPAIGN[campaign.type];

  touched.current = !equals(formData, initialFormData);

  const parseFile = (data) => {
    if (
      data.get("file").type.includes("csv") &&
      !(
        formData.source.toLowerCase() === "spoolfab" &&
        formData.schema.toLowerCase() === "fieldjointcoating"
      )
    ) {
      // eslint-disable-next-line no-console
      setError("Not allowed");

      return;
    } else {
      setError(null);
    }

    data.append("direction", formData.direction);
    data.append("source", formData.source);

    if (formData.schema) {
      data.append("schema", formData.schema);
    }

    return axios.post(ROUTES.CAMPAIGN[ACTIONS.PARSE](campaign._id), data);
  };

  const hasDirection = useMemo(() => {
    if (
      formData.schema === "AsBuiltRecord" ||
      formData.schema === "AsLaidRecord"
    ) {
      return true;
    }

    return false;
  }, [formData.schema]);

  return (
    <ImportWrapper
      parseFile={parseFile}
      formData={formData}
      setFormData={setFormData}
      disabled={
        !formData.source ||
        (TEST_SCHEMAS_BY_SOURCE[formData.source] && !formData.schema)
      }
      defaultData={{tests: []}}
      {...props}
    >
      <Grid item xs={2}>
        <Select
          label="Source"
          required
          value={formData.source}
          onChange={(e) => {
            const source = e.target.value;
            const schemas = TEST_SCHEMAS_BY_SOURCE[source] || [];

            const schema = schemas.length === 1 ? schemas[0] : "";

            setFormData({...formData, source, schema});
          }}
        >
          {keys(sources).map((source) => (
            <MenuItem key={sources[source]} value={sources[source]}>
              {source}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      {formData.source && TEST_SCHEMAS_BY_SOURCE[formData.source] && (
        <Grid item xs={2}>
          <Select
            label="Schema"
            required
            value={formData.schema}
            onChange={(e) =>
              setFormData({
                ...formData,
                schema: e.target.value,
                source: formData.source
              })
            }
          >
            {TEST_SCHEMAS_BY_SOURCE[formData.source].map((schema) => (
              <MenuItem key={schema} value={schema}>
                {schema}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      )}
      {hasDirection && (
        <Grid item xs={2}>
          <Select
            label="Direction"
            required
            value={formData.direction}
            onChange={(e) => {
              const direction = e.target.value;

              setFormData({...formData, direction: direction});
            }}
          >
            {keys(FILE_PIPE_DIRECTION).map((dir) => (
              <MenuItem key={FILE_PIPE_DIRECTION[dir].value} value={FILE_PIPE_DIRECTION[dir].value}>
                {FILE_PIPE_DIRECTION[dir].label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      )}
      {error && <div>{error}</div>}
    </ImportWrapper>
  );
};

export default observer(ImportData);
