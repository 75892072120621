import React, {useState} from "react";
import {Stack, ToggleButton, ToggleButtonGroup} from "@mui/material";

export const TOGGLE_DIRECTION = {
  right: "right",
  left: "left"
};

export const Toggle = ({options, onToggle, position, defaultValue}) => {
  const [currentView, setCurrentView] = useState(defaultValue ?? options[0]);
  const isLeftPositioned = position === TOGGLE_DIRECTION.left;

  const handleChange = (event, view) => {
    if (view !== null) {
      setCurrentView(view);
      onToggle(view);
    }
  };

  return (
    <Stack
      direction="row"
      spacing={4}
      sx={{
        position: "absolute",
        top: "5px",
        left: isLeftPositioned ? "5px" : "unset",
        right: isLeftPositioned ? "unset" : "5px",
        zIndex: 5
      }}
    >
      {options && options.length > 0 && (
        <ToggleButtonGroup
          value={currentView}
          exclusive
          onChange={handleChange}
        >
          {options.map((option, index) => (
            <ToggleButton
              key={Math.floor(Math.random() * index * 100)}
              value={option}
            >
              {option}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      )}
    </Stack>
  );
};
