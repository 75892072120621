const testStandards = [
  "ASTM E190 (2014)",
  "ASTM E290 (2014)",
  "ISO 5173:2009/AMD 1:2011",
  "ISO 7438:2020"
];

const acceptances = [
  {
    id: 1,
    value: "ASME IX (2021), QW-163"
  },
  {
    id: 2,
    value: "ISO 7438:2020, Paragraph 7"
  },
  {
    id: 3,
    value: "ISO 15614-1:2017 + AMD1 + AMD2, Paragraph 7.4.2"
  },
  {
    id: 4,
    value: "ISO 15614-7:2016, Paragraph 7.5.2"
  },
  {
    id: 5,
    value: "ISO 9606-1:2012 + COR1 + COR2, Paragraph 7"
  }
];

const positions = [
  {
    id: 1,
    value: "Side bend - transversal to weld"
  },
  {
    id: 2,
    value: "Root bend - transversal to weld"
  },
  {
    id: 3,
    value: "Face bend - transversal to weld"
  },
  {
    id: 4,
    value: "Base metal bend"
  }
];

const ELEMENT = {
  position: "",
  width: "",
  height: "",
  jig: "",
  rollsDistance: "",
  angle: "",
  result: "",
  specimenId: ""
};

const EXCEPTIONS = {
  "QCP ITP-00923-124277 (NOA-BUT-00013) - HRB - MPQT": {
    company: ["Tremblay Inc.", "Test Lab"],
    requirements: {}
  },
  "QCP ITP-00923-124277 (NOA-BUT-00013) - HRB": {
    company: ["Tremblay Inc.", "Test Lab"],
    requirements: {}
  },
  "QCP ITP 030/24-130786 (YGG-BUT-00073)": {
    company: ["Tremblay Inc.", "Test Lab"],
    requirements: {}
  },
  "ITP-BPM-22.20 Rev.6": {
    company: ["Berg Pipe", "Tremblay Inc."],
    requirements: {
      elements: [
        {
          ...ELEMENT,
          position: positions[1].value
        },
        {
          ...ELEMENT,
          position: positions[2].value
        }
      ]
    }
  },
  get "ITP-BPM-22.20 Rev.6 MPQT"() {
    return this["ITP-BPM-22.20 Rev.6"];
  },
  "ITP 011/23/124277 Rev. 05": {
    company: ["Tremblay Inc."],
    requirements: {
      notes:
        "No defects exceeding 1,6 mm. Minor ductile tears less than 3 mm, originating at the specimen edge may be disregarded if not associated with obvious defects."
    }
  },
  "ITP 011/23/124277 Rev. 05 MPQT": {
    requirements: {
      notes:
        "No complete fracture. No cracks or ruptures in the fusion line longer than 3,2 mm or deeper than 12,5 % of the  specified wall thickness. No cracks at the edges of the test piece during testing longer than 6,4 mm."
    }
  }
};

export {testStandards, acceptances, positions, EXCEPTIONS, ELEMENT};
