import React, {useCallback, useEffect, useMemo, useState} from "react";
import {observer} from "mobx-react-lite";
import {Grid, Typography} from "@mui/material";
import Confirmation from "@core/components/Modal/Confirmation/Confirmation";
import Table from "@core/components/Table";
import {useHistory, useRouteMatch} from "react-router-dom";
import AddOrder from "./components/AddOrder";
import useStores from "../../useStores";
import {getColumns} from "./columns";
import {getColumns as getCampaignsColumns} from "@core/columns/campaign";

const Project = observer(() => {
  const {ProjectStore, UserStore} = useStores();

  const match = useRouteMatch();
  const history = useHistory();

  const [orderToDelete, setOrderToDelete] = useState(null);

  const {data: project, isLoaded} = ProjectStore.project;
  const projectId = match.params.id;
  const companyId = UserStore.user.data.company._id;

  const [module] = UserStore.user.data.company.modules;

  useEffect(() => {
    ProjectStore.getProjectById(projectId);
  }, [projectId]);

  const deleteOrder = useCallback(async () => {
    await ProjectStore.unlinkOrder(projectId, orderToDelete);
    setOrderToDelete(null);
  }, [orderToDelete, projectId]);

  const openOrder = useCallback(
    (order) => {
      if (companyId !== order.sender._id)
        history.push(`/suppliers/orders/${order._id}`);
      else history.push(`/transfers/${order._id}`);
    },
    [companyId]
  );

  const transferColumns = getColumns(setOrderToDelete);
  const campaignsColumns = getCampaignsColumns(module.name);

  const transfers = project.transfers || [];
  const campaigns = useMemo(() => {
    return transfers.reduce((campaigns, transfer) => {
      campaigns.push(...transfer.campaigns);

      return campaigns;
    }, []);
  }, [transfers.length]);

  return (
    <div className="padded-container">
      <Grid container spacing={3} marginBottom={3}>
        <Grid item>
          <Typography variant="h4" fontSize="1.8rem">
            Project {project.name}
          </Typography>
        </Grid>
        <Grid item>
          <AddOrder selectedTransfers={transfers} />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        {!!campaigns.length && (
          <>
            <Grid item xs={12}>
              <Typography variant="h6">Project Campaigns</Typography>
            </Grid>
            <Grid item xs={12}>
              <Table
                items={campaigns}
                columns={campaignsColumns}
                isLoaded={isLoaded}
                total={campaigns.length}
                onRowClick={(campaign) =>
                  history.push(
                    `/suppliers/campaigns/${campaign.type.toLowerCase()}/${campaign._id}`
                  )
                }
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Typography variant="h6">Project Orders</Typography>
        </Grid>
        <Grid item xs={12}>
          <Table
            items={transfers}
            columns={transferColumns}
            isLoaded={isLoaded}
            total={transfers.length}
            onRowClick={openOrder}
            noDataText="No orders."
          />
        </Grid>
      </Grid>
      <Confirmation
        open={orderToDelete}
        onCancel={() => setOrderToDelete(null)}
        onConfirm={deleteOrder}
        alertText="Are you sure you want to unlink this order?"
      />
    </div>
  );
});

export default Project;
