import useResize from "@core/hooks/useResize";
import React, {useEffect, useRef, useState} from "react";
import {values, uniq, keys} from "ramda";
import {Box, Grid, Button, Tab, Tabs, MenuItem, Menu} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Label from "./components/Label";

const SubNavigation = ({activeSubTab, setActiveSubTab, subTabs}) => {
  const containerRef = useRef();
  const tabsRefs = useRef({});

  const [invisibleIndices, setInvisibleIndices] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const {width} = useResize(containerRef);

  const subTabsKeys = keys(subTabs);

  const recalculateInvisible = () => {
    if(!width) return;

    const options = {
      root: containerRef.current,
      rootMargin: "0px",
      threshold: 1,
    };

    setInvisibleIndices([]);

    values(tabsRefs.current).forEach((element, index) => {
      const observer = new IntersectionObserver(([entry]) => {
        if(!entry.isIntersecting) {
          setInvisibleIndices((invisibleIndices) => uniq([...invisibleIndices, index]));
        } else {
          setInvisibleIndices((invisibleIndices) => invisibleIndices.filter((idx) => idx !== index));
        }
      }, options);
      observer.observe(element);
    });
  };

  useEffect(() => {
    recalculateInvisible();
  }, [width]);

  return (
    <Box
      sx={{
        paddingLeft: 2.5,
        paddingRight: 2.5,
        borderBottom: (theme) => `1px solid ${theme.palette.deactivate.backgroundColor}`,
      }}
    >
      <Grid container alignItems="center" wrap="nowrap" ref={containerRef}>
        <Grid item>
          <Tabs
            sx={{
              minHeight: "39px",
              height: "39px",
              "& button": {
                minHeight: "37.5px",
                height: "37.5px",
                marginRight: 3,
                color: "#0000008a",
              }
            }}
            scrollButtons={false}
            value={activeSubTab}
            onChange={(_, value) => setActiveSubTab(value)}
          >
            {subTabsKeys.map((key, index) => (
              <Tab
                sx={{
                  display: invisibleIndices.includes(index) ? "none" : "inherit",
                  overflow: "visible",
                }}
                ref={(element) => tabsRefs.current[index] = element}
                key={key}
                value={key}
                label={(
                  <Label
                    subTab={subTabs[key]}
                  />
                )}
              />
            ))}
          </Tabs>
        </Grid>
        {!!invisibleIndices.length && (
          <Grid item>
            <Button
              size="small"
              sx={{
                color: (theme) => theme.palette.text.primary
              }}
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              More&nbsp;<ArrowDropDownIcon />
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              {invisibleIndices.map((index) => (
                <MenuItem
                  onClick={() => {
                    setActiveSubTab(subTabsKeys[index]);
                    setAnchorEl(null);
                  }}
                >
                  {subTabs[subTabsKeys[index]]}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default SubNavigation;