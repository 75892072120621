import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";
import {Qcp} from "@core/services/qcp";
import axios from "axios";
import React, {useState} from "react";
import {Button, Grid} from "@mui/material";
import {omit, partition} from "ramda";
import TestSummaryTable from "@core/components/TestSummaryTable";
import AddNewRow from "../AddNewRow";
import {COLUMNS} from "@core/components/TestSummaryTable/constants";
import {generateID, sort} from "@core/helpers";
import {QCPS} from "@core/constants/qcps";
import {INTERNAL_LAB, STATUSES} from "@core/constants/test";
import {ORDERS} from "@core/constants/sort";
import {WITNESS_STATUSES} from "@core/constants/witnessStatuses";
import useStores from "../../../../../useStores";

const TESTS_SUMMARY_COLUMNS = [
  COLUMNS.TITLE,
  COLUMNS.QUANTITY_EDITABLE,
  COLUMNS.WITNESS_EDITABLE,
  COLUMNS.LAB_EDITABLE,
  COLUMNS.CONFIDENTIAL_EDITABLE,
  COLUMNS.TAGS
];

const QCP_WITH_STATUS_TESTS_SUMMARY_COLUMNS = [
  COLUMNS.ACTIVITY_NUMBER,
  COLUMNS.TITLE,
  COLUMNS.QUANTITY,
  COLUMNS.WITNESS_EDITABLE,
  COLUMNS.LAB_EDITABLE,
  COLUMNS.STATUS,
  COLUMNS.RESULT,
  COLUMNS.CONFIDENTIAL_EDITABLE,
  COLUMNS.TAGS
];

const Overview = ({
  selectedQcp,
  close,
  assignTests,
  onTestsCreated,
  tests,
  setSelectedTests,
  qcp,
  ...props
}) => {
  const {TestStore, UserStore} = useStores();

  const companyId = UserStore.user.data.company._id;

  const [isLoading, setIsLoading] = useState(false);

  const changeTests = (changes, testIds) => {
    const newTests = tests.map((test) =>
      testIds.includes(test._id) ? {...test, ...changes} : test
    );
    setSelectedTests(newTests);
  };

  const addTest = (type) => {
    const newTest = {
      type,
      assignee: "",
      company: companyId,
      status: STATUSES.EMPTY,
      properties: {
        tags: []
      },
      witnesses: [],
      _id: generateID()
    };

    setSelectedTests([...tests, newTest]);
  };

  const formatLabTests = (tests) => {
    return tests.map((test) =>
      omit(
        ["assignee", "witnesses", "acceptance", "acceptanceItem", "_id"],
        test
      )
    );
  };

  const formatWitnesses = (witnesses) => {
    const filteredWitnesses = witnesses.filter((witness) => !!witness?.id);

    if (!filteredWitnesses.length) return [];

    return filteredWitnesses.map((witness) => ({
      company: witness._id,
      status: WITNESS_STATUSES.UNSET
    }));
  };

  const formatProducerTests = (tests) => {
    return tests.map((test) => ({
      ...omit(["assignee", "_id"], test),
      properties: {},
      status: STATUSES.EMPTY,
      witnesses: formatWitnesses(test.witnesses)
    }));
  };

  const formatProducerTestsWithQcp = async (tests) => {
    const {data} = await axios.get(ROUTES.QCP[ACTIONS.SINGLE](qcp._id));

    const qcpInstance = new Qcp(data);

    return tests.map((test) => ({
      ...omit(["assignee", "_id"], test),
      properties: {
        ...test.properties,
        ...qcpInstance.getTestProperties(test.type)
      },
      status: STATUSES.FILLED,
      witnesses: formatWitnesses(test.witnesses)
    }));
  };

  const onCreateTests = async () => {
    setIsLoading(true);

    const [testsToAssignToProducer, testsToAssignToLab] = partition(
      (test) => !test.assignee || test.assignee === INTERNAL_LAB,
      tests
    );

    const producerTestsHandler = qcp
      ? formatProducerTestsWithQcp
      : formatProducerTests;
    const formattedTestsToAssignToProducer = await producerTestsHandler(
      testsToAssignToProducer
    );
    const formattedTestsToAssignToLab = formatLabTests(testsToAssignToLab);

    const createTestsHandler = props.createTests || TestStore.create;
    const createdTests = await createTestsHandler([
      ...formattedTestsToAssignToProducer,
      ...formattedTestsToAssignToLab
    ]);

    if (createdTests?.length) await onTestsCreated(createdTests);

    setIsLoading(false);
    close();

    if (testsToAssignToLab?.length)
      await assignTests(testsToAssignToLab, createdTests);
  };

  const columns =
    selectedQcp && QCPS[selectedQcp].producerTests
      ? QCP_WITH_STATUS_TESTS_SUMMARY_COLUMNS
      : TESTS_SUMMARY_COLUMNS;
  const testsToCreate = sort(ORDERS.ASC, "type", tests);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TestSummaryTable
          collapsible
          withGrouping
          groupByProps={!selectedQcp ? ["type"] : null}
          tests={testsToCreate}
          updateTests={changeTests}
          setSelected={setSelectedTests}
          columns={columns}
          qcp={qcp}
        >
          {!selectedQcp && <AddNewRow addTest={addTest} />}
        </TestSummaryTable>
      </Grid>
      <Grid item xs={12} container justifyContent="flex-end">
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            component="span"
            disabled={!testsToCreate.length || isLoading}
            onClick={onCreateTests}
          >
            Create
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Overview;
