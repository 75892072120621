import {getPoItemNumber} from "@core/helpers";
import React, {useState} from "react";
import {Checkbox, TableCell, TableRow, Button} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {observer} from "mobx-react";
import {keys} from "ramda";
import modules from "@core/constants/modules";
import {QCPS} from "@core/constants/qcps";
import QcpSideBar from "@core/components/QcpSideBar";
import useStores from "../../../../useStores";
import styles from "./styles";

const QcpList = observer(({qcps, setQcps, setTypes, classes}) => {
  const [openQcp, setOpenQcp] = useState(null);

  const {UserStore, CertificateStore} = useStores();

  const [module] = UserStore.user.data.company.modules;
  const certificate = CertificateStore.certificate.data;

  const poItem = getPoItemNumber(certificate.lineItem);

  const onCheck = (qcp) => {
    if (qcps.length && !qcps.includes(qcp)) return;

    if (qcps.includes(qcp)) setQcps(qcps.filter((q) => q !== qcp));
    else {
      setQcps([...qcps, qcp]);
      setTypes({});
    }
  };

  const onOpenSideBar = (e, qcp) => {
    e.stopPropagation();
    setOpenQcp(qcp);
  };

  if (module.name === modules.LAB_INTERNAL) return null;

  const companyQcps = keys(QCPS).filter((qcp) =>
    QCPS[qcp].companies.includes(UserStore.user.data.company.name)
  );

  if (!companyQcps.length) return null;

  return (
    <>
      <TableRow>
        <TableCell colSpan={2} className={classes.qcpHeader}>
          QCPs/ITPs
        </TableCell>
      </TableRow>
      {companyQcps.map((qcp) => {
        return (
          <TableRow onClick={() => onCheck(qcp)} key={qcp} hover>
            <TableCell
              width="10%"
              className={classes.rightCell}
              classes={{root: classes.checkbox}}
            >
              <Checkbox
                disabled={qcps.length && !qcps.includes(qcp)}
                color="primary"
                checked={qcps.includes(qcp)}
              />
            </TableCell>
            <TableCell
              width="90%"
              className={classes.rightCell}
              classes={{root: classes.testName}}
            >
              {qcp}&nbsp;
              <Button
                size="small"
                color="primary"
                onClick={(e) => onOpenSideBar(e, qcp)}
              >
                View
              </Button>
            </TableCell>
          </TableRow>
        );
      })}
      <QcpSideBar
        open={!!openQcp}
        close={() => setOpenQcp(null)}
        qcp={openQcp}
        poItem={poItem}
      />
    </>
  );
});

export default withStyles(QcpList, styles);
