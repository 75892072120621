import React from "react";
import {Paper} from "@mui/material";

const Legend = () => {
  const Pill = ({color, description}) => {
    return (
      <div
        style={{
          display: "flex",
          gap: "12px",
          alignItems: "center",
          padding: "0 12px",
          borderRight: "1px solid grey",
          ":lastOfType div": {
            borderRight: "1px solid transparent",
          },
        }}
      >
        <div
          style={{
            width: "60px",
            height: "10px",
            backgroundColor: color,
            borderRadius: "10px",
          }}
        />
        <div>{description}</div>
      </div>
    );
  };

  return (
    <Paper
      sx={{
        padding: "12px",
        height: "min-content",
        alignItems: "center",
        display: "flex",
      }}
    >
      <Pill color={"blue"} description={"Pipe"} />
      <Pill color={"red"} description={"Weld"} />
    </Paper>
  );
};

export default Legend;
